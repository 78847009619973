import { FC, useState } from 'react';
import styled from 'styled-components';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import uuid from 'uuid-random';
import { OtherWages } from 'services/ManagerOneApi';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { useIntl } from 'react-intl';
import { cols, Column } from './Columns';
import {
    ActionBodyCell,
    ActionCell,
    bodyCells,
    columnCells,
} from './TableCells';
import { wagesAreDesc, filterWages } from './dataHelper';

type EventHandler = (args: unknown) => void;

enum SortOrder {
    aBeforeB = -1,
    bBeforeA = 1,
    same = 0,
}

const isDescStr = (isDesc: boolean): 'asc' | 'desc' =>
    isDesc ? 'desc' : 'asc';

const TableContainerRoot = styled.div`
    padding: 0 15px 15px;
`;

const HeaderRow = styled(TableRow)`
    border-bottom: 1.1px solid #757575;
`;

type Props = {
    otherWages?: OtherWages[];
    canEdit: boolean;
};

const OtherWagesTable: FC<Props> = ({ otherWages = [], canEdit }) => {
    const { formatMessage } = useIntl();
    const [activeField, setActiveField] =
        useState<Column['field']>('EmployeeName');
    const [isDescMode, setIsDesc] = useState<boolean>(false);
    const filterBy = useSelector((s: RootState) => s.punchEditor.tableFilter);

    const sortWages = (wagesRows: OtherWages[]): OtherWages[] => {
        const cp = [...wagesRows];
        cp.sort((a: OtherWages, b: OtherWages): SortOrder => {
            const areDesc = wagesAreDesc(a, b, activeField);
            const isCorrectOrder = isDescMode === areDesc;
            return isCorrectOrder ? SortOrder.same : SortOrder.aBeforeB;
        });
        return cp;
    };

    const onClickSort =
        (field: Column['field']): EventHandler =>
        () => {
            const isTogglingDirection = field === activeField;
            setIsDesc((wasDesc) => isTogglingDirection && !wasDesc);
            setActiveField(field);
        };

    return (
        <TableContainer
            data-testid="other-wages-table"
            component={TableContainerRoot}
        >
            <Table
                size="small"
                aria-label={formatMessage({
                    id: 'App.Containers.Punch.OtherWagesTable',
                })}
            >
                <TableHead>
                    <HeaderRow>
                        {cols.map(({ field, message }) => {
                            const Cell = columnCells[field];
                            return (
                                <Cell key={field}>
                                    <TableSortLabel
                                        active={field === activeField}
                                        direction={isDescStr(
                                            field === activeField && isDescMode
                                        )}
                                        onClick={onClickSort(field)}
                                    >
                                        {message}
                                    </TableSortLabel>
                                </Cell>
                            );
                        })}
                        {canEdit && <ActionCell />}
                    </HeaderRow>
                </TableHead>
                <TableBody>
                    {sortWages(otherWages)
                        .filter(filterWages(filterBy))
                        .map((wage) => (
                            <TableRow key={wage.Id || uuid()}>
                                {cols.map(({ field }: Column) => {
                                    const Cell = bodyCells[field];
                                    return (
                                        <Cell
                                            {...wage}
                                            key={`${wage.Id}-${field}`}
                                        />
                                    );
                                })}
                                <ActionCell>
                                    {canEdit && !wage.SystemGenerated && (
                                        <ActionBodyCell
                                        // editClick={() => {}}
                                        // deleteClick={() => {}}
                                        />
                                    )}
                                </ActionCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default OtherWagesTable;
