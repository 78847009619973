/* eslint-disable react/no-unused-prop-types */
import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ErrorModal from 'components/ErrorModal';
import { IErrorMessages } from 'components/ErrorModal/messages';
import { ManagerOne } from 'services/ManagerOneApi/ManagerOne.API';
import { ROUTES } from 'routes';
import useSelect from 'state/selector';
import { setViewingManagerOneApplet } from 'state/commonSlice';
import { useDispatch } from 'react-redux';

export interface Props {
    children?: NonNullable<ReactNode>;
    requiredRoles?: ManagerOne.API.User.UserRoles[];
    isCommonEditsView?: boolean;
}

const RestrictedRoute: FC<Props> = ({
    children,
    isCommonEditsView = false,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userHasNoSites =
        useSelect((state) => state.sitesInfo.sites?.length) === 0;
    const [noSites, setNoSites] = useState(userHasNoSites);
    const location = useLocation();

    useEffect(() => {
        setNoSites(userHasNoSites);
        dispatch(setViewingManagerOneApplet(!isCommonEditsView));
    }, [userHasNoSites, navigate, location, isCommonEditsView, dispatch]);

    // The only work around I could find without removing a lot of the TS rules
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { state } = location as any;

    // We only want to load this error on the FIRST time the /home route is loaded. So, if the previous page was "/login"
    // After that, we hide the message on /home
    const isDashboardInitialLoad = state?.from === ROUTES.LOGIN;

    const isDashboard =
        location.pathname === ROUTES.DASHBOARD && !isDashboardInitialLoad;

    const noSitesErrorModal = (
        <ErrorModal
            cancelOverride={() => navigate(ROUTES.DASHBOARD, { replace: true })}
            errorType={IErrorMessages.NoSites}
        />
    );

    return (
        <>
            {noSites && !isDashboard ? (
                noSitesErrorModal
            ) : (
                <>
                    {isDashboardInitialLoad && noSites && noSitesErrorModal}
                    {children || (
                        <ErrorModal
                            pathname={location.pathname}
                            errorType={IErrorMessages.NotImplemented}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default RestrictedRoute;
