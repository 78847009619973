import { combineReducers } from 'redux';
import { store } from 'store/createStore';
import { batch } from 'react-redux';
import userSlice from './userSlice';
import dashboardSlice from './dashboardSlice';
import commonSlice from './commonSlice';
import sitesInfoSlice from './sitesInfoSlice';
import schedulerSlice from './schedulerSlice';
import punchEditorSlice from './punchEditorSlice';
import businessDaySlice from './businessDaySlice';
import depositsSlice from './depositsSlice';
import routesSlice from './routesSlice';

const slices = [
    userSlice,
    dashboardSlice,
    commonSlice,
    sitesInfoSlice,
    schedulerSlice,
    punchEditorSlice,
    businessDaySlice,
    depositsSlice,
    routesSlice,
];

type Slices = typeof slices[number];
type SliceNames = Slices['name'];
type Reducers = {
    [slice in Slices as slice['name']]: slice['reducer'];
};

export const rootReducer = combineReducers<Reducers>({
    [userSlice.name]: userSlice.reducer,
    [dashboardSlice.name]: dashboardSlice.reducer,
    [commonSlice.name]: commonSlice.reducer,
    [sitesInfoSlice.name]: sitesInfoSlice.reducer,
    [schedulerSlice.name]: schedulerSlice.reducer,
    [punchEditorSlice.name]: punchEditorSlice.reducer,
    [businessDaySlice.name]: businessDaySlice.reducer,
    [depositsSlice.name]: depositsSlice.reducer,
    [routesSlice.name]: routesSlice.reducer,
});

export function purgeReducers(
    names: SliceNames[] = [],
    inclusive = false
): void {
    batch(() => {
        slices
            // inclusive === b is similar to a conditional `!` operator, where `inclusive` negates the expression if `inclusive` were false, `inclusive` does not negate if `inclusive` were true
            .filter((slice) => inclusive === names.includes(slice.name))
            .forEach(({ actions }) => store.dispatch(actions.purge()));
    });
}

export type RootState = ReturnType<typeof rootReducer>;
