export class InternalEndpoints {
    public static urls = {
        login: 'api/Authentication/Login',
        logout: 'api/Authentication/Logout',
        recoverPassword: 'api/Authentication/RecoverPassword',
        authenticateToken: 'api/Authentication/AuthenticateToken',
        changePassword: 'api/Authentication/ChangePassword',
        authStatus: 'api/Authentication',
        portalNavigation: 'api/portalnavigation',
        reportData: 'api/reportdata',
        userData: 'api/userdata',
        utilitiesData: 'api/utilitiesdata',
        punchData: 'api/punchdata',
        scheduleData: 'api/scheduledata',
        reprint: 'api/reprint',
        businessDay: 'api/businessday',
        tipShareData: 'api/tipsharedata',
        houseAccountsData: 'api/houseAccounts',
        helpData: 'api/helpdata',
        dashboard: {
            data: 'api/dashboard/GetDashboardData',
        },
        getSites: 'api/userdata/GetSites',
        deposits: 'api/deposits',
    };
}
