import { FC } from 'react';
import styled from 'styled-components';
import { Create, Delete, Warning } from '@mui/icons-material';
import { IconButton, IconButtonProps, TableCell, Tooltip } from '@mui/material';
import { Shift } from 'services/ManagerOneApi';
import { toAmPmTime } from 'services/utils.service';
import { FormattedNumber, useIntl } from 'react-intl';
import { rawIntl } from 'services/intl';
import { Column } from './Columns';
import { shiftsTableMap } from './dataHelper';

const StyledPenaltyCell = styled(TableCell)`
    max-width: 50px;
`;

export const PenaltyPaySummaryCell: FC = ({ children }) => (
    <StyledPenaltyCell>{children}</StyledPenaltyCell>
);

const StyledNameCell = styled(TableCell)`
    width: 18%;
`;

const EmployeeNameCell: FC = ({ children }) => (
    <StyledNameCell>{children}</StyledNameCell>
);

export const EmployeeIdCell: FC = ({ children }) => (
    <TableCell>{children}</TableCell>
);

export const JobTitleCell: FC = ({ children }) => (
    <TableCell>{children}</TableCell>
);

export const TimeInCell: FC = ({ children }) => (
    <TableCell>{children}</TableCell>
);

export const TimeOutCell: FC = ({ children }) => (
    <TableCell>{children}</TableCell>
);

export const ClockedOutByEodCell: FC = ({ children }) => (
    <TableCell>{children}</TableCell>
);

const HoursCell: FC = ({ children }) => (
    <TableCell align="right">{children}</TableCell>
);

export const BreakHoursCell: FC = ({ children }) => (
    <TableCell align="right">{children}</TableCell>
);

const StyledActionCell = styled(TableCell)`
    padding: 0 !important;
    min-width: 100px;
`;

export const ActionCell: FC = ({ children }) => (
    <StyledActionCell>{children}</StyledActionCell>
);

type ColumnCells = {
    [key in Column['field']]: FC;
};

export const columnCells: ColumnCells = {
    PenaltyPaySummary: PenaltyPaySummaryCell,
    EmployeeName: EmployeeNameCell,
    EmployeeId: EmployeeIdCell,
    JobTitle: JobTitleCell,
    TimeIn: TimeInCell,
    TimeOut: TimeOutCell,
    ClockedOutByEod: ClockedOutByEodCell,
    Hours: HoursCell,
    BreakHours: BreakHoursCell,
};

type PenaltyIndicatorProps = Pick<Shift, 'PenaltyPayTotal' | 'PenaltyPays'>;
const PenaltyPaySummary: FC<PenaltyIndicatorProps> = ({
    PenaltyPays = [],
    PenaltyPayTotal = 0,
}) => (
    <PenaltyPaySummaryCell>
        {PenaltyPays.length > 0 && (
            <Tooltip
                title={rawIntl.formatNumber(
                    shiftsTableMap.PenaltyPaySummary({
                        PenaltyPayTotal,
                    }) as number,
                    {
                        style: 'currency',
                        currency: 'USD',
                    }
                )}
            >
                <Warning />
            </Tooltip>
        )}
    </PenaltyPaySummaryCell>
);

const EmployeeName: FC<Pick<Shift, 'Employee'>> = (props) => (
    <EmployeeNameCell>{shiftsTableMap.EmployeeName(props)}</EmployeeNameCell>
);

const EmployeeId: FC<Pick<Shift, 'Employee'>> = (props) => (
    <EmployeeIdCell>{shiftsTableMap.EmployeeId(props)}</EmployeeIdCell>
);

const JobTitle: FC<Pick<Shift, 'Job'>> = (props) => (
    <JobTitleCell>{shiftsTableMap.JobTitle(props)}</JobTitleCell>
);

const TimeIn: FC<Pick<Shift, 'ClockIn'>> = ({ ClockIn }) => (
    <TimeInCell>
        {ClockIn && toAmPmTime(shiftsTableMap.TimeIn({ ClockIn }) as Date)}
    </TimeInCell>
);

const TimeOut: FC<Pick<Shift, 'ClockOut'>> = ({ ClockOut }) => (
    <TimeOutCell>
        {ClockOut && toAmPmTime(shiftsTableMap.TimeOut({ ClockOut }) as Date)}
    </TimeOutCell>
);

const ClockedOutByEodBodyCell: FC<Pick<Shift, 'ClockedOutByEod'>> = (props) => (
    <ClockedOutByEodCell>
        {shiftsTableMap.ClockedOutByEod(props)}
    </ClockedOutByEodCell>
);

const HoursBodyCell: FC<Pick<Shift, 'Hours'>> = (props) => (
    <HoursCell>
        <FormattedNumber
            value={shiftsTableMap.Hours(props) as number}
            maximumFractionDigits={2}
        />
    </HoursCell>
);

const BreakHoursBodyCell: FC<Pick<Shift, 'BreakHours'>> = (props) => (
    <BreakHoursCell>
        <FormattedNumber
            value={shiftsTableMap.BreakHours(props) as number}
            maximumFractionDigits={2}
        />
    </BreakHoursCell>
);

/**
 * TODO: make click functions required after implementing edit and delete
 */
type ActionsProps = {
    editClick?: IconButtonProps['onClick'];
    deleteClick?: IconButtonProps['onClick'];
};

export const ActionBodyCell: FC<ActionsProps> = ({
    editClick,
    deleteClick,
}) => {
    const { formatMessage } = useIntl();
    const titleEditShift = formatMessage({ id: 'App.Containers.Punch.Edit' });
    const titleDeleteShift = formatMessage({
        id: 'App.Containers.Punch.Delete',
    });

    return (
        <ActionCell>
            <Tooltip title={titleEditShift}>
                <IconButton onClick={editClick}>
                    <Create />
                </IconButton>
            </Tooltip>
            <Tooltip title={titleDeleteShift}>
                <IconButton onClick={deleteClick}>
                    <Delete />
                </IconButton>
            </Tooltip>
        </ActionCell>
    );
};

type BodyCells = {
    [key in Column['field']]: FC<Shift>;
};

export const bodyCells: BodyCells = {
    PenaltyPaySummary,
    EmployeeName,
    EmployeeId,
    JobTitle,
    TimeIn,
    TimeOut,
    ClockedOutByEod: ClockedOutByEodBodyCell,
    Hours: HoursBodyCell,
    BreakHours: BreakHoursBodyCell,
};
