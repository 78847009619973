import { FC } from 'react';
import styled from 'styled-components';
import CheckFunctionBar from './CheckFunctionBar';
import CheckFilters from './CheckFilters';
import CheckTable from './CheckTable';

const Article = styled.article`
    height: calc(100vh - 72px);
    display: grid;
    grid-template-rows: auto minmax(200px, 1fr);
    padding: 0 15px 15px;
`;

const Main = styled.main`
    display: grid;
    grid-template-rows: auto minmax(200px, 1fr);
    gap: 15px;
    padding: 15px;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
        0 1px 5px 0 rgb(0 0 0 / 12%);
`;

const CheckEditor: FC = () => (
    <Article>
        <header>
            <CheckFunctionBar />
        </header>
        <Main>
            <CheckFilters />
            <CheckTable />
        </Main>
    </Article>
);

export default CheckEditor;
