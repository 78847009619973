import { FC } from 'react';
import { Deposit } from 'services/ManagerOneApi';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import useSelect from 'state/selector';
import moment from 'moment';
import FunctionBar from './FunctionBar';
import TableContents from './TableContents';
import DepositsDialog from './Dialog';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 72px);
`;

const Article = styled.article`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
    padding: 24px;
`;

export type Props = {
    daysToBackEdit?: number;
    deposits: Deposit[];
    fetchDeposits: () => Promise<void>;
    firstBusinessDay: string;
    lastBusinessDay: string;
};

const EditDeposits: FC<Props> = ({
    daysToBackEdit,
    deposits,
    fetchDeposits,
    firstBusinessDay,
    lastBusinessDay,
}) => {
    const businessDay = useSelect((s) => s.deposits.businessDay);

    const canEdit =
        !daysToBackEdit ||
        moment(lastBusinessDay)
            .subtract(daysToBackEdit, 'days')
            .isSameOrBefore(businessDay);

    return (
        <Container data-testid="editDepositContent">
            {!canEdit && (
                <section role="banner" className="banner-message">
                    <FormattedMessage id="App.Containers.EditDeposits.ViewOnlyAlert" />
                </section>
            )}
            <DepositsDialog />
            <Article>
                <FunctionBar
                    canEdit={canEdit}
                    fetchDeposits={fetchDeposits}
                    firstBusinessDay={firstBusinessDay}
                    lastBusinessDay={lastBusinessDay}
                />
                <TableContents canEdit={canEdit} deposits={deposits} />
            </Article>
        </Container>
    );
};

export default EditDeposits;
