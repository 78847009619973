import { FC } from 'react';
import { LocalizationProvider } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import CheckEditor from './Editor';

// TODO: Invoke api from here
const GuestCheckRouter: FC = () => (
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <CheckEditor />
    </LocalizationProvider>
);

export default GuestCheckRouter;
