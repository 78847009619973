import {
    BusinessDayRangeResponse,
    GetPunchEditorDataResponse,
} from 'services/ManagerOneApi';

export const mockBusinessDaysRange = new BusinessDayRangeResponse({
    FirstBusinessDay: '2020-07-20T00:00:00',
    LastBusinessDay: '2021-10-06T00:00:00',
    Header: { Success: true, Statuses: [] },
});

export const mockPunchEditorData = new GetPunchEditorDataResponse({
    Header: {
        Success: true,
        Statuses: [],
    },
    OtherWages: {
        OtherWages: [
            {
                PayAdjustment: {
                    Id: '909',
                    Name: 'Paid break consent premium',
                    Type: 1,
                },
                Employee: {
                    EmployeeId: '50100',
                    Name: 'Betty  Employee',
                    IsTerminated: false,
                    Jobs: [
                        {
                            JobCodeId: '5',
                            PayRate: 3.33,
                            JobTitle: 'Server',
                            BreakConfiguration: 0,
                            IsValidJob: true,
                        },
                        {
                            JobCodeId: '13',
                            PayRate: 7.75,
                            JobTitle: 'Cashier-DC',
                            BreakConfiguration: 0,
                            IsValidJob: true,
                        },
                    ],
                },
                Hours: 0.0,
                Amount: 7.25,
                SystemGenerated: true,
            },
            {
                PayAdjustment: {
                    Id: '913',
                    Name: 'Spread of Hours Premium',
                    Type: 1,
                },
                Employee: {
                    EmployeeId: '50100',
                    Name: 'Betty  Employee',
                    IsTerminated: false,
                    Jobs: [
                        {
                            JobCodeId: '5',
                            PayRate: 3.33,
                            JobTitle: 'Server',
                            BreakConfiguration: 0,
                            IsValidJob: true,
                        },
                        {
                            JobCodeId: '13',
                            PayRate: 7.75,
                            JobTitle: 'Cashier-DC',
                            BreakConfiguration: 0,
                            IsValidJob: true,
                        },
                    ],
                },
                Hours: 0.0,
                Amount: 7.25,
                SystemGenerated: true,
            },
            {
                Id: '1048577',
                PayAdjustment: {
                    Id: '7',
                    Name: 'Jury Duty',
                    Type: 0,
                },
                Employee: {
                    EmployeeId: '50112',
                    Name: 'Fred  Employee',
                    IsTerminated: false,
                    Jobs: [
                        {
                            JobCodeId: '5',
                            PayRate: 17.25,
                            JobTitle: 'Server',
                            BreakConfiguration: 0,
                            IsValidJob: true,
                        },
                    ],
                },
                Job: {
                    JobCodeId: '5',
                    PayRate: 0.0,
                    JobTitle: 'Server',
                    BreakConfiguration: 0,
                    IsValidJob: false,
                },
                Hours: 4.0,
                Amount: 0.0,
            },
            {
                PayAdjustment: {
                    Id: '913',
                    Name: 'Spread of Hours Premium',
                    Type: 1,
                },
                Employee: {
                    EmployeeId: '50200',
                    Name: 'Manager  Manager',
                    IsTerminated: false,
                    Jobs: [
                        {
                            JobCodeId: '1',
                            PayRate: 0.0,
                            JobTitle: 'Manager',
                            BreakConfiguration: 0,
                            IsValidJob: true,
                        },
                        {
                            JobCodeId: '2',
                            PayRate: 0.0,
                            JobTitle: 'GM',
                            BreakConfiguration: 0,
                            IsValidJob: true,
                        },
                    ],
                },
                Hours: 0.0,
                Amount: 7.25,
                SystemGenerated: true,
            },
            {
                PayAdjustment: {
                    Id: '913',
                    Name: 'Spread of Hours Premium',
                    Type: 1,
                },
                Employee: {
                    EmployeeId: '50600',
                    Name: 'Cook  Cook',
                    IsTerminated: false,
                    Jobs: [
                        {
                            JobCodeId: '1',
                            PayRate: 25.0,
                            JobTitle: 'Manager',
                            BreakConfiguration: 0,
                            IsValidJob: true,
                        },
                        {
                            JobCodeId: '18',
                            PayRate: 22.0,
                            JobTitle: 'Cook',
                            BreakConfiguration: 0,
                            IsValidJob: true,
                        },
                    ],
                },
                Hours: 0.0,
                Amount: 7.25,
                SystemGenerated: true,
            },
        ],
        OtherWageBasicTypes: [
            {
                Id: '1',
                Name: 'Bonus',
                Type: 1,
            },
            {
                Id: '6',
                Name: 'Garnishment',
                Type: 1,
            },
            {
                Id: '7',
                Name: 'Jury Duty',
                Type: 0,
            },
            {
                Id: '5',
                Name: 'Meetings',
                Type: 0,
            },
            {
                Id: '25',
                Name: 'Misc. Pay',
                Type: 1,
            },
            {
                Id: '8',
                Name: 'OUTSTANDING PERFORMANCE AWARD',
                Type: 2,
            },
            {
                Id: '2',
                Name: 'Sick Leave',
                Type: 1,
            },
            {
                Id: '4',
                Name: 'Training',
                Type: 1,
            },
            {
                Id: '3',
                Name: 'Vacation',
                Type: 1,
            },
        ],
    },
    Shifts: {
        Shifts: [
            {
                Id: '2021-10-06T00:00:00Z-215177861529600',
                BusinessDay: '2021-10-06T00:00:00',
                ClockIn: '2021-10-06T12:19:00',
                ClockOut: '2021-12-02T09:21:00',
                Employee: {
                    EmployeeId: '50100',
                    Name: 'Betty  Employee',
                    IsTerminated: false,
                    Jobs: [
                        {
                            JobCodeId: '13',
                            PayRate: 7.75,
                            JobTitle: 'Cashier-DC',
                            BreakConfiguration: 2,
                            IsValidJob: true,
                        },
                        {
                            JobCodeId: '5',
                            PayRate: 3.33,
                            JobTitle: 'Server',
                            BreakConfiguration: 3,
                            IsValidJob: true,
                        },
                    ],
                },
                Job: {
                    JobCodeId: '5',
                    PayRate: 3.33,
                    JobTitle: 'Server',
                    BreakConfiguration: 3,
                    IsValidJob: true,
                },
                Hours: 1365.0333333333333,
                BreakHours: 0.0,
                ClockedOutByEod: true,
                DeclaredTips: 0.0,
                Breaks: [],
                PenaltyPays: [
                    {
                        BreakRuleName: 'Paid (Penalty / OT)',
                        Amount: 7.25,
                    },
                    {
                        BreakRuleName: 'Unpaid Break',
                        Amount: 0.0,
                    },
                    {
                        BreakRuleName: 'Paid - Default',
                        Amount: 0.0,
                    },
                ],
                PenaltyPayTotal: 7.25,
                PayRate: 3.33,
                TippableSales: 206.69,
            },
            {
                Id: '2021-10-06T00:00:00Z-215607358259200',
                BusinessDay: '2021-10-06T00:00:00',
                ClockIn: '2021-10-06T12:18:00',
                ClockOut: '2021-12-02T09:21:00',
                Employee: {
                    EmployeeId: '50200',
                    Name: 'Manager  Manager',
                    IsTerminated: false,
                    Jobs: [
                        {
                            JobCodeId: '2',
                            PayRate: 0.0,
                            JobTitle: 'GM',
                            BreakConfiguration: 1,
                            IsValidJob: true,
                        },
                        {
                            JobCodeId: '1',
                            PayRate: 0.0,
                            JobTitle: 'Manager',
                            BreakConfiguration: 2,
                            IsValidJob: true,
                        },
                    ],
                },
                Job: {
                    JobCodeId: '1',
                    PayRate: 0.0,
                    JobTitle: 'Manager',
                    BreakConfiguration: 2,
                    IsValidJob: true,
                },
                Hours: 1365.05,
                BreakHours: 19.016666666666666,
                ClockedOutByEod: true,
                DeclaredTips: 0.0,
                Breaks: [
                    {
                        Id: '2021-10-06T00:00:00Z-1048577',
                        ClockIn: '2021-10-06T12:18:00',
                        ClockOut: '2021-10-07T07:19:00',
                        BreakRuleId: '2',
                    },
                ],
                PenaltyPays: [],
                PenaltyPayTotal: 0.0,
                PayRate: 0.0,
                TippableSales: 103.25,
            },
            {
                Id: '2021-10-06T00:00:00Z-217325345177600',
                BusinessDay: '2021-10-06T00:00:00',
                ClockIn: '2021-11-15T08:28:00',
                ClockOut: '2021-12-02T09:19:00',
                Employee: {
                    EmployeeId: '50600',
                    Name: 'Cook  Cook',
                    IsTerminated: false,
                    Jobs: [
                        {
                            JobCodeId: '18',
                            PayRate: 22.0,
                            JobTitle: 'Cook',
                            BreakConfiguration: 2,
                            IsValidJob: true,
                        },
                        {
                            JobCodeId: '1',
                            PayRate: 25.0,
                            JobTitle: 'Manager',
                            BreakConfiguration: 2,
                            IsValidJob: true,
                        },
                    ],
                },
                Job: {
                    JobCodeId: '18',
                    PayRate: 22.0,
                    JobTitle: 'Cook',
                    BreakConfiguration: 2,
                    IsValidJob: true,
                },
                Hours: 408.85,
                BreakHours: 0.0,
                ClockedOutByEod: true,
                DeclaredTips: 0.0,
                Breaks: [],
                PenaltyPays: [
                    {
                        BreakRuleName: 'Unpaid Break',
                        Amount: 0.0,
                    },
                ],
                PenaltyPayTotal: 0.0,
                PayRate: 22.0,
                TippableSales: 0.0,
            },
        ],
        Employees: [
            {
                EmployeeId: '60405',
                Name: 'Adam (TS) Crum',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: 0.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 0.0,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60050',
                Name: 'AO Interface Employee  AO Interface Employee',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: 0.0,
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50108',
                Name: 'Bailey  Simmons',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '20',
                        PayRate: 7.5,
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 1.5,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50301',
                Name: 'Bartender  Bartender',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '6',
                        PayRate: 0.0,
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50300',
                Name: 'Bartender  Bartender',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '8',
                        PayRate: 11.0,
                        JobTitle: 'Barista',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '6',
                        PayRate: 10.75,
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50100',
                Name: 'Betty  Employee',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '13',
                        PayRate: 7.75,
                        JobTitle: 'Cashier-DC',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 3.33,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60416',
                Name: 'Bill (TS) Seale',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: 0.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60422',
                Name: 'Brent  Jepsen',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: 0.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50190',
                Name: 'Brittany  Ellington',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '8',
                        PayRate: 16.0,
                        JobTitle: 'Barista',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '6',
                        PayRate: 15.25,
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '18',
                        PayRate: 18.0,
                        JobTitle: 'Cook',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '17',
                        PayRate: 8.25,
                        JobTitle: 'Dispatcher',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: 7.25,
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 13.3,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60503',
                Name: 'brown  beth',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '4',
                        PayRate: 5.25,
                        JobTitle: 'Cashier',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '18',
                        PayRate: 5.0,
                        JobTitle: 'Cook',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50201',
                Name: 'Bruce  Springsteen',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '2',
                        PayRate: 0.0,
                        JobTitle: 'GM',
                        BreakConfiguration: 1,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '1',
                        PayRate: 0.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50109',
                Name: 'Christie  Carpenter',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '20',
                        PayRate: 8.5,
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 8.25,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50400',
                Name: 'Cocktail  Cocktail',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '3',
                        PayRate: 8.75,
                        JobTitle: 'Cocktail',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50600',
                Name: 'Cook  Cook',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '18',
                        PayRate: 22.0,
                        JobTitle: 'Cook',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '1',
                        PayRate: 25.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50191',
                Name: 'Daniella  Ramos',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '8',
                        PayRate: 15.5,
                        JobTitle: 'Barista',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '6',
                        PayRate: 15.25,
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '4',
                        PayRate: 7.5,
                        JobTitle: 'Cashier',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '17',
                        PayRate: 5.25,
                        JobTitle: 'Dispatcher',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '16',
                        PayRate: 5.25,
                        JobTitle: 'Driver',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: 7.25,
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 13.3,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60400',
                Name: 'Darlene (TS) Webb',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: 0.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50102',
                Name: 'Deferred Tips  Cashier',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '7',
                        PayRate: 8.75,
                        JobTitle: 'Cashier-DT',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 5.25,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50500',
                Name: 'Driver  Driver',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '16',
                        PayRate: 7.45,
                        JobTitle: 'Driver',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60500',
                Name: 'Driver 2  Driver 2',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '16',
                        PayRate: 0.0,
                        JobTitle: 'Driver',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50000',
                Name: 'EDC  EDC',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: 0.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60411',
                Name: 'Felipe  Segura',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: 0.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50112',
                Name: 'Fred  Employee',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '5',
                        PayRate: 17.25,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60421',
                Name: 'ImportTest  AGM',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '6',
                        PayRate: 0.0,
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '1',
                        PayRate: 0.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 0.0,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50110',
                Name: 'Katie  Cavanaugh',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '20',
                        PayRate: 9.75,
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50200',
                Name: 'Manager  Manager',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '2',
                        PayRate: 0.0,
                        JobTitle: 'GM',
                        BreakConfiguration: 1,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '1',
                        PayRate: 0.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50192',
                Name: 'Marshall  Davis',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '8',
                        PayRate: 16.0,
                        JobTitle: 'Barista',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '6',
                        PayRate: 15.25,
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60502',
                Name: 'Mary  Test',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '19',
                        PayRate: 6.0,
                        JobTitle: 'Busser',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '11',
                        PayRate: 5.25,
                        JobTitle: 'test',
                        BreakConfiguration: 1,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60410',
                Name: 'Matt (TS) Ginley',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: 0.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60011',
                Name: 'Mobile Pay  Server 1',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '100',
                        PayRate: 0.0,
                        JobTitle: 'Mobile Pay',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60450',
                Name: 'Mobile Pay  Server 1',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '101',
                        PayRate: 0.0,
                        JobTitle: 'Mobile Pay',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60012',
                Name: 'Mobile Pay  Server 2',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '100',
                        PayRate: 0.0,
                        JobTitle: 'Mobile Pay',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60013',
                Name: 'Mobile Pay  Server 3',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '100',
                        PayRate: 0.0,
                        JobTitle: 'Mobile Pay',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60420',
                Name: 'Nicole (TS) Mills',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: 0.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '70800',
                Name: 'Order  Taker',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '4',
                        PayRate: 0.0,
                        JobTitle: 'Cashier',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '99',
                        PayRate: 6.0,
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 5.25,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50101',
                Name: 'Order  Taker',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '4',
                        PayRate: 0.0,
                        JobTitle: 'Cashier',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '13',
                        PayRate: 7.75,
                        JobTitle: 'Cashier-DC',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '99',
                        PayRate: 6.0,
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 5.25,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50103',
                Name: 'Oscar  Smith',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '19',
                        PayRate: 7.25,
                        JobTitle: 'Busser',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '13',
                        PayRate: 7.75,
                        JobTitle: 'Cashier-DC',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '16',
                        PayRate: 5.25,
                        JobTitle: 'Driver',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: 7.25,
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 5.25,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50290',
                Name: 'Penelope  Montgomery',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '6',
                        PayRate: 13.75,
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '2',
                        PayRate: 0.0,
                        JobTitle: 'GM',
                        BreakConfiguration: 1,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '1',
                        PayRate: 0.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50107',
                Name: 'Retired Colonel  George',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '19',
                        PayRate: 7.25,
                        JobTitle: 'Busser',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: 7.25,
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 8.25,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60413',
                Name: 'Scott  West',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: 0.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60415',
                Name: 'Scott (TS) Weeks',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: 0.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50105',
                Name: 'Suzie  Dressage',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '19',
                        PayRate: 7.25,
                        JobTitle: 'Busser',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '16',
                        PayRate: 5.25,
                        JobTitle: 'Driver',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: 7.25,
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 5.25,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60000',
                Name: 'Takeout  ATO Interface Employee',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: 0.0,
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '59999',
                Name: 'Takeout  ATO Training Interface Emp',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: 0.0,
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60001',
                Name: 'Takeout  Interface1',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: 0.0,
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60010',
                Name: 'Takeout  Interface10',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: 0.0,
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60002',
                Name: 'Takeout  Interface2',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: 0.0,
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60003',
                Name: 'Takeout  Interface3',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: 0.0,
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60004',
                Name: 'Takeout  Interface4',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: 0.0,
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60005',
                Name: 'Takeout  Interface5',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: 0.0,
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60006',
                Name: 'Takeout  Interface6',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: 0.0,
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60007',
                Name: 'Takeout  Interface7',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: 0.0,
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60008',
                Name: 'Takeout  Interface8',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: 0.0,
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60009',
                Name: 'Takeout  Interface9',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: 0.0,
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60101',
                Name: 'Takeout  Tr Interface1',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: 0.0,
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60110',
                Name: 'Takeout  Tr Interface10',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: 0.0,
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60102',
                Name: 'Takeout  Tr Interface2',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: 0.0,
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60103',
                Name: 'Takeout  Tr Interface3',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: 0.0,
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60104',
                Name: 'Takeout  Tr Interface4',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: 0.0,
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60105',
                Name: 'Takeout  Tr Interface5',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: 0.0,
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60106',
                Name: 'Takeout  Tr Interface6',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: 0.0,
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60107',
                Name: 'Takeout  Tr Interface7',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: 0.0,
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60108',
                Name: 'Takeout  Tr Interface8',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: 0.0,
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60109',
                Name: 'Takeout  Tr Interface9',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: 0.0,
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60501',
                Name: 'Tes  Ally',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '20',
                        PayRate: 6.0,
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '11',
                        PayRate: 5.25,
                        JobTitle: 'test',
                        BreakConfiguration: 1,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '78204',
                Name: 'Test78204  Employee',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '4',
                        PayRate: 1.25,
                        JobTitle: 'Cashier',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '60412',
                Name: 'Thomas (TS) Nguyen',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: 0.0,
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50104',
                Name: 'Tom  Wilson',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '19',
                        PayRate: 7.25,
                        JobTitle: 'Busser',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '13',
                        PayRate: 7.75,
                        JobTitle: 'Cashier-DC',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '16',
                        PayRate: 5.25,
                        JobTitle: 'Driver',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: 7.25,
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 5.25,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '70801',
                Name: 'Tommy  Minor',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '6',
                        PayRate: 11.75,
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '19',
                        PayRate: 7.25,
                        JobTitle: 'Busser',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: 7.25,
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 8.25,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50106',
                Name: 'Tommy  Minor',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '6',
                        PayRate: 11.75,
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '19',
                        PayRate: 7.25,
                        JobTitle: 'Busser',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: 7.25,
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 8.25,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            {
                EmployeeId: '50111',
                Name: 'Wilma  Employee',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '17',
                        PayRate: 9.75,
                        JobTitle: 'Dispatcher',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: 3.33,
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
        ],
        OpenTime: '2021-10-06T06:00:00',
        CloseTime: '2021-10-07T06:00:00',
        BusinessDay: '2021-10-06T04:00:00.000Z',
        Reasons: [],
        EditsAllowedWeeksBack: 4,
        BreakTypes: [
            {
                Id: '1',
                Name: 'Paid (Penalty / OT)',
                Paid: true,
            },
            {
                Id: '2',
                Name: 'Unpaid Break',
            },
            {
                Id: '4',
                Name: 'Paid Break (Minors Only)',
                Paid: true,
            },
            {
                Id: '5',
                Name: 'Paid - Default',
                Paid: true,
            },
        ],
    },
});
