import { ReactNode } from 'react';
import { LoaderMask as Fallback } from '../LoaderMask';

export { Fallback };

export interface Props {
    loading: boolean;
    fallback?: ReactNode;
    children: NonNullable<ReactNode>;
}

/**
 * If loading is true, then render the overlay and loading icon on top of the children.
 *
 * @example
 * // Abstracts this
 * <>
 *      {loading && (
 *          <MaskingOverlay>
 *               <Loader>
 *          </MaskingOverlay>
 *      )}
 *      <RealComponent {...props}>
 *          What are you looking at?
 *      </RealComponent>
 * </>
 *
 * // to
 * <LoadWithMask loading={loading}>
 *     <RealComponent {...props}>
 *         What are you looking at?
 *     </RealComponent>
 * </LoadWithMask>
 */
const LoadWithMask = ({
    loading,
    fallback = <Fallback />,
    children,
}: Props): JSX.Element => (
    <>
        {loading && fallback}
        <>{children}</>
    </>
);

export default LoadWithMask;
