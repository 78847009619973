import config from './config.json';

export interface Config {
    environment: 'DEV' | 'QA' | 'PREP' | 'PROD';
    // Application variables
    applicationUrl: string;

    // Secrets
    muiLicenseKey?: string;
    // Optional Dev variables
    user?: string;
    password?: string;
    org?: string;
}
export default config as unknown as Config;
