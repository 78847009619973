// https://mui.com/customization/breakpoints/#custom-breakpoints
// These are official MUI default Breakpoints
// export const X_SMALL = '400px';
export const SMALL = '400px';
export const MEDIUM = '900px';
export const LARGE = '1200px';
export const X_LARGE = '1536px';

// For Theming, Each breakpoint (a key) matches with a fixed screen width (a value):

// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px
