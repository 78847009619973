import { FC } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
import { IconButton, Tooltip } from '@mui/material';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { VoidDetails } from 'services/ManagerOneApi';
import { rawIntl } from 'services/intl';
import { VoidsTable, VoidsColumn, FormattedVoidDetails } from './Table';
import { IDashboardData, DashboardCard } from '..';

const SectionTitle = styled.div`
    display: flex;
    justify-content: space-between;
`;

const i18n = {
    reason: <FormattedMessage id="App.Containers.VoidsCard.Reason" />,
    quantity: <FormattedMessage id="App.Containers.VoidsCard.Quantity" />,
    amount: <FormattedMessage id="Common.Amount" />,
    totalReason: rawIntl.formatMessage({ id: 'Common.Total' }),
    title: <FormattedMessage id="App.Containers.VoidsCard.Title" />,
    viewDetails: <FormattedMessage id="App.Containers.VoidsCard.ViewDetails" />,
    reasonOther: rawIntl.formatMessage({
        id: 'App.Containers.VoidsCard.OtherReason',
    }),
};

const cols: VoidsColumn[] = [
    { field: 'Reason', headerName: i18n.reason },
    { field: 'Quantity', headerName: i18n.quantity },
    { field: 'Amount', headerName: i18n.amount },
];

const formatVoidsDetails = ({
    Reason,
    Quantity,
    Amount,
}: VoidDetails): FormattedVoidDetails => ({
    Reason,
    Quantity: <FormattedNumber value={Quantity} />,
    Amount: <FormattedNumber value={Amount} style="currency" currency="USD" />,
});

const condenseVoids = ([init, ...rest]: VoidDetails[]): VoidDetails =>
    rest.reduce<VoidDetails>(
        (acc, cur) => ({
            Reason: i18n.reasonOther,
            Quantity: acc.Quantity + cur.Quantity,
            Amount: acc.Amount + cur.Amount,
        }),
        init
    );

// assumes incoming voids data is already sorted by Qty
const truncateVoids = (voids: VoidDetails[]): VoidDetails[] => {
    const maxLen = 4;
    const maxIndex = maxLen - 1;
    if (voids?.length > maxLen) {
        const kept = voids.slice(0, maxIndex);
        const toBeCondensed = voids.slice(maxIndex);
        return [...kept, condenseVoids(toBeCondensed)];
    }
    return voids;
};

const VoidsCard: FC<IDashboardData> = ({ dashboardData }) => {
    const TotalAmount = dashboardData?.Voids?.TotalAmount || 0;
    const TotalQuantity = dashboardData?.Voids?.TotalQuantity || 0;
    const Voids = dashboardData?.Voids?.Voids || null;
    const goToVoidsReport = () => {
        // TODO: send some request with { ...param, StartDay: BusinessDay }
        // Then navigate to page `/report-generator/preview/${request.ParamsId}`
        // Keep this a closure, if this involves hooks
    };

    const loading = useSelector((state: RootState) => state.common.loading);
    const hasVoids = TotalQuantity && TotalAmount && Voids?.length;
    const total = formatVoidsDetails({
        Reason: i18n.totalReason,
        Quantity: TotalQuantity,
        Amount: TotalAmount,
    });

    const compactVoids = truncateVoids(Voids);
    const voids = compactVoids?.map(formatVoidsDetails);

    return (
        <DashboardCard>
            {!loading && (
                <CardContent>
                    <SectionTitle>
                        <Typography
                            fontWeight="500"
                            component="div"
                            fontSize="18px"
                            marginBottom="25px"
                            data-testid="voids-heading"
                        >
                            {i18n.title}
                        </Typography>
                        <Tooltip title={i18n.viewDetails}>
                            <span // required for disabling button inside tool tip
                            >
                                <IconButton
                                    disabled={!hasVoids}
                                    onClick={goToVoidsReport}
                                    color="inherit"
                                    data-testid="view-details-button"
                                >
                                    <DescriptionIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </SectionTitle>
                    <VoidsTable
                        voidsRows={voids}
                        totalsRow={total}
                        cols={cols}
                    />
                </CardContent>
            )}
        </DashboardCard>
    );
};

export default VoidsCard;
