import { FC, useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment-timezone';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import * as ManagerOneData from 'services/ManagerOneApi/ManagerOne.API';
import TimePicker from '@mui/lab/DesktopTimePicker';
import { useDispatch, useSelector } from 'react-redux';
import { setShiftData, setShiftErrors } from 'state/schedulerSlice';
import { RootState } from 'state/reducers';
import { GetScheduleDataResponse } from 'services/ManagerOneApi';
import { rawIntl } from 'services/intl';
import { daysOfWeek } from '../schedulerHelper';

const { formatMessage } = rawIntl;

interface IProps {
    shift: number;
    employee: ManagerOneData.ManagerOne.API.Labor.Models.Employee;
    scheduleData: GetScheduleDataResponse;
    day: typeof daysOfWeek[number];
    dateOfCell: moment.Moment;
    readonly: boolean;
}

const messages = {
    rangeValidation: formatMessage({
        id: 'App.Containers.Scheduler.Validation.Range',
    }),
    shiftOverlap: formatMessage({
        id: 'App.Containers.Scheduler.Validation.ShiftOverlap',
    }),
    shiftOverlapReassign: formatMessage({
        id: 'App.Containers.Scheduler.Validation.ShiftOverlapReassign',
    }),
    hours: formatMessage({
        id: 'App.Containers.Scheduler.Hours',
    }),
    job: formatMessage({
        id: 'App.Containers.Scheduler.Job',
    }),
    startTime: formatMessage({
        id: 'App.Containers.Scheduler.ShiftStart',
    }),
    endTime: formatMessage({
        id: 'App.Containers.Scheduler.ShiftEnd',
    }),
    eligibleReassign: formatMessage({
        id: 'App.Containers.Scheduler.EligibleReassign',
    }),
};

const timeError = (
    <Typography mb={1} sx={{ color: '#b00200' }} variant="caption">
        {messages.rangeValidation}
    </Typography>
);

const overlapError = (
    <Typography mb={1} sx={{ color: '#b00200' }} variant="caption">
        {messages.shiftOverlap}
    </Typography>
);

const ShiftModal: FC<IProps> = ({
    shift,
    employee,
    scheduleData,
    day,
    dateOfCell,
    readonly,
}) => {
    const initialLoad = useRef(true);

    if (employee === undefined)
        // eslint-disable-next-line no-param-reassign
        employee = new ManagerOneData.ManagerOne.API.Labor.Models.Employee({});

    const dispatch = useDispatch();

    const shiftData = useSelector(
        (state: RootState) => state.scheduler.shiftData
    );
    const selectedShift = useSelector(
        (state: RootState) => state.scheduler.selectedScheduleShift
    );

    const startTime = useSelector(
        (state: RootState) => state.scheduler.shiftData[shift]?.startTime
    );
    const endTime = useSelector(
        (state: RootState) => state.scheduler.shiftData[shift]?.endTime
    );
    const timezone = useSelector(
        (state: RootState) =>
            state.dashboard.dashboardData?.Weather?.Site?.SiteTimeZone
    );

    const [timeDifference, setTimeDifference] = useState<string | number>(0);

    const [employeeOptions, setEmployeeOptions] = useState<
        ManagerOneData.ManagerOne.API.Labor.Models.Scheduler.EmployeeSchedule[]
    >([]);

    const reassignedEmployee = scheduleData.EmployeeSchedules.find(
        (x) => x.Employee.EmployeeId === shiftData[shift]?.reassignedEmployee
    );

    const assignmentError = (
        <Typography mb={1} sx={{ color: '#b00200' }} variant="caption">
            <FormattedMessage
                id="App.Containers.Scheduler.Validation.ShiftOverlapReassign"
                values={{ employee: reassignedEmployee?.Employee.Name }}
            />
        </Typography>
    );

    const shiftErrors = useSelector(
        (state: RootState) => state.scheduler.shiftErrors
    );

    const setSelectedJob = (job: string | null) => {
        const newShiftData = [...shiftData];
        newShiftData[shift] = {
            ...newShiftData[shift],
            job,
        };
        dispatch(setShiftData(newShiftData));
    };

    const checkTimeOverlaps = (
        newShiftsStart: moment.Moment,
        newShiftsEnd: moment.Moment,
        otherShiftsStart: moment.Moment,
        otherShiftsEnd: moment.Moment
    ): boolean => {
        const shiftOverlaps =
            newShiftsStart.isBetween(
                otherShiftsStart,
                otherShiftsEnd,
                'minutes',
                '[]'
            ) ||
            newShiftsEnd.isBetween(
                otherShiftsStart,
                otherShiftsEnd,
                'minutes',
                '[]'
            ) ||
            otherShiftsStart.isBetween(
                newShiftsStart,
                newShiftsEnd,
                'minutes',
                '[]'
            ) ||
            otherShiftsEnd.isBetween(
                newShiftsStart,
                newShiftsEnd,
                'minutes',
                '[]'
            );
        return shiftOverlaps;
    };

    const checkOverlappedShiftsFromSchedule = () => {
        try {
            const overlappedShiftsFromSchedule =
                scheduleData.EmployeeSchedules.findIndex(
                    (x) =>
                        x.Employee.EmployeeId ===
                        shiftData[shift].reassignedEmployee
                );

            const otherShifts: ManagerOneData.ManagerOne.API.Labor.Models.Scheduler.ScheduledShift[] =
                scheduleData?.EmployeeSchedules[overlappedShiftsFromSchedule][
                    day
                ];

            return otherShifts.filter((otherShift) => {
                const otherShiftsStart = moment(otherShift.StartTime);
                const otherShiftsEnd = moment(otherShift.EndTime);
                const newShiftsStart = moment(startTime);
                const newShiftsEnd = moment(endTime);

                return checkTimeOverlaps(
                    newShiftsStart,
                    newShiftsEnd,
                    otherShiftsStart,
                    otherShiftsEnd
                );
            });
        } catch (e) {
            return [];
        }
    };

    const checkOverlappedShiftsFromUnsavedModal = () => {
        const assignedEmployeeId = shiftData[shift].reassignedEmployee;
        const otherShiftsInModal = shiftData.filter(
            (_, index) => index !== shift
        );
        return otherShiftsInModal.filter((otherShift) => {
            if (otherShift.reassignedEmployee !== assignedEmployeeId)
                return false;

            const otherShiftsStart = moment(otherShift.startTime);
            const otherShiftsEnd = moment(otherShift.endTime);
            const newShiftsStart = moment(startTime);
            const newShiftsEnd = moment(endTime);

            return checkTimeOverlaps(
                newShiftsStart,
                newShiftsEnd,
                otherShiftsStart,
                otherShiftsEnd
            );
        });
    };

    const checkForErrors = (
        start: Date,
        end: Date,
        assignmentError2: null | boolean = null
    ) => {
        const otherShifts = [...shiftData];
        otherShifts.splice(shift, 1);
        let doesTimeOverlap = false;
        const newShiftsStart = moment(start);
        const newShiftsEnd = moment(end);
        otherShifts.forEach((otherShift) => {
            const otherShiftsStart = moment(otherShift.startTime);
            const otherShiftsEnd = moment(otherShift.endTime);
            const res = checkTimeOverlaps(
                newShiftsStart,
                newShiftsEnd,
                otherShiftsStart,
                otherShiftsEnd
            );
            if (
                res &&
                shiftData[selectedShift].reassignedEmployee ===
                    otherShift.reassignedEmployee
            )
                doesTimeOverlap = true;
        });

        const hours = moment
            .duration(moment(endTime).diff(moment(startTime)))
            .asHours();
        const roundedHoursToNearestQuarter = Math.floor(hours * 4) / 4;
        setTimeDifference(roundedHoursToNearestQuarter);

        const newShiftErrors = [...shiftErrors];
        newShiftErrors[shift] = {
            ...newShiftErrors[shift],
            hasTimeError: hours <= 0,
            hasOverlapError: doesTimeOverlap,
            hasAssignmentError:
                assignmentError2 !== null
                    ? assignmentError2
                    : newShiftErrors[shift].hasAssignmentError,
        };
        dispatch(setShiftErrors(newShiftErrors));
    };

    const getReassignableEmployees = (
        jobId: string | null,
        resetAssignmentError = false
    ): boolean => {
        let assignmentOverlapError = false;
        if (jobId === null) return false;
        const tmpEmployeeOptions: ManagerOneData.ManagerOne.API.Labor.Models.Scheduler.EmployeeSchedule[] =
            [];

        const overlappedShiftsFromUnsavedModal =
            checkOverlappedShiftsFromUnsavedModal();
        scheduleData.EmployeeSchedules.forEach((employeesSchedule) => {
            let isEmployeeEligible = false;
            employeesSchedule?.Employee?.Jobs?.forEach((job) => {
                if (
                    job.JobCodeId === jobId &&
                    employeesSchedule.Employee.EmployeeId !==
                        employee.EmployeeId
                ) {
                    const otherShifts: ManagerOneData.ManagerOne.API.Labor.Models.Scheduler.ScheduledShift[] =
                        employeesSchedule[day];

                    const assignedEmployeeId =
                        shiftData[shift].reassignedEmployee;

                    if (
                        employeesSchedule.Employee.EmployeeId ===
                        assignedEmployeeId
                    ) {
                        const overlappedShiftsFromSchedule =
                            checkOverlappedShiftsFromSchedule();

                        const combinedOverlaps = [
                            ...overlappedShiftsFromSchedule,
                            ...overlappedShiftsFromUnsavedModal,
                        ];
                        if (combinedOverlaps.length > 0)
                            assignmentOverlapError = true;
                    }

                    const overlappedShifts = otherShifts?.filter(
                        (otherShift) => {
                            const otherShiftsStart = moment(
                                otherShift.StartTime
                            );
                            const otherShiftsEnd = moment(otherShift.EndTime);
                            const newShiftsStart = moment(startTime);
                            const newShiftsEnd = moment(endTime);
                            const shiftOverlaps =
                                newShiftsStart.isBetween(
                                    otherShiftsStart,
                                    otherShiftsEnd,
                                    'minutes',
                                    '[]'
                                ) ||
                                newShiftsEnd.isBetween(
                                    otherShiftsStart,
                                    otherShiftsEnd,
                                    'minutes',
                                    '[]'
                                );

                            return shiftOverlaps;
                        }
                    );
                    if (
                        overlappedShifts?.length > 0 ||
                        otherShifts?.length === 3
                    )
                        isEmployeeEligible = false;
                    else isEmployeeEligible = true;
                }
            });

            if (isEmployeeEligible) {
                tmpEmployeeOptions.push(employeesSchedule);
            }
        });

        if (!resetAssignmentError) {
            if (startTime !== null && endTime !== null)
                checkForErrors(startTime, endTime, assignmentOverlapError);
        }
        setEmployeeOptions(tmpEmployeeOptions);
        return assignmentOverlapError;
    };

    const setAssignedEmployee = (emp: string) => {
        const newShiftData = [...shiftData];
        newShiftData[shift] = {
            ...newShiftData[shift],
            reassignedEmployee: emp,
        };
        dispatch(setShiftData(newShiftData));

        if (emp === '') {
            const newShiftErrors = [...shiftErrors];
            newShiftErrors[shift] = {
                ...newShiftErrors[shift],
                hasAssignmentError: false,
            };

            getReassignableEmployees(shiftData[shift].job, true);
            dispatch(setShiftErrors(newShiftErrors));
        } else getReassignableEmployees(shiftData[shift].job);
    };

    const setTimes = (start: Date | null, end: Date | null) => {
        const newShiftData = [...shiftData];
        const assignedJob = shiftData[shift].job;
        let job =
            assignedJob === '' || assignedJob === null
                ? employee?.Jobs?.[0]?.JobCodeId
                : assignedJob;
        if (job === undefined) job = '';
        if (start !== null && end !== null) {
            newShiftData[shift] = {
                ...newShiftData[shift],
                startTime: start,
                endTime: end,
                job,
            };
        } else if (start === null) {
            newShiftData[shift] = {
                ...newShiftData[shift],
                endTime: end,
                job,
            };
        } else if (end === null) {
            newShiftData[shift] = {
                ...newShiftData[shift],
                startTime: start,
                job,
            };
        }
        dispatch(setShiftData(newShiftData));
    };

    const JobSelector = (
        <FormControl variant="standard" sx={{ minWidth: 200 }}>
            <InputLabel>{messages.job}</InputLabel>
            <Select
                value={shiftData[shift]?.job || ''}
                onChange={(e) => {
                    setSelectedJob(e.target.value);
                    getReassignableEmployees(e.target.value);
                }}
                disabled={readonly}
                readOnly={readonly}
                label={messages.job}
                inputProps={{
                    'data-testid': 'shift-modal-job-select-test-id',
                }}
                name="job"
            >
                {employee &&
                    employee?.Jobs &&
                    employee.Jobs.map((val) => (
                        <MenuItem key={val.JobCodeId} value={val.JobCodeId}>
                            {val.JobTitle}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );

    const ReassignSelector = (
        <FormControl variant="standard" fullWidth>
            <InputLabel>{messages.eligibleReassign}</InputLabel>
            <Select
                value={shiftData[shift]?.reassignedEmployee}
                onChange={(e) => setAssignedEmployee(e.target.value)}
                label={messages.eligibleReassign}
                readOnly={readonly}
                disabled={readonly}
                id="shift-modal-reassign-select"
                inputProps={{
                    'data-testid': 'shift-modal-job-reassign',
                }}
            >
                <MenuItem value="" sx={{ height: '30px' }} />
                {employeeOptions.map((val) => (
                    <MenuItem
                        key={val.Employee.EmployeeId}
                        value={val.Employee.EmployeeId}
                    >
                        {val.Employee.Name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    const getDateTime = (time: moment.Moment) => {
        const posTime = moment.tz(time, timezone);
        return posTime.toDate();
    };

    const setInitialTimes = () => {
        let startMoment = dateOfCell.startOf('day');
        if (shift === 1) {
            startMoment = moment(shiftData[0].endTime).add(1, 'hours');
        } else if (shift === 2) {
            startMoment = moment(shiftData[1].endTime).add(1, 'hours');
        }

        const initialStartTime = getDateTime(startMoment);
        const endMoment = startMoment.add(4, 'hours');
        const initialEndTime = getDateTime(endMoment);

        setTimes(initialStartTime, initialEndTime);
    };

    useEffect(() => {
        // Checks if the already assigned employees day shifts has overlapping hours.

        try {
            if (startTime !== null && endTime !== null)
                checkForErrors(startTime, endTime);
        } catch (e) {
            // error
        }

        // This covers an edge case, where you could switch the times/job codes AFTER selecting an employee
        const tmp = getReassignableEmployees(shiftData[shift].job);

        if (tmp && shiftData[shift].reassignedEmployee !== '') {
            return;
        }

        if (initialLoad.current && startTime === null && endTime === null) {
            initialLoad.current = false;

            setInitialTimes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endTime, startTime, shift, selectedShift, shiftData]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Typography
                mb={shiftData?.[shift]?.reassignedEmployee !== '' ? 1 : 0}
                variant="subtitle1"
            >
                <FormattedMessage
                    id="App.Containers.Scheduler.CurrentlyAssigned"
                    values={{ name: employee.Name }}
                />
            </Typography>
            {shiftData[shift].reassignedEmployee !== '' && (
                <Typography mb={1} variant="subtitle1">
                    <FormattedMessage
                        id="App.Containers.Scheduler.Reassign"
                        values={{ name: reassignedEmployee?.Employee.Name }}
                    />
                </Typography>
            )}
            <Grid container justifyContent="flex-start" spacing={2}>
                <Grid item xs={12}>
                    {JobSelector}
                </Grid>
                <Grid item xs={4}>
                    <TimePicker
                        label={messages.startTime}
                        value={startTime}
                        onChange={(value) => setTimes(value, null)}
                        readOnly={readonly}
                        disabled={readonly}
                        data-testid="shift-modal-start-time"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                data-testid="start-time-input-test-id"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TimePicker
                        label={messages.endTime}
                        value={endTime}
                        onChange={(value) => setTimes(null, value)}
                        readOnly={readonly}
                        disabled={readonly}
                        data-testid="shift-modal-end-time"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                data-testid="end-time-input-test-id"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled
                        label={messages.hours}
                        value={timeDifference}
                        variant="standard"
                        data-testid="diff-time-test-id"
                    />
                </Grid>

                {shiftErrors[shift].hasAssignmentError && (
                    <Grid item xs={12} paddingTop={0}>
                        {assignmentError}
                    </Grid>
                )}
                {shiftErrors[shift].hasTimeError &&
                    !shiftErrors[shift].hasAssignmentError && (
                        <Grid item xs={12} paddingTop={0}>
                            {timeError}
                        </Grid>
                    )}
                {shiftErrors[shift].hasOverlapError &&
                    !shiftErrors[shift].hasAssignmentError && (
                        <Grid item xs={12} paddingTop={0}>
                            {overlapError}
                        </Grid>
                    )}
                <Grid item xs={12}>
                    {ReassignSelector}
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default ShiftModal;
