import { FC, useState } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Print from '@mui/icons-material/Print';
import Refresh from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import moment, { Moment } from 'moment';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { rawIntl } from 'services/intl';
import { SMALL } from 'styles/responsive_breakpoints';

const { formatMessage } = rawIntl;

const FunctionBarRoot = styled.menu`
    display: grid;
    grid-auto-flow: column;
    padding-left: 0;
    grid-template-columns: minmax(0, 1fr) repeat(2, auto);

    @media (max-width: ${SMALL}) {
        grid-auto-flow: row;
        grid-template-rows: repeat(2, auto);
        grid-template-columns: minmax(0, 1fr);
        justify-items: center;
        gap: 15px;
    }
`;

const CheckFunctionBar: FC = () => {
    // TODO: replace with value from businessDay, and possibly replace state setter with action dispatcher
    const [day, setDay] = useState<Moment | null>(moment());

    const moveDateByDay = (days: number) => (): void => {
        const newDay = moment(day).add(days, 'days');
        setDay(newDay);
    };

    const nextDay = moveDateByDay(1);
    const prevDay = moveDateByDay(-1);

    return (
        <FunctionBarRoot>
            <Typography
                variant="subtitle1"
                fontWeight="500"
                fontSize="28px"
                lineHeight="100%"
                whiteSpace="nowrap"
            >
                <FormattedMessage id="App.Containers.GuestChecks.Title" />
            </Typography>
            <section>
                <Tooltip title={formatMessage({ id: 'Common.PreviousDay' })}>
                    <IconButton onClick={prevDay}>
                        <ChevronLeft />
                    </IconButton>
                </Tooltip>
                <DatePicker
                    disableFuture
                    openTo="day"
                    views={['month', 'day', 'year']}
                    value={day}
                    onChange={setDay}
                    renderInput={(p) => (
                        <TextField
                            {...p}
                            variant="outlined"
                            size="small"
                            InputProps={{
                                ...p.InputProps,
                                sx: { width: '145px', borderRadius: '4px' },
                            }}
                        />
                    )}
                />
                <Tooltip title={formatMessage({ id: 'Common.NextDay' })}>
                    <span>
                        <IconButton
                            // TODO: disable on condition
                            onClick={nextDay}
                        >
                            <ChevronRight />
                        </IconButton>
                    </span>
                </Tooltip>
            </section>
            <section>
                <Tooltip
                    title={formatMessage({
                        id: 'App.Containers.GuestChecks.Refresh',
                    })}
                >
                    <IconButton>
                        <Refresh />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={formatMessage({
                        id: 'App.Containers.GuestChecks.Print',
                    })}
                >
                    <span>
                        <IconButton
                            disabled // TODO: disable if !checkDetail
                        >
                            <Print />
                        </IconButton>
                    </span>
                </Tooltip>
            </section>
        </FunctionBarRoot>
    );
};

export default CheckFunctionBar;
