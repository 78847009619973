import { rawIntl } from 'services/intl';

export const LaborCardi18n = {
    overtimePay: rawIntl.formatMessage({
        id: 'App.Containers.LaborCard.OvertimePay',
    }),
    regularPay: rawIntl.formatMessage({
        id: 'App.Containers.LaborCard.RegularPay',
    }),
    paidBreaks: rawIntl.formatMessage({
        id: 'App.Containers.LaborCard.PaidBreakPay',
    }),
    amount: rawIntl.formatMessage({
        id: 'App.Containers.LaborCard.Amount',
    }),
    title: rawIntl.formatMessage({
        id: 'App.Containers.LaborCard.Title',
    }),
    totalLabor: rawIntl.formatMessage({
        id: 'App.Containers.LaborCard.TotalLabor',
    }),
    payType: rawIntl.formatMessage({
        id: 'App.Containers.LaborCard.PayType',
    }),
    noLaborData: rawIntl.formatMessage({
        id: 'App.Containers.LaborCard.NoLaborData',
    }),
};
