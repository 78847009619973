/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { ROUTES } from 'routes';
import { store } from 'store/createStore';
import { setShowSignInModal } from 'state/userSlice';
import { setLoading } from 'state/commonSlice';
import { purgeReducers } from 'state/reducers';
import { setResumeFromLastSession } from 'state/schedulerSlice';

let numPendingRequests = 0;

const axiosInstance = axios.create({
    timeout: 30000,
    maxRedirects: 0,
});

axiosRetry(axiosInstance, {
    retries: 0,
    retryDelay: axiosRetry.exponentialDelay, // with each failure, increase the delay exponentially
});

export const axiosSuccess = (response: any): unknown => {
    try {
        // TODO: research possible preflight/CORS issue here, but this is a workaround for now
        if (response?.data === undefined) {
            return response;
        }
        return response?.data;
    } catch (e) {
        return { error: true };
    } finally {
        numPendingRequests -= 1;
        if (numPendingRequests === 0) {
            store.dispatch(setLoading(false));
        }
    }
};
export const axiosError = (error: any) => {
    const status = error?.response?.status;
    if (status === 401 && !window.location.href.includes(ROUTES.LOGIN)) {
        purgeReducers(['routes'], true);
        store.dispatch(setResumeFromLastSession(true));
        store.dispatch(setShowSignInModal(true));
    }
    numPendingRequests -= 1;
    if (numPendingRequests === 0) {
        store.dispatch(setLoading(false));
    }
    return { error: true };
};

export const setupInterceptors = (): void => {
    // Use interceptor here, for easy access to dispatch actions to store.
    axiosInstance.interceptors.response.use(axiosSuccess, axiosError);
    axiosInstance.interceptors.request.use((config) => {
        store.dispatch(setLoading(true));
        numPendingRequests += 1;
        return config;
    });
};

export default axiosInstance;
