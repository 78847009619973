import { rawIntl } from 'services/intl';
import { CategoryDetails, ProductDetails } from 'services/ManagerOneApi';
import {
    CategoryDataLabels,
    CategoryDataRow,
    CategoryDataSource,
    FormattedDataPoint,
    ProductDataRow,
    ProductDataSource,
} from './PMIXChart';

const formatCurrency = (
    value: FormattedDataPoint['v']
): FormattedDataPoint['f'] =>
    rawIntl.formatNumber(value, {
        style: 'currency',
        currency: 'USD',
    });

export const formatDataPoint = (
    v: FormattedDataPoint['v']
): FormattedDataPoint => ({
    v,
    f: formatCurrency(v),
});

export const formatCategory = (c: CategoryDetails): CategoryDataRow => [
    c.Name,
    formatDataPoint(c.TotalAmount),
    c.Id,
];

export const formatProduct = (p: ProductDetails): ProductDataRow => [
    p.Name,
    formatDataPoint(p.Amount),
];

export const formatCategories = (
    labels: CategoryDataLabels,
    categories: CategoryDetails[]
): CategoryDataSource => [labels, ...categories.map(formatCategory)];

export const formatProducts = (
    products: ProductDetails[]
): ProductDataSource => [
    [
        rawIntl.formatMessage({ id: 'App.Containers.PMIXCard.Product' }),
        rawIntl.formatMessage({ id: 'App.Containers.PMIXCard.Amount' }),
    ],
    ...products.map(formatProduct),
];

export const formatProductsFromCategory = (
    categories: CategoryDetails[],
    id: CategoryDetails['Id']
): ProductDataSource => {
    const category = categories.find(({ Id }) => Id === id);
    const { Products } = category as CategoryDetails;
    return formatProducts(Products);
};
