import { FC, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { ReportSection } from 'services/ManagerOneApi';
import { rawIntl } from 'services/intl';

interface HeadCell {
    id: string;
    label: string;
}

const headCells: HeadCell[] = [
    {
        id: 'name',
        label: 'Section Name',
    },
    {
        id: 'type',
        label: 'Section Type',
    },
    {
        id: 'transposed',
        label: 'Transposed',
    },
    {
        id: 'disable-sorting',
        label: 'Disable Sorting',
    },
    {
        id: 'column-count',
        label: 'Column Count',
    },
];

function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow sx={{ height: '40px' }}>
                <TableCell
                    key="reports-check-box"
                    align="left"
                    sx={{
                        flexDirection: 'row',
                        width: '50px',
                        textAlign: 'left',
                    }}
                >
                    <Checkbox
                        defaultChecked
                        sx={{
                            color: '#0f7a86',
                            '&.Mui-checked': {
                                color: '#0f7a86',
                            },
                        }}
                    />
                </TableCell>

                {headCells.map((cell) => (
                    <TableCell
                        key={cell.id}
                        align="left"
                        sx={{
                            flexDirection: 'row',
                            width: cell.id === 'name' ? '200px' : '100px',
                            textAlign: 'left',
                        }}
                    >
                        {cell.label}
                    </TableCell>
                ))}

                <TableCell
                    key="reports-add-icon"
                    align="left"
                    sx={{
                        flexDirection: 'row',
                        width: '50px',
                        textAlign: 'left',
                    }}
                >
                    <Tooltip
                        title={rawIntl.formatMessage({
                            id: 'Common.Add',
                        })}
                    >
                        <IconButton data-testid="add-icon">
                            <AddCircleOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

const TableContent: FC = (): JSX.Element => {
    type Rows = {
        Id: string;
        Name: string;
        Transposed: boolean;
        SortingSuppressed: boolean;
        Type: ReportSection['Type'];
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [rows, setRows] = useState<Rows[]>([]);

    return (
        <>
            <Box
                data-testid="reportBuilderTable"
                sx={{
                    height: '320px;',
                    marginTop: '20px',
                    flex: ' 1 calc(100vh - 400px)',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        height: '85%',
                        width: '95%',
                        borderRadius: '4px',
                        margin: 'auto',
                        boxShadow:
                            '0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
                    }}
                >
                    <TableContainer>
                        <Table aria-labelledby="tableTitle" size="medium">
                            <EnhancedTableHead />
                            <TableBody sx={{ marginBottom: '50px' }}>
                                {rows.map((row, i) => (
                                    <TableRow
                                        key={rows[i].Id}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell
                                            data-testid={`${i}Name`}
                                            component="th"
                                            scope="row"
                                        >
                                            {row.Name}
                                        </TableCell>
                                        <TableCell
                                            data-testid={`${i}Type`}
                                            align="left"
                                        >
                                            {row.Type}
                                        </TableCell>
                                        <TableCell
                                            data-testid={`${i}Transposed`}
                                            align="left"
                                        >
                                            {row.Transposed}
                                        </TableCell>
                                        <TableCell
                                            data-testid={`${i}SortingSuppressed`}
                                            align="left"
                                        >
                                            {row.SortingSuppressed}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </>
    );
};

export default TableContent;
