/* eslint-disable no-console */
/**
 *
 * This file holds components that are used as props for the main AppShell component.
 *
 */
import { FC, useRef } from 'react';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import { FullScreenLoaderMask } from 'components/LoaderMask';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import LoadWithMask from 'components/LoadWithMask';
import HomeIcon from '@mui/icons-material/Home';
import CloseIcon from '@mui/icons-material/Close';
import SignInModal from 'components/SignInModal';
import TrainingVideo from 'components/TrainingVideo';
import ReleaseNotes from 'components/ReleaseNotes';
import { Box, Toolbar, Typography, IconButton, Avatar } from '@mui/material';

// AppShell exports
import {
    // Utility Component for rendering a Navigation Item that looks like the
    // ones the app-shell sidebar uses
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    NavItem,
    // Utility that allows 'hiddenItems' within the `sidebarItems` to be triggered.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    useTriggerFrame,
    // Hook that can be used to open/close and see the state of the Right Panels in the app shell
    useRightPanel,
    // Utility to help making a 'linkComponent' with the correct type
    createLinkComponent,
    // Utility to make creating an item in the sidebar's top-bar easier
    NavigationTopBar,
} from '@ncr-design-system/pattern-app-shell';
import { ROUTES } from 'routes';
import SitesDropDown from 'components/HeaderNavigation/SitesDropDown';
import { SettingsDropDown } from 'components/HeaderNavigation/SettingsDropDown';
import { useLazyQuery } from '@apollo/client';
import { GET_MENU_ITEM } from 'graphQL/queries';
import PoweredByNCR from 'components/Icons/PoweredByNCR/NCRIcon';

const WrappedLink = createLinkComponent<HTMLAnchorElement>((props, ref) => (
    <Link {...props} to={props.href} ref={ref} />
));

const PageContent: FC = () => {
    const isLoading = useSelector((state: RootState) => state.common.loading);

    const Modals = (
        <>
            <SignInModal />
            <TrainingVideo />
            <ReleaseNotes />
        </>
    );

    return (
        <LoadWithMask loading={isLoading} fallback={<FullScreenLoaderMask />}>
            {Modals}
            <Outlet />
        </LoadWithMask>
    );
};

/**
 * --NEW!!--
 * This will take props, so that it can be one or more of the
 * RightPanels that we use within our AppShell.
 *
 * Of course, you can just use different components, too.
 *
 * One thing to note is that because these components sit at the top
 * of app, if you wish for these components to be dynamic, you'll need to
 * either use a context, or some other non-props based state-management
 * solution (redux, react-query, apollo, etc).
 */
const RightPanel = ({ title, body }: { title: string; body: string }) => {
    //! This is an exported hook from the AppShell that allows you to open, close,
    // and see the current state of the right-panel from deeper within the application.
    const { handleClose } = useRightPanel();
    return (
        <>
            <Toolbar
                sx={{
                    backgroundColor: 'transparent',
                    border: '1px solid transparent',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="subtitle1">{title}</Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            <Box
                display="flex"
                height="100%"
                width="100%"
                justifyContent="center"
                alignItems="center"
            >
                <Typography>{body}</Typography>
            </Box>
        </>
    );
};

/**
 * --NEW!!--
 * This is going to be what we place inside of the Appbar of our
 * AppShell. It'll go within the 'components.appbarContents' slot
 * of our AppShell implementation.
 */
const AppbarContents: FC = () => {
    //! This is an exported hook from the AppShell that allows you to open, close,
    // and see the current state of the right-panel from deeper within the applicaiton.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isOpen, currentPanel, handleOpen } = useRightPanel();
    const isViewingManagerOneApplet = useSelector(
        (state: RootState) => state.common.viewingManagerOneApplet
    );

    // TODO: remove this example
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [getMenuItem, { loading, error, data }] = useLazyQuery(GET_MENU_ITEM);

    return (
        <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            flexGrow={1}
        >
            {isViewingManagerOneApplet && (
                <>
                    <SitesDropDown />
                    <SettingsDropDown />
                </>
            )}
            <Box>
                <IconButton onClick={handleOpen('secondary_right_panel')}>
                    <Avatar
                        variant="circular"
                        sx={{
                            width: 32,
                            height: 32,
                            fontSize: 14,
                        }}
                    />
                </IconButton>
            </Box>
            <Typography
                sx={{ fontSize: '16px' }}
                onClick={() => getMenuItem({ variables: { menuId: '1' } })}
            >
                James Dean
            </Typography>
        </Box>
    );
};

/**
 * --NEW!!--
 * This is the bottom item for the sidebar, we're going to be using
 * this to show a hidden panel within the sidebar.
 *
 * You can read more about this in the sidebar docs regarding
 * 'hidden panels'.
 *
 * https://github.com/ncr-swt-xd/Shared-Frontend/wiki/Sidebar-Navigation-Hidden-Routes
 */
const SidebarBottomItem: FC = () => {
    const iconWrapperRef = useRef<HTMLInputElement>(null);
    return (
        <>
            <Box
                component="div"
                sx={{
                    zIndex: 1,
                    position: 'sticky',
                    borderBottom: '1px solid #BDBDBD',
                    width: String(iconWrapperRef.current?.width).concat(' px'),
                    opacity: '0.5',
                }}
            />
            <Box
                component="div"
                sx={{
                    zIndex: 1,
                    position: 'sticky',
                }}
                ref={iconWrapperRef}
            >
                <PoweredByNCR width={String(iconWrapperRef.current?.width)} />
            </Box>
        </>
    );
};

/**
 * --New!!--
 * This will be used to show a brand logo above the main sidebar content!
 */
const SidebarTopItem: FC = () => {
    const navigate = useNavigate();
    return (
        <NavigationTopBar>
            <IconButton
                onClick={() => navigate(ROUTES.DASHBOARD)}
                sx={{ color: 'black' }}
                data-testid="sidebar-navigation"
            >
                <HomeIcon sx={{ fontSize: '18px' }} />
            </IconButton>
            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                Customer Name
            </Typography>
        </NavigationTopBar>
    );
};

/// Export all of our components to be used within app shell.
export const Components = {
    RightPanel: {
        Primary: (): JSX.Element => (
            <RightPanel
                title="Main Right Panel"
                body="This is the right panel triggered by 'main_right_panel'!"
            />
        ),
        Secondary: (): JSX.Element => (
            <RightPanel
                title="Secondary Right Panel"
                body="This is the right panel triggered by 'secondary_right_panel'!"
            />
        ),
    },
    PageContent,
    AppbarContents,
    SidebarTopItem,
    SidebarBottomItem,
    WrappedLink,
};
