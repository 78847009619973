/* istanbul ignore file */
// TODO: Eventually, setup and use launch-darkly over using FF files like this one
// https://jira.ncr.com/browse/CFCWEB-92

const showAllFeatures = true;

const featureFlags = {
    // Dev use only
    showAllFeatures,
    managerOne: {
        // Same as CE dashboard?
        home: true,
        functions: {
            deposits: showAllFeatures || true,
            punches: showAllFeatures || true,
            scheduler: showAllFeatures || true,
            guestChecks: showAllFeatures || true,
            houseAccounts: showAllFeatures || true,
            tipShare: showAllFeatures || true,
        },
        reports: showAllFeatures || true,
        utilities: showAllFeatures || true,
    },
    commonEdits: {
        dashboard: showAllFeatures || true,
        menu: {
            menuItems: showAllFeatures || false,
            menuItemRouting: showAllFeatures || false,
            modifierGroups: showAllFeatures || false,
            menuCategories: showAllFeatures || false,
            panels: showAllFeatures || false,
            submenus: showAllFeatures || false,
        },
        employees: showAllFeatures || false,
        users: showAllFeatures || false,
    },
};

export default featureFlags;
