import { FC, useContext } from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogFooter from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { rawIntl } from 'services/intl';
import useAppDispatch from 'state/dispatch';
import { setShowConfirmation } from 'state/routesSlice';
import { SideEffectContext } from 'SideEffectContext';

const { formatMessage } = rawIntl;

const i18n = {
    title: formatMessage({ id: 'Common.UnsavedChanges' }),
    unsavedChangesPrompt: formatMessage({ id: 'Common.UnsavedChangesPrompt' }),
    no: formatMessage({ id: 'Common.No' }),
    yes: formatMessage({ id: 'Common.Yes' }),
    close: formatMessage({ id: 'Common.Close' }),
};

const Header = styled(DialogTitle)`
    display: flex;
    justify-content: space-between;
`;

const CloseButton = styled(IconButton)`
    padding-right: 0;
`;

type Props = {
    save: () => Promise<void>;
    resetData: () => Promise<void>;
    proceed: () => void;
    cancel: () => void;
};

const ConfirmSaveModal: FC<Props> = ({ save, resetData, proceed, cancel }) => {
    const dispatch = useAppDispatch();
    const { sideEffect, setSideEffect } = useContext(SideEffectContext);

    const close = () => {
        dispatch(setShowConfirmation(false));
    };

    const cancelHandler = () => {
        close();
        setSideEffect(null);
        cancel();
    };

    const yes = async () => {
        close();
        await save();
        await sideEffect?.();
        proceed();
    };

    const no = async () => {
        close();
        await resetData();
        await sideEffect?.();
        proceed();
    };

    return (
        <Dialog
            aria-describedby="confirm-save-message"
            aria-labelledby="confirm-save-title"
            data-testid="confirm-save-dialog"
            open
        >
            <Header>
                <section id="confirm-save-title">{i18n.title}</section>
                <Tooltip title={i18n.close}>
                    <CloseButton
                        onClick={cancelHandler}
                        data-testid="confirm-save-cancel"
                    >
                        <Close />
                    </CloseButton>
                </Tooltip>
            </Header>
            <DialogContent>
                <DialogContentText id="confirm-save-message">
                    {i18n.unsavedChangesPrompt}
                </DialogContentText>
            </DialogContent>
            <DialogFooter>
                <Button
                    variant="text"
                    aria-label={i18n.yes}
                    onClick={yes}
                    sx={{ textTransform: 'uppercase' }}
                    data-testid="confirm-save-yes"
                >
                    {i18n.yes}
                </Button>
                <Button
                    variant="text"
                    aria-label={i18n.no}
                    onClick={no}
                    sx={{ textTransform: 'uppercase' }}
                    data-testid="confirm-save-no"
                >
                    {i18n.no}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

export default ConfirmSaveModal;
