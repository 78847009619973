import { createSlice } from '@reduxjs/toolkit';
import { GetScheduleDataResponse } from 'services/ManagerOneApi';

export interface SchedulerState {
    selectedJobCodes: string[];
    shiftModalVisibleShifts: 1 | 2 | 3;
    shiftData: ShiftData[];
    shiftErrors: ShiftErrors[];
    unsavedScheduleData: GetScheduleDataResponse;
    selectedScheduleShift: number;
    isConfirmingDelivery: boolean;
    isCopyScheduleModalVisible: boolean;
    isConflictConfirmModalVisible: boolean;
    wasAScheduleCopied: boolean;
    resumeFromLastSession: boolean;
    showSaveModal: boolean;
}

interface ShiftData {
    job: string | null;
    startTime: Date | null;
    endTime: Date | null;
    reassignedEmployee: string;
}

interface ShiftErrors {
    hasTimeError: boolean;
    hasOverlapError: boolean;
    hasAssignmentError: boolean;
}

export const defaultShiftData: ShiftData = {
    job: null,
    startTime: null,
    endTime: null,
    reassignedEmployee: '',
};

export const defaultShiftErrors: ShiftErrors = {
    hasTimeError: false,
    hasOverlapError: false,
    hasAssignmentError: false,
};

export const initialState: SchedulerState = {
    selectedJobCodes: [],
    shiftModalVisibleShifts: 1,
    shiftData: [defaultShiftData, defaultShiftData, defaultShiftData],
    shiftErrors: [defaultShiftErrors, defaultShiftErrors, defaultShiftErrors],
    unsavedScheduleData: new GetScheduleDataResponse(),
    selectedScheduleShift: 0,
    isConfirmingDelivery: false,
    isCopyScheduleModalVisible: false,
    isConflictConfirmModalVisible: false,
    wasAScheduleCopied: false,
    resumeFromLastSession: false,
    showSaveModal: false,
};

const schedulerSlice = createSlice({
    name: 'scheduler',
    initialState,
    reducers: {
        setSelectedJobCodes: (state, action) => {
            state.selectedJobCodes = action.payload;
        },
        incrementShifts: (state) => {
            state.shiftModalVisibleShifts += 1;
        },
        decrementShifts: (state) => {
            state.shiftModalVisibleShifts -= 1;
        },
        setShiftModalVisibleShifts: (state, action) => {
            state.shiftModalVisibleShifts = action.payload;
        },
        setShiftData: (
            state,
            action: { payload: SchedulerState['shiftData'] }
        ) => {
            state.shiftData = action.payload;
        },
        setShiftErrors: (state, action) => {
            state.shiftErrors = action.payload;
        },
        setSelectedScheduleShift: (state, action) => {
            state.selectedScheduleShift = action.payload;
        },
        resetShiftData: (state, action) => {
            const shiftToReset = action.payload;

            switch (shiftToReset) {
                default:
                    state.shiftErrors = initialState.shiftErrors;
                    state.shiftData = initialState.shiftData;
                    state.shiftModalVisibleShifts =
                        initialState.shiftModalVisibleShifts;
                    state.selectedScheduleShift =
                        initialState.selectedScheduleShift;
                    break;
            }
        },
        setUnsavedSchedule: (state, action) => {
            state.unsavedScheduleData = action.payload;
        },
        setIsConfirmingDelivery: (state, { payload }) => {
            state.isConfirmingDelivery = payload;
        },
        setShowCopyScheduleModal: (state, { payload }) => {
            state.isCopyScheduleModalVisible = payload;
        },
        setShowConflictConfirmModal: (state, { payload }) => {
            state.isConflictConfirmModalVisible = payload;
        },
        setScheduleWasCopied: (state, { payload }) => {
            state.wasAScheduleCopied = payload;
        },
        setResumeFromLastSession: (state, { payload }) => {
            state.resumeFromLastSession = payload;
        },
        purge: () => ({
            ...initialState,
        }),
    },
});

export const {
    setSelectedJobCodes,
    purge,
    incrementShifts,
    decrementShifts,
    setShiftModalVisibleShifts,
    setShiftData,
    setShiftErrors,
    setUnsavedSchedule,
    resetShiftData,
    setSelectedScheduleShift,
    setIsConfirmingDelivery,
    setShowCopyScheduleModal,
    setShowConflictConfirmModal,
    setScheduleWasCopied,
    setResumeFromLastSession,
} = schedulerSlice.actions;

export default schedulerSlice;
