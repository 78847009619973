/* eslint-disable @typescript-eslint/no-unused-vars */
import { GetScheduleDataResponse } from 'services/ManagerOneApi';
import moment from 'moment';

const SUNDAY = moment().subtract(3, 'days').format('YYYY-MM-DD').toString();
const MONDAY = moment().subtract(2, 'days').format('YYYY-MM-DD').toString();
const TUESDAY = moment().subtract(1, 'days').format('YYYY-MM-DD').toString();
const WEDNESDAY = moment().subtract(0, 'days').format('YYYY-MM-DD').toString();
const THURSDAY = moment().add(1, 'days').format('YYYY-MM-DD').toString();
const FRIDAY = moment().add(2, 'days').format('YYYY-MM-DD').toString();
const SATURDAY = moment().add(3, 'days').format('YYYY-MM-DD').toString();

export const mockedGetScheduleData = new GetScheduleDataResponse({
    EmployeeSchedules: [
        {
            Employee: {
                EmployeeId: '60405',
                Name: 'Adam (TS) Crum',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: '0.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '0.00',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [
                {
                    Date: TUESDAY,
                    JobCode: '1',
                    StartTime: `${TUESDAY}T01:00:00`,
                    EndTime: `${TUESDAY}T04:00:00`,
                },
                {
                    Date: `${TUESDAY}`,
                    JobCode: '5',
                    StartTime: `${TUESDAY}T06:00:00`,
                    EndTime: `${TUESDAY}T09:00:00`,
                },
                {
                    Date: `${TUESDAY}`,
                    JobCode: '1',
                    StartTime: `${TUESDAY}T10:00:00`,
                    EndTime: `${TUESDAY}T14:00:00`,
                },
            ],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [
                {
                    Date: SATURDAY,
                    JobCode: '1',
                    StartTime: `${SATURDAY}T03:00:00`,
                    EndTime: `${SATURDAY}T04:00:00`,
                },
            ],
            Day7: [
                {
                    Date: SUNDAY,
                    JobCode: '1',
                    StartTime: `${SUNDAY}T09:00:00`,
                    EndTime: `${SUNDAY}T16:00:00`,
                },
            ],
            TotalHours: 20.0,
        },
        {
            Employee: {
                EmployeeId: '60050',
                Name: 'AO Interface Employee  AO Interface Employee',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: '0.00',
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [
                {
                    Date: WEDNESDAY,
                    JobCode: '99',
                    StartTime: `${WEDNESDAY}T00:00:00`,
                    EndTime: `${WEDNESDAY}T04:00:00`,
                },
            ],
            Day4: [],
            Day5: [
                {
                    Date: FRIDAY,
                    JobCode: '99',
                    StartTime: `${FRIDAY}T00:00:00`,
                    EndTime: `${FRIDAY}T04:00:00`,
                },
                {
                    Date: FRIDAY,
                    JobCode: '99',
                    StartTime: `${FRIDAY}T17:00:00`,
                    EndTime: `${FRIDAY}T21:00:00`,
                },
            ],
            Day6: [],
            Day7: [],
            TotalHours: 12.0,
        },
        {
            Employee: {
                EmployeeId: '50108',
                Name: 'Bailey  Simmons',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '20',
                        PayRate: '7.50',
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '1.50',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [
                {
                    Date: WEDNESDAY,
                    JobCode: '20',
                    StartTime: `${WEDNESDAY}T03:00:00`,
                    EndTime: `${WEDNESDAY}T04:00:00`,
                },
            ],
            Day4: [],
            Day5: [
                {
                    Date: FRIDAY,
                    JobCode: '20',
                    StartTime: `${FRIDAY}T16:00:00`,
                    EndTime: `${FRIDAY}T18:00:00`,
                },
            ],
            Day6: [],
            Day7: [],
            TotalHours: 3.0,
        },
        {
            Employee: {
                EmployeeId: '50300',
                Name: 'Bartender  Bartender',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '8',
                        PayRate: '11.00',
                        JobTitle: 'Barista',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '6',
                        PayRate: '10.75',
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [
                {
                    Date: FRIDAY,
                    JobCode: '8',
                    StartTime: `${FRIDAY}T15:00:00`,
                    EndTime: `${FRIDAY}T18:00:00`,
                },
            ],
            Day6: [],
            Day7: [],
            TotalHours: 3.0,
        },
        {
            Employee: {
                EmployeeId: '50301',
                Name: 'Bartender  Bartender',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '6',
                        PayRate: '0.00',
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50100',
                Name: 'Betty  Employee',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '13',
                        PayRate: '7.75',
                        JobTitle: 'Cashier-DC',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '3.33',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60416',
                Name: 'Bill (TS) Seale',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: '0.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60422',
                Name: 'Brent  Jepsen',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: '0.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50190',
                Name: 'Brittany  Ellington',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '8',
                        PayRate: '16.00',
                        JobTitle: 'Barista',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '6',
                        PayRate: '15.25',
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '18',
                        PayRate: '18.00',
                        JobTitle: 'Cook',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '17',
                        PayRate: '8.25',
                        JobTitle: 'Dispatcher',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: '7.25',
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '13.30',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60503',
                Name: 'brown  beth',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '4',
                        PayRate: '5.25',
                        JobTitle: 'Cashier',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '18',
                        PayRate: '5.00',
                        JobTitle: 'Cook',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50201',
                Name: 'Bruce  Springsteen',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '2',
                        PayRate: '0.00',
                        JobTitle: 'GM',
                        BreakConfiguration: 1,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '1',
                        PayRate: '0.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50109',
                Name: 'Christie  Carpenter',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '20',
                        PayRate: '8.50',
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '8.25',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50400',
                Name: 'Cocktail  Cocktail',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '3',
                        PayRate: '8.75',
                        JobTitle: 'Cocktail',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50600',
                Name: 'Cook  Cook',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '18',
                        PayRate: '22.00',
                        JobTitle: 'Cook',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '1',
                        PayRate: '25.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50191',
                Name: 'Daniella  Ramos',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '8',
                        PayRate: '15.50',
                        JobTitle: 'Barista',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '6',
                        PayRate: '15.25',
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '4',
                        PayRate: '7.50',
                        JobTitle: 'Cashier',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '17',
                        PayRate: '5.25',
                        JobTitle: 'Dispatcher',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '16',
                        PayRate: '5.25',
                        JobTitle: 'Driver',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: '7.25',
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '13.30',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60400',
                Name: 'Darlene (TS) Webb',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: '0.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50102',
                Name: 'Deferred Tips  Cashier',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '7',
                        PayRate: '8.75',
                        JobTitle: 'Cashier-DT',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '5.25',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50500',
                Name: 'Driver  Driver',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '16',
                        PayRate: '7.45',
                        JobTitle: 'Driver',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60500',
                Name: 'Driver 2  Driver 2',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '16',
                        PayRate: '0.00',
                        JobTitle: 'Driver',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50000',
                Name: 'EDC  EDC',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: '0.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60411',
                Name: 'Felipe  Segura',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: '0.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50112',
                Name: 'Fred  Employee',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '5',
                        PayRate: '17.25',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60421',
                Name: 'ImportTest  AGM',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '6',
                        PayRate: '0.00',
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '1',
                        PayRate: '0.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '0.00',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50110',
                Name: 'Katie  Cavanaugh',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '20',
                        PayRate: '9.75',
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50200',
                Name: 'Manager  Manager',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '2',
                        PayRate: '0.00',
                        JobTitle: 'GM',
                        BreakConfiguration: 1,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '1',
                        PayRate: '0.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50192',
                Name: 'Marshall  Davis',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '8',
                        PayRate: '16.00',
                        JobTitle: 'Barista',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '6',
                        PayRate: '15.25',
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60502',
                Name: 'Mary  Test',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '19',
                        PayRate: '6.00',
                        JobTitle: 'Busser',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '11',
                        PayRate: '5.25',
                        JobTitle: 'test',
                        BreakConfiguration: 1,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60410',
                Name: 'Matt (TS) Ginley',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: '0.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60011',
                Name: 'Mobile Pay  Server 1',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '100',
                        PayRate: '0.00',
                        JobTitle: 'Mobile Pay',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60450',
                Name: 'Mobile Pay  Server 1',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '101',
                        PayRate: '0.00',
                        JobTitle: 'Mobile Pay',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60012',
                Name: 'Mobile Pay  Server 2',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '100',
                        PayRate: '0.00',
                        JobTitle: 'Mobile Pay',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60013',
                Name: 'Mobile Pay  Server 3',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '100',
                        PayRate: '0.00',
                        JobTitle: 'Mobile Pay',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60420',
                Name: 'Nicole (TS) Mills',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: '0.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '70800',
                Name: 'Order  Taker',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '4',
                        PayRate: '0.00',
                        JobTitle: 'Cashier',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '99',
                        PayRate: '6.00',
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '5.25',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50101',
                Name: 'Order  Taker',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '4',
                        PayRate: '0.00',
                        JobTitle: 'Cashier',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '13',
                        PayRate: '7.75',
                        JobTitle: 'Cashier-DC',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '99',
                        PayRate: '6.00',
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '5.25',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50103',
                Name: 'Oscar  Smith',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '19',
                        PayRate: '7.25',
                        JobTitle: 'Busser',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '13',
                        PayRate: '7.75',
                        JobTitle: 'Cashier-DC',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '16',
                        PayRate: '5.25',
                        JobTitle: 'Driver',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: '7.25',
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '5.25',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50290',
                Name: 'Penelope  Montgomery',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '6',
                        PayRate: '13.75',
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '2',
                        PayRate: '0.00',
                        JobTitle: 'GM',
                        BreakConfiguration: 1,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '1',
                        PayRate: '0.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50107',
                Name: 'Retired Colonel  George',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '19',
                        PayRate: '7.25',
                        JobTitle: 'Busser',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: '7.25',
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '8.25',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60413',
                Name: 'Scott  West',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: '0.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60415',
                Name: 'Scott (TS) Weeks',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: '0.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50105',
                Name: 'Suzie  Dressage',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '19',
                        PayRate: '7.25',
                        JobTitle: 'Busser',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '16',
                        PayRate: '5.25',
                        JobTitle: 'Driver',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: '7.25',
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '5.25',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60000',
                Name: 'Takeout  ATO Interface Employee',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: '0.00',
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '59999',
                Name: 'Takeout  ATO Training Interface Emp',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: '0.00',
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60001',
                Name: 'Takeout  Interface1',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: '0.00',
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60010',
                Name: 'Takeout  Interface10',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: '0.00',
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60002',
                Name: 'Takeout  Interface2',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: '0.00',
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60003',
                Name: 'Takeout  Interface3',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: '0.00',
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60004',
                Name: 'Takeout  Interface4',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: '0.00',
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60005',
                Name: 'Takeout  Interface5',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: '0.00',
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60006',
                Name: 'Takeout  Interface6',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: '0.00',
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60007',
                Name: 'Takeout  Interface7',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: '0.00',
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60008',
                Name: 'Takeout  Interface8',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: '0.00',
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60009',
                Name: 'Takeout  Interface9',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '99',
                        PayRate: '0.00',
                        JobTitle: 'Interface',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60101',
                Name: 'Takeout  Tr Interface1',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: '0.00',
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60110',
                Name: 'Takeout  Tr Interface10',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: '0.00',
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60102',
                Name: 'Takeout  Tr Interface2',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: '0.00',
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60103',
                Name: 'Takeout  Tr Interface3',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: '0.00',
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60104',
                Name: 'Takeout  Tr Interface4',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: '0.00',
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60105',
                Name: 'Takeout  Tr Interface5',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: '0.00',
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60106',
                Name: 'Takeout  Tr Interface6',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: '0.00',
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60107',
                Name: 'Takeout  Tr Interface7',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: '0.00',
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60108',
                Name: 'Takeout  Tr Interface8',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: '0.00',
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60109',
                Name: 'Takeout  Tr Interface9',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '98',
                        PayRate: '0.00',
                        JobTitle: 'Train Int',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60501',
                Name: 'Tes  Ally',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '20',
                        PayRate: '6.00',
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '11',
                        PayRate: '5.25',
                        JobTitle: 'test',
                        BreakConfiguration: 1,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '78204',
                Name: 'Test78204  Employee',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '4',
                        PayRate: '1.25',
                        JobTitle: 'Cashier',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '60412',
                Name: 'Thomas (TS) Nguyen',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '1',
                        PayRate: '0.00',
                        JobTitle: 'Manager',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50104',
                Name: 'Tom  Wilson',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '19',
                        PayRate: '7.25',
                        JobTitle: 'Busser',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '13',
                        PayRate: '7.75',
                        JobTitle: 'Cashier-DC',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '16',
                        PayRate: '5.25',
                        JobTitle: 'Driver',
                        BreakConfiguration: 2,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: '7.25',
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '5.25',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '70801',
                Name: 'Tommy  Minor',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '6',
                        PayRate: '11.75',
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '19',
                        PayRate: '7.25',
                        JobTitle: 'Busser',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: '7.25',
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '8.25',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50106',
                Name: 'Tommy  Minor',
                IsTerminated: false,
                Jobs: [
                    {
                        JobCodeId: '6',
                        PayRate: '11.75',
                        JobTitle: 'Bartender',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '19',
                        PayRate: '7.25',
                        JobTitle: 'Busser',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '20',
                        PayRate: '7.25',
                        JobTitle: 'FoodRunner',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '8.25',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
        {
            Employee: {
                EmployeeId: '50111',
                Name: 'Terminated  Employee',
                IsTerminated: true,
                Jobs: [
                    {
                        JobCodeId: '17',
                        PayRate: '9.75',
                        JobTitle: 'Dispatcher',
                        BreakConfiguration: 0,
                        IsValidJob: true,
                    },
                    {
                        JobCodeId: '5',
                        PayRate: '3.33',
                        JobTitle: 'Server',
                        BreakConfiguration: 3,
                        IsValidJob: true,
                    },
                ],
            },
            Day1: [],
            Day2: [],
            Day3: [],
            Day4: [],
            Day5: [],
            Day6: [],
            Day7: [],
            TotalHours: 0.0,
        },
    ],
    OpenTime: '00:00:00',
    FirstDayOfWeek: `${MONDAY}T00:00:00`,
    BusinessDay: `${WEDNESDAY}T00:00:00Z`,
    UsedDays: ['2021-11-28', '2021-12-01', '2021-12-22'],
});
