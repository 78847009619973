import { createIntl, createIntlCache } from 'react-intl';
import messages from '../translations/en.json';

const DEFAULT_LOCALE = `en`;
const intlConfig = {
    defaultLocale: DEFAULT_LOCALE,
    locale: navigator.language,
    messages,
};

/**
 * Mainly used in unit tests, or anywhere outside of react components.
 *
 * Try to use FormattedMessage over this, if possible.
 * Inside function components, you can alternatively use the `useIntl` hook instead.
 *
 * But these should all be equivalent anyway, inside react components that are wrapped by RawIntlProvider.
 */
export const rawIntl = createIntl(intlConfig, createIntlCache());
