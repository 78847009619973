import { ManagerOne } from 'services/ManagerOneApi/ManagerOne.API';

export const sortReportsData = (
    rows: ManagerOne.API.Reports.Models.ReportInfo[],
    orderBy: string,
    order: string
): ManagerOne.API.Reports.Models.ReportInfo[] => {
    rows?.sort((a, b) => {
        if (orderBy === 'Name') {
            const first = a.Name;
            const second = b.Name;
            if (order === 'asc') return first.localeCompare(second);
            return second.localeCompare(first);
        }

        const first = a.Description;
        const second = b.Description;
        if (order === 'asc') return first.localeCompare(second);
        return second.localeCompare(first);
    });
    return rows;
};
