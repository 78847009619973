/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import Button, {
    ButtonProps as DefaultButtonProps,
} from '@mui/material/Button';

export interface ButtonProps extends DefaultButtonProps {
    id?: string;
    dataTestId?: string;
    loading?: boolean;
    onClick: () => void;
}

const MainButton: FC<ButtonProps> = ({
    children,
    // loading = false,
    id,
    dataTestId,
    ...rest
}) => (
    <Button id={id} data-testid={dataTestId} {...rest}>
        {children}
    </Button>
);

export default MainButton;
