import { FC, useRef, useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { rawIntl } from 'services/intl';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const { formatMessage } = rawIntl;

type TextFieldEvent = TextFieldProps['onBlur'] & TextFieldProps['onChange'];

const Container = styled.div`
    flex: 1 1 auto;
`;

export const messages = {
    cancel: formatMessage({ id: 'Common.Cancel' }),
    close: formatMessage({ id: 'Common.Close' }),
    description: formatMessage({ id: 'Common.Description' }),
    name: formatMessage({ id: 'App.Containers.Reports.ReportName' }),
    save: formatMessage({ id: 'Common.Save' }),
    reportNameErrorMessage: formatMessage({
        id: 'App.Containers.Reports.ReportNameRequired',
    }),
    descriptionErrorMessage: formatMessage({
        id: 'App.Containers.Reports.DescriptionRequired',
    }),
    shareMessage: formatMessage({
        id: 'App.Containers.Reports.Share',
    }),
    reportTypeSystem: formatMessage({
        id: 'App.Containers.Reports.Organization',
    }),
    reportTypePersonal: formatMessage({
        id: 'App.Containers.Reports.Personal',
    }),
};

const ReportInformationForm: FC = () => {
    const maxnamelength = 100;
    const maxdescriptionlength = 500;
    const reportNameRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLInputElement>(null);
    const [reportNameIsValid, setReportNameIsValid] = useState(true);
    const [descriptionIsValid, setDescriptionIsValid] = useState(true);
    const [reportType, setReportType] = useState<'system' | 'organization'>();

    const validator: Record<string, boolean> = {
        reportNameIsValid,
        descriptionIsValid,
    };

    const validateReportName: TextFieldEvent = ({ target: { value } }) => {
        const isValid = !!value && value.length <= maxnamelength;
        setReportNameIsValid(isValid);
    };
    const validateDescription: TextFieldEvent = ({ target: { value } }) => {
        const isValid = !!value && value.length <= maxdescriptionlength;
        setDescriptionIsValid(isValid);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleReportTypeChange = (event: any) => {
        setReportType(event.target.value);
    };

    return (
        <Container>
            <Paper
                component="form"
                elevation={3}
                sx={{
                    alignItems: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    height: '85%',
                    width: '95%',
                    borderRadius: 0,
                    margin: 'auto',
                    padding: '20px',
                    boxShadow:
                        '0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
                }}
            >
                <TextField
                    error={!validator.reportNameIsValid}
                    inputRef={reportNameRef}
                    label={messages.name}
                    inputProps={{
                        maxLength: maxnamelength,
                        'data-testid': 'textfield-name',
                    }}
                    onBlur={validateReportName}
                    onChange={validateReportName}
                    required
                    type="string"
                    variant="standard"
                    sx={{ width: '60%', marginBottom: '0.5ch' }}
                    helperText={
                        validator.reportNameIsValid
                            ? ' '
                            : messages.reportNameErrorMessage
                    }
                />
                <TextField
                    error={!validator.descriptionIsValid}
                    inputRef={descriptionRef}
                    inputProps={{
                        maxLength: maxdescriptionlength,
                        'data-testid': 'textfield-description',
                    }}
                    onBlur={validateDescription}
                    onChange={validateDescription}
                    variant="standard"
                    label={messages.description}
                    sx={{
                        width: '60%',
                        marginBottom: '0.5ch',
                    }}
                    required
                    helperText={
                        validator.descriptionIsValid
                            ? ' '
                            : messages.descriptionErrorMessage
                    }
                />
                <FormControl variant="standard">
                    <Box sx={{ minWidth: 190 }}>
                        <InputLabel>{messages.shareMessage}</InputLabel>
                        <Select
                            defaultValue="system"
                            sx={{
                                width: [185, 190],
                            }}
                            value={reportType}
                            onChange={handleReportTypeChange}
                            inputProps={{
                                maxdescriptionlength,
                                'data-testid': 'report-type-dropdown',
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        bgcolor: 'background.paper',
                                        borderRadius: '4px !important',
                                        '& .MuiMenuItem-root': {
                                            padding: 1,
                                        },
                                    },
                                },
                            }}
                        >
                            <MenuItem value="organization">
                                {messages.reportTypePersonal}
                            </MenuItem>
                            <MenuItem value="system">
                                {messages.reportTypeSystem}
                            </MenuItem>
                        </Select>
                    </Box>
                </FormControl>
            </Paper>
        </Container>
    );
};

export default ReportInformationForm;
