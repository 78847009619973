import { useState, FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Stack, TextField, Typography, useTheme } from '@mui/material';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import MainButton from 'components/MainButton';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import {
    setUsernameAction,
    setOrganization,
    setSignedIn,
    setShowSignInModal,
    setLoginErrorOnSignInModal,
    setCredentialsExpired,
} from 'state/userSlice';
import { purgeReducers, RootState } from 'state/reducers';
import { setLoading } from 'state/commonSlice';
import { getSites, getUserAuthorizations, logIn } from 'services/api';
import { ROUTES } from 'routes';
import config from 'appConfig';
import { rawIntl } from 'services/intl';

type LogInFormContainerProps = {
    isSignInModalVisible: boolean;
    isOnLoginPage: boolean;
};

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    height: 100%;
    justify-content: center;
`;

const LogInFormContainer = styled.div<LogInFormContainerProps>`
    flex-direction: column;
    padding: 24px;
    width: 368px;
    max-width: 100%;
    flex-grow: 1;
    align-self: center;
    background-color: white;
    margin-top: ${(props) =>
        props.isSignInModalVisible && !props.isOnLoginPage ? '0px' : '72px'};
`;

const Login: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const signedIn = useSelector((state: RootState) => state.user.signedIn);
    const isSignInModalVisible = useSelector(
        (state: RootState) => state.user.isSignInModalVisible
    );
    const theme = useTheme();

    const [username, setUsername] = useState(config.user || '');
    const [password, setPassword] = useState(config.password || '');
    const [org, setOrg] = useState(config.org || '');
    const [loginError, setLoginError] = useState(false);
    const signInEnabled = !!username && !!password && !!org;
    const { pathname } = useLocation();
    const isOnLoginPage = pathname === ROUTES.LOGIN;

    const signIn = async () => {
        const res = await logIn(username, password, org);
        if (res.LoggedIn) {
            dispatch(setUsernameAction(username));
            dispatch(setOrganization(org));
            await getSites();
            await getUserAuthorizations();

            dispatch(setSignedIn(true));
            if (loginError) {
                setLoginError(false);
                dispatch(setLoginErrorOnSignInModal(false));
            }
            if (isSignInModalVisible) dispatch(setShowSignInModal(false));
            if (res.CredentialsExpired) {
                dispatch(setCredentialsExpired(true));
                navigate(ROUTES.CHANGE_PASSWORD, {
                    state: { from: ROUTES.LOGIN },
                });
            } else {
                navigate(ROUTES.DASHBOARD, {
                    state: { from: ROUTES.LOGIN },
                });
            }
        } else {
            setLoginError(true);
            dispatch(setLoginErrorOnSignInModal(true));
        }
    };

    const sleep = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms));

    const handleOnCancel = async () => {
        dispatch(setLoading(true));
        await sleep(750);
        dispatch(setSignedIn(false));
        navigate(ROUTES.LOGIN);
        purgeReducers();
    };

    useEffect(() => {
        if (signedIn)
            navigate(ROUTES.DASHBOARD, {
                state: { from: ROUTES.LOGIN },
            });

        return () => {
            setLoginError(false);
            setLoginErrorOnSignInModal(false);
        };
    }, [signedIn, navigate]);

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'Enter' && signInEnabled) {
            event.preventDefault();
            event.stopPropagation();
            signIn();
        }
    };

    const messages = {
        login: rawIntl.formatMessage({ id: 'App.Components.Login.LoginId' }),
        password: rawIntl.formatMessage({
            id: 'App.Components.Login.Password',
        }),
        org: rawIntl.formatMessage({ id: 'App.Components.Login.Organization' }),
    };

    return (
        <Wrapper>
            <LogInFormContainer
                isSignInModalVisible={isSignInModalVisible}
                isOnLoginPage={isOnLoginPage}
            >
                <Typography
                    variant="subtitle1"
                    textAlign="center"
                    fontWeight={500}
                >
                    <FormattedMessage id="App.Components.Login.LoginHeader" />
                </Typography>
                <form>
                    {loginError && (
                        <Typography
                            variant="caption"
                            color={theme.palette.error.main}
                            data-testid="login-error"
                            component="div"
                            sx={{ marginTop: 1 }}
                        >
                            <FormattedMessage id="App.Components.Login.Error" />
                        </Typography>
                    )}

                    <TextField
                        label={messages.login}
                        variant="standard"
                        inputProps={{
                            'data-testid': 'textfield-user',
                        }}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyDown={onKeyDown}
                        fullWidth
                        sx={{ marginTop: 3 }}
                        value={username}
                    />

                    <TextField
                        label={messages.password}
                        variant="standard"
                        type="password"
                        inputProps={{
                            'data-testid': 'textfield-pwd',
                        }}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={onKeyDown}
                        fullWidth
                        sx={{ marginTop: 3 }}
                        value={password}
                    />

                    <TextField
                        label={messages.org}
                        variant="standard"
                        inputProps={{
                            'data-testid': 'textfield-org',
                        }}
                        onChange={(e) => setOrg(e.target.value)}
                        onKeyDown={onKeyDown}
                        fullWidth
                        sx={{ marginTop: 3 }}
                        value={org}
                    />

                    {!isSignInModalVisible ? (
                        <Box sx={{ width: '100%', marginTop: 6 }}>
                            <Stack spacing={1}>
                                <MainButton
                                    id="signInButton"
                                    variant="contained"
                                    dataTestId="signin-button"
                                    onClick={signIn}
                                    disabled={!signInEnabled}
                                    endIcon={<ArrowForwardSharpIcon />}
                                >
                                    <FormattedMessage id="App.Components.Login.LogInAction" />
                                </MainButton>
                                <Link
                                    to="/recover-password"
                                    id="forgot-password"
                                    style={{
                                        textDecoration: 'none',
                                        color: theme.palette.primary.main,
                                        marginTop: '20px',
                                    }}
                                >
                                    <FormattedMessage id="App.Components.Login.ForgotPassword" />
                                </Link>
                            </Stack>
                        </Box>
                    ) : (
                        <Box display="flex" justifyContent="flex-end" mt={4}>
                            <MainButton
                                id="signInButton"
                                variant="contained"
                                dataTestId="signin-modal-signin-button"
                                onClick={signIn}
                                disabled={!signInEnabled}
                                sx={{ width: '90px' }}
                            >
                                <FormattedMessage id="App.Components.Login.LogInAction" />
                            </MainButton>

                            <MainButton
                                id="signin-modal-cancel-button"
                                variant="outlined"
                                dataTestId="signin-modal-cancel-button"
                                onClick={handleOnCancel}
                                sx={{ marginLeft: '12px', width: '90px' }}
                            >
                                <FormattedMessage id="App.Components.Login.CancelAction" />
                            </MainButton>
                        </Box>
                    )}
                </form>
            </LogInFormContainer>
        </Wrapper>
    );
};

export default Login;
