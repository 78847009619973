import { FC } from 'react';
import { rawIntl } from 'services/intl';
import { Chart } from 'react-google-charts';
import { formatCurrency } from 'services/utils.service';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/system';

interface IProps {
    dataRows: (string | number)[][];
    moneyBack: number;
    hasMoneyBack: boolean;
    orderMode: boolean;
}

const i18n = {
    orderModeTitle: rawIntl.formatMessage({
        id: 'App.Containers.OrderModeSalesCard.DataChart',
    }),
    revCenterTitle: rawIntl.formatMessage({
        id: 'App.Containers.OrderModeSalesCard.RevCenterChart',
    }),
    refunds: (
        <FormattedMessage id="App.Containers.OrderModeSalesCard.Refunds" />
    ),
    returns: (
        <FormattedMessage id="App.Containers.OrderModeSalesCard.Returns" />
    ),
};

const DataChart: FC<IProps> = ({
    dataRows,
    moneyBack,
    hasMoneyBack,
    orderMode,
}) => {
    const theme = useTheme();
    return (
        <>
            <Chart
                width="285px"
                height={hasMoneyBack ? '275px' : '305px'}
                style={{ marginTop: '8px', margin: 'auto' }}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={[['Order Mode', 'Sales'], ...dataRows]}
                data-testid="datachart"
                options={{
                    title: orderMode
                        ? i18n.orderModeTitle
                        : i18n.revCenterTitle,
                    pieSliceText: 'none',
                    pieHole: 0.7,
                    colors: [
                        '#A6CE39',
                        '#0F7A86',
                        '#7DB7BF',
                        '#E0D120',
                        '#0099BF',
                        '#00A77E',
                    ],
                    legend: {
                        position: 'bottom',
                        alignment: 'center',
                        textStyle: {
                            fontName: 'Roboto',
                            fontSize: 10,
                        },
                    },
                    chartArea: {
                        left: 10,
                        right: 10,
                    },
                    titleTextStyle: {
                        fontName: 'Roboto',
                        fontSize: 18,
                        bold: true,
                    },
                }}
            />
            {hasMoneyBack && (
                <Typography
                    component="div"
                    variant="caption"
                    color={theme.palette.text.disabled}
                    textAlign="center"
                >
                    Total {orderMode ? i18n.refunds : i18n.returns}:{' '}
                    {formatCurrency(moneyBack).replace('-', '')}
                </Typography>
            )}
        </>
    );
};
export default DataChart;
