export enum Order {
    Asc = 'asc',
    Des = 'desc',
}

export type Row = {
    depositId: string;
    name: string;
    description: string;
    time: string;
    amount: number;
};

export const sortEditDepositData = (
    array: Row[],
    orderBy: string,
    order: Order
): Row[] => {
    array?.sort((a, b) => {
        if (orderBy === 'name') {
            const first = a[orderBy];
            const second = b[orderBy];
            if (order === Order.Asc) return first.localeCompare(second);
            return second.localeCompare(first);
        }
        if (orderBy === 'description') {
            const first = a[orderBy];
            const second = b[orderBy];
            if (order === Order.Asc) return first.localeCompare(second);
            return second.localeCompare(first);
        }
        if (orderBy === 'time') {
            const first = a[orderBy];
            const second = b[orderBy];
            if (order === Order.Asc) return first.localeCompare(second);
            return second.localeCompare(first);
        }

        if (orderBy === 'amount') {
            const first = a[orderBy];
            const second = b[orderBy];
            if (order === Order.Asc) return first - second;
            return second - first;
        }
        return 0;
    });

    return array;
};
