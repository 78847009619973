/* istanbul ignore file */
import { NavItemArray } from '@ncr-design-system/pattern-sidebar-navigation';
import { ROUTES } from 'routes';
import { ManagerOne } from 'services/ManagerOneApi/ManagerOne.API';
import featureFlags from 'feature-flags.dev';
import messages from './i18n';

const {
    deposits,
    functions,
    guestChecks,
    houseAccounts,
    punches,
    reports,
    scheduler,
    tipShare,
    utilities,
    dashboard,
    menu,
    menuItems,
    menuItemRouting,
    modifierGroups,
    menuCategories,
    panels,
    submenus,
    employees,
    users,
} = messages;

const {
    FINANCIAL_RECONCILIATION_DEPOSIT_ADMINISTRATOR,
    LABOR_DATA_MANAGEMENT_SHIFT_WRITER,
    TIP_MANAGEMENT_WRITER,
    LABOR_DATA_MANAGEMENT_SCHEDULE_WRITER,
    TIP_MANAGEMENT_SERVICE_TIP_SHARE_DISTRIBUTOR,
    FINANCIAL_RECONCILIATION_HOUSE_ACCOUNT_ADMINISTRATOR,
    ALOHA_MANAGER_ONE_USER,
} = ManagerOne.API.User.UserRoles;

interface INavItemNestedChild {
    title: string;
    children: NavItemArray;
}

export const buildManagerOneRoutesFromUserRoles = (
    userAuthorizations: ManagerOne.API.User.UserRoles[]
): NavItemArray => {
    const finalRoutes: NavItemArray = [];

    if (userAuthorizations?.includes(ALOHA_MANAGER_ONE_USER)) {
        const functionsRoute: INavItemNestedChild = {
            title: functions,
            children: [],
        };

        if (
            userAuthorizations?.includes(
                FINANCIAL_RECONCILIATION_DEPOSIT_ADMINISTRATOR
            ) &&
            featureFlags.managerOne.functions.deposits
        ) {
            functionsRoute.children.push({
                title: deposits,
                href: ROUTES.DEPOSITS,
            });
        }

        if (
            userAuthorizations?.includes(LABOR_DATA_MANAGEMENT_SHIFT_WRITER) &&
            userAuthorizations?.includes(TIP_MANAGEMENT_WRITER) &&
            featureFlags.managerOne.functions.punches
        ) {
            functionsRoute.children.push({
                title: punches,
                href: ROUTES.PUNCHES.BASE,
            });
        }

        if (
            userAuthorizations?.includes(
                LABOR_DATA_MANAGEMENT_SCHEDULE_WRITER
            ) &&
            featureFlags.managerOne.functions.scheduler
        ) {
            functionsRoute.children.push({
                title: scheduler,
                href: ROUTES.SCHEDULER,
            });
        }
        if (featureFlags.managerOne.functions.guestChecks)
            functionsRoute.children.push({
                title: guestChecks,
                href: ROUTES.GUEST_CHECKS,
            });

        if (
            userAuthorizations?.includes(
                FINANCIAL_RECONCILIATION_HOUSE_ACCOUNT_ADMINISTRATOR
            ) &&
            featureFlags.managerOne.functions.houseAccounts
        ) {
            functionsRoute.children.push({
                title: houseAccounts,
                href: ROUTES.HOUSE_ACCOUNTS,
            });
        }

        if (
            userAuthorizations?.includes(
                TIP_MANAGEMENT_SERVICE_TIP_SHARE_DISTRIBUTOR
            ) &&
            featureFlags.managerOne.functions.tipShare
        ) {
            functionsRoute.children.push({
                title: tipShare,
                href: ROUTES.TIP_SHARE,
            });
        }

        if (functionsRoute.children.length) finalRoutes.push(functionsRoute);

        if (featureFlags.managerOne.reports)
            finalRoutes.push({
                title: reports,
                href: ROUTES.REPORTS.BASE,
            });
    }

    if (featureFlags.managerOne.utilities)
        finalRoutes.push({
            title: utilities,
            href: ROUTES.UTILITIES,
        });

    return finalRoutes;
};

export const buildCommonEditsRoutes = (): NavItemArray => {
    const finalRoutes: NavItemArray = [];

    if (featureFlags.commonEdits.dashboard)
        finalRoutes.push({
            title: dashboard,
            href: ROUTES.DASHBOARD,
        });

    const menuRoutes: INavItemNestedChild = {
        title: menu,
        children: [],
    };

    if (featureFlags.commonEdits.menu.menuItems)
        menuRoutes.children.push({
            title: menuItems,
            href: ROUTES.MENU_ITEMS,
        });

    if (featureFlags.commonEdits.menu.menuItemRouting)
        menuRoutes.children.push({
            title: menuItemRouting,
            href: ROUTES.MENU_ITEM_ROUTING,
        });

    if (featureFlags.commonEdits.menu.modifierGroups)
        menuRoutes.children.push({
            title: modifierGroups,
            href: ROUTES.MODIFIER_GROUPS,
        });

    if (featureFlags.commonEdits.menu.menuCategories)
        menuRoutes.children.push({
            title: menuCategories,
            href: ROUTES.MENU_CATEGORIES,
        });

    if (featureFlags.commonEdits.menu.panels)
        menuRoutes.children.push({
            title: panels,
            href: ROUTES.PANELS,
        });

    if (featureFlags.commonEdits.menu.submenus)
        menuRoutes.children.push({
            title: submenus,
            href: ROUTES.SUBMENUS,
        });

    if (menuRoutes.children.length) finalRoutes.push(menuRoutes);

    if (featureFlags.commonEdits.employees)
        finalRoutes.push({
            title: employees,
            href: ROUTES.EMPLOYEES,
        });

    if (featureFlags.commonEdits.users)
        finalRoutes.push({
            title: users,
            href: ROUTES.USERS,
        });

    return finalRoutes;
};

export const getRoutes = (
    userAuthorizations: ManagerOne.API.User.UserRoles[]
): NavItemArray => {
    const combinedRoutes = [
        ...buildCommonEditsRoutes(),
        ...buildManagerOneRoutesFromUserRoles(userAuthorizations),
    ];
    return combinedRoutes.sort((a, b) => a.title.localeCompare(b.title));
};
