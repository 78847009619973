import { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DialogFooter from '@mui/material/DialogActions';
import Close from '@mui/icons-material/Close';
import styled from 'styled-components';
import MainButton from 'components/MainButton';
import { rawIntl } from 'services/intl';
import { deleteDeposit } from 'services/api';
import useSelect from 'state/selector';
import useAppDispatch from 'state/dispatch';
import { setDialogStatus } from 'state/depositsSlice';
import { momentBusinessDay } from 'services/utils.service';

const { formatMessage } = rawIntl;

export const messages = {
    close: formatMessage({ id: 'Common.Close' }),
    deleteConfirmationTitle: formatMessage({
        id: 'Common.DeleteConfirmationTitle',
    }),
    deleteConfirmation: formatMessage({ id: 'Common.DeleteConfirmation' }),
    no: formatMessage({ id: 'Common.No' }),
    yes: formatMessage({ id: 'Common.Yes' }),
};

const Header = styled(DialogTitle)`
    display: flex;
    justify-content: space-between;
`;

const CloseButton = styled(IconButton)`
    padding-right: 0;
`;

const DeleteDepositsDialog: FC = () => {
    const dispatch = useAppDispatch();
    const day = useSelect((s) => s.deposits.businessDay);
    const siteId = useSelect((s) => s.sitesInfo.selectedSiteId);
    const id = useSelect((s) => s.deposits.depositId);

    const close = () => dispatch(setDialogStatus(null));

    const confirm = async () => {
        const businessDay = momentBusinessDay(day);
        close();
        await deleteDeposit({ businessDay, siteId, id });
        dispatch(setDialogStatus('submit'));
    };

    return (
        <Dialog fullWidth open>
            <Header>
                {messages.deleteConfirmationTitle}
                <Tooltip title={messages.close}>
                    <CloseButton onClick={close}>
                        <Close />
                    </CloseButton>
                </Tooltip>
            </Header>
            <DialogContent>{messages.deleteConfirmation}</DialogContent>
            <DialogFooter>
                <MainButton
                    variant="text"
                    aria-label={messages.yes}
                    onClick={confirm}
                    sx={{ textTransform: 'uppercase' }}
                >
                    {messages.yes}
                </MainButton>
                <MainButton
                    variant="text"
                    aria-label={messages.no}
                    onClick={close}
                    sx={{ textTransform: 'uppercase' }}
                >
                    {messages.no}
                </MainButton>
            </DialogFooter>
        </Dialog>
    );
};

export default DeleteDepositsDialog;
