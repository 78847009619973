/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import TableSortLabel from '@mui/material/TableSortLabel';
import { ManagerOne } from 'services/ManagerOneApi/ManagerOne.API';
import { GetScheduleDataResponse } from 'services/ManagerOneApi';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { calculateHoursDifference } from 'services/utils.service';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import useSelect from 'state/selector';
import { daysOfWeek, sortScheduleData } from './schedulerHelper';
import DayCell from './DayCell';
import JobChips from './JobChips';

type Order = 'asc' | 'desc';

interface HeadCell {
    id: string;
    label: string;
}

const headCells: HeadCell[] = [
    {
        id: 'Name',
        label: 'Employee Name',
    },
    {
        id: 'JobTitle',
        label: 'Job Code',
    },
    {
        id: 'TotalHours',
        label: 'Hours',
    },
];

daysOfWeek.map((day) =>
    headCells.push({
        id: day,
        label: '',
    })
);

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow sx={{ height: '40px' }}>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            flexDirection: 'row',
                            padding: '0 0 0 16px',
                            textAlign: {
                                xs: 'center',
                                sm: 'center',
                                md: 'initial',
                            },
                        }}
                    >
                        <TableSortLabel
                            data-testid={headCell.id}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            sx={{ minWidth: '52px' }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const updateHeadCellsToCurrentWeek = (selectedBusinessDay: string) => {
    const selectedDay = moment(selectedBusinessDay);

    // eslint-disable-next-line array-callback-return
    Array.from({ length: 7 }, (_, i) => {
        const startOfWeekTest = selectedDay.startOf('week').add(1, 'day');
        const finalDayLabel = startOfWeekTest.add(i, 'days');

        let indexOfDay = finalDayLabel.get('day') - 1;

        // Adjust for Sunday being last day on calendar view
        if (indexOfDay === -1) indexOfDay = 6;

        const indexToUpdate = headCells.findIndex(
            (item: HeadCell) => item.id === daysOfWeek[indexOfDay]
        );

        headCells[indexToUpdate] = {
            ...headCells[indexToUpdate],
            label: `${finalDayLabel.format('ddd')} ${finalDayLabel.get(
                'date'
            )}`,
        };
    });
};

type TotalHours = {
    [key in typeof daysOfWeek[number] | 'Total']: number;
};

const getTotalHours = (
    data: GetScheduleDataResponse,
    timezone: string,
    isUserAllowedToViewWages: boolean
) => {
    const hours: TotalHours = {
        Total: 0,
        Day1: 0,
        Day2: 0,
        Day3: 0,
        Day4: 0,
        Day5: 0,
        Day6: 0,
        Day7: 0,
    };
    const wages: TotalHours = {
        Total: 0,
        Day1: 0,
        Day2: 0,
        Day3: 0,
        Day4: 0,
        Day5: 0,
        Day6: 0,
        Day7: 0,
    };

    data?.EmployeeSchedules?.forEach((es) => {
        daysOfWeek.forEach((day) => {
            if (es[day]) {
                const shifts = es[day];
                shifts.forEach(
                    (
                        shift: ManagerOne.API.Labor.Models.Scheduler.ScheduledShift
                    ) => {
                        const shiftHours = calculateHoursDifference(
                            shift.StartTime,
                            shift.EndTime,
                            timezone
                        );
                        const matchingJobs = es.Employee.Jobs?.filter(
                            (j) => j.JobCodeId === shift.JobCode
                        );
                        if (matchingJobs?.length !== 1) {
                            console.error(
                                `${
                                    'shift.JobCode has no matching jobs' +
                                    '(or multiple jobs with same jobCode) configured for employee: '
                                }${es.Employee.Name}`
                            );

                            console.error(shift);
                        } else {
                            const job = matchingJobs[0];
                            hours[day] += shiftHours;
                            hours.Total =
                                Math.round(
                                    (shiftHours + Number(hours.Total)) * 100
                                ) / 100;

                            if (isUserAllowedToViewWages) {
                                const shiftWages =
                                    shiftHours * Number(job.PayRate);
                                wages[day] += shiftWages;
                                wages.Total =
                                    Math.round(
                                        (shiftWages + Number(wages.Total)) * 100
                                    ) / 100;
                            }
                        }
                    }
                );
            }
        });
    });
    return { hours, wages };
};

const EnhancedTable: FC = () => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>('Name');
    const [totalHours, setTotalHours] = useState<TotalHours | null>(null);
    const [totalWages, setTotalWages] = useState<TotalHours | null>(null);
    const [page, setPage] = useState(0);
    const data = useSelect((s) => s.scheduler.unsavedScheduleData);
    const [filteredData, setFilteredData] = useState(data);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const selectedBusinessDay = useSelect((s) => s.common.selectedBusinessDay);

    const { LABOR_DATA_MANAGEMENT_PAY_RATE_VIEWER } =
        ManagerOne.API.User.UserRoles;

    const isUserAllowedToViewWages = useSelector(
        (state: RootState) => state.user.userAuthorizations
    )?.includes(LABOR_DATA_MANAGEMENT_PAY_RATE_VIEWER);

    const timezone = useSelector(
        (state: RootState) =>
            state.dashboard.dashboardData.Weather?.Site?.SiteTimeZone ||
            'America/New_York'
    );
    const selectedJobCodes = useSelector(
        (state: RootState) => state.scheduler.selectedJobCodes
    );

    const filterJobCodes = (
        scheduleData: GetScheduleDataResponse
    ): GetScheduleDataResponse => {
        const result = scheduleData.EmployeeSchedules?.filter(
            (es) =>
                es.Employee.Jobs?.filter((job) =>
                    selectedJobCodes.includes(job.JobTitle)
                ).length
        );
        return new GetScheduleDataResponse({
            ...scheduleData,
            EmployeeSchedules: result,
        });
    };

    useEffect(() => {
        let filteredDataByJobCode = data;
        if (selectedJobCodes.length > 0)
            filteredDataByJobCode = filterJobCodes(data);
        updateHeadCellsToCurrentWeek(selectedBusinessDay);
        const { hours: totalHoursResponse, wages: totalWagesResponse } =
            getTotalHours(
                filteredDataByJobCode,
                timezone,
                isUserAllowedToViewWages
            );
        setTotalHours(totalHoursResponse);
        setTotalWages(totalWagesResponse);
        setFilteredData(filteredDataByJobCode);
    }, [
        selectedBusinessDay,
        data,
        timezone,
        isUserAllowedToViewWages,
        selectedJobCodes,
    ]);

    const rows: ManagerOne.API.Labor.Models.Scheduler.EmployeeSchedule[] =
        filteredData.EmployeeSchedules;

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const sortedRows = sortScheduleData(rows, orderBy, order);

    const totalsRow = (isHours = true) => (
        <TableRow hover tabIndex={-1} sx={{ height: '44px' }}>
            <TableCell
                align="left"
                sx={{ padding: '0', borderBottom: isHours ? 'none' : '' }}
            />
            <TableCell
                align="center"
                sx={{
                    padding: '0',
                    fontWeight: 700,
                    borderBottom: isHours ? 'none' : '',
                }}
            >
                {isHours ? (
                    <FormattedMessage id="App.Components.Scheduler.TotalHours" />
                ) : (
                    <FormattedMessage id="App.Components.Scheduler.TotalWages" />
                )}
            </TableCell>
            <TableCell
                align="center"
                sx={{
                    padding: '0',
                    fontWeight: 700,
                    borderBottom: isHours ? 'none' : '',
                }}
            >
                {isHours ? (
                    totalHours?.Total
                ) : (
                    <FormattedNumber
                        value={totalWages?.Total || 0}
                        style="currency"
                        currency="USD"
                        maximumFractionDigits={2}
                        minimumFractionDigits={2}
                    />
                )}
            </TableCell>
            {daysOfWeek.map((day) => (
                <TableCell
                    key={`${isHours.toString()} ${day}`}
                    padding="none"
                    sx={{
                        textAlign: 'center',
                        borderBottom: isHours ? 'none' : '',
                    }}
                >
                    <Typography
                        component="div"
                        sx={{
                            width: {
                                xs: '54px',
                                sm: '54px',
                                md: '54px',
                                lg: '60px',
                                xl: '92px',
                            },
                        }}
                    >
                        {isHours
                            ? totalHours?.[day]
                            : totalWages && (
                                  <FormattedNumber
                                      value={totalWages[day]}
                                      style="currency"
                                      currency="USD"
                                      maximumFractionDigits={2}
                                      minimumFractionDigits={2}
                                  />
                              )}
                    </Typography>
                </TableCell>
            ))}
        </TableRow>
    );

    return (
        <Paper elevation={8} sx={{ margin: '0px auto', borderRadius: '4px' }}>
            <JobChips />
            <TableContainer>
                <Table aria-labelledby="tableTitle" size="medium">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody sx={{ marginBottom: '50px' }}>
                        {sortedRows
                            ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => {
                                const { Employee } = row;
                                const labelId = `table-name-${Employee.EmployeeId}`;

                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={Employee.EmployeeId}
                                        sx={{ height: '44px' }}
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            sx={{
                                                padding: '0 0 0 16px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                minWidth: '150px',
                                                maxWidth: ['100px', '150px'],
                                                // TODO: ask QA team how to validate/test this case
                                                backgroundColor:
                                                    Employee.IsTerminated
                                                        ? '#ED5562'
                                                        : 'inherit',
                                            }}
                                        >
                                            {Employee.Name}
                                        </TableCell>
                                        <TableCell
                                            padding="none"
                                            sx={{
                                                paddingLeft: '16px',
                                                minWidth: '100px',
                                                maxWidth: ['100px'],
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {Employee.Jobs?.map(
                                                (j) => j.JobTitle
                                            )
                                                .join(', ')
                                                .slice(0, -1)}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{ padding: '0' }}
                                        >
                                            {row.TotalHours}
                                        </TableCell>
                                        {daysOfWeek.map((day, i) => (
                                            <DayCell
                                                key={`${Employee.EmployeeId}, ${day}`}
                                                cellNumber={`${Employee.EmployeeId}, ${day}`}
                                                day={day}
                                                index={i}
                                                firstDayOfWeek={
                                                    data.FirstDayOfWeek
                                                }
                                                row={row}
                                                scheduleData={data}
                                            />
                                        ))}
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        {totalsRow()}
                        {isUserAllowedToViewWages && totalsRow(false)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows?.length || -1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                }}
            />
        </Paper>
    );
};

export default EnhancedTable;
