import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Chart } from 'react-google-charts';
import { rawIntl } from 'services/intl';

interface IProps {
    dataRows: (string | number)[][];
}

const DataChart: FC<IProps> = ({ dataRows }) => (
    <>
        <Chart
            width="320px"
            height="320px"
            chartType="PieChart"
            loader={
                <div>
                    <FormattedMessage id="Common.LoadingChart" />
                </div>
            }
            data={dataRows}
            options={{
                title: rawIntl.formatMessage({
                    id: 'App.Containers.LaborCard.Title',
                }),
                pieSliceText: 'none',
                pieHole: 0.7,
                colors: [
                    '#A6CE39',
                    '#0F7A86',
                    '#7DB7BF',
                    '#E0D120',
                    '#0099BF',
                    '#00A77E',
                ],
                legend: {
                    position: 'bottom',
                    alignment: 'center',
                    textStyle: {
                        fontName: 'Roboto',
                        fontSize: 10,
                    },
                },
                chartArea: {
                    left: 10,
                    right: 10,
                },
                titleTextStyle: {
                    fontName: 'Roboto',
                    fontSize: 18,
                    bold: true,
                },
            }}
            formatters={[
                {
                    type: 'NumberFormat',
                    column: 1,
                    options: {
                        prefix: '$',
                    },
                },
            ]}
        />
    </>
);
export default DataChart;
