import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { setShowReleaseNotesModal } from 'state/commonSlice';
import ReleaseMessages from 'release-notes.json';
import MainButton from '../MainButton';

const ReleaseNotes: FC = () => {
    const dispatch = useDispatch();
    const open = useSelector(
        (state: RootState) => state.common.showReleaseNotesModal
    );

    const handleClose = () => {
        dispatch(setShowReleaseNotesModal(false));
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="release-notes-title"
            aria-describedby="release-notes-description"
            maxWidth={false}
            data-testid="release-notes-dialog-box"
            id="release-nodes-module"
        >
            <DialogTitle
                id="release-notes-title"
                sx={{ fontWeight: '500', fontSize: '18px !important' }}
            >
                <FormattedMessage id="App.Components.HeaderNavigation.ReleaseNotesTitle" />
                <IconButton onClick={handleClose} sx={{ float: 'right' }}>
                    <CloseIcon data-testid="release-notes-close" />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <DialogContentText
                    id="release-notes-description"
                    sx={{ marginBottom: '15px' }}
                >
                    {ReleaseMessages.releaseDate}
                </DialogContentText>
                {ReleaseMessages?.sections?.map((section) => (
                    <Typography
                        fontWeight="500"
                        component="div"
                        fontSize="18px"
                        marginBottom="15px"
                        data-testid="voids-heading"
                        key={section.title}
                    >
                        {section.title}
                        <ul style={{ margin: 0 }}>
                            {section.bulletPoints?.map((note) => (
                                <DialogContentText
                                    key={note.primary}
                                    id="section-notes"
                                >
                                    <li>{note.primary}</li>
                                </DialogContentText>
                            ))}
                        </ul>
                    </Typography>
                ))}
            </DialogContent>
            <DialogActions>
                <MainButton
                    variant="text"
                    color="primary"
                    onClick={handleClose}
                    dataTestId="release-notes-ok"
                >
                    <FormattedMessage id="Common.Ok" />
                </MainButton>
            </DialogActions>
        </Dialog>
    );
};

export default ReleaseNotes;
