import { FormattedMessage } from 'react-intl';

export const cols = [
    {
        field: 'PenaltyPaySummary',
        message: null,
    },
    {
        field: 'EmployeeName',
        message: <FormattedMessage id="App.Containers.Punch.EmployeeName" />,
    },
    {
        field: 'EmployeeId',
        message: <FormattedMessage id="App.Containers.Punch.EmployeeId" />,
    },
    {
        field: 'JobTitle',
        message: <FormattedMessage id="App.Containers.Punch.Job" />,
    },
    {
        field: 'TimeIn',
        message: <FormattedMessage id="App.Containers.Punch.TimeIn" />,
    },
    {
        field: 'TimeOut',
        message: <FormattedMessage id="App.Containers.Punch.TimeOut" />,
    },
    {
        field: 'ClockedOutByEod',
        message: <FormattedMessage id="App.Containers.Punch.ClockedOutByEod" />,
    },
    {
        field: 'Hours',
        message: <FormattedMessage id="App.Containers.Punch.Hours" />,
    },
    {
        field: 'BreakHours',
        message: <FormattedMessage id="App.Containers.Punch.BreakHours" />,
    },
] as const;

export type Column = typeof cols[number];
