import { createSlice } from '@reduxjs/toolkit';
import { ManagerOne } from 'services/ManagerOneApi/ManagerOne.API';

interface UserState {
    username: string;
    organization: string;
    signedIn: boolean;
    userAuthorizations: ManagerOne.API.User.UserRoles[];
    isSignInModalVisible: boolean;
    showLoginError: boolean;
    credentialsExpired: boolean;
}

const initialState: UserState = {
    username: '',
    organization: '',
    signedIn: false,
    userAuthorizations: [],
    isSignInModalVisible: false,
    showLoginError: false,
    credentialsExpired: false,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUsernameAction: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.username = action.payload;
        },
        setOrganization: (state, action) => {
            state.organization = action.payload;
        },
        setSignedIn: (state, action) => {
            state.signedIn = action.payload;
        },
        setUserAuthorizations: (state, action) => {
            state.userAuthorizations = action.payload;
        },
        setShowSignInModal: (state, action) => {
            state.isSignInModalVisible = action.payload;
        },
        setLoginErrorOnSignInModal: (state, action) => {
            state.showLoginError = action.payload;
        },
        setCredentialsExpired: (state, { payload }) => {
            state.credentialsExpired = payload;
        },
        purge: () => initialState,
    },
});
// Action creators are generated for each case reducer function
export const {
    setUsernameAction,
    setOrganization,
    setSignedIn,
    setUserAuthorizations,
    setShowSignInModal,
    setLoginErrorOnSignInModal,
    setCredentialsExpired,
    purge,
} = userSlice.actions;

export default userSlice;
