import { FC, useState } from 'react';
import moment from 'moment-timezone';
import { ManagerOne } from 'services/ManagerOneApi/ManagerOne.API';
import { Typography, TableCell, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import { X_LARGE, MEDIUM } from 'styles/responsive_breakpoints';
import { GetScheduleDataResponse } from 'services/ManagerOneApi';
import {
    resetShiftData,
    setShiftData,
    setShiftModalVisibleShifts,
    initialState,
} from 'state/schedulerSlice';
import { useDispatch } from 'react-redux';
import ShiftController from './ShiftModal/ShiftController';
import { daysOfWeek } from './schedulerHelper';

const getDayCellBackgroundColor = (
    hasShift: boolean,
    disabled: boolean,
    showHoverColor = false
): string => {
    let backgroundColor = 'inherit';
    if (disabled) backgroundColor = '#f4f4f4';
    if (hasShift) backgroundColor = '#a6ce39';
    if (showHoverColor) backgroundColor = '#cce8eb';

    return backgroundColor;
};

interface DayCellWrapperProps {
    hasShift: boolean;
    disabled: boolean;
    multipleShifts?: boolean;
}

const DayCellWrapper = styled.div<DayCellWrapperProps>`
    border: ${({ multipleShifts }) =>
        multipleShifts ? `none` : ` 1px solid #ccc;`};
    height: 24px;
    text-align: center;
    cursor: ${({ disabled, hasShift }) =>
        disabled && !hasShift ? 'initial' : 'pointer'};
    background-color: ${({ hasShift, disabled, multipleShifts }) =>
        getDayCellBackgroundColor(hasShift && !multipleShifts, disabled)};
    display: flex;
    justify-content: ${({ multipleShifts }) =>
        multipleShifts ? 'space-between' : 'center'};
    align-content: center;
    gap: 3px;
    width: 92px;

    &:hover {
        background-color: ${({ hasShift, multipleShifts, disabled }) =>
            getDayCellBackgroundColor(
                hasShift,
                disabled,
                multipleShifts || (!disabled && !hasShift)
            )};

        .addShift {
            display: flex !important;
            justify-content: center;
            align-items: center;
        }
    }

    @media (max-width: ${X_LARGE}) {
        width: 60px;
        margin: 0 8px;
    }

    @media (max-width: ${MEDIUM}) {
        width: 54px;
        margin: 0 4px;
    }
`;

const SplitShift = styled.div`
    background-color: ${getDayCellBackgroundColor(true, false)};
    display: flex;
    flex: 1;
    justify-content: center;
`;

const convertTimes = (
    startTime: string,
    endTime: string,
    onlyStart: boolean
) => {
    const startMinutes = moment(startTime).minutes();
    const endMinutes = moment(endTime).minutes();
    let formattedStartTime = moment(startTime).format('h A');
    let formattedEndTime = moment(endTime).format('h A');
    if (startMinutes > 0)
        formattedStartTime = moment(startTime).format('h:mm A');

    if (endMinutes > 0) formattedEndTime = moment(endTime).format('h:mm A');

    if (onlyStart) return `${formattedStartTime}`;

    return `${formattedStartTime} - ${formattedEndTime}`;
};

export interface IProps {
    day: typeof daysOfWeek[number];
    row: ManagerOne.API.Labor.Models.Scheduler.EmployeeSchedule;
    firstDayOfWeek: string;
    index: number;
    scheduleData: GetScheduleDataResponse;
    cellNumber: string | number;
}

const DayCell: FC<IProps> = ({
    row,
    day,
    firstDayOfWeek,
    index,
    scheduleData,
    cellNumber = 0,
}) => {
    const dispatch = useDispatch();
    const isSmallScreen = useMediaQuery(`(max-width:${X_LARGE})`);
    const [showEditShiftModal, setShowEditShiftModal] = useState(false);
    const [isExistingShift, setIsExistingShift] = useState(false);

    const shifts: ManagerOne.API.Labor.Models.Scheduler.ScheduledShift[] =
        row[day];

    const hasMultipleShifts = shifts?.length > 1;
    const startTime = shifts[0]?.StartTime;
    const endTime = shifts[0]?.EndTime;

    const dateOfCell = moment(firstDayOfWeek).add(index, 'days');
    const today = moment();

    const disabled = dateOfCell.diff(today, 'days') < 0;

    const getClassName = (isDisabled: boolean) => {
        if (isDisabled) return 'disabled';
        return 'addShift';
    };

    const handleDayCellClick = () => {
        if (startTime) {
            setIsExistingShift(true);
            const selectedCellsShifts = row[day];

            const newShiftData = [...initialState.shiftData];

            selectedCellsShifts.forEach((data, i) => {
                newShiftData[i] = {
                    job: data.JobCode,
                    startTime: new Date(data.StartTime),
                    endTime: new Date(data.EndTime),
                    reassignedEmployee: '',
                };
            });

            dispatch(setShiftModalVisibleShifts(selectedCellsShifts.length));
            dispatch(setShiftData(newShiftData));
        } else {
            setIsExistingShift(false);
            dispatch(resetShiftData(3));
        }

        if (disabled && !startTime) return;

        setShowEditShiftModal(true);
    };

    return (
        <TableCell
            align="center"
            padding="none"
            data-testid={`scheduler-cell-${cellNumber}`}
            sx={{
                padding: 'none',
                maxWidth: '60px',
            }}
        >
            {hasMultipleShifts ? (
                <DayCellWrapper
                    hasShift
                    multipleShifts
                    disabled={false}
                    onClick={handleDayCellClick}
                    data-testid={`edit-shift-cell-${cellNumber}`}
                >
                    {shifts.map((shift) => {
                        const shiftStart = shift.StartTime;
                        const shiftEnd = shift.EndTime;

                        return (
                            <SplitShift key={shiftStart}>
                                <Typography
                                    variant="caption"
                                    fontSize={10}
                                    fontWeight={500}
                                    sx={{
                                        letterSpacing: '0.1em',
                                        lineHeight: `1.1`,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                        maxWidth: '10px',
                                    }}
                                >
                                    {convertTimes(shiftStart, shiftEnd, true)}
                                </Typography>
                            </SplitShift>
                        );
                    })}
                </DayCellWrapper>
            ) : (
                <DayCellWrapper
                    hasShift={!!startTime}
                    disabled={disabled}
                    multipleShifts={false}
                    onClick={handleDayCellClick}
                    data-testid={`${
                        startTime ? 'edit' : 'add'
                    }-shift-cell-${cellNumber}`}
                >
                    <Typography
                        variant="caption"
                        className={getClassName(disabled)}
                        fontSize={10}
                        fontWeight={500}
                        style={{
                            display: startTime ? 'contents' : 'none',
                        }}
                    >
                        {startTime
                            ? convertTimes(startTime, endTime, isSmallScreen)
                            : `+ ADD ${isSmallScreen ? '' : 'SHIFT'}`}
                    </Typography>
                </DayCellWrapper>
            )}
            {showEditShiftModal && (
                <ShiftController
                    open={showEditShiftModal}
                    close={() => setShowEditShiftModal(false)}
                    readonly={disabled}
                    dateOfCell={dateOfCell}
                    employee={row.Employee}
                    scheduleData={scheduleData}
                    day={day}
                    isEditShift={isExistingShift}
                />
            )}
        </TableCell>
    );
};

export default DayCell;
