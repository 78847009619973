import { FC, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MainButton from 'components/MainButton';
import { IErrorMessages, getErrorMessage } from './messages';

export interface IProps {
    errorType: IErrorMessages;
    cancelOverride?: () => void | null;
    pathname?: string;
}

const ErrorModal: FC<IProps> = ({
    errorType,
    cancelOverride = null,
    pathname = '',
}) => {
    const { title: initialTitle, message: intitialMessage } =
        getErrorMessage(errorType);
    const [open, setOpen] = useState(true);
    const [title, setTitle] = useState(initialTitle);
    const [previousPathname, setPreviousPathname] = useState('');
    const [message, setMessage] = useState(intitialMessage);

    const handleClose = () => {
        setOpen(false);
        if (cancelOverride) cancelOverride();
    };

    useEffect(() => {
        const { title: titleId, message: messageId } =
            getErrorMessage(errorType);
        setTitle(titleId);
        setMessage(messageId);
        if (previousPathname !== pathname) {
            setPreviousPathname(pathname);
            setOpen(true);
        }
    }, [setTitle, setMessage, errorType, pathname, previousPathname]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <FormattedMessage id={title} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormattedMessage id={message} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MainButton
                        variant="text"
                        onClick={handleClose}
                        dataTestId="error-modal-close"
                    >
                        Close
                    </MainButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ErrorModal;
