import { FC, MouseEventHandler, useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Deposit } from 'services/ManagerOneApi';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { IconButton, Tooltip } from '@mui/material';
import styled from 'styled-components';
import { rawIntl } from 'services/intl';
import useAppDispatch from 'state/dispatch';
import { setDepositId, setDialogStatus } from 'state/depositsSlice';
import useSelect from 'state/selector';
import { Order, Row, sortEditDepositData } from './editDepositsHelper';

const headCells = [
    {
        id: 'name',
        label: 'User',
    },
    {
        id: 'description',
        label: 'Description',
    },
    {
        id: 'time',
        label: 'Date/Time',
    },
    {
        id: 'amount',
        label: 'Amount',
    },
    {
        id: 'last',
        label: '',
    },
] as const;

const TableRoot = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
        0 1px 5px 0 rgb(0 0 0 / 12%);
`;

type HeadCellIds = typeof headCells[number]['id'];

const TotalsRow: FC<{ totalAmount: number }> = ({ totalAmount }) => (
    <TableRow hover tabIndex={-1} sx={{ height: '44px' }}>
        <TableCell
            sx={{
                fontWeight: 700,
                borderBottom: 'none',
            }}
        >
            <FormattedMessage id="Common.Total" />
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }} />
        <TableCell sx={{ borderBottom: 'none' }} />
        <TableCell
            align="right"
            sx={{
                fontWeight: 700,
                borderBottom: 'none',
            }}
        >
            <FormattedNumber
                value={totalAmount || 0}
                style="currency"
                currency="USD"
                maximumFractionDigits={2}
                minimumFractionDigits={2}
            />
        </TableCell>
    </TableRow>
);

interface EnhancedTableProps {
    canEdit: boolean;
    onRequestSort: (newOrderBy: HeadCellIds) => void;
    order: Order;
    orderBy: string;
}

const EnhancedTableHead: FC<EnhancedTableProps> = ({
    canEdit,
    order,
    orderBy,
    onRequestSort,
}) => {
    const createSortHandler =
        (newOrderBy: HeadCellIds): MouseEventHandler =>
        (_) => {
            onRequestSort(newOrderBy);
        };

    return (
        <TableHead>
            <TableRow sx={{ height: '40px' }}>
                {headCells.slice(0, -1).map(({ id, label }, i, self) => (
                    <TableCell
                        key={id}
                        align={i < self.length - 1 ? 'left' : 'right'}
                        sortDirection={orderBy === id ? order : false}
                    >
                        <TableSortLabel
                            data-testid={id}
                            active={orderBy === id}
                            direction={orderBy === id ? order : Order.Asc}
                            onClick={createSortHandler(id)}
                        >
                            {label}
                            {orderBy === id && (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === Order.Des
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </Box>
                            )}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {canEdit && (
                    <TableCell
                        // empty cell for edit and delete buttons
                        align="right"
                    />
                )}
            </TableRow>
        </TableHead>
    );
};

interface IProps {
    canEdit: boolean;
    deposits: Deposit[];
}

const EnhancedTable: FC<IProps> = ({ canEdit, deposits }) => {
    const dispatch = useAppDispatch();
    const filterBy = useSelect((s) => s.deposits.filter);

    const [order, setOrder] = useState<Order>(Order.Asc);
    const [orderBy, setOrderBy] = useState<HeadCellIds>('name');

    const rows: Row[] = deposits.map((dep) => ({
        depositId: dep.Id || '',
        name: dep.EditedBy || '',
        description: dep.Description || '',
        time: `${moment(dep.EditedDate).format('MM/DD/YYYY, h:mm A')}` || '',
        amount: dep.Amount || 0,
    }));

    const sortedRows = sortEditDepositData(rows, orderBy, order);
    const renderRows = sortedRows.filter(({ depositId: _, ...row }) => {
        if (!filterBy) {
            return true;
        }
        const rowString = Object.values(row).join().toLowerCase();
        return rowString.includes(filterBy.toLowerCase());
    });

    const totalAmount = renderRows.reduce((sum, { amount }) => sum + amount, 0);

    const handleRequestSort = useCallback(
        (newOrderBy: HeadCellIds) => {
            const isAsc = orderBy === newOrderBy && order === Order.Asc;
            setOrder(isAsc ? Order.Des : Order.Asc);
            setOrderBy(newOrderBy);
        },
        [order, orderBy]
    );

    const showEditDialog = (id: string) => () => {
        dispatch(setDepositId(id));
        dispatch(setDialogStatus('edit'));
    };

    const showDeleteDialog = (id: string) => () => {
        dispatch(setDepositId(id));
        dispatch(setDialogStatus('delete'));
    };

    return (
        <TableContainer component={TableRoot}>
            <Table
                aria-labelledby="table-title"
                id="edit-deposits-table"
                size="medium"
            >
                <EnhancedTableHead
                    canEdit={canEdit}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody sx={{ marginBottom: '50px' }}>
                    {renderRows.map((row, i) => (
                        <TableRow
                            key={row.time}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                data-testid={`${i}name`}
                                component="th"
                                scope="row"
                            >
                                {row.name}
                            </TableCell>
                            <TableCell data-testid={`${i}description`}>
                                {row.description}
                            </TableCell>
                            <TableCell data-testid={`${i}time`}>
                                {row.time}
                            </TableCell>
                            <TableCell data-testid={`${i}amount`} align="right">
                                <FormattedNumber
                                    value={row.amount || 0}
                                    style="currency"
                                    currency="USD"
                                    maximumFractionDigits={2}
                                    minimumFractionDigits={2}
                                />
                            </TableCell>
                            {canEdit && (
                                <TableCell align="right">
                                    <Tooltip
                                        title={rawIntl.formatMessage({
                                            id: 'Common.Edit',
                                        })}
                                    >
                                        <IconButton
                                            onClick={showEditDialog(
                                                row.depositId
                                            )}
                                            sx={{ padding: '0 8px' }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        title={rawIntl.formatMessage({
                                            id: 'Common.Delete',
                                        })}
                                    >
                                        <IconButton
                                            onClick={showDeleteDialog(
                                                row.depositId
                                            )}
                                            sx={{ padding: '0 8px' }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                    <TotalsRow totalAmount={totalAmount} />
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default EnhancedTable;
