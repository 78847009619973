import { FC } from 'react';
import { Chart } from 'react-google-charts';
import { ReactGoogleChartEvent } from 'react-google-charts/dist/types';
import { rawIntl } from 'services/intl';
import { CategoryDetails } from 'services/ManagerOneApi';

export type FormattedDataPoint = {
    v: number;
    f: string;
};

export type ProductDataRow = [string, FormattedDataPoint];
export type CategoryDataRow = [
    string,
    FormattedDataPoint,
    CategoryDetails['Id']
];

type ProductDataLabels = [string, string];
export type CategoryDataLabels = [string, string, string];

export type CategoryDataSource = [CategoryDataLabels, ...CategoryDataRow[]];
export type ProductDataSource = [ProductDataLabels, ...ProductDataRow[]];
type DataSource = CategoryDataSource | ProductDataSource;

export type ChartEventHandler = ReactGoogleChartEvent['callback'];

type P = {
    data: DataSource;
    onChartClick: ChartEventHandler;
};

const PMIXChart: FC<P> = ({ data, onChartClick }) => (
    <Chart
        chartType="PieChart"
        width="304px"
        height="304px"
        data={data}
        chartEvents={[
            {
                eventName: 'select',
                callback: onChartClick,
            },
        ]}
        options={{
            colors: [
                '#A6CE39',
                '#0F7A86',
                '#7DB7BF',
                '#E0D120',
                '#0099BF',
                '#00A77E',
            ],
            chartArea: {
                left: 10,
                right: 10,
            },
            legend: {
                alignment: 'center',
                textStyle: {
                    fontName: 'Roboto',
                    fontSize: 10,
                },
            },
            pieSliceText: 'label',
            sliceVisibilityThreshold: 0.05,
            title: rawIntl.formatMessage({
                id: 'App.Containers.PMIXCard.Title',
            }),
            titleTextStyle: {
                fontName: 'Roboto',
                fontSize: 18,
                bold: true,
            },
        }}
    />
);

export default PMIXChart;
