import {
    FC,
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { LocalizationProvider } from '@mui/lab';
import moment from 'moment';
import { Deposit } from 'services/ManagerOneApi';
import {
    daysToBackEditDeposits,
    getDepositsData,
    getLastBusinessDay,
    getFirstBusinessDay,
} from 'services/api';
import useSelect from 'state/selector';
import { BusinessDayType } from 'state/businessDaySlice';
import { momentBusinessDay } from 'services/utils.service';
import { setBusinessDay } from 'state/depositsSlice';
import useAppDispatch from 'state/dispatch';
import EditDeposits from './EditDeposits';

export const EditDepositsContainer = styled.div`
    height: calc(100vh - 72px);
`;

const useMounted = (): MutableRefObject<boolean> => {
    const mounted = useRef(true);
    useEffect(
        () => () => {
            // prevents memory leak
            mounted.current = false;
        },
        []
    );
    return mounted;
};

const EditDepositsLanding: FC = () => {
    const dispatch = useAppDispatch();
    const dialogStatus = useSelect((s) => s.deposits.dialogStatus);
    const businessDay = useSelect((s) => s.deposits.businessDay);
    const errorBusinessDay = useSelect((s) => s.deposits.errorBusinessDay);
    const siteId = useSelect((s) => s.sitesInfo.selectedSiteId);
    const [daysToBackEdit, setDaysToBackEdit] = useState(0);
    const [deposits, setDeposits] = useState<Deposit[]>([]);
    const [firstBusinessDay, setFirstBusinessDay] =
        useState<string>(momentBusinessDay);
    const [lastBusinessDay, setLastBusinessDay] =
        useState<string>(momentBusinessDay);

    const mounted = useMounted();

    const fetchDeposits = useCallback(async (): Promise<void> => {
        if (errorBusinessDay) return;

        const depositsRes = await getDepositsData({
            siteId,
            businessDay: momentBusinessDay(businessDay),
        });
        if (mounted) {
            setDeposits(depositsRes || []);
        }
    }, [businessDay, errorBusinessDay, mounted, siteId]);

    const fetchData = useCallback(async (): Promise<void> => {
        const lastDayRes = await getLastBusinessDay({
            siteId,
            dayType: BusinessDayType.Sale,
        });
        const lastDay = moment(lastDayRes).toString();
        const [
            depositsRes = [],
            firstDay = moment().toString(),
            daysToBackEditRes,
        ] = await Promise.all([
            getDepositsData({
                siteId,
                businessDay: momentBusinessDay(lastDay),
            }),
            getFirstBusinessDay({ siteId, dayType: BusinessDayType.Sale }),
            daysToBackEditDeposits({ siteId }),
        ]);
        if (mounted.current) {
            dispatch(setBusinessDay(lastDay));
            setLastBusinessDay(lastDay);
            setFirstBusinessDay(firstDay);
            setDaysToBackEdit(daysToBackEditRes);
            setDeposits(depositsRes);
        }
    }, [dispatch, mounted, siteId]);

    // mainly for initializing data
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // dependencies from useCallback also treated as part of useEffect's dependencies behind the scenes, even if they're not explicitly coded here
    useEffect(() => {
        if (businessDay && dialogStatus === 'submit') {
            fetchDeposits();
        }
    }, [businessDay, dialogStatus, fetchDeposits]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <EditDeposits
                daysToBackEdit={daysToBackEdit}
                deposits={deposits}
                fetchDeposits={fetchDeposits}
                firstBusinessDay={firstBusinessDay}
                lastBusinessDay={lastBusinessDay}
            />
        </LocalizationProvider>
    );
};

export default EditDepositsLanding;
