import { FC, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import produce from 'immer';
import Dialog from '@mui/material/Dialog';
import { GetScheduleDataResponse } from 'services/ManagerOneApi';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import MainButton from 'components/MainButton';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import * as ManagerOneData from 'services/ManagerOneApi/ManagerOne.API';
import WarningIcon from '@mui/icons-material/Warning';
import { useSelector, useDispatch } from 'react-redux';
import uuid from 'uuid-random';
import { RootState } from 'state/reducers';
import {
    decrementShifts,
    incrementShifts,
    resetShiftData,
    setShiftData,
    setShiftErrors,
    setUnsavedSchedule,
    setSelectedScheduleShift,
    defaultShiftData,
} from 'state/schedulerSlice';
import { rawIntl } from 'services/intl';
import moment from 'moment';
import { dateToIsoString } from 'services/utils.service';
import AddShift from './AddShift';
import { daysOfWeek } from '../schedulerHelper';

const { formatMessage } = rawIntl;

const messages = {
    add: formatMessage({ id: 'App.Containers.Scheduler.AddShift' }),
    edit: formatMessage({ id: 'App.Containers.Scheduler.EditShift' }),
    delete: formatMessage({ id: 'Common.Delete' }),
    cancel: formatMessage({ id: 'Common.Cancel' }),
    update: formatMessage({ id: 'App.Containers.Scheduler.UpdateShift' }),
};

const errorIcon = <WarningIcon sx={{ marginRight: '4px' }} />;

export interface IProps {
    open: boolean;
    close: () => void;
    dateOfCell: moment.Moment;
    employee: ManagerOneData.ManagerOne.API.Labor.Models.Employee;
    scheduleData: GetScheduleDataResponse;
    day: typeof daysOfWeek[number];
    readonly: boolean;
    isEditShift: boolean;
}

const ShiftModalController: FC<IProps> = ({
    open,
    close,
    dateOfCell,
    employee,
    scheduleData,
    day,
    readonly,
    isEditShift,
}) => {
    const dispatch = useDispatch();

    const shiftErrors = useSelector(
        (state: RootState) => state.scheduler.shiftErrors
    );
    const shiftData = useSelector(
        (state: RootState) => state.scheduler.shiftData
    );
    const unsavedScheduleData = useSelector(
        (state: RootState) => state.scheduler.unsavedScheduleData
    );
    const visibleShifts = useSelector(
        (state: RootState) => state.scheduler.shiftModalVisibleShifts
    );
    const selectedShift = useSelector(
        (state: RootState) => state.scheduler.selectedScheduleShift
    );

    const [hasError, setHasError] = useState(false);

    const shiftDate = (
        <Typography mb={1} variant="subtitle1">
            {dateOfCell?.format('dddd MMMM Do').toString()}
        </Typography>
    );

    const doesShiftHaveError = (shift: number) =>
        shiftErrors[shift].hasOverlapError ||
        shiftErrors[shift].hasTimeError ||
        shiftErrors[shift].hasAssignmentError;

    const addShift = () => {
        dispatch(setSelectedScheduleShift(visibleShifts));
        dispatch(incrementShifts());
    };

    const removeEmployeesShiftsFromSchedule = () => {
        const tempScheduleData = { ...unsavedScheduleData };
        const { EmployeeSchedules: unsavedSchedules } = tempScheduleData;

        const indexOfEmployeeToUpdate = unsavedSchedules.findIndex(
            (x) => x.Employee.EmployeeId === employee.EmployeeId
        );
        const finalSchedule = produce(tempScheduleData, (draft) => {
            const schedule = draft.EmployeeSchedules[indexOfEmployeeToUpdate];
            schedule[day] = [];
        });
        dispatch(setUnsavedSchedule(finalSchedule));
    };

    const deleteShift = () => {
        const newShiftErrors = [...shiftErrors];

        newShiftErrors[selectedShift] = {
            hasAssignmentError: false,
            hasOverlapError: false,
            hasTimeError: false,
        };
        dispatch(setShiftErrors(newShiftErrors));

        const newShiftData = [...shiftData];
        newShiftData.splice(selectedShift, 1);
        newShiftData.push({ ...defaultShiftData });

        if (isEditShift && visibleShifts === 1) {
            removeEmployeesShiftsFromSchedule();
            close();
        } else {
            dispatch(setSelectedScheduleShift(visibleShifts - 2));
            dispatch(decrementShifts());
            dispatch(setShiftData(newShiftData));
        }
    };

    useEffect(() => {
        if (selectedShift === null) dispatch(setSelectedScheduleShift(0));
        let errorExists = false;

        shiftErrors.forEach((shift) => {
            const res =
                shift.hasOverlapError ||
                shift.hasTimeError ||
                shift.hasAssignmentError;
            if (res) errorExists = true;
        });

        setHasError(errorExists);
    }, [dispatch, selectedShift, shiftErrors, visibleShifts]);

    const handleClose = () => {
        dispatch(resetShiftData(3));
        close();
    };

    const handleUpdate = () => {
        if (unsavedScheduleData === null) {
            close();
            return;
        }

        const tempScheduleData = { ...unsavedScheduleData };
        const { EmployeeSchedules: unsavedSchedules } = tempScheduleData;
        const indexOfEmployeeToUpdate = unsavedSchedules.findIndex(
            (x) => x.Employee.EmployeeId === employee.EmployeeId
        );

        const shiftsEmployeeIndex = shiftData
            .slice(0, visibleShifts)
            .map((shift, shiftId) => {
                const { reassignedEmployee } = shift;

                if (reassignedEmployee === '') {
                    return {
                        indexOfEmployeeToUpdate,
                        isReassigning: false,
                        shiftId,
                    };
                }
                const res = unsavedSchedules.findIndex(
                    (x) => x.Employee.EmployeeId === reassignedEmployee
                );

                return {
                    indexOfEmployeeToUpdate: res,
                    isReassigning: true,
                    shiftId,
                };
            });

        const shiftDataForCell: ManagerOneData.ManagerOne.API.Labor.Models.Scheduler.ScheduledShift[] =
            shiftData.slice(0, visibleShifts).map((shift) => ({
                JobCode: shift.job || '',
                Date: dateOfCell.format('YYYY-MM-DD').toString(),
                StartTime: dateToIsoString(shift.startTime || new Date()),
                EndTime: dateToIsoString(shift.endTime || new Date()),
                ReassignedEmployee: shift.reassignedEmployee || '',
            }));

        // New shift
        if (!isEditShift) {
            const finalSchedule = produce(tempScheduleData, (draft) => {
                shiftsEmployeeIndex.forEach((shiftIndex, index) => {
                    const schedule =
                        draft.EmployeeSchedules[
                            shiftIndex.indexOfEmployeeToUpdate
                        ];
                    schedule[day].push(shiftDataForCell[index]);
                });
            });
            dispatch(setUnsavedSchedule(finalSchedule));
            handleClose();
        }

        // Editing existing
        else {
            const finalSchedule = produce(tempScheduleData, (draft) => {
                const shiftsToKeep = shiftDataForCell.filter((x) => {
                    if (x?.ReassignedEmployee === '') return true;

                    return false;
                });

                const shiftsToReassign = shiftDataForCell.filter((x) => {
                    if (x?.ReassignedEmployee !== '') return true;

                    return false;
                });

                shiftsToReassign.forEach((shift) => {
                    const employeeScheduleIndex = unsavedSchedules.findIndex(
                        (x) =>
                            x.Employee.EmployeeId === shift.ReassignedEmployee
                    );
                    const existingShifts =
                        tempScheduleData.EmployeeSchedules[
                            employeeScheduleIndex
                        ][day];

                    // We know the final shift length will end up as 3 or less, because the UI should restrict it otherwise. No need to check here
                    const mergedShifts = [
                        ...existingShifts,
                        ...shiftDataForCell.filter(
                            (x) =>
                                x.ReassignedEmployee ===
                                shift.ReassignedEmployee
                        ),
                    ];

                    mergedShifts.sort((a, b) => {
                        const first = moment(a.StartTime);
                        const second = moment(b.StartTime);
                        return first.isBefore(second) ? -1 : 1;
                    });

                    const scheduleToAddShift =
                        draft.EmployeeSchedules[employeeScheduleIndex];

                    // Add to new employees cell
                    // First, we need to determine the "placement" of the shift (is it before or after, chronologically, any)
                    scheduleToAddShift[day] = mergedShifts;
                });
                const scheduleToRemoveShift =
                    draft.EmployeeSchedules[indexOfEmployeeToUpdate];

                scheduleToRemoveShift[day] = shiftsToKeep;
            });
            dispatch(setUnsavedSchedule(finalSchedule));
            handleClose();
        }
    };

    return (
        <Dialog open={open} id="scheduler-shift-modal" maxWidth={false}>
            <DialogContent sx={{ width: '500px' }}>
                <Typography variant="h6">
                    {isEditShift ? messages.edit : messages.add}
                </Typography>
                {shiftDate}
                {visibleShifts <= 1 ? (
                    <Divider
                        sx={{ borderColor: 'lightgray', marginBottom: '10px' }}
                    />
                ) : (
                    <ToggleButtonGroup
                        value={selectedShift}
                        exclusive
                        onChange={(_, value: number) => {
                            if (value === null) return;
                            dispatch(setSelectedScheduleShift(value));
                        }}
                        fullWidth
                    >
                        {Array.from(Array(visibleShifts)).map((_, i) => (
                            <ToggleButton value={i} key={uuid()} role="tab">
                                {doesShiftHaveError(i) && errorIcon}
                                <FormattedMessage
                                    id="App.Containers.Scheduler.Shift"
                                    values={{ number: i + 1 }}
                                />
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                )}
                <AddShift
                    key={selectedShift} // key creates new component (instead of updating/re-rendering) whenever key changes. This is mainly to enable toggling of shifts
                    employee={employee}
                    day={day}
                    scheduleData={scheduleData}
                    shift={selectedShift}
                    dateOfCell={dateOfCell}
                    readonly={readonly}
                />
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex">
                        <MainButton
                            sx={{ textTransform: 'uppercase' }}
                            variant="text"
                            onClick={addShift}
                            disabled={visibleShifts === 3 || readonly}
                            dataTestId="shift-modal-add-button"
                        >
                            {messages.add}
                        </MainButton>
                        <MainButton
                            sx={{ textTransform: 'uppercase' }}
                            variant="text"
                            onClick={deleteShift}
                            disabled={
                                readonly ||
                                (visibleShifts === 1 && !isEditShift)
                            }
                            dataTestId="shift-modal-delete-button"
                        >
                            {messages.delete}
                        </MainButton>
                    </Box>
                    <Box display="flex">
                        <MainButton
                            sx={{ textTransform: 'uppercase' }}
                            variant="text"
                            onClick={handleClose}
                            dataTestId="shift-modal-cancel-button"
                        >
                            {messages.cancel}
                        </MainButton>
                        <MainButton
                            sx={{ textTransform: 'uppercase' }}
                            variant="text"
                            onClick={handleUpdate}
                            disabled={hasError || readonly}
                            dataTestId="shift-modal-update-button"
                        >
                            {messages.update}
                        </MainButton>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ShiftModalController;
