import * as dashboardData from 'containers/Dashboard/Tests/mockedData';
import { store } from 'store/createStore';
import { mockedGetScheduleData } from 'containers/Scheduler/mockedData';
import { mockedData as mockedDepositsData } from 'containers/EditDeposits/mockedData';
import commonEditsData, { MenuItem } from 'commonEditsMockedData/mockedData';
import * as ManagerOneApi from '../ManagerOneApi';
import { ManagerOne } from '../ManagerOneApi/ManagerOne.API';

const {
    ALOHA_MANAGER_ONE_SUPPORT,
    ALOHA_MANAGER_ONE_USER,
    ALOHA_MANAGER_ONE_CONFIGURATION_REFRESH_COMMAND,
    ALOHA_MANAGER_ONE_FORCE_END_OF_DAY_COMMAND,
    TIP_MANAGEMENT_SERVICE_TIP_SHARE_DISTRIBUTOR,
    LABOR_DATA_MANAGEMENT_SHIFT_WRITER,
    TIP_MANAGEMENT_WRITER,
    LABOR_DATA_MANAGEMENT_SCHEDULE_WRITER,
    FINANCIAL_RECONCILIATION_DEPOSIT_ADMINISTRATOR,
    LABOR_DATA_MANAGEMENT_PAY_RATE_VIEWER,
    FINANCIAL_RECONCILIATION_HOUSE_ACCOUNT_ADMINISTRATOR,
} = ManagerOne.API.User.UserRoles;

const ALL_ROLES = [
    ALOHA_MANAGER_ONE_SUPPORT,
    ALOHA_MANAGER_ONE_USER,
    ALOHA_MANAGER_ONE_CONFIGURATION_REFRESH_COMMAND,
    ALOHA_MANAGER_ONE_FORCE_END_OF_DAY_COMMAND,
    TIP_MANAGEMENT_SERVICE_TIP_SHARE_DISTRIBUTOR,
    LABOR_DATA_MANAGEMENT_SHIFT_WRITER,
    TIP_MANAGEMENT_WRITER,
    LABOR_DATA_MANAGEMENT_SCHEDULE_WRITER,
    FINANCIAL_RECONCILIATION_DEPOSIT_ADMINISTRATOR,
    LABOR_DATA_MANAGEMENT_PAY_RATE_VIEWER,
    FINANCIAL_RECONCILIATION_HOUSE_ACCOUNT_ADMINISTRATOR,
];

const siteIds = [
    '3efdb3652d124765b46ae7524feee84d',
    '2d3b6d9840b84b5080532a42a3863cc3',
    'c9bf1d0c9b4b488d83dbba9a31e21fc3',
    '1f4135ddcb314c8f96da253d77b8a341',
];

const sitesResponse: ManagerOneApi.GetSitesResponse =
    new ManagerOneApi.GetSitesResponse({
        Sites: [
            {
                Name: 'AOAutomationTS',
                SiteId: siteIds[0],
            },
            {
                Name: 'AOPadmaTS',
                SiteId: siteIds[1],
            },
            {
                Name: 'AoTestTS',
                SiteId: siteIds[2],
            },
            {
                Name: 'AOTrinityBlvdTS',
                SiteId: siteIds[3],
            },
        ],
    });

interface IUserMockedData {
    password: string;
    userDetails: ManagerOne.API.User.UserRoles[];
    sitesResponse: ManagerOneApi.GetSitesResponse;
    dashboardData: {
        siteId: string;
        data: ManagerOneApi.GetDashboardResponse;
    }[];
    scheduleResponse: ManagerOneApi.GetScheduleDataResponse;
    recoverPasswordResponse: ManagerOneApi.RecoverPasswordResponse;
    depositsData: ManagerOneApi.GetDepositsResponse;
    getMenuItemsResponse: MenuItem[];
}

const defaultResponse = {
    password: 'user1',
    userDetails: ALL_ROLES,
    sitesResponse,
    dashboardData: [
        {
            siteId: siteIds[0],
            data: dashboardData.dashboardData,
        },
        {
            siteId: siteIds[1],
            data: dashboardData.emptyDashboardData,
        },
        {
            siteId: siteIds[2],
            data: dashboardData.dashboardDataSite3,
        },
        {
            siteId: siteIds[3],
            data: dashboardData.dashboardDataSite4,
        },
    ],
    scheduleResponse: mockedGetScheduleData,
    recoverPasswordResponse: new ManagerOneApi.RecoverPasswordResponse({}),
    depositsData: mockedDepositsData,
    getMenuItemsResponse: commonEditsData.mockedMenuItems,
};

export const getMockedDataForUser = (username = ''): IUserMockedData => {
    // Only pass in username param on login. Otherwise, check what is saved in redux
    const currentUser =
        username === '' ? store.getState().user.username : username;

    switch (currentUser) {
        case 'user1':
            return defaultResponse;
        case 'user2':
            return {
                ...defaultResponse,
                password: 'noSites',
                sitesResponse: new ManagerOneApi.GetSitesResponse({
                    Sites: [],
                }),
                recoverPasswordResponse:
                    new ManagerOneApi.RecoverPasswordResponse({}),
            };

        default:
            return { ...defaultResponse, password: 'error' };
    }
};
