import { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background-color: #ffeb8f;
    width: 100%;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.35);
    padding-left: 8px;
    position: relative;
`;

export interface IProps {
    message: string;
}

const AlertBar: FC<IProps> = ({ message }) => (
    <Container data-testid="alert-bar-id">{message}</Container>
);

export default AlertBar;
