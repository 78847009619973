import { FC, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { Box } from '@mui/system';
import { DashboardCard, IDashboardData } from '../index';

const WeatherCard: FC<IDashboardData> = ({ dashboardData }): JSX.Element => {
    const [address, setAddress] = useState('');
    const [weather, setWeather] = useState('');
    const [tempF, setTempF] = useState(0);
    const [tempC, setTempC] = useState(0);
    const [fahrenheit, setFahrenheit] = useState(true);
    const [icon, setIcon] = useState('');
    const result = dashboardData.Weather;
    const tempK = result?.Temp || 0;
    const loading = useSelector((s: RootState) => s.common.loading);
    const sites = useSelector((s: RootState) => s.sitesInfo.sites);
    const siteId = useSelector((s: RootState) => s.sitesInfo.selectedSiteId);
    const store = sites?.find(({ SiteId }) => SiteId === siteId)?.Name;

    const updateWeather = () => {
        if (result) {
            setWeather(result.Description);
            if (result.Icon) {
                setIcon(`https://openweathermap.org/img/wn/${result.Icon}.png`);
            }
            setTempF(Math.round((tempK - 273.15) * (9 / 5) + 32));
            setTempC(Math.round(tempK - 273.15));
            setAddress(result.Site.AddressLine1);
        } else {
            setAddress('');
            setWeather('');
        }
    };

    const getDate = () => {
        const timeZone = dashboardData?.Weather?.Site?.SiteTimeZone;
        const currentTime = moment().format();
        const posTime = moment.tz(currentTime, timeZone);
        return posTime.format('h:mm A');
    };

    useEffect(() => {
        updateWeather();
    });

    return (
        <DashboardCard>
            {!loading && (
                <CardContent data-testid="weatherContent">
                    <Typography
                        fontWeight="500"
                        component="div"
                        fontSize="18px"
                        id="weather-site-name"
                    >
                        {store}
                    </Typography>
                    <Typography>{address}</Typography>
                    <Typography
                        mt={result ? 6 : 9}
                        mb={3}
                        textAlign="center"
                        variant="h3"
                        fontWeight="400"
                    >
                        {getDate()}
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={1}
                    >
                        <span>{weather}</span>
                        <img src={icon} alt="" />
                    </Box>
                    {!!tempK && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <div>{fahrenheit ? tempF : tempC}°</div>
                            <div>
                                <ToggleButtonGroup
                                    value={
                                        fahrenheit ? 'fahrenheit' : 'celsius'
                                    }
                                    exclusive
                                    aria-label="text alignment"
                                >
                                    <ToggleButton
                                        value="fahrenheit"
                                        aria-label="left aligned"
                                        data-testid="fahrenheit"
                                        onClick={() => setFahrenheit(true)}
                                        sx={{
                                            height: '36px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        °F
                                    </ToggleButton>
                                    <ToggleButton
                                        value="celsius"
                                        aria-label="centered"
                                        data-testid="celsius"
                                        onClick={() => setFahrenheit(false)}
                                        sx={{
                                            height: '36px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        °C
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </Box>
                    )}
                </CardContent>
            )}
        </DashboardCard>
    );
};

export default WeatherCard;
