/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from 'state/reducers';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    // blacklist: [apiName.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

const persistor = persistStore(store);

const getMockedStore = (
    preloadedState: ConfigureStoreOptions['preloadedState']
) => {
    const mockedStore = configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
        preloadedState,
    });
    return mockedStore;
};

export { store, persistor, getMockedStore };
