import { createSlice } from '@reduxjs/toolkit';
import { DepositStatus } from 'containers/EditDeposits/Dialog';
import moment from 'moment';

type DepositsState = {
    businessDay: string;
    dialogStatus: DepositStatus;
    depositId: string;
    errorBusinessDay: boolean;
    filter: string;
};

const initialState: DepositsState = {
    businessDay: '',
    dialogStatus: null,
    depositId: '',
    errorBusinessDay: false,
    filter: '',
};

const depositsSlice = createSlice({
    name: 'deposits',
    initialState,
    reducers: {
        setBusinessDay: (state, { payload }) => {
            state.businessDay = payload;
            state.errorBusinessDay = !moment(payload).isValid();
        },
        setDepositId: (state, { payload }) => {
            state.depositId = payload;
        },
        setDialogStatus: (state, { payload }: { payload: DepositStatus }) => {
            state.dialogStatus = payload;
        },
        setFilter: (state, { payload }) => {
            state.filter = payload;
        },
        purge: (_) => {
            _ = initialState;
        },
    },
});

export const {
    setBusinessDay,
    setDepositId,
    setDialogStatus,
    setFilter,
    purge,
} = depositsSlice.actions;

export default depositsSlice;
