import { Box } from '@mui/material';
import Loader from 'components/Loader';
import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Props {}

export const LoaderMask: FC<Props> = () => (
    <Box
        sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            width: '100%',
            height: '100%',
            zIndex: '3000',
            opacity: '0.5',
            backgroundColor: '#535453',
        }}
    >
        <Loader />
    </Box>
);
