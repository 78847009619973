import { rawIntl } from '../../services/intl';

const messages = {
    longName: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.LongName',
    }),
    shortName: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.ShortName',
    }),
    chitName: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.chitName',
    }),
    sku: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.sku',
    }),
    onlineName: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.onlineName',
    }),
    onlineDescription: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.onlineDescription',
    }),
    receipeText: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.receipeText',
    }),
    combinePriceWithParent: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.combinePriceWithParent',
    }),
    allowSitesToChangePrice: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.allowSitesToChangePrice',
    }),
    printOnCheckWhenPriceIsZero: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.printOnCheckWhenPriceIsZero',
    }),
    printerGroupRouting: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.printerGroupRouting',
    }),
    videoGroupRouting: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.videoGroupRouting',
    }),
    modifierGroups: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.modifierGroups',
    }),
    backgroundColor: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.backgroundColor',
    }),
    salesRetailCategory: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.salesRetailCategory',
    }),
    defaultPrice: rawIntl.formatMessage({
        id: 'App.Containers.MenuItems.MenuItemsDrawerContent.defaultPrice',
    }),
};

export default messages;
