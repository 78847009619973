import { Chart } from 'react-google-charts';
import { FormattedMessage } from 'react-intl';
import { LaborCardi18n } from './i18n';

const NoDataChart = (): JSX.Element => (
    <Chart
        width="304px"
        height="304px"
        chartType="PieChart"
        loader={
            <div>
                <FormattedMessage id="Common.LoadingChart" />
            </div>
        }
        data={[
            [LaborCardi18n.payType, LaborCardi18n.amount],
            [LaborCardi18n.noLaborData, 1],
        ]}
        options={{
            title: 'Labor',
            pieSliceText: 'none',
            pieHole: 0.75,
            colors: ['#D3D3D3', '#D3D3D3'],
            legend: {
                position: 'bottom',
                alignment: 'center',
                textStyle: {
                    fontName: 'Roboto',
                    fontSize: 10,
                },
            },
            chartArea: {
                left: 10,
                right: 10,
            },
            titleTextStyle: {
                fontName: 'Roboto',
                fontSize: 18,
                bold: true,
            },
            tooltip: { trigger: 'none' },
        }}
    />
);

export default NoDataChart;
