/* eslint-disable react/no-array-index-key */
import { FC, MouseEventHandler, ReactNode, useContext, useState } from 'react';
import { ROUTES } from 'routes';
import ListItem from '@mui/material/ListItem';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';
import BusinessIcon from '@mui/icons-material/Business';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import {
    setShowReleaseNotesModal,
    setShowTrainingVideoModal,
} from 'state/commonSlice';
import useSelect from 'state/selector';
import { logOut } from 'services/api';
import { SideEffectContext } from 'SideEffectContext';

interface MenuItem {
    disabled?: boolean;
    onClick: MouseEventHandler;
    icon: JSX.Element;
    text: ReactNode | string;
    id: string;
}

const onClickPlaceholder = () => {};

export const SettingsDropDown: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setSideEffect } = useContext(SideEffectContext);
    const username = useSelect((s) => s.user.username);
    const org = useSelect((s) => s.user.organization);
    const signedIn = useSelect((s) => s.user.signedIn);
    const hasUnsavedChanges = useSelect((s) => s.routes.hasUnsavedChanges);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const signOut = async () => {
        if (!hasUnsavedChanges) {
            await logOut();
        }
        setSideEffect(logOut);
        navigate(ROUTES.LOGIN);
    };

    // List of all menu items
    const menuItemsList: MenuItem[] = [
        {
            icon: <PersonOutlineIcon />,
            text: username,
            onClick: onClickPlaceholder,
            disabled: true,
            id: 'person-outline',
        },
        {
            icon: <BusinessIcon />,
            text: org,
            onClick: onClickPlaceholder,
            disabled: true,
            id: 'business-line-item',
        },
        {
            icon: <HelpOutlineIcon />,
            text: (
                <FormattedMessage id="App.Components.HeaderNavigation.Help" />
            ),
            onClick: onClickPlaceholder,
            id: 'help-line-item',
        },
        {
            icon: <VideoLibraryIcon />,
            text: (
                <FormattedMessage id="App.Components.HeaderNavigation.TrainingVideo" />
            ),
            onClick: () => {
                dispatch(setShowTrainingVideoModal(true));
            },
            id: 'training-vids-line-item',
        },
        {
            id: 'release-notes-line-item',
            icon: <InfoIcon data-testid="release-notes" />,
            text: (
                <FormattedMessage id="App.Components.HeaderNavigation.ReleaseNotes" />
            ),
            onClick: () => {
                dispatch(setShowReleaseNotesModal(true));
            },
        },
        {
            icon: <SettingsIcon />,
            text: (
                <FormattedMessage id="App.Components.HeaderNavigation.ChangePassword" />
            ),
            onClick: () => navigate(ROUTES.CHANGE_PASSWORD),
            id: 'settings-line-item',
        },
        {
            icon: <ExitToAppIcon data-testid="signout" />,
            text: (
                <FormattedMessage id="App.Components.HeaderNavigation.SignOut" />
            ),
            onClick: signOut,
            id: 'sign-out-line-item',
        },
    ];

    const dividerLocations = [2];

    const menuItems = menuItemsList.map((menuItem, index) => {
        const divider = dividerLocations.includes(index) ? <Divider /> : null;

        const item = (
            <ListItem
                disablePadding
                disabled={menuItem.disabled}
                onClick={menuItem.onClick}
                key={index}
                id={menuItem.id}
            >
                <ListItemButton>
                    <ListItemIcon>{menuItem.icon}</ListItemIcon>
                    <ListItemText primary={menuItem.text} />
                </ListItemButton>
            </ListItem>
        );

        return [divider, item];
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return signedIn ? (
        <>
            <IconButton
                onClick={handleClick}
                data-testid="menu-appbar-open"
                id="settings-drop-down"
                sx={{ marginLeft: '15px', color: '#949494' }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        maxWidth: '305px',
                        borderRadius: '4px !important',
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 20,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {menuItems}
            </Menu>
        </>
    ) : (
        <></>
    );
};
