import { FC, useCallback, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import PrintIcon from '@mui/icons-material/Print';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import Badge from '@mui/material/Badge';
import WeekDatePicker from 'components/DatePickers';
import { rawIntl } from 'services/intl';
import styled from 'styled-components';
import { MEDIUM, SMALL } from 'styles/responsive_breakpoints';
import { Box } from '@mui/system';
import {
    setIsConfirmingDelivery,
    setShowCopyScheduleModal,
} from 'state/schedulerSlice';
import useAppDispatch from 'state/dispatch';
import useSelect from 'state/selector';
import { setShowConfirmation } from 'state/routesSlice';
import { SideEffectContext } from 'SideEffectContext';
import JobCodesDropdown from './JobCodesDropdown';
import { downloadPdf } from './pdf';

const SmallScreenTopWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: ${MEDIUM}) {
        margin-top: 25px;
        display: flex;
        width: 100%;
    }

    @media (max-width: ${SMALL}) {
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
`;
const SmallScreenBottomWrapper = styled.div`
    display: contents;

    @media (max-width: ${MEDIUM}) {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
    }
`;

interface IProps {
    disableSave: boolean;
    defaultSelectedDay: Date;
    save: () => Promise<void>;
}

const FunctionBar: FC<IProps> = ({ disableSave, defaultSelectedDay, save }) => {
    const dispatch = useAppDispatch();
    const { setSideEffect } = useContext(SideEffectContext);
    const data = useSelect((s) => s.scheduler.unsavedScheduleData);
    const siteId = useSelect((s) => s.sitesInfo.selectedSiteId);
    const sites = useSelect((s) => s.sitesInfo.sites);
    const siteName = sites.find((site) => site.SiteId === siteId)?.Name || '';
    const isMobile = useMediaQuery(`(max-width:${MEDIUM})`);

    const messages = {
        title: rawIntl.formatMessage({ id: 'App.Containers.Scheduler.Title' }),
        save: rawIntl.formatMessage({ id: 'Common.Save' }),
        copy: rawIntl.formatMessage({ id: 'Common.Copy' }),
        print: rawIntl.formatMessage({ id: 'App.Containers.Scheduler.Print' }),
        deliver: rawIntl.formatMessage({
            id: 'App.Containers.Scheduler.DeliverSchedules',
        }),
    };

    const printSchedules = async () => {
        await downloadPdf({
            schedules: data.EmployeeSchedules,
            siteName,
            day: defaultSelectedDay,
        });
    };

    const confirmDelivery = () => {
        dispatch(setIsConfirmingDelivery(true));
    };

    const openCopyModal = useCallback(() => {
        dispatch(setShowCopyScheduleModal(true));
    }, [dispatch]);

    const onClickCopyButton = () => {
        if (disableSave) {
            openCopyModal();
            return;
        }
        setSideEffect(openCopyModal);
        dispatch(setShowConfirmation(true));
    };

    return (
        <AppBar
            sx={{
                height: {
                    xs: '150px',
                    sm: '105px',
                    md: '105px',
                    lg: '72px',
                    xl: '72px',
                },
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                flexDirection: isMobile ? 'column' : 'row',
                border: 'none',
                position: 'inherit',
                backgroundColor: '#fafafa',
                width: '100%',
                margin: 'auto',
            }}
        >
            <SmallScreenTopWrapper>
                <Typography
                    variant="subtitle1"
                    fontWeight="500"
                    fontSize="28px"
                    lineHeight="100%"
                    whiteSpace="nowrap"
                    mb={isMobile ? 1 : 0}
                >
                    {messages.title}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <WeekDatePicker defaultSelectedDay={defaultSelectedDay} />
                </Box>
            </SmallScreenTopWrapper>
            <SmallScreenBottomWrapper>
                <JobCodesDropdown />

                <Tooltip title={messages.save}>
                    <span>
                        <IconButton
                            disabled={disableSave}
                            sx={{ marginLeft: '10px' }}
                            data-testid="save-button"
                            onClick={save}
                        >
                            <Badge
                                invisible={disableSave}
                                badgeContent="!"
                                color="info"
                            >
                                <SaveIcon fontSize="medium" />
                            </Badge>
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip title={messages.copy}>
                    <IconButton
                        sx={{ marginLeft: '10px' }}
                        data-testid="scheduler-copy-icon"
                        onClick={onClickCopyButton}
                    >
                        <ContentCopyIcon
                            sx={{ color: 'black' }}
                            fontSize="medium"
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title={messages.print}>
                    <span>
                        <IconButton
                            data-testid="print-icon"
                            disabled={!data.EmployeeSchedules}
                            onClick={printSchedules}
                            sx={{ marginLeft: '10px' }}
                        >
                            <PrintIcon
                                sx={{ color: 'black' }}
                                fontSize="medium"
                            />
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip title={messages.deliver}>
                    <IconButton
                        data-testid="deliver-schedules-icon"
                        onClick={confirmDelivery}
                        sx={{ marginLeft: '10px' }}
                    >
                        <SystemUpdateAltIcon
                            sx={{ color: 'black' }}
                            fontSize="medium"
                        />
                    </IconButton>
                </Tooltip>
            </SmallScreenBottomWrapper>
        </AppBar>
    );
};

export default FunctionBar;
