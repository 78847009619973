import {
    addDays,
    differenceInHours,
    endOfWeek,
    format,
    startOfWeek,
} from 'date-fns';
import produce from 'immer';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ContentStack, Table, Content, TableCell } from 'pdfmake/interfaces';
import { rawIntl } from 'services/intl';
import { EmployeeSchedule } from 'services/ManagerOneApi';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { formatMessage, formatNumber } = rawIntl;

const i18n = {
    schedulerTitle: formatMessage({ id: 'App.Containers.Scheduler.Title' }),
    employeeName: formatMessage({
        id: 'App.Containers.Scheduler.EmployeeName',
    }),
    totalHours: formatMessage({ id: 'App.Containers.Scheduler.TotalHours' }),
};

const fields = [
    'Employee',
    'TotalHours',
    'Day1',
    'Day2',
    'Day3',
    'Day4',
    'Day5',
    'Day6',
    'Day7',
] as const;

const daysOfWeek = [
    'Day1',
    'Day2',
    'Day3',
    'Day4',
    'Day5',
    'Day6',
    'Day7',
] as const;

const totalsCell = (
    field: typeof fields[number],
    schedules: EmployeeSchedule[]
): TableCell => {
    let total = 0;
    switch (field) {
        case 'Employee':
            return '';
        case 'TotalHours':
            schedules.forEach(({ [field]: totalHours }) => {
                total += totalHours;
            });
            break;
        default:
            schedules.forEach(({ [field]: shifts }) => {
                shifts.forEach((shift) => {
                    total += differenceInHours(
                        new Date(shift.EndTime),
                        new Date(shift.StartTime)
                    );
                });
            });
    }
    return {
        alignment: 'center',
        text: total,
        bold: true,
        style: 'tableHeader',
    };
};

const headerRowLabels = (
    field: typeof fields[number],
    weekStart: Date
): Content => {
    let text = '';
    switch (field) {
        case 'Employee':
            text = i18n.employeeName;
            break;
        case 'TotalHours':
            text = i18n.totalHours;
            break;
        default: {
            const day = Number(field.slice(-1)) - 1;
            text = format(addDays(weekStart, day), 'eee d');
            break;
        }
    }
    return {
        alignment: 'center',
        text,
        bold: true,
        style: 'tableHeader',
    };
};

const shiftsCell = (
    field: typeof fields[number],
    schedule: EmployeeSchedule
): Content => {
    switch (field) {
        case 'Employee':
            return schedule[field].Name || '';
        case 'TotalHours':
            return {
                alignment: 'center',
                text: formatNumber(schedule[field], {
                    maximumFractionDigits: 2,
                }),
            };
        default: {
            const jobTitleLookup: Record<string, string> = {};
            schedule.Employee.Jobs?.forEach((job) => {
                jobTitleLookup[job.JobCodeId] = job.JobTitle;
            });

            // arrays are sorted in-place, so deep copy is made via immer
            const sortedSchedule = produce(schedule[field], (draft) =>
                draft.sort((a, b) => {
                    // first, sort by job
                    const jobOrder = Number(a.JobCode) - Number(b.JobCode);
                    if (jobOrder !== 0) return jobOrder;

                    // if job is the same, then sort by time
                    return a.StartTime.localeCompare(b.StartTime);
                })
            );

            const cell: ContentStack = { stack: [], alignment: 'center' };

            cell.stack = sortedSchedule.flatMap((shift, i, self) => {
                const start = format(new Date(shift.StartTime), 'h:mm aaa');
                const end = format(new Date(shift.EndTime), 'h:mm aaa');
                const shiftLabel = `${start} - ${end}`;
                const prevJobCode = self[i - 1]?.JobCode;
                if (prevJobCode === shift.JobCode) return shiftLabel;

                return [jobTitleLookup[shift.JobCode], shiftLabel];
            });

            return cell;
        }
    }
};

const drawScheduleTable = (
    schedules: EmployeeSchedule[],
    weekStart: Date
): Table['body'] => {
    const headerRow = fields.map((field) => headerRowLabels(field, weekStart));

    const bodyRows = schedules
        .filter((schedule) => daysOfWeek.some((day) => schedule[day].length))
        .map((schedule) => fields.map((field) => shiftsCell(field, schedule)));

    const totalHoursRow = fields.map((field) => totalsCell(field, schedules));

    return [headerRow, ...bodyRows, totalHoursRow];
};

type SchedulePdfConfig = {
    schedules: EmployeeSchedule[];
    siteName: string;
    day: Date;
};

export const downloadPdf = async ({
    schedules,
    siteName,
    day,
}: SchedulePdfConfig): Promise<void> => {
    const weekStart = startOfWeek(day);
    const weekStartTitle = format(weekStart, 'MMMM d');
    const weekEndTitle = format(endOfWeek(day), 'MMMM d');
    const title = `${i18n.schedulerTitle} ${siteName} (${weekStartTitle} - ${weekEndTitle})`;

    const body = drawScheduleTable(schedules, weekStart);

    const pdf = pdfMake.createPdf({
        content: [
            { text: title, fontSize: 12, bold: true, alignment: 'center' },
            {
                table: {
                    body,
                    headerRows: 1,
                    // eslint-disable-next-line prettier/prettier
                    widths: [ '*', '5%', '12%', '12%', '12%', '12%', '12%', '12%', '12%' ],
                },
            },
        ],
        defaultStyle: { fontSize: 8, font: 'Roboto' },
        info: { title },
        pageOrientation: 'landscape',
        pageMargins: [25, 25, 25, 25],
    });

    try {
        pdf.open();
    } catch (e) {
        pdf.download(`${title}.pdf`);
    }
};
