import { AppShell } from '@ncr-design-system/pattern-app-shell';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { NavItemArray } from '@ncr-design-system/pattern-sidebar-navigation';
import { useDispatch } from 'react-redux';
import { FC, useEffect, useRef, useState } from 'react';
import { setupMockedInterceptors } from 'services/mocks/mockedApi';
import { setLoading, setMockingEnabled } from 'state/commonSlice';
import { setupInterceptors } from 'services/axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MEDIUM } from 'styles/responsive_breakpoints';
import { ROUTES } from 'routes';
import useSelect from 'state/selector';
import { Components } from './internal-components';
import { getRoutes } from './sidebar-routes';

const useComponentWillMount = (callback: () => unknown) => {
    const willMount = useRef(true);

    if (willMount.current) callback();

    willMount.current = false;
};

const NCRAppShell: FC = () => {
    const initialLoad = useRef(true);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isSignedIn = useSelect((s) => s.user.signedIn);
    const isMobile = useMediaQuery(`(max-width:${MEDIUM})`);

    useComponentWillMount(() => setupInterceptors());

    const [routes, setRoutes] = useState<NavItemArray>([]);

    const userAuthorizations = useSelect((s) => s.user.userAuthorizations);

    useEffect(() => {
        setRoutes(getRoutes(userAuthorizations));

        const allowedPathnames = [
            ROUTES.RECOVER_PASSWORD,
            ROUTES.CHANGE_PASSWORD,
        ];

        window.scrollTo(0, 0);
        if (
            (pathname === '/' || !isSignedIn) &&
            !allowedPathnames.includes(pathname)
        )
            navigate(ROUTES.LOGIN, { replace: true });
        if (initialLoad.current) {
            initialLoad.current = false;
            dispatch(setLoading(false));

            // TODO: do not allow in Production env
            const mockingEnabled = window.location.href.includes(
                'mockingEnabled=true'
            );
            if (mockingEnabled) {
                setupMockedInterceptors();
            }
            dispatch(setMockingEnabled(mockingEnabled));
        }
    }, [
        dispatch,
        pathname,
        navigate,
        isSignedIn,
        isMobile,
        userAuthorizations,
    ]);

    return pathname === '/' || !isSignedIn ? (
        <Outlet />
    ) : (
        <AppShell
            // The 'items' we want our left-panel to display.
            sidebarItems={routes}
            // Lets the AppShell know where within the application we currently are
            currentUrl={pathname}
            // Our WrappedLink component from internal-components.tsx
            linkComponent={Components.WrappedLink}
            // Custom components we can show within the AppShell!
            components={{
                appbarContents: <Components.AppbarContents />,
                sidebarTopItem: <Components.SidebarTopItem />,
                sidebarBottomItems: <Components.SidebarBottomItem />,
                rightPanels: [
                    {
                        name: 'primary_right_panel',
                        width: 320,
                        component: <Components.RightPanel.Primary />,
                    },
                    {
                        name: 'secondary_right_panel',
                        width: 320,
                        component: <Components.RightPanel.Secondary />,
                    },
                ],
            }}
            sxProps={{
                appBar: { height: '70px' },
            }}
        >
            <Components.PageContent />
        </AppShell>
    );
};

export default NCRAppShell;
