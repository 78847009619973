/* istanbul ignore file */

import { FC, useCallback, useState } from 'react';
import { CardContent } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { rawIntl } from 'services/intl';
import PMIXChart, { CategoryDataLabels, ChartEventHandler } from './PMIXChart';
import BlankPMIXChart from './BlankPMIXChart';
import { formatCategories, formatProductsFromCategory } from './PMIXDataUtils';
import { DashboardCard, IDashboardData } from '..';

const categoryLabels: CategoryDataLabels = [
    rawIntl.formatMessage({ id: 'App.Containers.PMIXCard.Category' }),
    rawIntl.formatMessage({ id: 'App.Containers.PMIXCard.Amount' }),
    rawIntl.formatMessage({ id: 'App.Containers.PMIXCard.CategoryId' }),
];

const PMIXCard: FC<IDashboardData> = ({ dashboardData }) => {
    const NetSales = dashboardData?.NetSales?.NetSales || 0;
    const Categories = dashboardData?.Products?.Categories || [];
    const loading = useSelector((s: RootState) => s.common.loading);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');

    const hideProductsChart = () => setSelectedCategoryId('');

    const showProductsChart: ChartEventHandler = useCallback(
        ({ chartWrapper }) => {
            const selection = chartWrapper.getChart().getSelection();
            const dataTable = chartWrapper.getDataTable();
            const alreadyShowing = !!selectedCategoryId;
            const validUserInput = selection.length === 1;
            if (!alreadyShowing && validUserInput) {
                const pieSliceIndex = selection[0].row;
                const idIndex = 2; // based on labels of chart data
                const id = dataTable?.getValue(pieSliceIndex, idIndex) || '';
                setSelectedCategoryId(id as string);
                return;
            }
            hideProductsChart();
        },
        [selectedCategoryId]
    );

    const data = selectedCategoryId
        ? formatProductsFromCategory(Categories, selectedCategoryId)
        : formatCategories(categoryLabels, Categories);

    return (
        <DashboardCard sx={{ padding: '0px' }}>
            {!loading && (
                <CardContent>
                    {data.length > 1 && NetSales ? (
                        <PMIXChart
                            data={data}
                            onChartClick={showProductsChart}
                        />
                    ) : (
                        <BlankPMIXChart dataLabels={categoryLabels} />
                    )}
                </CardContent>
            )}
        </DashboardCard>
    );
};

export default PMIXCard;
