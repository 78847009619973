import { ManagerOne } from '../services/ManagerOneApi/ManagerOne.API';

const UserEnum = ManagerOne.API.User.UserRoles;

export const convertAuthStringToEnum = (
    userRole: string
): ManagerOne.API.User.UserRoles | null => {
    switch (userRole) {
        case UserEnum[10]:
            return UserEnum.ALOHA_MANAGER_ONE_SUPPORT;
        case UserEnum[20]:
            return UserEnum.ALOHA_MANAGER_ONE_USER;
        case UserEnum[30]:
            return UserEnum.ALOHA_MANAGER_ONE_CONFIGURATION_REFRESH_COMMAND;
        case UserEnum[40]:
            return UserEnum.ALOHA_MANAGER_ONE_FORCE_END_OF_DAY_COMMAND;
        case UserEnum[50]:
            return UserEnum.TIP_MANAGEMENT_SERVICE_TIP_SHARE_DISTRIBUTOR;
        case UserEnum[60]:
            return UserEnum.LABOR_DATA_MANAGEMENT_SHIFT_WRITER;
        case UserEnum[70]:
            return UserEnum.TIP_MANAGEMENT_WRITER;
        case UserEnum[80]:
            return UserEnum.LABOR_DATA_MANAGEMENT_SCHEDULE_WRITER;
        case UserEnum[90]:
            return UserEnum.FINANCIAL_RECONCILIATION_DEPOSIT_ADMINISTRATOR;
        case UserEnum[100]:
            return UserEnum.LABOR_DATA_MANAGEMENT_PAY_RATE_VIEWER;
        case UserEnum[110]:
            return UserEnum.FINANCIAL_RECONCILIATION_HOUSE_ACCOUNT_ADMINISTRATOR;
        default:
            return null;
    }
};
