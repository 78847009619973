import * as React from 'react';
import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { drawerWidth } from '.';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: `${0}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    }),
}));

interface IProps {
    open: boolean;
    children: NonNullable<React.ReactNode>;
    height?: string;
}
const ExternalDrawerContent: FC<IProps> = ({
    children,
    open,
    height = '460px',
}) => (
    <Main sx={{ height, padding: 0 }} open={open}>
        {children}
    </Main>
);

export default ExternalDrawerContent;
