/* istanbul ignore file */
import {
    useEffect,
    useState,
    FC,
    SetStateAction,
    Dispatch,
    useRef,
} from 'react';
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import PickersDay, { PickersDayProps } from '@mui/lab/PickersDay';
import endOfWeekRaw from 'date-fns/endOfWeek';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfWeekRaw from 'date-fns/startOfWeek';
import { useDispatch } from 'react-redux';
import { setShowViewOnlyAlert } from 'state/commonSlice';

const startOfWeek = (
    ...[date, options]: Parameters<typeof startOfWeekRaw>
): ReturnType<typeof startOfWeekRaw> => {
    const opts: typeof options = {
        ...options,
        weekStartsOn: 1,
    };
    return startOfWeekRaw(date, opts);
};

const endOfWeek = (
    ...[date, options]: Parameters<typeof endOfWeekRaw>
): ReturnType<typeof endOfWeekRaw> => {
    const opts: typeof options = {
        ...options,
        weekStartsOn: 1,
    };
    return endOfWeekRaw(date, opts);
};

type TextFieldEvent = TextFieldProps['onBlur'] & TextFieldProps['onChange'];

type CustomPickerDayProps = PickersDayProps<Date> & {
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' &&
        prop !== 'isFirstDay' &&
        prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
})) as React.ComponentType<CustomPickerDayProps>;

interface IProps {
    defaultSelectedDay: Date;
    minDate: Date;
    setNewDate: Dispatch<SetStateAction<Date>>;
    isMonthValid: boolean;
}

const CopyScheduleDatePicker: FC<IProps> = ({
    defaultSelectedDay,
    setNewDate,
    minDate,
    isMonthValid,
}) => {
    const [dateInputValue, setDateInputValue] = useState<Date | null>(
        defaultSelectedDay
    );
    const [displayText, setDisplayText] = useState<string>('');
    const [isDateValid, setIsDateValid] = useState<boolean>(true);
    const dispatch = useDispatch();
    const debounce = useRef<NodeJS.Timeout>();
    const dateInputRef = useRef<HTMLInputElement>(null);

    const validateDate: TextFieldEvent = ({ target: { value } }) => {
        const isValid = !!value && value !== ' ';
        setIsDateValid(isValid);
    };

    const setFinalText = (start: Date, end: Date) => {
        const isLastDayOfWeekInPast = moment(end).diff(moment(), 'days') < 0;
        dispatch(setShowViewOnlyAlert(isLastDayOfWeekInPast));

        const startText = moment(start).format('MMM D');
        const endText = moment(end).format('MMM D');
        const finalText = `${startText} - ${endText}`;
        setDisplayText(finalText);
    };

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (debounce.current) {
                clearTimeout(debounce.current);
            }
            debounce.current = setTimeout(() => {
                if (
                    dateInputValue === null ||
                    dateInputRef.current?.value === null
                ) {
                    setNewDate(new Date('Invalid Date'));
                } else setNewDate(dateInputValue || defaultSelectedDay);
            }, 300);

            const selectedDate = moment(dateInputValue, 'MM-DD-YYYY', true);
            if (
                selectedDate.isValid() &&
                moment(dateInputValue).get('years') > 2000
            ) {
                if (!dateInputValue) return;
                const start = startOfWeek(dateInputValue);
                const end = endOfWeek(dateInputValue);
                setFinalText(start, end);
            }
        }
        // eslint-disable-next-line
        return () => {
            // prevents memory leak
            mounted = false;
        };
    }, [dateInputValue]);

    const renderWeekPickerDay = (
        date: Date,
        selectedDates: Array<Date | null>,
        pickersDayProps: PickersDayProps<Date>
    ) => {
        if (!dateInputValue) {
            return <PickersDay {...pickersDayProps} />;
        }

        let dayIsBetween = false;

        let isFirstDay = false;
        let isLastDay = false;

        try {
            const start = startOfWeek(dateInputValue);
            const end = endOfWeek(dateInputValue);
            dayIsBetween = isWithinInterval(date, { start, end });

            isFirstDay = isSameDay(date, start);
            isLastDay = isSameDay(date, end);

            setFinalText(start, end);
        } catch (e) {
            setDisplayText('Please select a valid date');
        }
        return (
            <CustomPickersDay
                {...pickersDayProps}
                disableMargin
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
            />
        );
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <label htmlFor="calenderInput" data-testid="copy-to-week-range">
                    {displayText}
                </label>
                <DatePicker
                    value={dateInputValue}
                    onChange={(newValue) => {
                        setDateInputValue(newValue);
                    }}
                    inputFormat="MMMM d"
                    renderDay={renderWeekPickerDay}
                    renderInput={(params) => (
                        <TextField
                            id="calenderInput"
                            inputRef={dateInputRef}
                            variant="outlined"
                            required
                            style={{ textAlign: 'center' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                width: '100%',
                                textAlign: 'center',
                                justifyItems: 'center',
                                fontSize: 5,
                                paddingTop: '12px',
                            }}
                            data-testid="calenderInput"
                            helperText={
                                isDateValid && isMonthValid
                                    ? 'Month Day'
                                    : ' Please enter a valid date'
                            }
                            {...params}
                            onBlur={validateDate}
                            onChange={validateDate}
                            error={
                                !isDateValid ||
                                !isMonthValid ||
                                dateInputValue === null
                            }
                        />
                    )}
                    minDate={minDate}
                    disableMaskedInput
                    showTodayButton
                />
            </LocalizationProvider>
        </>
    );
};

export default CopyScheduleDatePicker;
