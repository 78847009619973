import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dateToBusinessDay } from 'services/utils.service';
import { useThunkDispatcher } from 'state/apiHelper';
import {
    BusinessDayType,
    getBusinessDayRangeThunk,
} from 'state/businessDaySlice';
import useAppDispatch from 'state/dispatch';
import {
    setSelectedBusinessDay,
    getPunchEditorDataThunk,
} from 'state/punchEditorSlice';
import { RootState } from 'state/reducers';
import PunchEditor, { Props } from './Editor';

const PunchesLandingPage: FC = () => {
    const [data, setData] = useState<Props>();
    const dispatch = useAppDispatch();
    const fetchPunchEditorData = useThunkDispatcher(getPunchEditorDataThunk);
    const fetchBusinessDayRange = useThunkDispatcher(getBusinessDayRangeThunk);

    const siteId = useSelector((s: RootState) => s.sitesInfo.selectedSiteId);
    const selectedBusinessDay = useSelector(
        (s: RootState) => s.punchEditor.selectedBusinessDay
    );

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            const {
                FirstBusinessDay = dateToBusinessDay(new Date()),
                LastBusinessDay = dateToBusinessDay(new Date()),
            } = await fetchBusinessDayRange({
                siteId,
                dayType: BusinessDayType.Control,
            });

            const businessDay = selectedBusinessDay || LastBusinessDay;
            dispatch(setSelectedBusinessDay(businessDay));

            const { Shifts, OtherWages } = await fetchPunchEditorData({
                siteId,
                businessDay: dateToBusinessDay(new Date(businessDay)),
            });

            if (mounted) {
                setData({
                    shiftsData: Shifts,
                    otherWagesData: OtherWages,
                    lastBusinessDay: LastBusinessDay,
                    firstConfigurationDay: FirstBusinessDay,
                });
            }
        };

        fetchData();
        return () => {
            // prevents memory leak
            mounted = false;
        };
    }, [
        siteId,
        selectedBusinessDay,
        fetchBusinessDayRange,
        fetchPunchEditorData,
        dispatch,
    ]);

    return <PunchEditor {...data} />;
};

export default PunchesLandingPage;
