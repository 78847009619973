import { FC, useState, MouseEventHandler, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { GetReportsResponse } from 'services/ManagerOneApi';
import { sortReportsData } from './reportsHelper';

type Order = 'asc' | 'desc';

const headCells = [
    {
        id: 'Name',
        label: 'Report Name',
    },
    {
        id: 'Description',
        label: 'Description',
    },
] as const;

type HeadCellType = typeof headCells[number]['id'];
interface EnhancedTableHeadProps {
    onRequestSort: (newOrderBy: HeadCellType) => void;
    order: Order;
    orderBy: string;
}

const EnhancedTableHead: FC<EnhancedTableHeadProps> = ({
    order,
    orderBy,
    onRequestSort,
}) => {
    const createSortHandler =
        (newOrderBy: HeadCellType): MouseEventHandler =>
        (_) => {
            onRequestSort(newOrderBy);
        };

    return (
        <TableHead>
            <TableRow sx={{ height: '40px' }}>
                <TableCell
                    key={headCells[0].id}
                    align="left"
                    sx={{
                        flexDirection: 'row',
                        width: '100px',
                        textAlign: 'left',
                    }}
                    sortDirection={orderBy === headCells[0].id ? order : false}
                >
                    <TableSortLabel
                        data-testid={headCells[0].id}
                        active={orderBy === headCells[0].id}
                        direction={orderBy === headCells[0].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[0].id)}
                    >
                        {headCells[0].label}
                        {orderBy === headCells[0].id ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key={headCells[1].id}
                    align="left"
                    sx={{
                        flexDirection: 'row',
                        width: '80%',
                        textAlign: 'left',
                    }}
                    sortDirection={orderBy === headCells[1].id ? order : false}
                >
                    <TableSortLabel
                        data-testid={headCells[1].id}
                        active={orderBy === headCells[1].id}
                        direction={orderBy === headCells[1].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[1].id)}
                    >
                        {headCells[1].label}
                        {orderBy === headCells[1].id ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

type Props = {
    reports: GetReportsResponse;
    searchText: string;
    reportType: string;
};

const EnhancedTable: FC<Props> = ({ reports, searchText, reportType }) => {
    const [rows, setRows] = useState(new GetReportsResponse().Reports);
    const reportsGenerated = reports.Reports;
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>('Name');

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    useEffect(() => {
        let mounted = true;
        let dropDownList = reportsGenerated;
        if (mounted) {
            switch (reportType) {
                case 'System':
                    dropDownList = reportsGenerated.filter(
                        (report) => report.IsSystem
                    );
                    break;
                case 'Custom':
                    dropDownList = reportsGenerated.filter(
                        (report) => !report.IsSystem
                    );
                    break;
                default:
                    break;
            }

            if (searchText && searchText.length > 0) {
                const lowerCaseFilter = searchText.toLowerCase();
                dropDownList = dropDownList.filter((report) => {
                    const searchValues = [report.Name, report.Description];
                    return (
                        searchValues.filter(
                            (v) => v.toLowerCase().indexOf(lowerCaseFilter) > -1
                        ).length > 0
                    );
                });
            }
            setRows(dropDownList);
        }
        return () => {
            // prevents memory leak
            mounted = false;
        };
    }, [searchText, reportType, reportsGenerated]);

    const sortedReports = sortReportsData(rows, orderBy, order);
    return (
        <>
            <Paper
                elevation={3}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    height: '85%',
                    width: '95%',
                    borderRadius: '4px',
                    margin: 'auto',
                    boxShadow:
                        '0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
                }}
            >
                <TableContainer>
                    <Table aria-labelledby="tableTitle" size="medium">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody sx={{ marginBottom: '50px' }}>
                            {sortedReports &&
                                sortedReports?.map((row, i) => (
                                    <TableRow
                                        key={rows[i].Name}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell
                                            data-testid={`${i}name`}
                                            component="th"
                                            scope="row"
                                        >
                                            {row.Name}
                                        </TableCell>
                                        <TableCell
                                            data-testid={`${i}description`}
                                            align="left"
                                        >
                                            {row.Description}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
};

export default EnhancedTable;
