import { createSlice } from '@reduxjs/toolkit';
import { GetDashboardResponse } from 'services/ManagerOneApi';

interface InitialState {
    dashboardData: GetDashboardResponse;
}
const initialState: InitialState = {
    dashboardData: new GetDashboardResponse(),
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDashboardData: (state, action) => {
            state.dashboardData = action.payload;
        },
        purge: () => initialState,
    },
});
export const { setDashboardData, purge } = dashboardSlice.actions;
export default dashboardSlice;
