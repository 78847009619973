import { FC } from 'react';
import { Chart } from 'react-google-charts';

interface IProps {
    title: string;
    width?: string;
    height?: string;
}

const NoDataChart: FC<IProps> = ({
    title,
    width = '310px',
    height = '310px',
}) => (
    <Chart
        width={width}
        height={height}
        style={{ marginTop: '8px', margin: 'auto' }}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
            ['Order Mode', 'Sales'],
            ['No Sales Data', 1],
        ]}
        options={{
            title,
            pieSliceText: 'none', // percentage (default), value, label or none
            pieHole: 0.75,
            colors: ['#D3D3D3', '#D3D3D3'],
            legend: {
                position: 'bottom',
                alignment: 'center',
                textStyle: {
                    fontName: 'Roboto',
                    fontSize: 10,
                },
            },
            chartArea: {
                left: 10,
                right: 10,
            },
            titleTextStyle: {
                fontName: 'Roboto',
                fontSize: 18,
                bold: true,
            },
            tooltip: { trigger: 'none' },
        }}
    />
);

export default NoDataChart;
