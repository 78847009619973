import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import styled from 'styled-components';
import { RootState } from 'state/reducers';
import { Shift } from 'services/ManagerOneApi';
import { useIntl } from 'react-intl';
import { shiftsAreDesc, filterShifts } from './dataHelper';
import {
    ActionBodyCell,
    ActionCell,
    bodyCells,
    columnCells,
} from './TableCells';
import { cols, Column } from './Columns';

type EventHandler = (args: unknown) => void;

enum SortOrder {
    aBeforeB = -1,
    bBeforeA = 1,
    same = 0,
}

const isDescStr = (isDesc: boolean): 'asc' | 'desc' =>
    isDesc ? 'desc' : 'asc';

const HeaderRow = styled(TableRow)`
    border-bottom: 1.1px solid #757575;
`;

const TableContainerRoot = styled.div`
    padding: 0 15px 15px;
`;

type P = {
    shifts?: Shift[];
    canEdit: boolean;
};

// TODO: load when deleting shift
const ShiftsTable: FC<P> = ({ shifts = [], canEdit }) => {
    const { formatMessage } = useIntl();
    // Use for deleting shift
    // const dayOfShift = useSelector((s: RootState) => s.punchEditor.selectedBusinessDay);
    const [activeField, setActiveField] =
        useState<Column['field']>('EmployeeName');
    const [isDescMode, setIsDesc] = useState<boolean>(false);
    const filterBy = useSelector((s: RootState) => s.punchEditor.tableFilter);

    const sortShifts = (shiftsRows: Shift[]): Shift[] => {
        const cp = [...shiftsRows];
        cp.sort((a: Shift, b: Shift) => {
            const areDesc = shiftsAreDesc(a, b, activeField);
            const isCorrectOrder = isDescMode === areDesc;
            return isCorrectOrder ? SortOrder.same : SortOrder.aBeforeB;
        });
        return cp;
    };

    const onClickSort =
        (field: Column['field']): EventHandler =>
        () => {
            const isTogglingDirection = field === activeField;
            setIsDesc((wasDesc) => isTogglingDirection && !wasDesc);
            setActiveField(field);
        };

    /*
    TODO
    const editShift = (shift: Shift) => (): void => {
        if (shift.Job?.IsValidJob) {
            dispatch(setSelectedShift);
            history.push(Routes.EditShift);
        }
        // else show invalid job modal (or forgo else statement in favor of returning from if)
    };
    */

    return (
        <TableContainer
            data-testid="shifts-table"
            component={TableContainerRoot}
        >
            <Table
                aria-label={formatMessage({
                    id: 'App.Containers.Punch.ShiftsTable',
                })}
            >
                <TableHead>
                    <HeaderRow>
                        {cols.map(({ field, message }) => {
                            const Cell = columnCells[field];
                            return (
                                <Cell key={field}>
                                    <TableSortLabel
                                        active={field === activeField}
                                        direction={isDescStr(
                                            field === activeField && isDescMode
                                        )}
                                        onClick={onClickSort(field)}
                                    >
                                        {message}
                                    </TableSortLabel>
                                </Cell>
                            );
                        })}
                        {canEdit && <ActionCell />}
                    </HeaderRow>
                </TableHead>
                <TableBody>
                    {sortShifts(shifts)
                        .filter(filterShifts(filterBy))
                        .map((shift) => (
                            <TableRow key={shift.Id}>
                                {cols.map(({ field }) => {
                                    const Cell = bodyCells[field];
                                    return (
                                        <Cell
                                            {...shift}
                                            key={`${shift.Id}-${field}`}
                                        />
                                    );
                                })}
                                {canEdit && (
                                    <ActionBodyCell
                                    // editClick={editShift(shift)}
                                    // deleteClick={() => {}}
                                    />
                                )}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ShiftsTable;
