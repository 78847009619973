import { RawIntlProvider } from 'react-intl';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from 'styles/theme';
import { setupRoutes } from 'routes';
import { Store } from 'redux';
import SideEffectProvider from 'SideEffectContext';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { rawIntl } from './services/intl';
import { store as defaultStore, persistor } from './store/createStore';
import AppShell from './components/AppShell/component';
import 'license';

const client = new ApolloClient({
    uri: '/graphql',
    cache: new InMemoryCache(),
});

type Props = {
    store?: Store;
};

function App({ store = defaultStore }: Props): JSX.Element {
    return (
        <RawIntlProvider value={rawIntl}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ApolloProvider client={client}>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <SideEffectProvider>
                                <HashRouter>
                                    <Routes>
                                        <Route path="/" element={<AppShell />}>
                                            {setupRoutes()}
                                        </Route>
                                    </Routes>
                                </HashRouter>
                            </SideEffectProvider>
                        </PersistGate>
                    </Provider>
                </ApolloProvider>
            </ThemeProvider>
        </RawIntlProvider>
    );
}

export default App;
