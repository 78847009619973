import { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Close from '@mui/icons-material/Close';
import MainButton from 'components/MainButton';
import { FormattedMessage } from 'react-intl';
import { rawIntl } from 'services/intl';
import styled from 'styled-components';
import { doCopy } from 'services/api';
import {
    setScheduleWasCopied,
    setShowConflictConfirmModal,
} from 'state/schedulerSlice';
import useAppDispatch from 'state/dispatch';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { CopyFromCopyToDates } from '../..';

const { formatMessage } = rawIntl;

const DialogRoot = styled(Paper)`
    border-radius: 4px;
    width: 700px !important;
    height: 200px !important;
`;

const Header = styled(DialogTitle)`
    display: flex;
    justify-content: space-between;
`;

export interface IProps {
    copyDates: CopyFromCopyToDates;
}

const ConflictConfirmationModal: FC<IProps> = ({ copyDates }) => {
    const isVisible = useSelector(
        (state: RootState) => state.scheduler.isConflictConfirmModalVisible
    );
    const currentSiteId = useSelector(
        (state: RootState) => state.sitesInfo.selectedSiteId
    );
    const userName = useSelector((state: RootState) => state.user.username);
    const dispatch = useAppDispatch();
    const close = () => {
        dispatch(setShowConflictConfirmModal(false));
    };
    const cancel = () => {
        close();
    };

    const confirm = async () => {
        await doCopy({
            CopyFrom: copyDates[0],
            CopyTo: copyDates[1],
            SiteId: currentSiteId,
            UpdatedBy: userName,
            Overwrite: true,
        });
        dispatch(setScheduleWasCopied(true));
        close();
    };

    return (
        <Dialog
            open={isVisible}
            data-testid="conflict-confirmation-modal"
            aria-labelledby="conflict-confirmation-title"
            aria-describedby="conflict-confirmation-message"
            onClose={close}
            PaperComponent={DialogRoot}
            maxWidth={false}
        >
            <Header>
                <section id="conflict-confirm-title">
                    <FormattedMessage id="App.Containers.CopySchedule.CopyWarningTitle" />
                </section>
                <Tooltip title={formatMessage({ id: 'Common.Cancel' })}>
                    <IconButton onClick={close}>
                        <Close />
                    </IconButton>
                </Tooltip>
            </Header>
            <DialogContent>
                <DialogContentText id="conflict-confirm-message">
                    <FormattedMessage id="App.Containers.CopySchedule.CopyWarningDialogMessage" />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <MainButton
                    variant="text"
                    data-testid="confirm-conflict"
                    aria-label={formatMessage({ id: 'Common.Yes' })}
                    onClick={confirm}
                    sx={{ textTransform: 'uppercase' }}
                >
                    <FormattedMessage id="Common.Yes" />
                </MainButton>
                <MainButton
                    variant="text"
                    data-testid="decline-conflict"
                    aria-label={formatMessage({ id: 'Common.No' })}
                    onClick={cancel}
                    sx={{ textTransform: 'uppercase' }}
                >
                    <FormattedMessage id="Common.No" />
                </MainButton>
            </DialogActions>
        </Dialog>
    );
};

export default ConflictConfirmationModal;
