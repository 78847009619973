/* istanbul ignore file */
import { useEffect, useState, FC, useContext } from 'react';
import { styled } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import PickersDay, { PickersDayProps } from '@mui/lab/PickersDay';
import endOfWeekRaw from 'date-fns/endOfWeek';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfWeekRaw from 'date-fns/startOfWeek';
import { dateToBusinessDay } from 'services/utils.service';
import { useDispatch, useSelector } from 'react-redux';
import {
    setSelectedBusinessDay,
    setShowViewOnlyAlert,
} from 'state/commonSlice';
import { IconButton, useMediaQuery } from '@mui/material';
import { MEDIUM } from 'styles/responsive_breakpoints';
import { RootState } from 'state/reducers';
import { setScheduleWasCopied } from 'state/schedulerSlice';
import useSelect from 'state/selector';
import { SideEffectContext } from 'SideEffectContext';
import { setShowConfirmation } from 'state/routesSlice';

const startOfWeek = (
    ...[date, options]: Parameters<typeof startOfWeekRaw>
): ReturnType<typeof startOfWeekRaw> => {
    const opts: typeof options = {
        ...options,
        weekStartsOn: 1,
    };
    return startOfWeekRaw(date, opts);
};

const endOfWeek = (
    ...[date, options]: Parameters<typeof endOfWeekRaw>
): ReturnType<typeof endOfWeekRaw> => {
    const opts: typeof options = {
        ...options,
        weekStartsOn: 1,
    };
    return endOfWeekRaw(date, opts);
};

type CustomPickerDayProps = PickersDayProps<Date> & {
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' &&
        prop !== 'isFirstDay' &&
        prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
})) as React.ComponentType<CustomPickerDayProps>;

interface IProps {
    defaultSelectedDay: Date;
}

const CustomDay: FC<IProps> = ({ defaultSelectedDay }) => {
    const [value, setValue] = useState<Date | null>(defaultSelectedDay);
    const [displayText, setDisplayText] = useState<string>('');
    const { setSideEffect } = useContext(SideEffectContext);
    const isMobile = useMediaQuery(`(max-width:${MEDIUM})`);
    const hasUnsavedChanges = useSelect((s) => s.routes.hasUnsavedChanges);
    const dispatch = useDispatch();

    const updateDate = (date: typeof value) => {
        if (!date) return;

        const sideEffect = () => setValue(date);

        const prevDate = value || defaultSelectedDay;
        const start = startOfWeek(prevDate);
        const end = endOfWeek(prevDate);
        const isDiffWeek = !isWithinInterval(date, { start, end });
        if (isDiffWeek && hasUnsavedChanges) {
            setSideEffect(sideEffect);
            dispatch(setShowConfirmation(true));
            return;
        }
        sideEffect();
    };

    const setFinalText = (start: Date, end: Date) => {
        const isLastDayOfWeekInPast = moment(end).diff(moment(), 'days') < 0;
        dispatch(setShowViewOnlyAlert(isLastDayOfWeekInPast));

        const startText = moment(start).format('MMM D');
        const endText = moment(end).format('MMM D');
        const finalText = `${startText} - ${endText}`;
        setDisplayText(finalText);
    };
    const wasAScheduleCopied = useSelector(
        (state: RootState) => state.scheduler.wasAScheduleCopied
    );

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (wasAScheduleCopied) {
                setValue(defaultSelectedDay);
                dispatch(setScheduleWasCopied(false));
            }

            const selectedDate = moment(value, 'MM-DD-YYYY', true);
            if (selectedDate.isValid() && moment(value).get('years') > 2000) {
                dispatch(
                    setSelectedBusinessDay(
                        dateToBusinessDay(value || new Date())
                    )
                );

                if (!value) return;
                const start = startOfWeek(value);
                const end = endOfWeek(value);
                setFinalText(start, end);
            }
        }
        // eslint-disable-next-line
        return () => {
            // prevents memory leak
            mounted = false;
        };
    }, [dispatch, setFinalText, value, defaultSelectedDay]);

    const renderWeekPickerDay = (
        date: Date,
        selectedDates: Array<Date | null>,
        pickersDayProps: PickersDayProps<Date>
    ) => {
        if (!value) {
            return <PickersDay {...pickersDayProps} />;
        }

        const start = startOfWeek(value);
        const end = endOfWeek(value);

        const dayIsBetween = isWithinInterval(date, { start, end });
        const isFirstDay = isSameDay(date, start);
        const isLastDay = isSameDay(date, end);

        setFinalText(start, end);

        return (
            <CustomPickersDay
                {...pickersDayProps}
                disableMargin
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
            />
        );
    };
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    marginRight: '5px',
                    height: '100%',
                    alignItems: 'end',
                }}
            >
                <IconButton
                    onClick={() =>
                        updateDate(moment(value).add(-1, 'week').toDate())
                    }
                >
                    <ArrowBackIosNewIcon sx={{ fontSize: '16px' }} />
                </IconButton>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    value={value}
                    onChange={(newValue) => {
                        updateDate(newValue);
                    }}
                    renderDay={renderWeekPickerDay}
                    renderInput={(params) => (
                        <TextField
                            size="small"
                            variant="standard"
                            disabled
                            {...params}
                            style={{ textAlign: 'center' }}
                            sx={{
                                width: isMobile ? '100px' : '130px',
                                textAlign: 'center',
                                color: 'black',
                                marginBottom: '10px',
                            }}
                            data-testid="calenderInput"
                        />
                    )}
                    label={displayText}
                    showTodayButton
                />
            </LocalizationProvider>
            <Box
                sx={{
                    display: 'flex',
                    marginLeft: '5px',
                    height: '100%',
                    alignItems: 'end',
                }}
            >
                <IconButton
                    onClick={() =>
                        updateDate(moment(value).add(1, 'week').toDate())
                    }
                >
                    <ArrowForwardIosIcon sx={{ fontSize: '16px' }} />
                </IconButton>
            </Box>
        </>
    );
};

export default CustomDay;
