import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Add, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { DatePicker, DatePickerProps } from '@mui/lab';
import { IconButton, TextField, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import useAppDispatch from 'state/dispatch';
import { setSelectedBusinessDay } from 'state/punchEditorSlice';
import styled from 'styled-components';
import { MEDIUM } from 'styles/responsive_breakpoints';
import MainButton from 'components/MainButton';
import { ROUTES } from 'routes';
import TableRowsFilterInput from './TableRowsFilterInput';
import { PunchTabIndex } from '.';

const Root = styled.menu`
    display: flex;
    padding-left: 0;
    @media screen and (max-width: ${MEDIUM}) {
        flex-direction: column;
        align-items: center;
        row-gap: 8px;
    }
`;

const FormControlItem = styled.section`
    display: flex;
    max-width: 250px;
    align-items: center;
`;

const FormControlLabel = styled.div`
    flex-grow: 1;
`;

const TextFieldStyled = styled(TextField)`
    fieldset {
        border-radius: 4px;
    }
`;
TextFieldStyled.defaultProps = {
    variant: 'outlined',
    size: 'small',
};

const AddShiftButtonWrapper = styled.span`
    margin: 0 8px;
`;

type Props = {
    hasShiftsData: boolean;
    businessDay: string;
    firstConfigurationDay: string;
    lastBusinessDay: string;
    canAddShift: boolean;
    selectedTab: PunchTabIndex;
};

const PunchToolbar: FC<Props> = ({
    hasShiftsData,
    businessDay,
    firstConfigurationDay,
    lastBusinessDay,
    canAddShift,
    selectedTab,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();

    const updateBusinessDay: DatePickerProps<string>['onChange'] = (date) => {
        dispatch(setSelectedBusinessDay(date));
    };

    const addRow = (): void => {
        if (selectedTab === PunchTabIndex.OtherWages) {
            navigate(ROUTES.PUNCHES.EDITOTHERWAGE);
        }
    };

    const moveDateByDay = (days: number) => (): void => {
        const newDay = moment(businessDay).add(days, 'days').toString();
        updateBusinessDay(newDay);
    };

    const toTomorrow = moveDateByDay(1);
    const toYesterday = moveDateByDay(-1);

    return (
        <Root
            data-testid="punch-toolbar"
            aria-label={formatMessage({
                id: 'App.Containers.Punch.Toolbar',
            })}
        >
            <FormControlLabel>
                <Typography
                    variant="subtitle1"
                    fontWeight="500"
                    fontSize="28px"
                    lineHeight="100%"
                    whiteSpace="nowrap"
                >
                    <FormattedMessage id="App.Containers.Punch.Title" />
                </Typography>
            </FormControlLabel>
            <FormControlItem
                aria-label={formatMessage({
                    id: 'App.Containers.Punch.DatePicker',
                })}
            >
                <Tooltip
                    title={formatMessage({
                        id: 'App.Containers.Punch.PreviousDay',
                    })}
                >
                    <span>
                        <IconButton
                            disabled={moment(businessDay).isSameOrBefore(
                                firstConfigurationDay
                            )}
                            onClick={toYesterday}
                        >
                            <ChevronLeft />
                        </IconButton>
                    </span>
                </Tooltip>
                <DatePicker
                    maxDate={lastBusinessDay}
                    minDate={firstConfigurationDay}
                    openTo="day"
                    views={['year', 'month', 'day']}
                    value={businessDay}
                    disabled={!hasShiftsData}
                    onChange={updateBusinessDay}
                    renderInput={(params) => <TextFieldStyled {...params} />}
                />
                <Tooltip
                    title={formatMessage({
                        id: 'App.Containers.Punch.NextDay',
                    })}
                >
                    <span>
                        <IconButton
                            disabled={moment(businessDay).isSameOrAfter(
                                lastBusinessDay
                            )}
                            onClick={toTomorrow}
                        >
                            <ChevronRight />
                        </IconButton>
                    </span>
                </Tooltip>
            </FormControlItem>
            <FormControlItem>
                <TableRowsFilterInput
                    Component={TextFieldStyled}
                    disabled={!hasShiftsData}
                    placeholder={formatMessage({
                        id: 'App.Containers.Punch.Search',
                    })}
                />
                {canAddShift && (
                    <Tooltip
                        title={formatMessage({
                            id: 'App.Containers.Punch.AddShift',
                        })}
                    >
                        <AddShiftButtonWrapper>
                            <MainButton startIcon={<Add />} onClick={addRow}>
                                <FormattedMessage id="App.Containers.Punch.AddShift" />
                            </MainButton>
                        </AddShiftButtonWrapper>
                    </Tooltip>
                )}
            </FormControlItem>
        </Root>
    );
};

export default PunchToolbar;
