import { FC } from 'react';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

// TODO: Angular included the `fill` and `color` may be included as part of the theme/global styling. Delete these two properties if react's theme will provide them
const Spinner = styled(CircularProgress)`
    fill: transparent;
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Props {}

const Loader: FC<Props> = () => <Spinner size={100} thickness={4.25} />;

export default Loader;
