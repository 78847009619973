import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { rawIntl } from 'services/intl';
import { TableContainer } from '@mui/material';

const { formatMessage } = rawIntl;

const fields = [
    'EmployeeName',
    'CheckNumber',
    'Time',
    'Table',
    'PrimaryPayment',
    'Amount',
] as const;

type Columns = {
    [key in typeof fields[number]]: ReactNode;
};

const columns: Columns = {
    /* eslint-disable prettier/prettier */
    EmployeeName: formatMessage({ id: 'App.Containers.GuestChecks.EmployeeName' }),
    CheckNumber: formatMessage({ id: 'App.Containers.GuestChecks.CheckNumber' }),
    Time: formatMessage({ id: 'App.Containers.GuestChecks.Time' }),
    Table: formatMessage({ id: 'App.Containers.GuestChecks.Table' }),
    PrimaryPayment: formatMessage({ id: 'App.Containers.GuestChecks.PrimaryPayment' }),
    Amount: formatMessage({ id: 'Common.Amount' }),
    /* eslint-enable prettier/prettier */
};

const StyledTable = styled(Table)`
    table-layout: fixed;
`;

const Th = styled(TableCell)`
    width: 100px;
`;

const CheckTable: FC = () => (
    <TableContainer component="section">
        <StyledTable>
            <TableHead>
                <TableRow>
                    {fields.map((field, i) => (
                        // TODO: make columns sortable
                        <Th
                            key={field}
                            align={fields[i + 1] ? 'left' : 'right'}
                        >
                            {columns[field]}
                        </Th>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody />
        </StyledTable>
    </TableContainer>
);

export default CheckTable;
