import { ManagerOne } from './ManagerOne.API';

// Why do we have this file? We can't alias Classes to type, in a way that can be used for generics AND instantiation. Details of the workaround for this here: https://github.com/microsoft/TypeScript/issues/2559
// At least, not in ts 4.4.4.
// Anyway, please keep in abc order of the full namespace names, if possible
// Example:
// 1st ==> export class ManagerOneLoginResponse extends ManagerOne.API.Common.ManagerOneLoginResponse {}
// 2nd ==> export class Alphabet extends ManagerOne.API.RecoverPassword.Models.Alphabet {}

export class ManagerOneLoginResponse extends ManagerOne.API.Common.ManagerOneLoginResponse {}
export class ManagerOneLogin extends ManagerOne.API.Common.ManagerOneLogin {}
export class SiteInfo extends ManagerOne.API.Common.SiteInfo {}
export class RequestBase extends ManagerOne.API.Common.RequestBase  {}

export class CategoryDetails extends ManagerOne.API.Dashboard.Models.CategoryDetails {}
export class ProductDetails extends ManagerOne.API.Dashboard.Models.ProductDetails {}
export class GetDashboardRequest extends ManagerOne.API.Dashboard.Models.GetDashboardRequest {}
export class GetDashboardResponse extends ManagerOne.API.Dashboard.Models.GetDashboardResponse {}

export class VoidDetails extends ManagerOne.API.Dashboard.Models.VoidDetails {}
export class EditReason extends ManagerOne.API.Labor.Models.PunchEditor.EditReason {}
export class GetPunchEditorData extends ManagerOne.API.Labor.Models.PunchEditor.GetPunchEditorData {}
export class GetOtherWagesDataResponse extends ManagerOne.API.Labor.Models.PunchEditor.GetOtherWagesDataResponse {}
export class GetPunchEditorDataResponse extends ManagerOne.API.Labor.Models.PunchEditor.GetPunchEditorDataResponse {}
export class OtherWages extends ManagerOne.API.Labor.Models.PunchEditor.OtherWage { }
export class GetPunchedShiftDataResponse extends ManagerOne.API.Labor.Models.PunchEditor.GetPunchedShiftDataResponse {}
export class Shift extends ManagerOne.API.Labor.Models.PunchEditor.Shift {}

export class ChangePasswordRequest extends ManagerOne.API.RecoverPassword.Models.ChangePasswordRequest {}
export class ChangePasswordResponse extends ManagerOne.API.RecoverPassword.Models.ChangePasswordResponse {}
export const { ChangeResult } = ManagerOne.API.RecoverPassword.Models;
export class RecoverPasswordRequest extends ManagerOne.API.RecoverPassword.Models.RecoverPasswordRequest {}
export class RecoverPasswordResponse extends ManagerOne.API.RecoverPassword.Models.RecoverPasswordResponse {}

export class GetScheduleData extends ManagerOne.API.Labor.Models.Scheduler.GetScheduleData {}
export class GetScheduleDataResponse extends ManagerOne.API.Labor.Models.Scheduler.GetScheduleDataResponse {}
export class DeliverSchedulesResponse extends ManagerOne.API.Labor.Models.Scheduler.DeliverSchedulesResponse {}
export class DeliverSchedules extends ManagerOne.API.Labor.Models.Scheduler.DeliverSchedules {}
export class EmployeeSchedule  extends ManagerOne.API.Labor.Models.Scheduler.EmployeeSchedule {}
export class CopySchedule extends ManagerOne.API.Labor.Models.Scheduler.CopySchedule {}
export class CopyScheduleResponse extends ManagerOne.API.Labor.Models.Scheduler.CopyScheduleResponse {}
export class SaveSchedule  extends ManagerOne.API.Labor.Models.Scheduler.SaveSchedule {}
export class SaveScheduleResponse extends ManagerOne.API.Labor.Models.Scheduler.SaveScheduleResponse {}
export class ValidateTokenResponse extends ManagerOne.API.RecoverPassword.Models.ValidateTokenResponse {}
export class ValidateTokenRequest extends ManagerOne.API.RecoverPassword.Models.ValidateTokenRequest {}
export class GetSitesResponse extends ManagerOne.API.User.Models.GetSitesResponse {}
export class CreateDepositResponse  extends ManagerOne.API.Deposits.Models.CreateDepositResponse {}
export class EditDepositResponse  extends ManagerOne.API.Deposits.Models.EditDepositResponse {}
export class GetDaysToBackEditResponse  extends ManagerOne.API.Deposits.Models.GetDaysToBackEditResponse {}
export class CreateDeposit extends ManagerOne.API.Deposits.Models.CreateDeposit {}
export class Deposit extends ManagerOne.API.Deposits.Models.Deposit {}
export class GetDaysToBackEdit extends ManagerOne.API.Deposits.Models.GetDaysToBackEdit {}
export class DeleteDeposit extends ManagerOne.API.Deposits.Models.DeleteDeposit {}
export class EditDeposit extends ManagerOne.API.Deposits.Models.EditDeposit {}
export class GetDeposits extends ManagerOne.API.Deposits.Models.GetDeposits {}
export class GetDepositsResponse extends ManagerOne.API.Deposits.Models.GetDepositsResponse {}
export class BusinessDayRangeResponse extends ManagerOne.API.Utilities.Models.BusinessDayRangeResponse {}
export class BusinessDayResponse extends ManagerOne.API.Utilities.Models.BusinessDayResponse {}
export class BusinessDaysResponse extends ManagerOne.API.Utilities.Models.BusinessDaysResponse {}
export class UserDetailsResponse extends ManagerOne.API.User.Models.UserDetailsResponse {}

export class GetReportsResponse extends ManagerOne.API.Reports.Models.GetReportsResponse {}
export class ReportInfo extends ManagerOne.API.Reports.Models.ReportInfo {}
export class ReportSection extends ManagerOne.API.Reports.Models.Builder.ReportSection {}