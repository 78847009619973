import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSiteId } from 'state/sitesInfoSlice';
import { GetDashboardResponse } from 'services/ManagerOneApi';
import { RootState } from 'state/reducers';
import { getDashboardData } from 'services/api';
import { Box } from '@mui/system';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import DailySalesCard from './DailySalesCard';
import WeatherCard from './WeatherCard';
import LaborOvertimePayCard from './LaborOvertimePayCard';
import PMIXCard from './PMIXCard/index';
import VoidsCard from './VoidsCard/index';
import RevCenterSalesCard from './RevCenterSalesCard';
import OrderModeSalesCard from './OrderModeSalesCard';

export interface IDashboardData {
    dashboardData: GetDashboardResponse;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DashboardCard = styled(Card)(({ theme }) => ({
    position: 'relative',
    margin: '15px',
    width: '320px',
    height: '320px',
    boxShadow:
        '0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
    paddingTop: '15px',
}));

export const ChartCenterLabel = styled(Box)(() => ({
    position: 'absolute',
    height: 320,
    width: 320,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
}));

export const ChartHeader = styled(Box)(() => ({
    fontWeight: 'bold',
    fontSize: 18,
    zIndex: 1,
}));

export const ChartSubHeader = styled(Box)(() => ({
    fontSize: 14,
    zIndex: 1,
}));

const Dashboard: FC = () => {
    const dispatch = useDispatch();
    const dashboardData = useSelector(
        (state: RootState) => state.dashboard.dashboardData
    );
    const selectedSiteId = useSelector(
        (state: RootState) => state.sitesInfo.selectedSiteId
    );
    const sites = useSelector((state: RootState) => state.sitesInfo.sites);

    useEffect(() => {
        if (selectedSiteId === '' || selectedSiteId === undefined) {
            dispatch(setSelectedSiteId(sites?.[0]?.SiteId || ''));
        } else getDashboardData(selectedSiteId);
    }, [dispatch, selectedSiteId, sites]);

    return (
        <Box
            component="div"
            sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '24px 28px 24px 28px',
            }}
        >
            <WeatherCard dashboardData={dashboardData} />
            <DailySalesCard dashboardData={dashboardData} />
            <VoidsCard dashboardData={dashboardData} />
            <RevCenterSalesCard dashboardData={dashboardData} />
            <LaborOvertimePayCard dashboardData={dashboardData} />
            <PMIXCard dashboardData={dashboardData} />
            <OrderModeSalesCard dashboardData={dashboardData} />
        </Box>
    );
};

export default Dashboard;
