import { useCallback, useContext, useEffect, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { Blocker, History, Transition } from 'history';

/**
 * - conditionally blocks navigation and show a prompt
 * - navigation includes clicking on achor tags, clicking back button of browser, and clicking forward button of browser
 * - However, this does not block navigation of browser's refresh button
 */
const useBlocker = (blocker: Blocker, shouldBlock = true): void => {
    const navigator = useContext(NavigationContext).navigator as History;

    useEffect(() => {
        if (!shouldBlock) return () => {};

        const unblock = navigator.block((tx: Transition) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                },
            };

            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [navigator, blocker, shouldBlock]);
};

type ConfirmActions = {
    cancel: () => void;
    proceed: () => void;
};

export default function useConfirmNavigation(
    showPrompt: (shouldPrompt: boolean) => void,
    shouldPrompt: boolean
): ConfirmActions {
    const [transition, setTransition] = useState<Transition | null>(null);

    const prompt = useCallback(
        (next: Transition) => {
            showPrompt(shouldPrompt);
            setTransition(next);
        },
        [showPrompt, shouldPrompt]
    );

    useBlocker(prompt, shouldPrompt);

    const proceed = useCallback(async () => {
        if (!transition) return;

        transition.retry();
        // TODO (Dan): fix memory leak, since state is set even after dismounting
        setTransition(null);
    }, [transition]);

    const cancel = useCallback(() => {
        setTransition(null);
    }, []);

    return { cancel, proceed };
}
