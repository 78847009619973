import {
    createTheme,
    NCRThemeOptions,
} from '@ncr-design-system/shared-frontend';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        // mobile: true; // adds the `mobile` breakpoint
        // tablet: true;
        // laptop: true;
        // desktop: true;
    }
}

const themeOptions: NCRThemeOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 400,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
};

const theme = createTheme(themeOptions);

export default theme;
