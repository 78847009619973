/* istanbul ignore file */
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setSelectedJobCodes } from 'state/schedulerSlice';
import MainButton from 'components/MainButton';
import List from '@mui/material/List';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ListItem from '@mui/material/ListItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { FormattedMessage } from 'react-intl';
import { MEDIUM } from 'styles/responsive_breakpoints';
import { GetScheduleDataResponse } from 'services/ManagerOneApi';
import useSelect from 'state/selector';

const Wrapper = styled.div`
    display: flex;
    margin-left: 10px;
    z-index: 1;
    width: 150px;
    @media (max-width: ${MEDIUM}) {
        margin-left: 0;
        width: 100px;
    }
`;

const Content = styled.div`
    display: flex;
    margin-top: 40px;
    flex-direction: column;
    position: absolute;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    height: 50px;
    background-color: white;
    z-index: 1;
`;

const ApplyButton = styled.div`
    display: flex;
    justify-content: end;
    height: 50px;
    background-color: white;
`;

const ListContent = styled.div`
    max-height: 50vh;
    width: 250px;
    overflow-y: scroll;
`;

const getJobFilterOptions = (
    scheduleData: GetScheduleDataResponse
): string[] => {
    const allJobTitles: string[] = [];
    scheduleData?.EmployeeSchedules?.map((es) =>
        es?.Employee?.Jobs?.forEach((job) => {
            allJobTitles.push(job.JobTitle);
        })
    );
    const uniqueJobTitles = Array.from(new Set(allJobTitles.map((job) => job)));
    return uniqueJobTitles.sort((a, b) => a.localeCompare(b));
};

const JobCodesDropDown: FC = () => {
    const dispatch = useDispatch();
    const selectedJobCodes = useSelect((s) => s.scheduler.selectedJobCodes);
    const data = useSelect((s) => s.scheduler.unsavedScheduleData);
    const [checked, setChecked] = useState<string[]>(selectedJobCodes);
    const [opened, setOpened] = useState(false);
    const [jobCodes, setJobCodes] = useState<string[]>([]);

    useEffect(() => {
        setJobCodes(getJobFilterOptions(data));
        setChecked(selectedJobCodes);
    }, [data, selectedJobCodes]);

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const clearAll = () => {
        setChecked([]);
    };

    const selectAll = () => {
        setChecked(jobCodes);
    };

    const apply = () => {
        dispatch(setSelectedJobCodes(checked));
        setOpened(false);
    };

    const clickAway = () => {
        setOpened(false);
    };

    const listContent = (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        >
            {jobCodes.map((value) => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                    <ListItem key={value} disablePadding>
                        <ListItemButton
                            role={undefined}
                            onClick={handleToggle(value)}
                            dense
                            sx={{ height: '40px' }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    data-testid={`${value}-dropdown-checkbox-id`}
                                    size="small"
                                />
                            </ListItemIcon>
                            <ListItemText
                                data-testid={`${value}-dropdown-id`}
                                id={labelId}
                                primary={value}
                            />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );

    return (
        <ClickAwayListener onClickAway={clickAway}>
            <Wrapper>
                <MainButton
                    variant="text"
                    color="inherit"
                    onClick={() => setOpened(!opened)}
                    dataTestId="job-code-test-id"
                >
                    <FormattedMessage id="App.Containers.Scheduler.JobCode" />
                    <ArrowDropDownIcon />
                </MainButton>

                {opened && (
                    <Content data-testid="job-code-content-test-id">
                        <ButtonContainer>
                            <MainButton
                                variant="text"
                                onClick={clearAll}
                                style={{ width: '100%' }}
                                dataTestId="jobcodes-clear"
                            >
                                <FormattedMessage id="Common.ClearAll" />
                            </MainButton>
                            <MainButton
                                variant="text"
                                onClick={selectAll}
                                style={{ width: '100%' }}
                                dataTestId="jobcodes-select-all"
                            >
                                <FormattedMessage id="Common.SelectAll" />
                            </MainButton>
                        </ButtonContainer>
                        <Divider sx={{ borderColor: 'lightgray' }} />
                        <ListContent>{listContent}</ListContent>
                        <Divider sx={{ borderColor: 'lightgray' }} />
                        <ApplyButton>
                            <MainButton
                                variant="text"
                                onClick={apply}
                                style={{ width: '75px' }}
                                dataTestId="jobcodes-apply"
                            >
                                <FormattedMessage id="Common.Apply" />
                            </MainButton>
                        </ApplyButton>
                    </Content>
                )}
            </Wrapper>
        </ClickAwayListener>
    );
};

export default JobCodesDropDown;
