import { gql } from '@apollo/client';

export const GET_MENU_ITEM = gql`
    query MenuItem($menuId: String!) {
        menuItem(menuId: $menuId) {
            id
            defaultAmount
            name
        }
    }
`;
