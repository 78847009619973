export const containsDigit = (value: string): boolean => {
    const re = /[0-9]/;
    return re.test(value);
};

export const containsUppercase = (value: string): boolean => {
    const re = /[A-Z]/;
    return re.test(value);
};

export const containsLowercase = (value: string): boolean => {
    const re = /[a-z]/;
    return re.test(value);
};

export const containsSpecialChar = (value: string): boolean => {
    const re = /[!@#$%^&*=+\-_?]/;
    return re.test(value);
};
