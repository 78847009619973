import { FC, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import DialogFooter from '@mui/material/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import styled from 'styled-components';
import useAppDispatch from 'state/dispatch';
import { rawIntl } from 'services/intl';
import MainButton from 'components/MainButton';
import { addDeposit, editDeposit } from 'services/api';
import useSelect from 'state/selector';
import { momentBusinessDay } from 'services/utils.service';
import { setDialogStatus } from 'state/depositsSlice';
import { DepositStatus } from '.';

type AddEditVariant = Extract<DepositStatus, 'add' | 'edit'>;
type AddEditTitle = {
    [key in AddEditVariant]: string;
};
type TextFieldEvent = TextFieldProps['onBlur'] & TextFieldProps['onChange'];

const { formatMessage } = rawIntl;

const addEditTitles: AddEditTitle = {
    add: formatMessage({ id: 'App.Containers.EditDeposits.AddDeposit' }),
    edit: formatMessage({ id: 'App.Containers.EditDeposits.EditDeposit' }),
};

export const messages = {
    ...addEditTitles,
    amount: formatMessage({ id: 'Common.Amount' }),
    cancel: formatMessage({ id: 'Common.Cancel' }),
    close: formatMessage({ id: 'Common.Close' }),
    description: formatMessage({ id: 'Common.Description' }),
    save: formatMessage({ id: 'Common.Save' }),
};

const Header = styled(DialogTitle)`
    display: flex;
    justify-content: space-between;
`;

const CloseButton = styled(IconButton)`
    padding-right: 0;
`;

const StyledDialogContent = styled(DialogContent)`
    display: grid;
    gap: 16px;
`;

const min = -999999;
const max = 999999;
const maxLength = 45;

const inRange = (num: number): boolean => num >= min && num <= max;

export type Props = {
    addOrEdit: AddEditVariant;
};

const EditAddDepositsDialog: FC<Props> = ({ addOrEdit }) => {
    const dispatch = useAppDispatch();
    const day = useSelect((s) => s.deposits.businessDay);
    const id = useSelect((s) => s.deposits.depositId);
    const siteId = useSelect((s) => s.sitesInfo.selectedSiteId);
    const amountRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLInputElement>(null);

    const [amountIsValid, setAmoundIsValid] = useState(true);
    const [formWasClicked, setFormWasClicked] = useState<true>();

    const validator: Record<string, boolean> = {
        amountIsValid,
    };

    const close = () => {
        dispatch(setDialogStatus(null));
    };

    const validateAmount: TextFieldEvent = ({ target: { value } }) => {
        const isValid = !!value && inRange(Number(value));
        setAmoundIsValid(isValid);
        setFormWasClicked(true);
    };

    const validateDescription: TextFieldEvent = () => {
        setFormWasClicked(true);
    };

    const save = async () => {
        const amount = Number(amountRef.current?.value || '0');
        const description = descriptionRef.current?.value || '';
        const businessDay = momentBusinessDay(day);

        close();
        if (addOrEdit === 'add') {
            await addDeposit({ amount, businessDay, description, siteId });
        } else {
            await editDeposit({ amount, businessDay, description, id, siteId });
        }
        dispatch(setDialogStatus('submit'));
    };

    return (
        <Dialog fullWidth open>
            <Header>
                {messages[addOrEdit]}
                <Tooltip title={messages.close}>
                    <CloseButton onClick={close}>
                        <Close />
                    </CloseButton>
                </Tooltip>
            </Header>
            <StyledDialogContent>
                <TextField
                    error={!validator.amountIsValid}
                    id="edit-add-dialog-amount-input"
                    inputRef={amountRef}
                    inputProps={{ min, max }}
                    label={messages.amount}
                    onBlur={validateAmount}
                    onChange={validateAmount}
                    required
                    type="number"
                    variant="standard"
                />
                <TextField
                    id="edit-add-dialog-description-input"
                    inputRef={descriptionRef}
                    inputProps={{ maxLength }}
                    onBlur={validateDescription}
                    onChange={validateDescription}
                    variant="standard"
                    label={messages.description}
                />
            </StyledDialogContent>
            <DialogFooter>
                <MainButton
                    variant="text"
                    aria-label={messages.cancel}
                    onClick={close}
                    sx={{ textTransform: 'uppercase' }}
                >
                    {messages.cancel}
                </MainButton>
                <MainButton
                    id="edit-add-dialog-save"
                    variant="text"
                    aria-label={messages.save}
                    disabled={
                        !formWasClicked ||
                        Object.values(validator).includes(false)
                    }
                    onClick={save}
                    sx={{ textTransform: 'uppercase' }}
                >
                    {messages.save}
                </MainButton>
            </DialogFooter>
        </Dialog>
    );
};

export default EditAddDepositsDialog;
