import { FC, useRef } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import moment, { Moment } from 'moment';
import { InputBaseProps } from '@mui/material/InputBase';
import AddIcon from '@mui/icons-material/Add';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import DatePicker, { DatePickerProps } from '@mui/lab/DatePicker';
import Search from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { rawIntl } from 'services/intl';
import styled from 'styled-components';
import MainButton from 'components/MainButton';
import useSelect from 'state/selector';
import useAppDispatch from 'state/dispatch';
import {
    setBusinessDay,
    setDialogStatus,
    setFilter,
} from 'state/depositsSlice';
import { MEDIUM } from 'styles/responsive_breakpoints';

const messages = {
    title: rawIntl.formatMessage({
        id: 'App.Containers.EditDeposits.Title',
    }),
    refresh: rawIntl.formatMessage({ id: 'Common.Refresh' }),
    add: rawIntl.formatMessage({ id: 'Common.Add' }),
};

const Header = styled.header`
    display: flex;
    @media (max-width: ${MEDIUM}) {
        align-items: center;
        flex-direction: column;
        gap: 8px;
    }
`;

const DatePickerSection = styled.section`
    display: flex;
    max-width: 250px;
`;

const FormControlItem = styled.section`
    display: flex;
    max-width: 300px;
    gap: 8px;
`;

const TextFieldStyled = styled(TextField)`
    fieldset {
        border-radius: 4px;
    }
`;
TextFieldStyled.defaultProps = {
    variant: 'outlined',
    size: 'small',
};

type Props = {
    canEdit: boolean;
    lastBusinessDay: string;
    firstBusinessDay: string;
    fetchDeposits: () => Promise<void>;
};

const FunctionBar: FC<Props> = ({
    canEdit,
    firstBusinessDay,
    lastBusinessDay,
    fetchDeposits,
}) => {
    const dispatch = useAppDispatch();
    const debounce = useRef<NodeJS.Timeout>();
    const businessDay = useSelect((s) => s.deposits.businessDay);
    const filter = useSelect((s) => s.deposits.filter);
    const isMobile = useMediaQuery(`(max-width:${MEDIUM})`);

    const updateCalendar: DatePickerProps<Moment>['onChange'] = (date) => {
        dispatch(setBusinessDay(date?.toString() || ''));
    };

    const moveDateByDay = (days: number) => (): void => {
        const newDay = moment(businessDay).add(days, 'days');
        updateCalendar(newDay);
    };

    const toTomorrow = moveDateByDay(1);
    const toYesterday = moveDateByDay(-1);

    const showAddDeposits = () => {
        dispatch(setDialogStatus('add'));
    };

    const onInput: InputBaseProps['onChange'] = ({ target }) => {
        if (debounce.current) {
            clearTimeout(debounce.current);
        }
        debounce.current = setTimeout(() => {
            dispatch(setFilter(target.value));
        }, 300);
    };

    return (
        <Header>
            <Typography
                id="table-title"
                component="div"
                sx={{
                    marginRight: '15px',
                }}
                fontSize="28px"
                flexGrow={1}
            >
                {messages.title}
            </Typography>
            <DatePickerSection
                aria-label={rawIntl.formatMessage({
                    id: 'App.Containers.EditDeposits.DatePicker',
                })}
            >
                <Tooltip
                    title={rawIntl.formatMessage({
                        id: 'App.Containers.Punch.PreviousDay',
                    })}
                >
                    <span>
                        <IconButton
                            aria-label={rawIntl.formatMessage({
                                id: 'App.Containers.Punch.PreviousDay',
                            })}
                            disabled={moment(businessDay).isBefore(
                                firstBusinessDay
                            )}
                            onClick={toYesterday}
                        >
                            <ChevronLeft />
                        </IconButton>
                    </span>
                </Tooltip>
                <DatePicker
                    disabled={!businessDay || !firstBusinessDay}
                    maxDate={moment(lastBusinessDay)}
                    minDate={moment(firstBusinessDay)}
                    onChange={updateCalendar}
                    openTo="day"
                    renderInput={(params) => <TextFieldStyled {...params} />}
                    views={['year', 'month', 'day']}
                    value={businessDay}
                />
                <Tooltip
                    title={rawIntl.formatMessage({
                        id: 'Common.NextDay',
                    })}
                >
                    <span>
                        <IconButton
                            aria-label={rawIntl.formatMessage({
                                id: 'Common.NextDay',
                            })}
                            disabled={moment(businessDay).isSameOrAfter(
                                lastBusinessDay
                            )}
                            onClick={toTomorrow}
                        >
                            <ChevronRight />
                        </IconButton>
                    </span>
                </Tooltip>
            </DatePickerSection>
            <FormControlItem>
                <TextFieldStyled
                    onChange={onInput}
                    defaultValue={filter}
                    placeholder={rawIntl.formatMessage({
                        id: 'Common.Search',
                    })}
                    inputProps={{
                        'aria-label': rawIntl.formatMessage({
                            id: 'Common.Search',
                        }),
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
                {!isMobile && (
                    <Tooltip title={messages.refresh}>
                        <IconButton
                            id="deposits-refresh-button"
                            onClick={fetchDeposits}
                            sx={{ padding: 0 }}
                        >
                            <RefreshIcon
                                sx={{ color: 'black' }}
                                fontSize="medium"
                            />
                        </IconButton>
                    </Tooltip>
                )}
                {canEdit && (
                    <MainButton
                        aria-label={messages.add}
                        id="deposits-add-button"
                        disabled={!businessDay}
                        size="large"
                        variant="contained"
                        startIcon={<AddIcon />}
                        sx={{ height: '40px' }}
                        onClick={showAddDeposits}
                    >
                        {messages.add}
                    </MainButton>
                )}
            </FormControlItem>
        </Header>
    );
};

export default FunctionBar;
