/* istanbul ignore file */
import { ReactNode } from 'react';
import { Route, Outlet, RouteObject } from 'react-router-dom';
import Dashboard from 'containers/Dashboard';
import PunchesLandingPage from 'containers/Punch';
import Scheduler from 'containers/Scheduler';
import Reports from 'containers/Reports';
import ReportBuilder from 'containers/Reports/ReportsBuilder';
import Login from 'components/Login';
import { ManagerOne } from 'services/ManagerOneApi/ManagerOne.API';
import ResetPassword from 'components/ResetPassword';
import EditDepositsLanding from 'containers/EditDeposits';
import ChangePassword from 'components/ChangePassword';
import { IErrorMessages } from 'components/ErrorModal/messages';
import RestrictedRoute from 'components/RestrictedRoute';
import ErrorModal from 'components/ErrorModal';
import GuestCheckRouter from 'containers/GuestCheck';
import PunchLayout from 'containers/Punch/PunchLayout';
import MenuItems from 'containers/MenuItems';

export const ROUTES = {
    LOGIN: '/login',
    RECOVER_PASSWORD: '/recover-password',
    CHANGE_PASSWORD: '/change-password',
    DEPOSITS: '/deposits',
    PUNCHES: {
        BASE: '/punches',
        LANDING: '',
        EDITSHIFT: 'edit/shift',
        EDITOTHERWAGE: 'edit/otherwage',
    },
    SCHEDULER: '/scheduler',
    GUEST_CHECKS: '/guestChecks',
    HOUSE_ACCOUNTS: '/houseAccounts',
    TIP_SHARE: '/tipShare',
    REPORTS: {
        BASE: '/reports',
        LANDING: '',
        REPORTBUILDER: '/reports/builder',
    },
    UTILITIES: '/utilities',
    // Common Edits
    DASHBOARD: '/dashboard',
    MENU_ITEMS: '/menu-items',
    MENU_ITEM_ROUTING: '/menu-item-routing',
    MODIFIER_GROUPS: '/modifier-groups',
    MENU_CATEGORIES: '/menu-categories',
    PANELS: '/panels',
    SUBMENUS: '/submenus',
    EMPLOYEES: '/employees',
    USERS: '/users',
};

const {
    FINANCIAL_RECONCILIATION_DEPOSIT_ADMINISTRATOR,
    LABOR_DATA_MANAGEMENT_SHIFT_WRITER,
    TIP_MANAGEMENT_WRITER,
    LABOR_DATA_MANAGEMENT_SCHEDULE_WRITER,
    TIP_MANAGEMENT_SERVICE_TIP_SHARE_DISTRIBUTOR,
    FINANCIAL_RECONCILIATION_HOUSE_ACCOUNT_ADMINISTRATOR,
} = ManagerOne.API.User.UserRoles;

const ApplicationRoutes: RouteObject[] = [
    {
        path: ROUTES.LOGIN,
        element: <Login />,
    },
    {
        path: ROUTES.RECOVER_PASSWORD,
        element: <ResetPassword />,
    },
    {
        path: ROUTES.CHANGE_PASSWORD,
        element: (
            <RestrictedRoute>
                <ChangePassword />
            </RestrictedRoute>
        ),
    },
    {
        path: ROUTES.DASHBOARD,
        element: (
            <RestrictedRoute>
                <Dashboard />
            </RestrictedRoute>
        ),
    },
    {
        path: ROUTES.DEPOSITS,
        element: (
            <RestrictedRoute
                requiredRoles={[FINANCIAL_RECONCILIATION_DEPOSIT_ADMINISTRATOR]}
            >
                <EditDepositsLanding />
            </RestrictedRoute>
        ),
    },
    {
        path: ROUTES.PUNCHES.BASE,
        element: (
            <RestrictedRoute
                requiredRoles={[
                    LABOR_DATA_MANAGEMENT_SHIFT_WRITER,
                    TIP_MANAGEMENT_WRITER,
                ]}
            >
                <PunchLayout>
                    <Outlet />
                </PunchLayout>
            </RestrictedRoute>
        ),
        children: [
            {
                path: ROUTES.PUNCHES.LANDING,
                element: <PunchesLandingPage />,
            },
            {
                path: ROUTES.PUNCHES.EDITSHIFT,
                element: (
                    <ErrorModal
                        pathname={ROUTES.PUNCHES.EDITSHIFT}
                        errorType={IErrorMessages.NotImplemented}
                    />
                ),
            },
            {
                path: ROUTES.PUNCHES.EDITOTHERWAGE,
                element: (
                    <ErrorModal
                        pathname={ROUTES.PUNCHES.EDITOTHERWAGE}
                        errorType={IErrorMessages.NotImplemented}
                    />
                ),
            },
        ],
    },
    {
        path: ROUTES.SCHEDULER,
        element: (
            <RestrictedRoute
                requiredRoles={[LABOR_DATA_MANAGEMENT_SCHEDULE_WRITER]}
            >
                <Scheduler />
            </RestrictedRoute>
        ),
    },
    {
        path: ROUTES.GUEST_CHECKS,
        element: (
            <RestrictedRoute>
                <GuestCheckRouter />
            </RestrictedRoute>
        ),
    },
    {
        path: ROUTES.HOUSE_ACCOUNTS,
        element: (
            <RestrictedRoute
                requiredRoles={[
                    FINANCIAL_RECONCILIATION_HOUSE_ACCOUNT_ADMINISTRATOR,
                ]}
            />
        ),
    },
    {
        path: ROUTES.TIP_SHARE,
        element: (
            <RestrictedRoute
                requiredRoles={[TIP_MANAGEMENT_SERVICE_TIP_SHARE_DISTRIBUTOR]}
            />
        ),
    },
    {
        path: ROUTES.REPORTS.BASE,
        element: (
            <RestrictedRoute>
                <Outlet />
            </RestrictedRoute>
        ),
        children: [
            {
                path: ROUTES.REPORTS.LANDING,
                element: <Reports />,
            },
            {
                path: ROUTES.REPORTS.REPORTBUILDER,
                element: <ReportBuilder />,
            },
        ],
    },
    {
        path: ROUTES.UTILITIES,
        element: <RestrictedRoute />,
    },
    {
        path: ROUTES.MENU_ITEMS,
        element: (
            <RestrictedRoute isCommonEditsView>
                <MenuItems />
            </RestrictedRoute>
        ),
    },
    {
        path: ROUTES.MENU_ITEM_ROUTING,
        element: <RestrictedRoute isCommonEditsView />,
    },
    {
        path: ROUTES.MODIFIER_GROUPS,
        element: <RestrictedRoute isCommonEditsView />,
    },
    {
        path: ROUTES.MENU_CATEGORIES,
        element: <RestrictedRoute isCommonEditsView />,
    },
    {
        path: ROUTES.PANELS,
        element: <RestrictedRoute isCommonEditsView />,
    },
    {
        path: ROUTES.SUBMENUS,
        element: <RestrictedRoute isCommonEditsView />,
    },
    {
        path: ROUTES.EMPLOYEES,
        element: <RestrictedRoute isCommonEditsView />,
    },
    {
        path: ROUTES.USERS,
        element: <RestrictedRoute isCommonEditsView />,
    },
];

export const setupRoutes = (): ReactNode => {
    const routes = ApplicationRoutes.map(({ path, element, children }) => (
        <Route key={path} path={path} element={element}>
            {children?.map((subRoute) => (
                <Route
                    key={`${path}/${subRoute.path || 'landingPage'}`}
                    path={subRoute.path}
                    element={subRoute.element}
                />
            ))}
        </Route>
    ));

    return routes;
};
