import { FC, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ROUTES } from 'routes';
import CheckIcon from '@mui/icons-material/Check';
import MainButton from 'components/MainButton';
import { FormControl, Box, Typography, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
    containsDigit,
    containsLowercase,
    containsSpecialChar,
    containsUppercase,
} from 'services/validationHelper';
import { changePassword, logOut } from 'services/api';
import { ChangeResult } from 'services/ManagerOneApi';
import useSelect from 'state/selector';
import { rawIntl } from 'services/intl';

const ErrorText = styled.span`
    color: #b00200;
    font-size: 13px;
    margin-bottom: 10px;
`;

const ErrorMessageIds = {
    [ChangeResult.InvalidLength]:
        'App.Components.ChangePassword.PasswordLengthError',
    [ChangeResult.InvalidStrength]:
        'App.Components.ChangePassword.PasswordStrengthError',
    [ChangeResult.PasswordMatches]:
        'App.Components.ChangePassword.PasswordMatchesError',
    [ChangeResult.SomethingWentWrong]:
        'App.Components.ChangePassword.PasswordError',
} as const;

const ChangePassword: FC = () => {
    const expired = useSelect((s) => s.user.credentialsExpired);
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [reEnterPassword, setReEnterPassword] = useState('');
    const [inputValues, setInputValues] = useState({
        hasDigit: false,
        hasUppercase: false,
        hasLowercase: false,
        hasSpecialChar: false,
        isValid: false,
    });
    const navigate = useNavigate();

    useEffect(() => {
        const hasDigit = containsDigit(password);
        const hasUppercase = containsUppercase(password);
        const hasLowercase = containsLowercase(password);
        const hasSpecialChar = containsSpecialChar(password);
        const containsMinLength = password.length >= 10;
        const passwordsMatch = password === reEnterPassword;
        const isValid =
            hasDigit &&
            hasUppercase &&
            hasSpecialChar &&
            hasLowercase &&
            containsMinLength &&
            passwordsMatch;

        setInputValues({
            hasDigit,
            hasUppercase,
            hasLowercase,
            hasSpecialChar,
            isValid,
        });
    }, [password, reEnterPassword]);

    const cancel = async () => {
        if (expired) {
            await logOut();
            navigate(ROUTES.LOGIN);
            return;
        }
        navigate(ROUTES.DASHBOARD);
    };

    const submitChangePasswordRequest = async () => {
        try {
            const { Result: result } = await changePassword(password);

            if (result === ChangeResult.Success) {
                if (expired) {
                    await logOut();
                    navigate(ROUTES.LOGIN);
                } else {
                    navigate(ROUTES.DASHBOARD);
                }
            } else {
                const message =
                    ErrorMessageIds[result] ||
                    ErrorMessageIds[ChangeResult.SomethingWentWrong];
                setErrorMessage(message);
            }
        } catch (e) {
            setErrorMessage(ErrorMessageIds[ChangeResult.SomethingWentWrong]);
        }
    };

    const messages = {
        password: rawIntl.formatMessage({
            id: 'App.Components.ChangePassword.NewPassword',
        }),
        reEnterPassword: rawIntl.formatMessage({
            id: 'App.Components.ChangePassword.ReEnterNewPassword',
        }),
    };

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                marginTop: '72px',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0px 35px 100px 0px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '380px',
                }}
            >
                <Typography variant="h5" mb={2} mt={3} component="div">
                    {expired ? (
                        <FormattedMessage id="App.Components.Login.ExpiredCredentials" />
                    ) : (
                        <FormattedMessage id="App.Components.ChangePassword.Title" />
                    )}
                </Typography>
                <Typography variant="body1" mb={2} component="div">
                    <FormattedMessage id="App.Components.ChangePassword.PleaseReset" />
                </Typography>
                <Typography variant="body1" component="div">
                    <FormattedMessage id="App.Components.ChangePassword.PasswordConstraints" />
                </Typography>
                <ul>
                    <li>
                        <Box display="flex">
                            <FormattedMessage id="App.Components.ChangePassword.OneDigit" />
                            {inputValues.hasDigit && (
                                <CheckIcon sx={{ paddingBottom: '2px' }} />
                            )}
                        </Box>
                    </li>
                    <li>
                        <Box display="flex">
                            <FormattedMessage id="App.Components.ChangePassword.OneLowerCase" />
                            {inputValues.hasLowercase && (
                                <CheckIcon sx={{ paddingBottom: '2px' }} />
                            )}
                        </Box>
                    </li>
                    <li>
                        <Box display="flex">
                            <FormattedMessage id="App.Components.ChangePassword.OneUpperCase" />
                            {inputValues.hasUppercase && (
                                <CheckIcon sx={{ paddingBottom: '2px' }} />
                            )}
                        </Box>
                    </li>
                    <li>
                        <Box display="flex">
                            <FormattedMessage id="App.Components.ChangePassword.OneSpecialCharacter" />
                            {inputValues.hasSpecialChar && (
                                <CheckIcon sx={{ paddingBottom: '2px' }} />
                            )}
                        </Box>
                    </li>
                </ul>

                <FormControl>
                    <TextField
                        label={messages.password}
                        variant="standard"
                        fullWidth
                        sx={{ marginTop: 1 }}
                        type="password"
                        value={password}
                        inputProps={{
                            'data-testid': 'enter-password-input',
                        }}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        label={messages.reEnterPassword}
                        variant="standard"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        type="password"
                        value={reEnterPassword}
                        inputProps={{
                            'data-testid': 're-enter-password-input',
                        }}
                        onChange={(e) => setReEnterPassword(e.target.value)}
                    />
                    {errorMessage !== '' && (
                        <ErrorText data-testid="change-password-error">
                            <FormattedMessage id={errorMessage} />
                        </ErrorText>
                    )}
                    <Box display="flex" justifyContent="flex-end" mt={4}>
                        <MainButton
                            id="change-cancel-button"
                            variant="outlined"
                            dataTestId="change-cancel-button"
                            onClick={cancel}
                            sx={{ width: '90px' }}
                        >
                            <FormattedMessage id="App.Components.Login.CancelAction" />
                        </MainButton>
                        <MainButton
                            id="change-password-submit-button"
                            variant="contained"
                            dataTestId="change-password-submit-button"
                            onClick={submitChangePasswordRequest}
                            disabled={!inputValues.isValid}
                            sx={{ marginLeft: '12px', width: '90px' }}
                        >
                            <FormattedMessage id="Common.Submit" />
                        </MainButton>
                    </Box>
                </FormControl>
            </Box>
        </div>
    );
};

export default ChangePassword;
