import { FC } from 'react';
import { Chart } from 'react-google-charts';
import { rawIntl } from 'services/intl';
import { CategoryDataLabels } from './PMIXChart';

type P = {
    dataLabels: CategoryDataLabels;
};

const BlankPMIXChart: FC<P> = ({ dataLabels }) => {
    const noSalesMessage = rawIntl.formatMessage({
        id: 'App.Containers.PMIXCard.NoSalesCategoryData',
    });

    const dataRow = [noSalesMessage];
    dataLabels.slice(1).forEach((_) => dataRow.push('1'));
    const data = [dataLabels, dataRow];

    return (
        <Chart
            chartType="PieChart"
            width="304px"
            height="304px"
            data={data}
            options={{
                tooltip: { trigger: 'none' },
                title: rawIntl.formatMessage({
                    id: 'App.Containers.PMIXCard.Title',
                }),
                pieSliceText: 'label',
                titleTextStyle: {
                    fontName: 'Roboto',
                    fontSize: 18,
                    bold: true,
                },
                colors: ['#D3D3D3', '#D3D3D3'],
                chartArea: {
                    left: 10,
                    right: 10,
                },
                legend: {
                    alignment: 'center',
                    textStyle: {
                        fontName: 'Roboto',
                        fontSize: 10,
                    },
                },
            }}
        />
    );
};

export default BlankPMIXChart;
