import { GetDepositsResponse } from 'services/ManagerOneApi';

export const mockedData = new GetDepositsResponse({
    Header: { Success: true, Statuses: [] },
    Deposits: [
        {
            Id: '2d3b6d9840b84b5080532a42a3863cc3-20211006-15003-1-47745',
            Amount: 20.0,
            EditedBy: 'user1',
            EditedDate: '2021-10-27T13:15:50',
            Description: 'test',
        },
        {
            Id: 'c51568ad4e074c14b8e862a140fbbdeb',
            Amount: 100.0,
            EditedBy: 'user2',
            EditedDate: '2021-12-02T08:53:54',
            Description: '12/2/20121 Test',
        },
        {
            Id: '1bb3e9dcfd4d42849b40c285a1e20dfc',
            Amount: 8.23,
            EditedBy: 'user1',
            EditedDate: '2021-12-02T08:54:22',
            Description: 'Chris was here',
        },
    ],
});
