import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    InputAdornment,
    StandardTextFieldProps,
    TextField,
} from '@mui/material';
import { setTableFilter } from 'state/punchEditorSlice';
import useAppDispatch from 'state/dispatch';
import { useIntl } from 'react-intl';
import { Search } from '@mui/icons-material';

const TextFieldStyled = styled(TextField)`
    fieldset {
        border-radius: 4px;
    }
`;
TextFieldStyled.defaultProps = {
    variant: 'outlined',
    size: 'small',
};

type Props = {
    Component: typeof TextField;
    disabled: boolean;
    placeholder: string;
};

const TableRowsFilterInput: FC<Props> = ({
    Component = TextField,
    disabled,
    placeholder,
}) => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        const debounce = setTimeout(() => {
            dispatch(setTableFilter(inputValue));
        }, 300);

        return () => {
            clearTimeout(debounce);
        };
    }, [dispatch, inputValue]);

    // TODO: move all deboucing logic from useEffect into event handler function
    const onChange: StandardTextFieldProps['onChange'] = ({ target }) => {
        setInputValue(target.value);
    };

    return (
        <Component
            aria-label={formatMessage({
                id: 'App.Containers.Punch.SearchTable',
            })}
            role="search"
            disabled={disabled}
            placeholder={placeholder}
            value={inputValue}
            onChange={onChange}
            inputProps={{ 'data-testid': 'search-input' }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Search />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default TableRowsFilterInput;
