import { FormattedMessage } from 'react-intl';

export const cols = [
    {
        field: 'EmployeeName',
        message: <FormattedMessage id="App.Containers.Punch.EmployeeName" />,
    },
    {
        field: 'EmployeeId',
        message: <FormattedMessage id="App.Containers.Punch.EmployeeId" />,
    },
    {
        field: 'JobTitle',
        message: <FormattedMessage id="App.Containers.Punch.Job" />,
    },
    {
        field: 'PayAdjustment',
        message: (
            <FormattedMessage id="App.Containers.Punch.OtherWage.PayAdjustment" />
        ),
    },
    {
        field: 'Type',
        message: <FormattedMessage id="App.Containers.Punch.OtherWage.Type" />,
    },
    {
        field: 'Hours',
        message: <FormattedMessage id="App.Containers.Punch.Hours" />,
    },
    {
        field: 'Amount',
        message: (
            <FormattedMessage id="App.Containers.Punch.OtherWage.Amount" />
        ),
    },
] as const;

export type Column = typeof cols[number];
