import { FC } from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { RootState } from 'state/reducers';
import Login from 'components/Login';
import { ROUTES } from 'routes';

type DialogWrapperProps = {
    showLoginError: boolean;
};

const DialogWrapper = styled.div<DialogWrapperProps>`
    height: ${(props) => (props.showLoginError ? '512px' : '457px')} !important;
    width: 368px;
    overflow: hidden !important;
    border-radius: 0 !important;
    margin-left: 0 !important;
    @media (max-width: 750px) {
        width: 288px !important;
    }
`;

const SignInModal: FC = (): JSX.Element => {
    const { pathname } = useLocation();
    const isOnLoginPage = pathname === ROUTES.LOGIN;
    const isVisible = useSelector(
        (state: RootState) => state.user.isSignInModalVisible
    );
    const showLoginError = useSelector(
        (state: RootState) => state.user.showLoginError
    );

    return (
        <>
            {isVisible && !isOnLoginPage && (
                <Dialog
                    PaperProps={{
                        style: {
                            borderRadius: 0,
                            border: 'none',
                        },
                    }}
                    open
                >
                    <DialogWrapper
                        data-testid="signInModal"
                        showLoginError={showLoginError}
                    >
                        <DialogContent
                            sx={{
                                padding: 0,
                                overflowX: 'hidden',
                                overflowY: 'hidden',
                                borderRadius: 0,
                                marginTop: '45px',
                            }}
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    top: 0,
                                    zIndex: 1,
                                    position: 'sticky',
                                    borderRadius: 0,
                                }}
                            >
                                <Login />
                            </Box>
                        </DialogContent>
                    </DialogWrapper>
                </Dialog>
            )}
        </>
    );
};

export default SignInModal;
