import { FC, ReactNode } from 'react';
import Drawer from '@mui/material/Drawer';
import Header from './Header';

interface IProps {
    open: boolean;
    children: NonNullable<ReactNode>;
    handleDrawerClose: () => void;
}

export const drawerWidth = 520;

const CustomDrawer: FC<IProps> = ({ open, children, handleDrawerClose }) => (
    <Drawer
        sx={{
            width: 0,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
            },
        }}
        variant="persistent"
        anchor="right"
        open={open}
    >
        <Header handleDrawerClose={handleDrawerClose} />
        {children}
    </Drawer>
);

export default CustomDrawer;
