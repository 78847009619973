import { FC } from 'react';
import { Theme } from '@mui/system';
import {
    Grid,
    Typography,
    TypographyTypeMap,
    SxProps,
    Divider,
} from '@mui/material';
import moment from 'moment-timezone';
import { MenuItem } from 'commonEditsMockedData/mockedData';
import i18n from './i18n';

interface GridItemProps {
    message: string | number;
    xs: number;
    sx: SxProps<Theme>;
    variant: TypographyTypeMap['props']['variant'];
    dataTestId?: string;
}

const GridItem: FC<GridItemProps> = ({
    message,
    xs,
    sx,
    variant,
    dataTestId,
}) => (
    <Grid item xs={xs} data-testid={dataTestId}>
        <Typography variant={variant} sx={sx}>
            {message}
        </Typography>
    </Grid>
);

export interface MenuItemsDrawerContentProps {
    selectedMenuItem: MenuItem;
}

const MenuItemsDrawerContent: FC<MenuItemsDrawerContentProps> = ({
    selectedMenuItem,
}) => {
    const labelProps = {
        fontSize: '12px',
        color: '#A9A9A9',
        lineHeight: '12px',
    };
    const valueProps = { fontSize: '16px' };
    const labelVariant = 'caption';
    const valueVariant = 'body1';
    const rowItemsAboveDivider = [
        ['longName', 'shortName'],
        ['chitName', 'onlineName'],
        ['sku'],
    ] as const;
    const rowItemsBelowDivider = [
        ['onlineDescription'],
        ['receipeText'],
        ['defaultPrice', 'combinePriceWithParent'],
        ['allowSitesToChangePrice', 'printOnCheckWhenPriceIsZero'],
        ['printerGroupRouting', 'videoGroupRouting'],
        ['backgroundColor', 'salesRetailCategory'],
    ] as const;
    const numberFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const currentDateTime = moment(new Date())
        .format('YY/MM/DD HH:MM a')
        .toString();
    return (
        <Grid container spacing={2} direction="row" sx={{ padding: '32px' }}>
            <Grid item xs={12} container>
                <GridItem
                    key="header-name"
                    xs={12}
                    message={selectedMenuItem?.name}
                    sx={{ fontSize: '12px' }}
                    variant="h5"
                />
                <GridItem
                    key="last-updated-time"
                    xs={12}
                    message={`Last Updated : `.concat(currentDateTime)}
                    sx={{ fontSize: '12px' }}
                    variant="caption"
                />
            </Grid>
            {selectedMenuItem &&
                rowItemsAboveDivider.map((row) => {
                    const firstColElement = row[0];
                    const secondColElement = row[1];
                    return (
                        <>
                            <Grid
                                item
                                container
                                key={`${firstColElement}`.concat(
                                    `${firstColElement}-rowItemsAboveDivider`
                                )}
                            >
                                <Grid item xs={12} container direction="row">
                                    <GridItem
                                        xs={6}
                                        key={firstColElement.concat(
                                            `label-rowItemsAboveDivider`
                                        )}
                                        dataTestId={firstColElement}
                                        message={i18n[firstColElement]}
                                        sx={labelProps}
                                        variant={labelVariant}
                                    />
                                    {secondColElement && (
                                        <GridItem
                                            xs={6}
                                            key={secondColElement.concat(
                                                `label-rowItemsAboveDivider`
                                            )}
                                            dataTestId={secondColElement}
                                            message={i18n[secondColElement]}
                                            sx={labelProps}
                                            variant={labelVariant}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} container direction="row">
                                    <GridItem
                                        xs={6}
                                        key={firstColElement.concat(
                                            `value-rowItemsAboveDivider`
                                        )}
                                        dataTestId={firstColElement
                                            .toString()
                                            .concat(`Value`)}
                                        message={
                                            selectedMenuItem[firstColElement]
                                        }
                                        sx={valueProps}
                                        variant={valueVariant}
                                    />
                                    {secondColElement && (
                                        <GridItem
                                            xs={6}
                                            key={secondColElement
                                                .toString()
                                                .concat(
                                                    `value-rowItemsAboveDivider`
                                                )}
                                            dataTestId={secondColElement
                                                .toString()
                                                .concat(`Value`)}
                                            message={
                                                selectedMenuItem[
                                                    secondColElement
                                                ]
                                            }
                                            sx={valueProps}
                                            variant={valueVariant}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    );
                })}
            <Divider style={{ width: '100%', marginTop: '38px' }} />
            {selectedMenuItem &&
                rowItemsBelowDivider.map((row) => {
                    const firstColElement = row[0];
                    const secondColElement = row[1];
                    return (
                        <>
                            <Grid
                                item
                                container
                                key={`${firstColElement}`.concat(
                                    `${firstColElement}-rowItemsBelowDivider`
                                )}
                            >
                                <Grid item xs={12} container direction="row">
                                    <GridItem
                                        xs={secondColElement ? 6 : 12}
                                        key={firstColElement.concat(
                                            `label-rowItemsBelowDivider`
                                        )}
                                        dataTestId={firstColElement}
                                        message={i18n[firstColElement]}
                                        sx={labelProps}
                                        variant={labelVariant}
                                    />
                                    {secondColElement && (
                                        <GridItem
                                            key={secondColElement.concat(
                                                `label-rowItemsBelowDivider`
                                            )}
                                            dataTestId={secondColElement}
                                            xs={6}
                                            message={i18n[secondColElement]}
                                            sx={labelProps}
                                            variant={labelVariant}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} container direction="row">
                                    <GridItem
                                        key={firstColElement
                                            .toString()
                                            .concat(
                                                `value-rowItemsBelowDivider`
                                            )}
                                        dataTestId={firstColElement
                                            .toString()
                                            .concat(`Value`)}
                                        xs={secondColElement ? 6 : 12}
                                        message={
                                            firstColElement === 'defaultPrice'
                                                ? numberFormatter.format(
                                                      selectedMenuItem[
                                                          firstColElement
                                                      ]
                                                  )
                                                : selectedMenuItem[
                                                      firstColElement
                                                  ].toString()
                                        }
                                        sx={valueProps}
                                        variant={valueVariant}
                                    />
                                    {secondColElement && (
                                        <GridItem
                                            key={secondColElement
                                                .toString()
                                                .concat(
                                                    `value-rowItemsBelowDivider`
                                                )}
                                            dataTestId={secondColElement
                                                .toString()
                                                .concat(`Value`)}
                                            xs={6}
                                            message={selectedMenuItem[
                                                secondColElement
                                            ].toString()}
                                            sx={valueProps}
                                            variant={valueVariant}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    );
                })}
            <Grid item container>
                <GridItem
                    xs={8}
                    message={i18n.modifierGroups}
                    sx={labelProps}
                    variant={labelVariant}
                    key="modifier-label"
                />
                {selectedMenuItem &&
                    selectedMenuItem.modifierGroups.map((modifier, i) => (
                        <>
                            <GridItem
                                xs={12}
                                message={`${i + 1}. `.concat(modifier.Name)}
                                sx={valueProps}
                                variant={valueVariant}
                                key={`${modifier.Name}`}
                            />
                        </>
                    ))}
            </Grid>
        </Grid>
    );
};

export default MenuItemsDrawerContent;
