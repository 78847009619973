import { rawIntl } from 'services/intl';
import { Shift } from 'services/ManagerOneApi';
import { toAmPmTime } from 'services/utils.service';
import { Column } from './Columns';

export type Data = string | number | boolean | Date;

type ShiftsTableMap = {
    [key in Column['field']]: (shift: Shift) => Data;
};

export const shiftsTableMap: ShiftsTableMap = {
    PenaltyPaySummary: (s) => s.PenaltyPayTotal || 0,
    EmployeeName: (s) => s.Employee?.Name || '',
    EmployeeId: (s) => s.Employee?.EmployeeId || '',
    JobTitle: (s) => s.Job?.JobTitle || '',
    TimeIn: (s) => new Date(s.ClockIn || 0),
    TimeOut: (s) => new Date(s.ClockOut || 0),
    ClockedOutByEod: ({ ClockedOutByEod = null }) =>
        rawIntl.formatMessage({
            id: `App.Containers.Punch.ClockedInByEod.${ClockedOutByEod}`,
        }),
    Hours: (s) => s.Hours || 0,
    BreakHours: (s) => s.BreakHours || 0,
};

export const shiftsAreDesc = (
    shiftA: Shift,
    shiftB: Shift,
    sortBy: Column['field']
): boolean => {
    const getFieldValue = shiftsTableMap[sortBy];
    const a = getFieldValue(shiftA);
    const b = getFieldValue(shiftB);
    return a < b;
};

export const filterShifts =
    (target: string) =>
    (shift: Shift): boolean => {
        if (!target) {
            return true;
        }
        const found = Object.values(shiftsTableMap).some((accessor) => {
            let data = accessor(shift);
            if (data instanceof Date) {
                data = toAmPmTime(data);
            }
            const cellContent = `${data}`
                .replace(/\s\s+/g, ' ') // removes extra spaces between words
                .toLowerCase();
            const targetFound = cellContent.includes(target.toLowerCase());
            return targetFound;
        });
        return found;
    };
