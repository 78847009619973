import { AsyncThunk, AsyncThunkOptions } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import useAppDispatch from './dispatch';
import { RootState } from './reducers';

type ThunkConfig = { state: RootState } & AsyncThunkOptions;
type ThunkDispatcher = <Response, Args>(
    thunk: AsyncThunk<Response, Args, ThunkConfig>
) => Dispatcher<Response, Args>;

// TODO: may make the Dispatcher return Promise<Response | undefined>
type Dispatcher<Response, Args> = (args: Args) => Promise<Response>;

export const useThunkDispatcher: ThunkDispatcher = (thunk) => {
    const dispatch = useAppDispatch();
    // prevents dispatch from being called infinitely
    const memoizedDispatcher = useCallback(
        async (args) => dispatch(thunk(args)).unwrap(),
        [dispatch, thunk]
    );
    return memoizedDispatcher;
};
