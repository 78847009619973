import { createSlice } from '@reduxjs/toolkit';

type RoutesState = {
    hasUnsavedChanges: boolean;
    confirmNavigation: boolean;
};

const initialState: RoutesState = {
    hasUnsavedChanges: false,
    confirmNavigation: false,
};

const routesSlice = createSlice({
    name: 'routes',
    initialState,
    reducers: {
        setHasUnsavedChanges: (
            state,
            action: { payload: RoutesState['hasUnsavedChanges'] }
        ) => {
            state.hasUnsavedChanges = action.payload;
        },
        setShowConfirmation: (
            state,
            action: { payload: RoutesState['confirmNavigation'] }
        ) => {
            state.confirmNavigation = action.payload;
        },
        purge: (_) => {
            _ = initialState;
        },
    },
});

export const { setHasUnsavedChanges, setShowConfirmation } =
    routesSlice.actions;

export default routesSlice;
