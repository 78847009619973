import { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';
import { AppBar, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import Paper from '@mui/material/Paper';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import styled from 'styled-components';
import { rawIntl } from '../../services/intl';
import { MEDIUM, SMALL } from '../../styles/responsive_breakpoints';

const SmallScreenTopWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;
`;

const LeftScreenWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const TextFieldStyled = styled(TextField)`
    fieldset {
        border-radius: 4px;
    }
`;
TextFieldStyled.defaultProps = {
    variant: 'outlined',
    size: 'small',
};

type Props = {
    setSearchText: Dispatch<SetStateAction<string>>;
    setReportType: Dispatch<SetStateAction<string>>;
};

const FunctionBar: FC<Props> = ({ setSearchText, setReportType }) => {
    const isMobile = useMediaQuery(`(max-width:${MEDIUM})`);
    const isSmallMobile = useMediaQuery(`(max-width:${SMALL})`);
    const height = isMobile ? 92 : 72;
    const [reportTypeVal, setReportTypeVal] = useState('');
    const debounce = useRef<NodeJS.Timeout>();
    const navigate = useNavigate();

    const messages = {
        add: rawIntl.formatMessage({ id: 'App.Containers.Reports.Add' }),
        all: rawIntl.formatMessage({ id: 'App.Containers.Reports.All' }),
        system: rawIntl.formatMessage({ id: 'App.Containers.Reports.System' }),
        custom: rawIntl.formatMessage({ id: 'App.Containers.Reports.Custom' }),
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleReportTypeChange = (event: any) => {
        setReportType(event.target.value);
        setReportTypeVal(event.target.value);
    };

    const handleSearchText: InputBaseProps['onChange'] = ({ target }) => {
        if (debounce.current) {
            clearTimeout(debounce.current);
        }
        debounce.current = setTimeout(() => {
            setSearchText(target.value);
        }, 300);
    };
    return (
        <AppBar
            sx={{
                height,
                display: 'flex',
                alignItems: 'center',
                flexDirection: isMobile ? 'column' : 'row',
                border: 'none',
                position: 'inherit',
                backgroundColor: '#fafafa',
                width: '95%',
                margin: 'auto',
                marginBottom: '8px',
            }}
        >
            <SmallScreenTopWrapper>
                <LeftScreenWrapper>
                    <Select
                        sx={{ width: '100px', borderRadius: 0, height: '40px' }}
                        onChange={handleReportTypeChange}
                        value={reportTypeVal}
                        displayEmpty
                        inputProps={{
                            'data-testid': 'report-type-select-dropdown',
                        }}
                    >
                        <MenuItem value="" data-testid="report-type-all">
                            <em>{messages.all}</em>
                        </MenuItem>
                        <MenuItem
                            value="System"
                            data-testid="report-type-system"
                        >
                            {messages.system}
                        </MenuItem>
                        <MenuItem
                            value="Custom"
                            data-testid="report-type-custom"
                        >
                            {messages.custom}
                        </MenuItem>
                    </Select>
                    <Paper
                        component="form"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            // eslint-disable-next-line no-nested-ternary
                            width: isMobile
                                ? isSmallMobile
                                    ? '150px'
                                    : '230px'
                                : '400px',
                            minWidth: '100px',
                            height: '40px',
                            borderRadius: 0,
                        }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Filter"
                            inputProps={{ 'data-testid': 'inputfield-search' }}
                            onChange={handleSearchText}
                        />
                    </Paper>
                </LeftScreenWrapper>
                <Tooltip title={messages.add}>
                    <IconButton
                        sx={{
                            marginLeft: '10px',
                            marginRight: '10px',
                            float: 'right',
                        }}
                        data-testid="copy-icon"
                        onClick={() => {
                            navigate(ROUTES.REPORTS.REPORTBUILDER);
                        }}
                    >
                        <AddCircleIcon
                            sx={{ color: 'black' }}
                            fontSize="medium"
                        />
                    </IconButton>
                </Tooltip>
            </SmallScreenTopWrapper>
        </AppBar>
    );
};

export default FunctionBar;
