import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import config from 'appConfig';
import styled from 'styled-components';
import { ROUTES } from 'routes';
import MainButton from 'components/MainButton';
import { FormControl, Box, Typography, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { recoverPassword } from 'services/api';
import { rawIntl } from 'services/intl';

const ErrorText = styled.span`
    color: #b00200;
    font-size: 13px;
`;

const ResetPassword: FC = () => {
    const [isError, setIsError] = useState(false);
    const [username, setUsername] = useState('');
    const [org, setOrg] = useState('');
    const navigate = useNavigate();

    const recoverEnabled = !!username && !!org;

    const submitRecover = async () => {
        try {
            const baseUrl = config.applicationUrl;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const recoverPasswordResponse = await recoverPassword(
                baseUrl,
                org,
                username
            );
            // TODO: What does a response look like?
        } catch (e) {
            setIsError(true);
        }
    };

    const messages = {
        loginId: rawIntl.formatMessage({
            id: 'App.Components.Login.LoginId',
        }),
        org: rawIntl.formatMessage({
            id: 'App.Components.Login.Organization',
        }),
    };
    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                marginTop: '72px',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0px 35px 100px 0px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '380px',
                }}
            >
                <Typography variant="h5" mb={2} mt={3} component="div">
                    <FormattedMessage id="App.Components.Login.RecoverPassword" />
                </Typography>
                <Typography variant="body1" mb={3} component="div">
                    <FormattedMessage id="App.Components.Login.RecoverPasswordDescription" />
                </Typography>
                <FormControl>
                    <TextField
                        label={messages.loginId}
                        variant="standard"
                        fullWidth
                        type="text"
                        value={username}
                        inputProps={{
                            'data-testid': 'textfield-recover-username',
                        }}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        label={messages.org}
                        variant="standard"
                        fullWidth
                        sx={{ marginTop: 1 }}
                        type="text"
                        value={org}
                        inputProps={{
                            'data-testid': 'textfield-recover-org',
                        }}
                        onChange={(e) => setOrg(e.target.value)}
                    />
                    {isError && (
                        <ErrorText data-testid="recover-error">
                            <FormattedMessage id="App.Components.Login.RecoverError" />
                        </ErrorText>
                    )}
                    <Box display="flex" justifyContent="flex-end" mt={4}>
                        <MainButton
                            id="recover-cancel-button"
                            variant="outlined"
                            dataTestId="recover-cancel-button"
                            onClick={() => navigate(ROUTES.LOGIN)}
                            sx={{ width: '90px' }}
                        >
                            <FormattedMessage id="App.Components.Login.CancelAction" />
                        </MainButton>
                        <MainButton
                            id="recover-button"
                            variant="contained"
                            dataTestId="recover-button"
                            onClick={submitRecover}
                            disabled={!recoverEnabled}
                            sx={{ marginLeft: '12px', width: '90px' }}
                        >
                            <FormattedMessage id="App.Components.Login.Recover" />
                        </MainButton>
                    </Box>
                </FormControl>
            </Box>
        </div>
    );
};

export default ResetPassword;
