export enum IErrorMessages {
    NoSites = 0,
    NotImplemented = 1,
    GeneralError = 2,
}

interface ErrorMessage {
    title: string;
    message: string;
}

export const getErrorMessage = (key: IErrorMessages): ErrorMessage => {
    let title = '';
    let message = '';
    switch (key) {
        case IErrorMessages.NoSites:
            title = 'App.Components.ErrorModal.NoSitesTitle';
            message = 'App.Components.ErrorModal.NoSitesMessage';
            break;
        case IErrorMessages.NotImplemented:
            title = 'App.Components.ErrorModal.NotYetImplementedTitle';
            message = 'App.Components.ErrorModal.NotYetImplementedMessage';
            break;
        default:
            title = 'App.Components.ErrorModal.GeneralErrorTitle';
            message = 'App.Components.ErrorModal.GeneralErrorMessage';
            break;
    }

    return { title, message };
};
