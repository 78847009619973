import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPunchEditorData, GetPunchEditorDataParams } from 'services/api';
import { GetPunchEditorDataResponse, Shift } from '../services/ManagerOneApi';
import { RootState } from './reducers';

type InitialState = {
    loading: boolean;
    selectedBusinessDay: string;
    selectedShift: Shift;
    tableFilter: string;
};

const initialState: InitialState = {
    loading: false,
    selectedBusinessDay: '',
    selectedShift: new Shift(),
    tableFilter: '',
};

/**
 * TODO: when implementing caching for businessDays or Punch Editor data, decide whether to:
 *      - refactor this thunk to use redux toolkit's `createApi` function
 *      - create manual solution for caching
 * TODO: the cache for punches editor data should be a key-value pair in which
 * the key is the url for fetching the businessDay range, and the value be the get Punch Editor data response
 */
export const getPunchEditorDataThunk = createAsyncThunk<
    GetPunchEditorDataResponse,
    GetPunchEditorDataParams,
    { state: RootState }
>('punchEditor/getPunchEditorData', getPunchEditorData);

const punchEditorSlice = createSlice({
    name: 'punchEditor',
    initialState,
    reducers: {
        setTableFilter: (state, { payload }) => {
            state.tableFilter = payload;
        },
        setSelectedShift: (state, { payload }) => {
            state.selectedShift = payload;
        },
        setSelectedBusinessDay: (state, { payload }) => {
            state.selectedBusinessDay = payload;
        },
        purge: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getPunchEditorDataThunk.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPunchEditorDataThunk.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(getPunchEditorDataThunk.rejected, (state) => {
            state.loading = false;
        });
    },
});

export const {
    setSelectedBusinessDay,
    setSelectedShift,
    setTableFilter,
    purge,
} = punchEditorSlice.actions;

export default punchEditorSlice;
