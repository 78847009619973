import { mockedMenuItems } from './mockedMenuItems';

export interface MenuItem {
    name: string;
    defaultPrice: number;
    submenu: string;
    retailCategory: string;
    longName: string;
    shortName: string;
    chitName: string;
    onlineName: string;
    sku: number;
    onlineDescription: string;
    receipeText: string;
    combinePriceWithParent: boolean;
    allowSitesToChangePrice: boolean;
    printOnCheckWhenPriceIsZero: boolean;
    printerGroupRouting: string;
    videoGroupRouting: string;
    modifierGroups: Modifier[];
    backgroundColor: string;
    salesRetailCategory: string;
}

export interface Modifier {
    modifierId: number;
    modifierGroupId: number;
    modifierAction: number;
    weight: number;
    price: number;
    caloricValue: number;
    Name: string;
    Description: string;
    IsDefault: boolean;
}

const mockedData = {
    mockedMenuItems,
};

export default mockedData;
