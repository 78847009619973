import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { SiteInfo } from 'services/ManagerOneApi';
import StoreIcon from '@mui/icons-material/Store';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MEDIUM } from 'styles/responsive_breakpoints';
import Menu from '@mui/material/Menu';
import { setSelectedSiteId } from '../../state/sitesInfoSlice';
import { RootState } from '../../state/reducers';

const SitesDropDown: FC = (): JSX.Element => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const isMobile = useMediaQuery(`(max-width:${MEDIUM})`);
    const availableSites = useSelector(
        (state: RootState) => state.sitesInfo.sites
    );

    const currentSiteId = useSelector(
        (state: RootState) => state.sitesInfo.selectedSiteId
    );

    const handleOnSiteChange = (updatedSiteId: string) => {
        dispatch(setSelectedSiteId(updatedSiteId));
    };

    const open = Boolean(anchorEl);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div data-testid="dropdown-container">
                {!isMobile && (
                    <Box id="dropdown-wrapper" data-testid="dropdown-wrapper">
                        <Select
                            id="dropdown-site-select"
                            value={currentSiteId || ''}
                            defaultValue={currentSiteId}
                            onChange={(e) => handleOnSiteChange(e.target.value)}
                            sx={{
                                width: [185, 250],
                                height: '40px',
                                borderRadius: 0,
                            }}
                            inputProps={{
                                'data-testid': 'dropdown-site-select',
                            }}
                        >
                            {availableSites &&
                                availableSites?.map((site: SiteInfo, index) => (
                                    <MenuItem
                                        id={`site_${index}`}
                                        key={site.Name}
                                        value={site.SiteId}
                                        data-testid={site.SiteId}
                                    >
                                        {site.Name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Box>
                )}
                {isMobile && (
                    <div>
                        <StoreIcon
                            sx={{ marginTop: '5px' }}
                            onClick={handleClick}
                        />
                        <Box
                            id="dropdown-wrapper"
                            data-testid="dropdown-wrapper"
                        >
                            <Menu
                                data-testid="dropdown-site-select"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                style={{ left: '-72px' }}
                            >
                                {availableSites &&
                                    availableSites?.map((site: SiteInfo) => (
                                        <MenuItem
                                            data-testid={site.SiteId}
                                            id="dropdown-menu-item"
                                            key={site.Name}
                                            value={site.SiteId}
                                            onClick={() =>
                                                handleOnSiteChange(site.SiteId)
                                            }
                                        >
                                            {site.Name}
                                        </MenuItem>
                                    ))}
                            </Menu>
                        </Box>
                    </div>
                )}
            </div>
        </>
    );
};

export default SitesDropDown;
