import { FC } from 'react';
import { LocalizationProvider } from '@mui/lab';
import styled from 'styled-components';
import AdapterMoment from '@mui/lab/AdapterMoment';

export const PunchPageContainer = styled.div`
    height: calc(100vh - 72px);
`;

const PunchLayout: FC = ({ children }) => (
    <PunchPageContainer>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            {children}
        </LocalizationProvider>
    </PunchPageContainer>
);

export default PunchLayout;
