import {
    FC,
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { rawIntl } from 'services/intl';
import { getScheduleData, saveSchedule } from 'services/api';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import AlertBar from 'components/AlertBar';
import {
    setUnsavedSchedule,
    setResumeFromLastSession,
} from 'state/schedulerSlice';
import useSelect from 'state/selector';
import { endOfWeek, startOfWeek } from 'date-fns';
import { getCurrentDay, getDateString } from 'services/utils.service';
import { setShowConfirmation, setHasUnsavedChanges } from 'state/routesSlice';
import useConfirmNavigation from 'services/useConfirmNavigation';
import { usePromptOnBeforeUnload } from 'services/eventListeners';
import FunctionBar from './FunctionBar';
import TableContents from './TableContents';
import ConfirmDeliveryModal from './ConfirmDeliveryModal';
import CopySchedule from './CopySchedule';
import ConflictConfirmationModal from './CopySchedule/ConflictConfirmation/ConflictConfirmationModal';
import ConfirmSaveModal from './ConfirmSaveModal';

const { formatMessage } = rawIntl;

const i18n = {
    viewOnly: formatMessage({
        id: 'App.Containers.Scheduler.ViewOnly',
    }),
    saveSuccessful: formatMessage({
        id: 'App.Containers.Scheduler.SaveSuccessful',
    }),
    saveFailed: formatMessage({
        id: 'App.Containers.Scheduler.SaveFailed',
    }),
};

const useMounted = (): MutableRefObject<boolean> => {
    const mounted = useRef(true);
    useEffect(
        () => () => {
            // prevents memory leak
            mounted.current = false;
        },
        []
    );
    return mounted;
};

export interface IProps {
    defaultSelectedDay?: Date;
}
export type CopyFromCopyToDates = [string, string];
const Scheduler: FC<IProps> = ({ defaultSelectedDay = new Date() }) => {
    const dispatch = useDispatch();
    const mounted = useMounted();
    const siteId = useSelect((s) => s.sitesInfo.selectedSiteId);
    const showAlert = useSelect((s) => s.common.showViewOnlyAlert);
    const isConfirmingDelivery = useSelect(
        (s) => s.scheduler.isConfirmingDelivery
    );
    const username = useSelect((s) => s.user.username);
    const isConfirmingNavigation = useSelect((s) => s.routes.confirmNavigation);
    const selectedBusinessDay = useSelect((s) => s.common.selectedBusinessDay);
    const unsavedSchedules = useSelect(
        (s) => s.scheduler.unsavedScheduleData.EmployeeSchedules
    );
    const isConflictConfirmationModalVisible = useSelect(
        (s) => s.scheduler.isConflictConfirmModalVisible
    );
    const wasAScheduleCopied = useSelect((s) => s.scheduler.wasAScheduleCopied);

    const [usedDays, setUsedDays] = useState<string[]>([]);
    const [copyDates, setCopyDates] = useState<CopyFromCopyToDates>(['', '']);
    const [savedSchedules, setSavedSchedules] = useState(unsavedSchedules);
    const [saveNotification, setSaveNotification] = useState('');
    const displayDate = wasAScheduleCopied
        ? new Date(copyDates[1])
        : defaultSelectedDay;

    const disableSave = useMemo(
        // TODO (Dan): schedules returned from backend may omit properties that have empty string "" as the value. This will cause unexpected behavior with checking for equality
        () => _.isEqual(savedSchedules, unsavedSchedules),
        [savedSchedules, unsavedSchedules]
    );

    const resume = useSelect((s) => s.scheduler.resumeFromLastSession);
    const resumeRef = useRef(resume);
    resumeRef.current = resume;

    const initializeData = useCallback(async () => {
        const res = await getScheduleData(siteId, selectedBusinessDay);
        if (mounted.current) {
            if (!resumeRef.current) {
                dispatch(setUnsavedSchedule(res));
            }
            setSavedSchedules(res.EmployeeSchedules);
            dispatch(setResumeFromLastSession(false));
            setUsedDays(res.UsedDays);
        }
    }, [dispatch, mounted, selectedBusinessDay, siteId]);

    const fetchData = useCallback(async () => {
        const res = await getScheduleData(siteId, selectedBusinessDay);
        if (mounted.current) {
            dispatch(setUnsavedSchedule(res));
            setSavedSchedules(res.EmployeeSchedules);
        }
    }, [dispatch, mounted, selectedBusinessDay, siteId]);

    const save = useCallback(async () => {
        const weekStart = startOfWeek(defaultSelectedDay);
        const today = getCurrentDay();
        const todayDate = new Date(today);
        const start = weekStart < todayDate ? today : getDateString(weekStart);

        const { error } = await saveSchedule({
            employeeSchedules: unsavedSchedules,
            siteId,
            start,
            end: getDateString(
                endOfWeek(defaultSelectedDay, { weekStartsOn: 1 })
            ),
            updatedBy: username,
        });

        if (mounted.current && error) {
            setSaveNotification(i18n.saveFailed);
            return;
        }

        await fetchData();
        if (mounted.current) {
            setSaveNotification(i18n.saveSuccessful);
        }
    }, [
        defaultSelectedDay,
        fetchData,
        mounted,
        siteId,
        unsavedSchedules,
        username,
    ]);

    const closeSnackbar = () => setSaveNotification('');

    const prompt = (shouldPrompt: boolean) => {
        dispatch(setShowConfirmation(shouldPrompt));
    };

    const { proceed, cancel } = useConfirmNavigation(
        prompt,
        !disableSave && !resumeRef.current
    );

    useEffect(() => {
        initializeData();
    }, [initializeData]);

    usePromptOnBeforeUnload(!disableSave);

    const hasData = savedSchedules && unsavedSchedules;
    useEffect(() => {
        if (hasData) {
            dispatch(setHasUnsavedChanges(!disableSave));
        }
    }, [disableSave, dispatch, hasData]);

    return (
        <>
            {showAlert && <AlertBar message={i18n.viewOnly} />}
            {isConfirmingDelivery && <ConfirmDeliveryModal save={save} />}
            {isConfirmingNavigation && (
                <ConfirmSaveModal
                    save={save}
                    resetData={fetchData}
                    proceed={proceed}
                    cancel={cancel}
                />
            )}
            <Snackbar
                open={!!saveNotification}
                autoHideDuration={2500}
                onClose={closeSnackbar}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                message={saveNotification}
            />
            <CopySchedule usedDays={usedDays} setCopyDates={setCopyDates} />
            {isConflictConfirmationModalVisible && (
                <ConflictConfirmationModal copyDates={copyDates} />
            )}

            <Box sx={{ padding: '0 24px 24px' }}>
                <FunctionBar
                    disableSave={disableSave}
                    defaultSelectedDay={displayDate}
                    save={save}
                />

                <TableContents />
            </Box>
        </>
    );
};

export default Scheduler;
