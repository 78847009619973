import { FC } from 'react';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box } from '@mui/system';
import MainButton from 'components/MainButton';
import { FormattedMessage } from 'react-intl';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'space-between',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface IProps {
    handleDrawerClose: () => void;
}

const MenuItemsDrawer: FC<IProps> = ({ handleDrawerClose }) => (
    <>
        <DrawerHeader>
            <>
                <Box>
                    <MainButton
                        id="header-close-button"
                        variant="text"
                        dataTestId="header-close-button"
                        onClick={handleDrawerClose}
                        sx={{ width: '75px' }}
                    >
                        <ArrowBackIcon
                            sx={{
                                marginRight: '8px',
                                fontSize: '1.4em',
                            }}
                        />
                        <FormattedMessage id="Common.Close" />
                    </MainButton>
                    <MainButton
                        id="header-more-button"
                        variant="text"
                        dataTestId="header-more-button"
                        onClick={() => {}}
                        sx={{ width: '75px' }}
                    >
                        <MoreHorizIcon
                            sx={{
                                marginRight: '8px',
                                fontSize: '1.4em',
                            }}
                        />
                        <FormattedMessage id="Common.More" />
                    </MainButton>
                </Box>
                <Box>
                    <MainButton
                        id="header-edit-button"
                        variant="contained"
                        dataTestId="header-edit-button"
                        onClick={handleDrawerClose}
                        sx={{ width: '60px' }}
                    >
                        <FormattedMessage id="Common.Edit" />
                    </MainButton>
                </Box>
            </>
        </DrawerHeader>
        <Divider />
    </>
);

export default MenuItemsDrawer;
