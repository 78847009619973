import { AxiosRequestConfig } from 'axios';
import { store } from 'store/createStore';
import { convertAuthStringToEnum } from 'utils/dataHelper';
import { removePrefixFromListItems } from 'utils/listHelper';
import { ManagerOne } from 'services/ManagerOneApi/ManagerOne.API';
import { setSites } from 'state/sitesInfoSlice';
import { setUserAuthorizations } from 'state/userSlice';
import { setDashboardData } from 'state/dashboardSlice';
import { purgeReducers } from 'state/reducers';
import {
    mockBusinessDaysRange,
    mockPunchEditorData,
} from 'containers/Punch/mockData';
import { mockedReportsData } from 'containers/Reports/mockedData';
import { BusinessDayType } from 'state/businessDaySlice';
import { MenuItem } from 'commonEditsMockedData/mockedData';
import axios from './axios';
import * as mockedApi from './mocks/mockedApi';
import { InternalEndpoints } from './InternalEndpoints';
import {
    BusinessDayRangeResponse,
    ChangePasswordRequest,
    ChangePasswordResponse,
    DeliverSchedules,
    DeliverSchedulesResponse,
    GetDashboardRequest,
    GetDashboardResponse,
    GetPunchEditorData,
    GetPunchEditorDataResponse,
    GetDeposits,
    GetDepositsResponse,
    GetReportsResponse,
    GetScheduleData,
    GetScheduleDataResponse,
    GetSitesResponse,
    ManagerOneLogin,
    ManagerOneLoginResponse,
    RecoverPasswordRequest,
    RecoverPasswordResponse,
    UserDetailsResponse,
    Deposit,
    CreateDepositResponse,
    EditDeposit,
    RequestBase,
    CreateDeposit,
    EditDepositResponse,
    BusinessDayResponse,
    GetDaysToBackEdit,
    GetDaysToBackEditResponse,
    DeleteDeposit,
    CopyScheduleResponse,
    CopySchedule,
    SaveSchedule,
    SaveScheduleResponse,
} from './ManagerOneApi';
import { UncapitalizeType } from './utilTypes';

const isMockingEnabled = (): boolean => {
    if (
        process.env.NODE_ENV === 'test' ||
        window.location.href.includes('6006')
    )
        return true;
    const { mockingEnabled } = store.getState().common;
    return mockingEnabled;
};

const requestOptions = (
    method: AxiosRequestConfig['method'],
    url: AxiosRequestConfig['url'],
    data: AxiosRequestConfig['data'] = null
): AxiosRequestConfig => ({
    method,
    url,
    data: { ...data },
});

export const logOut = async (): Promise<void> => {
    try {
        const opts = requestOptions('POST', InternalEndpoints.urls.logout);
        await axios.request<null, ManagerOneLoginResponse>(opts);
        purgeReducers();
    } catch (error) {
        // error
    }
};

export const logIn = async (
    username: string,
    password: string,
    organization: string
): Promise<ManagerOneLoginResponse> => {
    let response;
    try {
        if (!isMockingEnabled()) {
            const loginRequest = new ManagerOneLogin({
                Organization: organization,
                Username: username,
                Password: password,
            });

            const opts = requestOptions(
                'POST',
                InternalEndpoints.urls.login,
                loginRequest
            );

            response = await axios.request<null, ManagerOneLoginResponse>(opts);
        } else
            response = await mockedApi.logIn(username, password, organization);
    } catch (error) {
        return new ManagerOneLoginResponse({
            LoggedIn: false,
        });
    }
    return response;
};

export const getDashboardData = async (
    SiteId: string
): Promise<GetDashboardResponse> => {
    let response;
    if (!isMockingEnabled()) {
        const dashboardRequest = new GetDashboardRequest({
            SiteId,
        });

        const opts = requestOptions(
            'POST',
            InternalEndpoints.urls.dashboard.data,
            dashboardRequest
        );

        response = await axios.request<null, GetDashboardResponse>(opts);
    } else response = await mockedApi.getDashboardData(SiteId);
    store.dispatch(setDashboardData(response));
    return response;
};

type GetDepositsType = Omit<GetDeposits, keyof RequestBase>;
type GetDepositsData = UncapitalizeType<GetDepositsType>;
export const getDepositsData = async (
    data: GetDepositsData
): Promise<GetDepositsResponse['Deposits']> => {
    let response;
    if (!isMockingEnabled()) {
        const opts = requestOptions(
            'POST',
            `${InternalEndpoints.urls.deposits}/GetDeposits`,
            data
        );

        response = await axios.request<null, GetDepositsResponse>(opts);
    } else response = await mockedApi.getDepositsRequest(data);
    return response.Deposits;
};

type EditDepositType = Omit<EditDeposit, keyof RequestBase>;
type EditDepositData = UncapitalizeType<EditDepositType>;
export const editDeposit = async (data: EditDepositData): Promise<Deposit> => {
    if (!isMockingEnabled()) {
        const res = await axios.post<null, EditDepositResponse>(
            `${InternalEndpoints.urls.deposits}/editDeposit`,
            data
        );
        return res.Deposit;
    }
    return mockedApi.editDepositRequest(data);
};

type CreateDepositType = Omit<CreateDeposit, keyof RequestBase>;
type CreateDepositData = UncapitalizeType<CreateDepositType>;
export const addDeposit = async (data: CreateDepositData): Promise<Deposit> => {
    if (!isMockingEnabled()) {
        const res = await axios.post<null, CreateDepositResponse>(
            `${InternalEndpoints.urls.deposits}/AddDeposit`,
            data
        );
        return res.Deposit;
    }
    return mockedApi.addDepositRequest(data);
};

type DeleteDepositType = Omit<DeleteDeposit, keyof RequestBase>;
type DeleteDepositData = UncapitalizeType<DeleteDepositType>;
export const deleteDeposit = async (data: DeleteDepositData): Promise<void> => {
    if (!isMockingEnabled()) {
        await axios.post(
            `${InternalEndpoints.urls.deposits}/deleteDeposit`,
            data
        );
    }
    await mockedApi.deleteDepositRequest(data);
};

type GetDaysToBackEditType = Omit<GetDaysToBackEdit, keyof RequestBase>;
type GetDaysToBackEditData = UncapitalizeType<GetDaysToBackEditType>;
export const daysToBackEditDeposits = async (
    data: GetDaysToBackEditData
): Promise<GetDaysToBackEditResponse['DaysToBackEdit']> => {
    if (!isMockingEnabled()) {
        const { DaysToBackEdit } = await axios.post<
            null,
            GetDaysToBackEditResponse
        >(`${InternalEndpoints.urls.deposits}/GetDaysToBackEdit`, data);
        return DaysToBackEdit;
    }
    return mockedApi.daysToBackEditDeposits(data);
};

export const getScheduleData = async (
    siteId: string,
    selectedBusinessDay: string
): Promise<GetScheduleDataResponse> => {
    let response;
    if (!isMockingEnabled()) {
        const url = `${InternalEndpoints.urls.scheduleData}/getScheduleData`;

        const getSchedulerRequest = new GetScheduleData({
            SiteId: siteId,
            BusinessDay: selectedBusinessDay,
        });

        const opts = requestOptions('POST', url, getSchedulerRequest);

        response = await axios.request<null, GetScheduleDataResponse>(opts);
    } else
        response = await mockedApi.getScheduleData(siteId, selectedBusinessDay);

    return response;
};

type GetCopyScheduleRequest = Omit<CopySchedule, keyof RequestBase>;
export const doCopy = async (
    data: GetCopyScheduleRequest
): Promise<CopyScheduleResponse> => {
    if (!isMockingEnabled()) {
        const res = await axios.post<null, CopyScheduleResponse>(
            `${InternalEndpoints.urls.scheduleData}/copySchedule`,
            data
        );
        return res;
    }
    return mockedApi.doCopy(data);
};
type SaveScheduleType = Omit<SaveSchedule, keyof RequestBase>;
type SaveScheduleData = UncapitalizeType<SaveScheduleType>;
export const saveSchedule = async (
    data: SaveScheduleData
    // TODO: properly type-check error responses
): Promise<SaveScheduleResponse & { error?: boolean }> => {
    let response;
    if (!isMockingEnabled()) {
        const url = `${InternalEndpoints.urls.scheduleData}/saveSchedule`;

        const opts = requestOptions('POST', url, data);

        response = await axios.request<null, SaveScheduleResponse>(opts);
    } else response = await mockedApi.saveSchedule(data);

    return response;
};

export const getSites = async (): Promise<GetSitesResponse> => {
    let response;
    if (!isMockingEnabled()) {
        const getSitesRequest = requestOptions(
            'GET',
            InternalEndpoints.urls.getSites
        );

        response = await axios.request<null, GetSitesResponse>(getSitesRequest);
    } else response = await mockedApi.getSites();
    store.dispatch(setSites(response?.Sites));
    return response;
};

export const getUserAuthorizations = async (): Promise<
    ManagerOne.API.User.UserRoles[]
> => {
    let response;
    if (!isMockingEnabled()) {
        const url = `${InternalEndpoints.urls.userData}/getUserInfo`;
        const opts = requestOptions('GET', url);

        const res = await axios.request<null, UserDetailsResponse>(opts);
        const BSP_ENVIRONMENT_PREFIX =
            process.env.REACT_APP_BSP_ENVIRONMENT_PREFIX;

        const correctedAuthorizations = removePrefixFromListItems(
            `${BSP_ENVIRONMENT_PREFIX}_`,
            res.Authorizations
        );

        if (correctedAuthorizations.length === 0) return [];

        // Convert string responses to enums (keys are one to one)
        const authEnumList: ManagerOne.API.User.UserRoles[] = [];
        correctedAuthorizations.forEach((enumKey) => {
            const value = convertAuthStringToEnum(enumKey);
            if (value !== null && value !== undefined) authEnumList.push(value);
        });
        response = authEnumList?.sort((a, b) => a - b);
    } else response = await mockedApi.fetchUserInfo();
    store.dispatch(setUserAuthorizations(response));
    return response;
};

export const changePassword = async (
    password: string
): Promise<ChangePasswordResponse> => {
    let response;
    if (!isMockingEnabled()) {
        const changePasswordRequest = new ChangePasswordRequest({
            Password: password,
            Token: null,
            Username: null,
        });

        const opts = requestOptions(
            'POST',
            InternalEndpoints.urls.changePassword,
            changePasswordRequest
        );

        response = await axios.request<null, ChangePasswordResponse>(opts);
    } else response = await mockedApi.changePassword(password);
    return response;
};

export const recoverPassword = async (
    baseUrl: string,
    organization: string,
    username: string
): Promise<RecoverPasswordResponse> => {
    let response;
    if (!isMockingEnabled()) {
        const changePasswordRequest = new RecoverPasswordRequest({
            BaseUrl: baseUrl,
            Organization: organization,
            Username: username,
        });

        const opts = requestOptions(
            'POST',
            InternalEndpoints.urls.recoverPassword,
            changePasswordRequest
        );

        response = await axios.request<null, RecoverPasswordResponse>(opts);
    } else response = await mockedApi.recoverPassword();
    return response;
};

export type GetPunchEditorDataParams = {
    siteId: string;
    businessDay: string;
};

export async function getPunchEditorData(
    data: GetPunchEditorDataParams
): Promise<GetPunchEditorDataResponse> {
    if (isMockingEnabled()) {
        return mockPunchEditorData;
    }

    const opts: AxiosRequestConfig = {
        method: 'POST',
        url: `${InternalEndpoints.urls.punchData}/getPunchEditorData/`,
        data,
    };

    const res = await axios.request<
        null,
        GetPunchEditorDataResponse,
        GetPunchEditorData
    >(opts);
    return res;
}

export type BusinessDayParams = {
    dayType: BusinessDayType;
    siteId: string;
};

// TODO: abstract businessDay-related functions into generic
export async function getBusinessDayRange({
    dayType,
    siteId,
}: BusinessDayParams): Promise<BusinessDayRangeResponse> {
    if (isMockingEnabled()) {
        return mockBusinessDaysRange;
    }
    // TODO: handle null response
    const urlPrefix = `${InternalEndpoints.urls.businessDay}/${siteId}`;
    const url = `${urlPrefix}/${dayType}/range`;
    const res = await axios.get<null, BusinessDayRangeResponse>(url);
    return res;
}

// TODO: abstract businessDay-related functions into generic
export const getLastBusinessDay = async ({
    dayType,
    siteId,
}: BusinessDayParams): Promise<BusinessDayResponse['BusinessDay']> => {
    if (isMockingEnabled()) {
        return '2021-02-28T00:00:00';
    }
    // TODO: handle null response
    const urlPrefix = `${InternalEndpoints.urls.businessDay}/${siteId}`;
    const url = `${urlPrefix}/${dayType}/last`;
    const { BusinessDay } = await axios.get<null, BusinessDayResponse>(url);
    return BusinessDay;
};

// TODO: abstract businessDay-related functions into generic
export const getFirstBusinessDay = async ({
    dayType,
    siteId,
}: BusinessDayParams): Promise<BusinessDayResponse['BusinessDay']> => {
    if (isMockingEnabled()) {
        return '2020-01-27T00:00:00';
    }
    // TODO: handle null response
    const urlPrefix = `${InternalEndpoints.urls.businessDay}/${siteId}`;
    const url = `${urlPrefix}/${dayType}/first`;
    const { BusinessDay } = await axios.get<null, BusinessDayResponse>(url);
    return BusinessDay;
};

export const deliverSchedules = async (
    siteId: string
): Promise<DeliverSchedulesResponse> => {
    if (!isMockingEnabled()) {
        const opts = requestOptions(
            'POST',
            `${InternalEndpoints.urls.scheduleData}/deliverSchedules`,
            new DeliverSchedules({ SiteId: siteId })
        );
        return axios.request<null, DeliverSchedulesResponse>(opts);
    }
    return new DeliverSchedulesResponse();
};

export const getReportsCall = async (): Promise<GetReportsResponse> => {
    if (isMockingEnabled()) {
        return mockedReportsData;
    }
    const opts = requestOptions(
        'GET',
        `${InternalEndpoints.urls.reportData}/getReports`
    );

    const response = await axios.request<null, GetReportsResponse>(opts);
    return response;
};

// Common Edits API
export const getMenuItems = async (): Promise<MenuItem[]> =>
    mockedApi.getMenuItems();
