import { FC } from 'react';
import { Typography } from '@mui/material';

interface IProps {
    title: string;
    description: string;
}

const index: FC<IProps> = ({ title, description }) => (
    <>
        <Typography sx={{ marginBottom: '16px' }} variant="h4">
            {title}
        </Typography>
        <Typography sx={{ marginBottom: '32px' }} variant="body1">
            {description}
        </Typography>
    </>
);

export default index;
