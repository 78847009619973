/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useDemoData } from '@mui/x-data-grid-generator';
import { DataGridPro } from '@mui/x-data-grid-pro';
import AppletHeader from 'components/AppletHeader';
import CustomDrawer from 'components/CustomDrawer';
import ExternalDrawerContent from 'components/CustomDrawer/ExternalDrawerContent';
import { getMenuItems } from 'services/api';
import { MenuItem } from 'commonEditsMockedData/mockedData';
import MenuItemsDrawerContent from './MenuItemsDrawerContent';

const MenuItems: FC = () => {
    const [open, setOpen] = useState(false);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    // const [isMenuItemsDrawerOpen, setIsMenuItemsDrawerOpen] =
    //     useState<boolean>(false);

    const handleDrawerOpen = () => {
        setOpen(true);
        // setIsMenuItemsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const { data } = useDemoData({
        dataSet: 'Commodity',
        rowLength: 1000,
        editable: true,
    });

    const initializeData = useCallback(async () => {
        const res = await getMenuItems();
        setMenuItems(res);
    }, []);

    useEffect(() => {
        initializeData();
    }, [initializeData]);

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <AppletHeader
                    title="title goes here"
                    description="this is where the description goes"
                />
                <CustomDrawer open={open} handleDrawerClose={handleDrawerClose}>
                    <MenuItemsDrawerContent
                        // TODO : set the menu item id on the fly and pass it in the drawer content
                        selectedMenuItem={menuItems[0]}
                    />
                </CustomDrawer>
                <ExternalDrawerContent open={open}>
                    <DataGridPro
                        {...data}
                        loading={data.rows.length === 0}
                        rowHeight={38}
                        checkboxSelection
                        disableSelectionOnClick
                        onCellClick={() => {
                            handleDrawerOpen();
                        }}
                        sx={{ padding: 0 }}
                    />
                    TODO: New data grid with following items:
                    <Box display="flex" overflow="auto">
                        {JSON.stringify(menuItems, null, 4)}
                    </Box>
                </ExternalDrawerContent>
            </Box>
        </>
    );
};

export default MenuItems;
