export namespace ManagerOne.API {
    export namespace Common {
        export enum MessageLevel {
            Debug = 0,
            Info = 1,
            Warning = 2,
            Error = 3,
            Fatal = 4,
        }
    }
    export namespace Common {
        export class RequestBase { constructor(init?: Partial<RequestBase>) {

            if (init !== null) { Object.assign(this, init); }}

            Header!: Common.RequestHeader;
        }
    }
    export namespace Common {
        export class RequestHeader { constructor(init?: Partial<RequestHeader>) {

            if (init !== null) { Object.assign(this, init); }}

            ClientApplication!: string;
            MessageID!: string;
        }
    }
    export namespace Common {
        export class ResponseBase { constructor(init?: Partial<ResponseBase>) {

            if (init !== null) { Object.assign(this, init); }}

            Header!: Common.ResponseHeader;
        }
    }
    export namespace Common {
        export class ResponseHeader { constructor(init?: Partial<ResponseHeader>) {

            if (init !== null) { Object.assign(this, init); }}

            MessageID?: string;
            StackTrace?: string;
            Statuses?: Common.ResponseMessage[];
            Success?: boolean;
        }
    }
    export namespace Common {
        export class ResponseMessage { constructor(init?: Partial<ResponseMessage>) {

            if (init !== null) { Object.assign(this, init); }}

            Level!: Common.MessageLevel;
            Message!: string;
        }
    }
    export namespace Common {
        export class SiteDetails { constructor(init?: Partial<SiteDetails>) {

            if (init !== null) { Object.assign(this, init); }}

            AddressLine1!: string;
            AddressLine2!: string;
            CityOrTown!: string;
            County!: string;
            Name!: string;
            Number!: string;
            PostalCode!: string;
            SiteTimeZone!: string;
            State!: string;
            TelephoneNumber1!: string;
            TelephoneNumber2!: string;
        }
    }
    export namespace Common {
        export class SiteDetailsRequest { constructor(init?: Partial<SiteDetailsRequest>) {

            if (init !== null) { Object.assign(this, init); }}

            SiteId!: string;
        }
    }
    export namespace Common {
        export class SiteInfo { constructor(init?: Partial<SiteInfo>) {

            if (init !== null) { Object.assign(this, init); }}

            Name!: string;
            Site?: Common.SiteDetails;
            SiteId!: string;
        }
    }
    export namespace Dashboard.Models {
        export class CategoryDetails { constructor(init?: Partial<CategoryDetails>) {

            if (init !== null) { Object.assign(this, init); }}

            Id!: string;
            Name!: string;
            Products!: Dashboard.Models.ProductDetails[];
            TotalAmount!: number;
            TotalQuantity!: number;
        }
    }
    export namespace Dashboard.Models {
        export class GetDashboardRequest { constructor(init?: Partial<GetDashboardRequest>) {

            if (init !== null) { Object.assign(this, init); }}

            SiteId!: string;
        }
    }
    export namespace Dashboard.Models {
        export class GetDashboardResponse { constructor(init?: Partial<GetDashboardResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            LaborPay!: Dashboard.Models.GetLaborPayResponse;
            NetSales!: Dashboard.Models.GetNetSalesResponse;
            Products!: Dashboard.Models.GetProductsSalesResponse;
            SalesByOrder!: Dashboard.Models.GetSalesByOrderResponse;
            Voids!: Dashboard.Models.GetVoidsResponse;
            Weather!: Dashboard.Models.GetSiteWeatherResponse;
        }
    }
    export namespace Dashboard.Models {
        export class GetLaborPayResponse { constructor(init?: Partial<GetLaborPayResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            OvertimePay!: number;
            PaidBreakPay!: number;
            RegularPay!: number;
            TotalPay!: number;
        }
    }
    export namespace Dashboard.Models {
        export class GetNetSalesResponse { constructor(init?: Partial<GetNetSalesResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            CheckAvgSales!: number;
            GuestAvgSales!: number;
            NetSales!: number;
            NetSalesByRevenueCenter!: { [id: string]: number; };
            TotalChecks!: number;
            TotalGuests!: number;
            TotalReturns!: number;
        }
    }
    export namespace Dashboard.Models {
        export class GetProductsSalesResponse { constructor(init?: Partial<GetProductsSalesResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            Categories!: Dashboard.Models.CategoryDetails[];
        }
    }
    export namespace Dashboard.Models {
        export class GetSalesByOrderResponse { constructor(init?: Partial<GetSalesByOrderResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            Refunds!: number;
            salesByOrder!: { [id: string]: number; };
        }
    }
    export namespace Dashboard.Models {
        export class GetSiteWeatherResponse extends Common.ResponseBase { constructor(init?: Partial<GetSiteWeatherResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Description!: string;
            Icon!: string;
            Site!: Common.SiteDetails;
            Temp!: number;
            TimeZone!: string;
        }
    }
    export namespace Dashboard.Models {
        export class GetVoidsResponse { constructor(init?: Partial<GetVoidsResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            TotalAmount!: number;
            TotalQuantity!: number;
            Voids!: Dashboard.Models.VoidDetails[];
        }
    }
    export namespace Dashboard.Models {
        export class ProductDetails { constructor(init?: Partial<ProductDetails>) {

            if (init !== null) { Object.assign(this, init); }}

            Amount!: number;
            CategoryId!: string;
            Name!: string;
            Quantity!: number;
        }
    }
    export namespace Dashboard.Models {
        export class VoidDetails { constructor(init?: Partial<VoidDetails>) {

            if (init !== null) { Object.assign(this, init); }}

            Amount!: number;
            Quantity!: number;
            Reason!: string;
        }
    }
    export namespace DebugTools.NetworkMonitor {
        export class OperationModel { constructor(init?: Partial<OperationModel>) {

            if (init !== null) { Object.assign(this, init); }}

            Id!: string;
            Request!: DebugTools.NetworkMonitor.RequestModel;
            Response!: DebugTools.NetworkMonitor.ResponseModel;
        }
    }
    export namespace DebugTools.NetworkMonitor {
        export class RequestModel { constructor(init?: Partial<RequestModel>) {

            if (init !== null) { Object.assign(this, init); }}

            Body!: string;
            Headers!: { [id: string]: string; };
            Id!: string;
            Method!: string;
            Path!: string;
        }
    }
    export namespace DebugTools.NetworkMonitor {
        export class ResponseModel { constructor(init?: Partial<ResponseModel>) {

            if (init !== null) { Object.assign(this, init); }}

            Body!: string;
            Id!: string;
            Successful!: boolean;
            Time!: number;
        }
    }
    export namespace Deposits.Models {
        export class CreateDeposit extends Common.RequestBase { constructor(init?: Partial<CreateDeposit>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Amount!: number;
            BusinessDay!: string;
            Description!: string;
            SiteId!: string;
        }
    }
    export namespace Deposits.Models {
        export class CreateDepositResponse extends Common.ResponseBase { constructor(init?: Partial<CreateDepositResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Deposit!: Deposits.Models.Deposit;
        }
    }
    export namespace Deposits.Models {
        export class DeleteDeposit extends Common.RequestBase { constructor(init?: Partial<DeleteDeposit>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            Id!: string;
            SiteId!: string;
        }
    }
    export namespace Deposits.Models {
        export class Deposit { constructor(init?: Partial<Deposit>) {

            if (init !== null) { Object.assign(this, init); }}

            Amount?: number;
            Description?: string;
            EditedBy?: string;
            EditedDate?: string;
            Id?: string;
        }
    }
    export namespace Deposits.Models {
        export class EditDeposit extends Common.RequestBase { constructor(init?: Partial<EditDeposit>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Amount!: number;
            BusinessDay!: string;
            Description!: string;
            Id!: string;
            SiteId!: string;
        }
    }
    export namespace Deposits.Models {
        export class EditDepositResponse extends Common.ResponseBase { constructor(init?: Partial<EditDepositResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Deposit!: Deposits.Models.Deposit;
        }
    }
    export namespace Deposits.Models {
        export class GetDaysToBackEdit extends Common.RequestBase { constructor(init?: Partial<GetDaysToBackEdit>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            SiteId!: string;
        }
    }
    export namespace Deposits.Models {
        export class GetDaysToBackEditResponse extends Common.ResponseBase { constructor(init?: Partial<GetDaysToBackEditResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            DaysToBackEdit!: number;
        }
    }
    export namespace Deposits.Models {
        export class GetDeposits extends Common.RequestBase { constructor(init?: Partial<GetDeposits>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            SiteId!: string;
        }
    }
    export namespace Deposits.Models {
        export class GetDepositsResponse extends Common.ResponseBase { constructor(init?: Partial<GetDepositsResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Deposits?: Deposits.Models.Deposit[];
        }
    }
    export namespace Help.Models {
        export class Article { constructor(init?: Partial<Article>) {

            if (init !== null) { Object.assign(this, init); }}

            Body!: string;
            Downvotes!: number;
            Id!: string;
            IsRecommended!: boolean;
            Subject!: string;
            Title!: string;
            Upvotes!: number;
        }
    }
    export namespace Help.Models {
        export class ArticleSubject { constructor(init?: Partial<ArticleSubject>) {

            if (init !== null) { Object.assign(this, init); }}

            ArticleList!: Help.Models.Article[];
            Title!: string;
        }
    }
    export namespace Help.Models {
        export class GetArticles extends Common.RequestBase { constructor(init?: Partial<GetArticles>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Help.Models {
        export class GetArticlesResponse extends Common.ResponseBase { constructor(init?: Partial<GetArticlesResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Articles!: Help.Models.Article[];
        }
    }
    export namespace Help.Models {
        export class GetArticleSubjects extends Common.RequestBase { constructor(init?: Partial<GetArticleSubjects>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Help.Models {
        export class GetArticleSubjectsResponse extends Common.ResponseBase { constructor(init?: Partial<GetArticleSubjectsResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            ArticleSubjects!: Help.Models.ArticleSubject[];
        }
    }
    export namespace Help.Models {
        export class UpdateFeedbackRequest extends Common.RequestBase { constructor(init?: Partial<UpdateFeedbackRequest>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            ArticleId!: string;
            Positive!: boolean;
        }
    }
    export namespace Help.Models {
        export class UpdateFeedbackResponse extends Common.ResponseBase { constructor(init?: Partial<UpdateFeedbackResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Downvotes!: number;
            Upvotes!: number;
        }
    }
    export namespace Home.Models {
        export class HomeModel { constructor(init?: Partial<HomeModel>) {

            if (init !== null) { Object.assign(this, init); }}

            EnvironmentPrefix!: string;
            LoggedIn!: boolean;
            Organization!: string;
            ShowHelpUi!: boolean;
            ShowReportBuilder!: boolean;
            Username!: string;
            Version!: string;
        }
    }
    export namespace HouseAccounts.Models {
        export class CreateHouseAccountTransactionRequest { constructor(init?: Partial<CreateHouseAccountTransactionRequest>) {

            if (init !== null) { Object.assign(this, init); }}

            AccountId!: string;
            AccountName!: string;
            SiteId!: string;
            Transaction!: HouseAccounts.Models.Transaction;
        }
    }
    export namespace HouseAccounts.Models {
        export class CreateHouseAccountTransactionResponse { constructor(init?: Partial<CreateHouseAccountTransactionResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            Transaction!: HouseAccounts.Models.Transaction;
        }
    }
    export namespace HouseAccounts.Models {
        export class GetHouseAccountsRequest { constructor(init?: Partial<GetHouseAccountsRequest>) {

            if (init !== null) { Object.assign(this, init); }}

            SiteId!: string;
        }
    }
    export namespace HouseAccounts.Models {
        export class GetHouseAccountsResponse { constructor(init?: Partial<GetHouseAccountsResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            Accounts!: HouseAccounts.Models.HouseAccount[];
        }
    }
    export namespace HouseAccounts.Models {
        export class GetHouseAccountTransactionsRequest { constructor(init?: Partial<GetHouseAccountTransactionsRequest>) {

            if (init !== null) { Object.assign(this, init); }}

            AccountId!: string;
            BusinessDate!: string;
            SiteId!: string;
        }
    }
    export namespace HouseAccounts.Models {
        export class GetHouseAccountTransactionsResponse { constructor(init?: Partial<GetHouseAccountTransactionsResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            Transactions!: HouseAccounts.Models.Transaction[];
        }
    }
    export namespace HouseAccounts.Models {
        export class HouseAccount { constructor(init?: Partial<HouseAccount>) {

            if (init !== null) { Object.assign(this, init); }}

            Balance!: number;
            Id!: string;
            Name!: string;
        }
    }
    export namespace HouseAccounts.Models {
        export class Transaction { constructor(init?: Partial<Transaction>) {

            if (init !== null) { Object.assign(this, init); }}

            Amount!: number;
            BusinessDay!: string;
            Description!: string;
            LinkedTransactions!: HouseAccounts.Models.Transaction[];
            TransactionId!: string;
            Type!: HouseAccounts.Models.TransactionType;
        }
    }
    export namespace HouseAccounts.Models {
        export enum TransactionType {
            PREVIOUS_BALANCE = 0,
            PAYMENT = 1,
            CREDIT = 2,
            DEBIT = 3,
            CORRECTION = 4,
            UNKNOWN = 5,
        }
    }
    export namespace Labor.Models {
        export class Employee { constructor(init?: Partial<Employee>) {

            if (init !== null) { Object.assign(this, init); }}

            EmployeeId?: string;
            IsTerminated?: boolean;
            Jobs?: Labor.Models.EmployeeJob[];
            Name?: string;
            TerminatedEffectiveDate?: string;
        }
    }
    export namespace Labor.Models {
        export class EmployeeJob { constructor(init?: Partial<EmployeeJob>) {

            if (init !== null) { Object.assign(this, init); }}

            BreakConfiguration!: Labor.Models.PunchEditor.JobCodeBreakType;
            IsValidJob!: boolean;
            JobCodeId!: string;
            JobTitle!: string;
            PayRate!: number | string;
        }
    }
    export namespace Labor.Models {
        export class FirstBusinessDayResponse { constructor(init?: Partial<FirstBusinessDayResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            DateOfBusiness!: string;
        }
    }
    export namespace Labor.Models {
        export class JobCode { constructor(init?: Partial<JobCode>) {

            if (init !== null) { Object.assign(this, init); }}

            Id!: string;
            Name!: string;
            ShortName!: string;
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class Break { constructor(init?: Partial<Break>) {

            if (init !== null) { Object.assign(this, init); }}

            BreakRuleId?: string;
            ClockIn?: string;
            ClockOut?: string;
            EditReason?: string;
            Id?: string;
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class BreakType { constructor(init?: Partial<BreakType>) {

            if (init !== null) { Object.assign(this, init); }}

            Id?: string;
            Name?: string;
            Paid?: boolean;
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class DeleteOtherWage extends Common.RequestBase { constructor(init?: Partial<DeleteOtherWage>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            EditReason!: string;
            EmployeeId!: string;
            OtherWageId!: string;
            SiteId!: string;
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class DeleteOtherWageResponse extends Common.ResponseBase { constructor(init?: Partial<DeleteOtherWageResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Labor.Models.PunchEditor {
        export class DeleteShift extends Common.RequestBase { constructor(init?: Partial<DeleteShift>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            EditReason!: string;
            EmployeeId!: string;
            RoleId!: string;
            ShiftId!: string;
            SiteId!: string;
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class DeleteShiftResponse extends Common.ResponseBase { constructor(init?: Partial<DeleteShiftResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Error!: string;
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class EditReason { constructor(init?: Partial<EditReason>) {

            if (init !== null) { Object.assign(this, init); }}

            Id!: string;
            Name!: string;
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class GetOtherWagesDataResponse { constructor(init?: Partial<GetOtherWagesDataResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            OtherWageBasicTypes!: Labor.Models.PunchEditor.OtherWageBasicType[];
            OtherWages!: Labor.Models.PunchEditor.OtherWage[];
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class GetPunchEditorData extends Common.RequestBase { constructor(init?: Partial<GetPunchEditorData>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            SiteId!: string;
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class GetPunchEditorDataResponse extends Common.ResponseBase { constructor(init?: Partial<GetPunchEditorDataResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            OtherWages!: Labor.Models.PunchEditor.GetOtherWagesDataResponse;
            Shifts!: Labor.Models.PunchEditor.GetPunchedShiftDataResponse;
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class GetPunchedShiftDataResponse { constructor(init?: Partial<GetPunchedShiftDataResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            BreakTypes?: Labor.Models.PunchEditor.BreakType[];
            BusinessDay?: string;
            CloseTime?: string;
            EditsAllowedWeeksBack?: number;
            Employees?: Labor.Models.Employee[];
            OpenTime?: string;
            Reasons?: Labor.Models.PunchEditor.EditReason[];
            Shifts?: Labor.Models.PunchEditor.Shift[];
        }
    }
    export namespace Labor.Models.PunchEditor {
        export enum JobCodeBreakType {
            None = 0,
            Paid = 1,
            Unpaid = 2,
            PaidAndUnpaid = 3,
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class OtherWage { constructor(init?: Partial<OtherWage>) {

            if (init !== null) { Object.assign(this, init); }}

            Amount?: number;
            EditReason?: string;
            Employee?: Labor.Models.Employee;
            Hours?: number;
            Id?: string;
            Job?: Labor.Models.EmployeeJob;
            PayAdjustment?: Labor.Models.PunchEditor.OtherWageBasicType;
            SystemGenerated?: boolean;
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class OtherWageBasicType { constructor(init?: Partial<OtherWageBasicType>) {

            if (init !== null) { Object.assign(this, init); }}

            Id!: string;
            Name!: string;
            Type!: Labor.Models.PunchEditor.OtherWageConfigurationType;
        }
    }
    export namespace Labor.Models.PunchEditor {
        export enum OtherWageConfigurationType {
            TIME = 0,
            AMOUNT = 1,
            AMOUNT_OR_TIME = 2,
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class PenaltyPay { constructor(init?: Partial<PenaltyPay>) {

            if (init !== null) { Object.assign(this, init); }}

            Amount!: number;
            BreakRuleName!: string;
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class SaveOtherWage extends Common.RequestBase { constructor(init?: Partial<SaveOtherWage>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            EditedOtherWage!: Labor.Models.PunchEditor.OtherWage;
            OriginalOtherWage!: Labor.Models.PunchEditor.OtherWage;
            SiteId!: string;
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class SaveOtherWageResponse { constructor(init?: Partial<SaveOtherWageResponse>) {

            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Labor.Models.PunchEditor {
        export class SaveShiftData extends Common.RequestBase { constructor(init?: Partial<SaveShiftData>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            EditedShift!: Labor.Models.PunchEditor.Shift;
            OriginalShift!: Labor.Models.PunchEditor.Shift;
            SiteId!: string;
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class SaveShiftDataResponse { constructor(init?: Partial<SaveShiftDataResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            Error!: string;
        }
    }
    export namespace Labor.Models.PunchEditor {
        export class Shift { constructor(init?: Partial<Shift>) {

            if (init !== null) { Object.assign(this, init); }}

            BreakHours?: number;
            Breaks?: Labor.Models.PunchEditor.Break[];
            BusinessDay?: string;
            ClockedOutByEod?: boolean | null;
            ClockIn?: string;
            ClockOut?: string;
            DeclaredTips?: number;
            EditReason?: string;
            Employee?: Labor.Models.Employee;
            Hours?: number;
            Id?: string;
            Job?: Labor.Models.EmployeeJob;
            PayRate?: number;
            PenaltyPays?: Labor.Models.PunchEditor.PenaltyPay[];
            PenaltyPayTotal?: number;
            TippableSales?: number;
        }
    }
    export namespace Labor.Models.Scheduler {
        export class CopySchedule extends Common.RequestBase { constructor(init?: Partial<CopySchedule>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            CopyFrom!: string;
            CopyTo!: string;
            Overwrite!: boolean;
            SiteId!: string;
            UpdatedBy!: string;
        }
    }
    export namespace Labor.Models.Scheduler {
        export class CopyScheduleResponse { constructor(init?: Partial<CopyScheduleResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            Conflict!: boolean;
        }
    }
    export namespace Labor.Models.Scheduler {
        export class DeliverSchedules extends Common.RequestBase { constructor(init?: Partial<DeliverSchedules>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            SiteId!: string;
        }
    }
    export namespace Labor.Models.Scheduler {
        export class DeliverSchedulesResponse { constructor(init?: Partial<DeliverSchedulesResponse>) {

            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Labor.Models.Scheduler {
        export class EmployeeSchedule { constructor(init?: Partial<EmployeeSchedule>) {

            if (init !== null) { Object.assign(this, init); }}

            Day1!: Labor.Models.Scheduler.ScheduledShift[];
            Day2!: Labor.Models.Scheduler.ScheduledShift[];
            Day3!: Labor.Models.Scheduler.ScheduledShift[];
            Day4!: Labor.Models.Scheduler.ScheduledShift[];
            Day5!: Labor.Models.Scheduler.ScheduledShift[];
            Day6!: Labor.Models.Scheduler.ScheduledShift[];
            Day7!: Labor.Models.Scheduler.ScheduledShift[];
            Employee!: Labor.Models.Employee;
            TotalHours!: number;
        }
    }
    export namespace Labor.Models.Scheduler {
        export class GetScheduleData extends Common.RequestBase { constructor(init?: Partial<GetScheduleData>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            SiteId!: string;
        }
    }
    export namespace Labor.Models.Scheduler {
        export class GetScheduleDataResponse { constructor(init?: Partial<GetScheduleDataResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            EmployeeSchedules!: Labor.Models.Scheduler.EmployeeSchedule[];
            FirstDayOfWeek!: string;
            OpenTime!: string;
            UsedDays!: string[];
        }
    }
    export namespace Labor.Models.Scheduler {
        export class SaveSchedule extends Common.RequestBase { constructor(init?: Partial<SaveSchedule>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            EmployeeSchedules!: Labor.Models.Scheduler.EmployeeSchedule[];
            End!: string;
            SiteId!: string;
            Start!: string;
            UpdatedBy!: string;
        }
    }
    export namespace Labor.Models.Scheduler {
        export class SaveScheduleResponse { constructor(init?: Partial<SaveScheduleResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            Error!: string;
        }
    }
    export namespace Labor.Models.Scheduler {
        export class ScheduledShift { constructor(init?: Partial<ScheduledShift>) {

            if (init !== null) { Object.assign(this, init); }}

            Date!: string;
            EndTime!: string;
            JobCode!: string;
            StartTime!: string;
            // TODO: maybe strip this out later for the API request, but it should be fine to leave it
            ReassignedEmployee?: string;
        }
    }
    export namespace RecoverPassword.Models {
        export class ChangePasswordRequest extends Common.RequestBase { constructor(init?: Partial<ChangePasswordRequest>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Password?: string;
            SiteId?: string;
            Token?: string | null;
            Username?: string | null;
        }
    }
    export namespace RecoverPassword.Models {
        export class ChangePasswordResponse extends Common.ResponseBase { constructor(init?: Partial<ChangePasswordResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Result!: RecoverPassword.Models.ChangeResult;
        }
    }
    export namespace RecoverPassword.Models {
        export enum ChangeResult {
            Success = 0,
            InvalidLength = 1,
            InvalidStrength = 2,
            PasswordMatches = 3,
            SomethingWentWrong = 4,
        }
    }
    export namespace RecoverPassword.Models {
        export class RecoverPasswordRequest extends Common.RequestBase { constructor(init?: Partial<RecoverPasswordRequest>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            BaseUrl!: string;
            Organization!: string;
            Username!: string;
        }
    }
    export namespace RecoverPassword.Models {
        export class RecoverPasswordResponse extends Common.ResponseBase { constructor(init?: Partial<RecoverPasswordResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace RecoverPassword.Models {
        export class ValidateTokenRequest extends Common.RequestBase { constructor(init?: Partial<ValidateTokenRequest>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Token!: string;
        }
    }
    export namespace RecoverPassword.Models {
        export class ValidateTokenResponse extends Common.ResponseBase { constructor(init?: Partial<ValidateTokenResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Authenticated!: boolean;
            CredentialsExpired!: boolean;
            OrganizationName!: string;
            Username!: string;
        }
    }
    export namespace Reports.Models {
        export class AggregateColumn { constructor(init?: Partial<AggregateColumn>) {

            if (init !== null) { Object.assign(this, init); }}

            ColumnType!: Reports.Models.Builder.ColumnType;
            Index!: number;
            IsTranslatable!: boolean;
            Name!: string;
            Type!: Reports.Models.Builder.ReportColumnDataType;
        }
    }
    export namespace Reports.Models {
        export class AmountById { constructor(init?: Partial<AmountById>) {

            if (init !== null) { Object.assign(this, init); }}

            Amount!: number;
            Id!: string;
            Name!: string;
            PercentTotal!: number;
            Quantity!: number;
        }
    }
    export namespace Reports.Models.Builder {
        export class Column { constructor(init?: Partial<Column>) {

            if (init !== null) { Object.assign(this, init); }}

            Compatibility!: Reports.Models.Builder.CompatibilityCriteria;
            Description!: string;
            Id!: string;
            Type!: Reports.Models.Builder.ColumnType;
        }
    }
    export namespace Reports.Models.Builder {
        export enum ColumnType {
            NetSalesByCategory = 101,
            CompsByCategory = 102,
            PromosByCategory = 103,
            OrderModeChargesByCategory = 104,
            TaxesByCategory = 105,
            VoidsByCategory = 106,
            GrossSalesByCategory = 107,
            RevenueItemAmountByCategory = 108,
            SurchargesByCategory = 109,
            NetSalesByOrderMode = 201,
            OrderModeNameByOrderMode = 202,
            CompNameByCompId = 301,
            QuantityByCompId = 302,
            AmountByCompId = 303,
            PercentTotalByCompId = 304,
            PromoNameByPromoId = 401,
            QuantityByPromoId = 402,
            AmountByPromoId = 403,
            PercentTotalByPromoId = 404,
            TenderByNonCashPayments = 501,
            QuantityByNonCashPayments = 502,
            AmountByNonCashPayments = 503,
            TipsByNonCashPayments = 504,
            AutoGratuityByNonCashPayments = 505,
            SalesByNonCashPayments = 506,
            NetSalesByCategoryAndDayPart = 701,
            CategoryNameByCategoryAndDayPart = 702,
            TotalByCategoryAndDayPart = 703,
            CheckCountByDayPart = 801,
            GuestCountByDayPart = 802,
            CheckAvgGrossSaleByDayPart = 803,
            CheckAvgNetSaleByDayPart = 804,
            GuestAvgGrossSaleByDayPart = 805,
            GuestAvgNetSaleByDayPart = 806,
            AvgGuestsPerCheckByDayPart = 807,
            GrossSalesByDayPart = 808,
            NetSalesByDayPart = 809,
            StartTimeByDayPart = 810,
            DayPartNameByDayPart = 811,
            CheckCountByTimePeriod = 901,
            GuestCountByTimePeriod = 902,
            CheckAvgGrossSaleByTimePeriod = 903,
            CheckAvgNetSaleByTimePeriod = 904,
            GuestAvgGrossSaleByTimePeriod = 905,
            GuestAvgNetSaleByTimePeriod = 906,
            AvgGuestsPerCheckByTimePeriod = 907,
            GrossSalesByTimePeriod = 908,
            NetSalesByTimePeriod = 909,
            LaborHoursByTimePeriod = 910,
            LaborDollarsByTimePeriod = 911,
            LaborDollarsOverGrossByTimePeriod = 912,
            LaborDollarsOverNetByTimePeriod = 913,
            TimeByTimePeriod = 914,
            TaxNameByTaxId = 1201,
            TaxMethodByTaxId = 1202,
            TaxAmountByTaxId = 1203,
            TotalNetSales = 1301,
            TotalGrossSales = 1302,
            TotalAdditionalCharges = 1303,
            TotalOrderModeCharges = 1304,
            TotalCheckAvg = 1305,
            TotalGuestAvg = 1306,
            TotalVoidsAmount = 1307,
            TotalVoidsQuantity = 1308,
            TotalVoidedGiftCertificatesRevenueItems = 1309,
            TotalDepositCalculated = 1310,
            TotalDeposit = 1311,
            TotalDepositOverShort = 1312,
            TotalSurchargesCollected = 1313,
            TotalSurchargesNotCollected = 1314,
            TotalSurcharges = 1315,
            TotalCashPayments = 1317,
            TotalTipReductions = 1318,
            TotalPettyCash = 1319,
            TotalDistributedTips = 1320,
            TotalUndistributedTips = 1321,
            TotalTipShare = 1322,
            TotalSurchargesWageGratuity = 1323,
            TotalOtherWageAmount = 1324,
            TotalOtherWage = 1325,
            TotalOtherWageHours = 1326,
            TotalComps = 1327,
            TotalPromos = 1328,
            TotalGuestCount = 1329,
            TotalCheckCount = 1330,
            VoidsQuantityByEmployee = 1401,
            VoidsAmountByEmployee = 1402,
            OverShortByEmployee = 1403,
            EmployeeNameByEmployee = 1404,
            TippableSalesByEmployee = 1405,
            NetSalesByEmployee = 1406,
            EmployeeIdByEmployee = 1407,
            PaymentsByEmployee = 1408,
            DeclaredTipsByEmployee = 1409,
            NetNonCashTipsByEmployee = 1410,
            NonCashGratuityByEmployee = 1413,
            CashGratuityByEmployee = 1414,
            TotalGratuityByEmployee = 1415,
            TotalTipsAndGratuityByEmployee = 1416,
            TipPercentageByEmployee = 1417,
            NonCashSalesByEmployee = 1419,
            NonCashTipsPercentageByEmployee = 1420,
            CashSalesByEmployee = 1421,
            SalesWithAutoGratuityByEmployee = 1424,
            AutoGratuityByEmployee = 1425,
            NonCashTipsByEmployee = 1426,
            DistributedTipsByEmployee = 1427,
            DirectlyTippedByEmployee = 1428,
            IndirectlyTippedByEmployee = 1429,
            OtherWageHours = 1430,
            OtherWageAmount = 1431,
            OtherWageTotal = 1432,
            AmountByPaymentType = 1501,
            AutoGratuityByPaymentType = 1502,
            ChargedTipsByPaymentType = 1503,
            DeferredTipsByPaymentType = 1504,
            SalesByPaymentType = 1505,
            RefundsByPaymentType = 1506,
            PaymentTypeNameByPaymentType = 1507,
            TaxNameByTaxType = 1601,
            TaxAmountByTaxType = 1602,
            EmployeeIdByShift = 1701,
            EmployeeNameByShift = 1702,
            BusinessDateByShift = 1703,
            JobCodeByShift = 1704,
            TimeInByShift = 1705,
            TimeOutByShift = 1706,
            PaidBreakHoursByShift = 1707,
            UnpaidBreakHoursByShift = 1708,
            RegularRateByShift = 1709,
            RegularHoursByShift = 1710,
            RegularPayByShift = 1711,
            OvertimeRateByShift = 1712,
            OvertimeHoursByShift = 1713,
            OvertimePayByShift = 1714,
            TotalHoursByShift = 1715,
            TotalPayByShift = 1716,
            EditedByByShift = 1717,
            ClockedOutByEodByShift = 1718,
            NonCashTipsByShift = 1719,
            DeclaredTipsByShift = 1720,
            TippableSalesByShift = 1721,
            TipThresholdTypeByShift = 1722,
            TipThresholdValueByShift = 1723,
            TipReductionByShift = 1724,
            SurchargesWageGratuityByShift = 1725,
            PenaltyPayHoursByShift = 1726,
            PenaltyPayTotalsByShift = 1727,
            ItemNumberByItem = 1801,
            ItemNameByItem = 1802,
            NumberSoldByItem = 1803,
            PriceSoldByItem = 1804,
            AmountByItem = 1805,
            CostByItem = 1806,
            ProfitByItem = 1807,
            FoodCostByItem = 1808,
            PercentSalesByItem = 1809,
            CategoryNameByItem = 1810,
            EmployeeIdByEmployeeBreak = 1901,
            EmployeeNameByEmployeeBreak = 1902,
            PeriodByEmployeeBreak = 1903,
            DateByEmployeeBreak = 1904,
            BreakTypeByEmployeeBreak = 1905,
            StartTimeByEmployeeBreak = 1906,
            EndTimeByEmployeeBreak = 1907,
            TotalTimeByEmployeeBreak = 1908,
            WaivedBreakByEmployeeBreak = 1909,
            EmployeeIdByShiftEdit = 2001,
            EmployeeNameByShiftEdit = 2002,
            DateByShiftEdit = 2003,
            JobCodeByShiftEdit = 2004,
            TimeInByShiftEdit = 2005,
            TimeOutByShiftEdit = 2006,
            PayRateByShiftEdit = 2007,
            ManagerByShiftEdit = 2008,
            EditTimeByShiftEdit = 2009,
            EditReasonByShiftEdit = 2010,
            PunchApprovalByShiftEdit = 2011,
            DeclaredTipsByShiftEdit = 2012,
            ShiftIdByShiftEdit = 2013,
            ApprovalManagerByShiftEdit = 2014,
            EmployeeIdByBreakEdit = 2101,
            EmployeeNameByBreakEdit = 2102,
            DateByBreakEdit = 2103,
            JobCodeByBreakEdit = 2104,
            TimeInByBreakEdit = 2105,
            TimeOutByBreakEdit = 2106,
            ManagerByBreakEdit = 2107,
            EditTimeByBreakEdit = 2108,
            EditReasonByBreakEdit = 2109,
            PunchApprovalByBreakEdit = 2110,
            BreakIdByBreakEdit = 2111,
            ApprovalManagerByBreakEdit = 2112,
            PaymentTypeByTenderTypeTotals = 2201,
            QuantityByTenderTypeTotals = 2202,
            AmountByTenderTypeTotals = 2203,
            PercentTotalByTenderTypeTotals = 2204,
            CheckNumberByCheckAndTender = 2205,
            CardNumberByCheckAndTender = 2206,
            QuantityByCheckAndTender = 2207,
            AmountByCheckAndTender = 2208,
            GratuityByCheckAndTender = 2209,
            TipByCheckAndTender = 2210,
            TotalByCheckAndTender = 2211,
            EmployeeByCheckAndTender = 2212,
            AuditTime = 2301,
            AuditEvent = 2302,
            AuditTransaction = 2303,
            CheckNumberByVoidDetail = 2401,
            BusinessDayByVoidDetail = 2402,
            ItemByVoidDetail = 2403,
            ReasonByVoidDetail = 2404,
            ManagerByVoidDetail = 2405,
            TimeByVoidDetail = 2406,
            EmployeeByVoidDetail = 2407,
            AmountByVoidDetail = 2408,
            EmployeeByApproval = 2501,
            ManagerByApproval = 2502,
            AmountByApproval = 2503,
            QuantityByApproval = 2504,
            NameByRevenueItem = 2601,
            AmountByRevenueItem = 2602,
            NameByPettyCash = 2700,
            AmountByPettyCash = 2701,
            CheckNumberByPromoDetails = 2800,
            QuantityByPromoDetails = 2801,
            AmountByPromoDetails = 2802,
            ApproverNameByPromoDetails = 2803,
            CheckNumberByCompDetail = 2900,
            QuantityByCompDetail = 2901,
            AmountByCompDetail = 2902,
            ManagerNameByCompDetail = 2903,
            EmployeeIdByEmployeeCheckout = 3100,
            EmployeeNameByEmployeeCheckout = 3101,
            ShiftIdByEmployeeCheckout = 3102,
            TenderedByEmployeeCheckout = 3103,
            PaidInSumByEmployeeCheckout = 3104,
            PaidOutSumByEmployeeCheckout = 3105,
            SessionIdByEmployeeCheckout = 3106,
            ExpectedCashByEmployeeCheckout = 3107,
            TipAmountByEmployeeCheckout = 3108,
            TipReductionAmountByEmployeeCheckout = 3109,
            SurchargeGratuityByEmployeeCheckout = 3110,
            SessionIdByDrawerCheckout = 3201,
            PaidInByDrawerCheckout = 3202,
            PaidOutByDrawerCheckout = 3203,
            TenderedAmountByDrawerCheckout = 3204,
            ExpectedCashByDrawerCheckout = 3205,
            DrawerNameByDrawerCheckout = 3206,
            AccountIdByHouseAccount = 3301,
            AccountNameByHouseAccount = 3302,
            TransactionDateByHouseAccount = 3303,
            TransactionTypeByHouseAccount = 3304,
            TransactionAmountByHouseAccount = 3305,
        }
    }
    export namespace Reports.Models.Builder {
        export enum CompatibilityCriteria {
            Category = 100,
            OrderMode = 200,
            Comps = 300,
            Promos = 400,
            NonCashPayments = 500,
            CategoryAndDayPart = 700,
            DayPart = 800,
            TimePeriod = 900,
            Taxes = 1200,
            Totals = 1300,
            Employee = 1400,
            PaymentType = 1500,
            TaxType = 1600,
            EmployeeShift = 1700,
            Item = 1800,
            EmployeeBreak = 1900,
            ShiftEdit = 2000,
            BreakEdit = 2100,
            CheckAndTender = 2200,
            Audit = 2300,
            VoidDetail = 2400,
            Approval = 2500,
            RevenueItem = 2600,
            TenderTypeTotals = 2700,
            PettyCash = 2800,
            PromoDetails = 2900,
            CompDetail = 3000,
            EmployeeCheckout = 3100,
            DrawerCheckout = 3200,
            HouseAccount = 3300,
        }
    }
    export namespace Reports.Models.Builder {
        export enum DetailsOrTotalsOption {
            Totals = 0,
            Details = 1,
        }
    }
    export namespace Reports.Models.Builder {
        export enum GrossOrNetSalesOption {
            GrossSales = 0,
            NetSales = 1,
        }
    }
    export namespace Reports.Models.Builder {
        export class LineValue { constructor(init?: Partial<LineValue>) {

            if (init !== null) { Object.assign(this, init); }}

            Content!: string;
            IndentationFactor!: number;
        }
    }
    export namespace Reports.Models.Builder {
        export enum ListAllOrListWithValuesOrTotalsOption {
            ListWithValues = 0,
            ListAll = 1,
            Totals = 2,
        }
    }
    export namespace Reports.Models.Builder {
        export class MultiLineValue { constructor(init?: Partial<MultiLineValue>) {

            if (init !== null) { Object.assign(this, init); }}

            Lines!: Reports.Models.Builder.LineValue[];
        }
    }
    export namespace Reports.Models.Builder {
        export class Parameter { constructor(init?: Partial<Parameter>) {

            if (init !== null) { Object.assign(this, init); }}

            DefaultValue!: any;
            DoNotReloadOnBusinessDayChange!: boolean;
            Id!: string;
            Name!: string;
            Options!: Reports.Models.Builder.ParameterOption[];
            Type!: Reports.Models.Builder.ParameterType;
            UseAllValuesWhenUserSelectsNone!: boolean;
        }
    }
    export namespace Reports.Models.Builder {
        export class ParameterContext { constructor(init?: Partial<ParameterContext>) {

            if (init !== null) { Object.assign(this, init); }}

            Id!: string;
            Value!: any;
            Visible!: boolean;
        }
    }
    export namespace Reports.Models.Builder {
        export class ParameterOption { constructor(init?: Partial<ParameterOption>) {

            if (init !== null) { Object.assign(this, init); }}

            Id!: number;
            Name!: string;
        }
    }
    export namespace Reports.Models.Builder {
        export class ParameterSet { constructor(init?: Partial<ParameterSet>) {

            if (init !== null) { Object.assign(this, init); }}

            Id!: string;
            Label!: string;
            Parameters!: Reports.Models.Builder.Parameter[];
        }
    }
    export namespace Reports.Models.Builder {
        export class ParameterSetContext { constructor(init?: Partial<ParameterSetContext>) {

            if (init !== null) { Object.assign(this, init); }}

            Id!: string;
            Label!: string;
            LabelVisible!: boolean;
            Parameters!: Reports.Models.Builder.ParameterContext[];
            Visible!: boolean;
        }
    }
    export namespace Reports.Models.Builder {
        export enum ParameterType {
            String = 0,
            Boolean = 1,
            Option = 2,
            Choice = 3,
            TimeRange = 4,
            MultiSelect = 5,
        }
    }
    export namespace Reports.Models.Builder {
        export enum ReportColumnDataType {
            Double = 0,
            Integer = 1,
            Percent = 2,
            Currency = 3,
            String = 4,
            Translation = 5,
            Date = 6,
            Time = 7,
            DateTime = 8,
            MultiLineString = 9,
            CurrencyMask = 10,
            DateTimeUTC = 11,
        }
    }
    export namespace Reports.Models.Builder {
        export class ReportDefinition { constructor(init?: Partial<ReportDefinition>) {

            if (init !== null) { Object.assign(this, init); }}

            CreatedAt!: Date;
            CreatedBy!: string;
            CreatedByFullName!: string;
            Description!: string;
            Id!: string;
            IsSystemReport!: boolean;
            Name!: string;
            RequiresSingleDaySelection!: boolean;
            Sections!: Reports.Models.Builder.ReportSection[];
            Visibility!: Reports.Models.Builder.ReportVisibility;
        }
    }
    export namespace Reports.Models.Builder {
        export class ReportDefinitionContext { constructor(init?: Partial<ReportDefinitionContext>) {

            if (init !== null) { Object.assign(this, init); }}

            Id!: string;
            Sections!: Reports.Models.Builder.ReportSectionContext[];
        }
    }
    export namespace Reports.Models.Builder {
        export class ReportSection { constructor(init?: Partial<ReportSection>) {

            if (init !== null) { Object.assign(this, init); }}

            Columns!: Reports.Models.Builder.ColumnType[];
            Enabled!: boolean;
            Id!: string;
            Name!: string;
            ParameterSets!: Reports.Models.Builder.ParameterSetContext[];
            Sort!: Reports.Models.Builder.Sort[];
            SortingSuppressed!: boolean;
            Transposed!: boolean;
            Type!: Reports.Models.Builder.CompatibilityCriteria;
        }
    }
    export namespace Reports.Models.Builder {
        export class ReportSectionContext { constructor(init?: Partial<ReportSectionContext>) {

            if (init !== null) { Object.assign(this, init); }}

            Enabled!: boolean;
            Id!: string;
            ParameterSets!: Reports.Models.Builder.ParameterSetContext[];
        }
    }
    export namespace Reports.Models.Builder {
        export enum ReportVisibility {
            Personal = 0,
            Organization = 1,
        }
    }
    export namespace Reports.Models.Builder {
        export enum ShiftGroupingOption {
            None = 0,
            Employee = 1,
            EmployeeAndJob = 2,
        }
    }
    export namespace Reports.Models.Builder {
        export class ShiftGroupingParameterSet extends Reports.Models.Builder.ParameterSet { constructor(init?: Partial<ShiftGroupingParameterSet>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models.Builder {
        export class Sort { constructor(init?: Partial<Sort>) {

            if (init !== null) { Object.assign(this, init); }}

            Column!: Reports.Models.Builder.ColumnType;
            Id!: string;
            Order!: Reports.Models.Builder.SortOrder;
        }
    }
    export namespace Reports.Models.Builder {
        export enum SortOrder {
            Ascending = 0,
            Descending = 1,
        }
    }
    export namespace Reports.Models.Builder {
        export enum SummaryTotalsOrCheckDetailsOption {
            SummaryTotals = 0,
            CheckDetails = 1,
        }
    }
    export namespace Reports.Models.Builder {
        export class SuppressPayRateInformationParameterSet extends Reports.Models.Builder.ParameterSet { constructor(init?: Partial<SuppressPayRateInformationParameterSet>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models {
        export class CalculateReport extends Common.RequestBase { constructor(init?: Partial<CalculateReport>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Parameters!: Reports.Models.ReportParameters;
            ParamsId!: string;
        }
    }
    export namespace Reports.Models {
        export class CalculateReportResponse extends Common.ResponseBase { constructor(init?: Partial<CalculateReportResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            ReportResult!: Reports.Models.ReportTemplateResult;
        }
    }
    export namespace Reports.Models {
        export class ColumnReportParameters { constructor(init?: Partial<ColumnReportParameters>) {

            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models {
        export class ColumnRowData { constructor(init?: Partial<ColumnRowData>) {

            if (init !== null) { Object.assign(this, init); }}

            SortValues!: { [id: number]: any; };
            Values!: { [id: number]: any; };
        }
    }
    export namespace Reports.Models.Custom.EmployeeEfficiency {
        export class EmployeeDetail { constructor(init?: Partial<EmployeeDetail>) {

            if (init !== null) { Object.assign(this, init); }}

            Data!: Reports.Models.Custom.EmployeeEfficiency.Fact[];
            Id!: string;
            Name!: string;
        }
    }
    export namespace Reports.Models.Custom.EmployeeEfficiency {
        export class Fact { constructor(init?: Partial<Fact>) {

            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            CheckCount!: number;
            DayPartId!: string;
            GuestCount!: number;
            JobCode!: string;
            Sales!: number;
        }
    }
    export namespace Reports.Models.Custom.EmployeeEfficiency {
        export class GetEmployeeEfficiencyDataRequest { constructor(init?: Partial<GetEmployeeEfficiencyDataRequest>) {

            if (init !== null) { Object.assign(this, init); }}

            BusinessDate!: string;
            SiteId!: string;
        }
    }
    export namespace Reports.Models.Custom.EmployeeEfficiency {
        export class GetEmployeeEfficiencyDataResponse { constructor(init?: Partial<GetEmployeeEfficiencyDataResponse>) {

            if (init !== null) { Object.assign(this, init); }}

            BusinessDate!: string;
            DayParts!: { [id: string]: string; };
            Employees!: Reports.Models.Custom.EmployeeEfficiency.EmployeeDetail[];
            Jobs!: { [id: string]: string; };
        }
    }
    export namespace Reports.Models {
        export enum DayRangeType {
            SingleDay = 0,
            DateRange = 1,
        }
    }
    export namespace Reports.Models {
        export class DeleteReport extends Common.RequestBase { constructor(init?: Partial<DeleteReport>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            ReportId!: string;
        }
    }
    export namespace Reports.Models {
        export enum DocumentType {
            HTML = 0,
            PDF = 1,
            Delimited = 2,
            Excel = 3,
            RTF = 4,
        }
    }
    export namespace Reports.Models {
        export class GetReportColumnsResponse extends Common.ResponseBase { constructor(init?: Partial<GetReportColumnsResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Columns!: Reports.Models.Builder.Column[];
            NonTransposableSections!: Reports.Models.Builder.CompatibilityCriteria[];
        }
    }
    export namespace Reports.Models {
        export class GetReportParameterSets extends Common.RequestBase { constructor(init?: Partial<GetReportParameterSets>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            ReportId!: string;
            SiteId!: string;
        }
    }
    export namespace Reports.Models {
        export class GetReportParameterSetsResponse extends Common.ResponseBase { constructor(init?: Partial<GetReportParameterSetsResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            CompatibilityGroupParameterSetIds!: { [id: string]: string[]; };
            ParameterSets!: Reports.Models.Builder.ParameterSet[];
        }
    }
    export namespace Reports.Models {
        export class GetReportsResponse extends Common.ResponseBase { constructor(init?: Partial<GetReportsResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Reports!: Reports.Models.ReportInfo[];
        }
    }
    export namespace Reports.Models {
        export class GetStoreConfig extends Common.RequestBase { constructor(init?: Partial<GetStoreConfig>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            StoreId!: string;
        }
    }
    export namespace Reports.Models {
        export class GetStoreConfigResponse extends Common.ResponseBase { constructor(init?: Partial<GetStoreConfigResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            OpenHour!: number;
        }
    }
    export namespace Reports.Models {
        export class GroupingValue { constructor(init?: Partial<GroupingValue>) {

            if (init !== null) { Object.assign(this, init); }}

            ColumnName!: string;
            ColumnValue!: any;
        }
    }
    export namespace Reports.Models {
        export class OpenReport extends Common.RequestBase { constructor(init?: Partial<OpenReport>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            ReportId!: string;
        }
    }
    export namespace Reports.Models {
        export class OpenReportResponse extends Common.ResponseBase { constructor(init?: Partial<OpenReportResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Report!: Reports.Models.Builder.ReportDefinition;
        }
    }
    export namespace Reports.Models {
        export class ReportInfo { constructor(init?: Partial<ReportInfo>) {

            if (init !== null) { Object.assign(this, init); }}

            Description!: string;
            IsSystem!: boolean;
            Name!: string;
            Owner!: string;
            ReportId!: string;
            ReportType!: Reports.Models.ReportType;
        }
    }
    export namespace Reports.Models {
        export class ReportParameters { constructor(init?: Partial<ReportParameters>) {

            if (init !== null) { Object.assign(this, init); }}

            DateRangeType!: Reports.Models.DayRangeType;
            EndDay!: string;
            OutputType!: Reports.Models.DocumentType;
            ReportDefinitionContext!: Reports.Models.Builder.ReportDefinitionContext;
            StartDay!: string;
            StoreId!: string;
        }
    }
    export namespace Reports.Models {
        export class ReportResult { constructor(init?: Partial<ReportResult>) {

            if (init !== null) { Object.assign(this, init); }}

            Logo!: string;
            OrganizationName!: string;
            PrintedBy!: string;
            PrintedOn!: Date;
            ReportDate!: string;
            ReportName!: string;
            StoreName!: string;
        }
    }
    export namespace Reports.Models {
        export class ReportTemplateGroup { constructor(init?: Partial<ReportTemplateGroup>) {

            if (init !== null) { Object.assign(this, init); }}

            GroupingValues!: Reports.Models.GroupingValue[];
            GroupSets!: Reports.Models.ReportTemplateGroup[];
            HeaderText!: string;
            RowKeys!: number[];
            Rows!: Reports.Models.ReportTemplateRow[];
            Totals!: Reports.Models.ColumnRowData;
        }
    }
    export namespace Reports.Models {
        export class ReportTemplateResult extends Reports.Models.ReportResult { constructor(init?: Partial<ReportTemplateResult>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Sections!: Reports.Models.ReportTemplateCompatibilitySection[];
        }
    }
    export namespace Reports.Models {
        export class ReportTemplateRow { constructor(init?: Partial<ReportTemplateRow>) {

            if (init !== null) { Object.assign(this, init); }}

            Cells!: Reports.Models.ColumnRowData;
        }
    }
    export namespace Reports.Models {
        export enum ReportType {
            System = 1,
            User = 2,
            Custom = 3,
        }
    }
    export namespace Reports.Models {
        export class SaveReport extends Common.RequestBase { constructor(init?: Partial<SaveReport>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Report!: Reports.Models.Builder.ReportDefinition;
        }
    }
    export namespace Reports.Models {
        export class SaveReportResponse extends Common.ResponseBase { constructor(init?: Partial<SaveReportResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            CreatedAt!: Date;
            CreatedBy!: string;
            EditedAt!: Date;
            EditedBy!: string;
            SavedReportId!: string;
        }
    }
    export namespace Reprint.Models {
        export class Check { constructor(init?: Partial<Check>) {

            if (init !== null) { Object.assign(this, init); }}

            Amount!: string;
            CheckNumber!: string;
            EmployeeName!: string;
            PrimaryPayment!: string;
            Table!: string;
            Time!: string;
        }
    }
    export namespace Reprint.Models {
        export class CheckComp { constructor(init?: Partial<CheckComp>) {

            if (init !== null) { Object.assign(this, init); }}

            Amount!: number;
            Description!: string;
            Name!: string;
        }
    }
    export namespace Reprint.Models {
        export class CheckDetailRequest extends Common.RequestBase { constructor(init?: Partial<CheckDetailRequest>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            SiteId!: string;
            TransactionId!: string;
        }
    }
    export namespace Reprint.Models {
        export class CheckDetailResponse extends Common.ResponseBase { constructor(init?: Partial<CheckDetailResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            CheckType!: Reprint.Models.CheckType;
            Comps!: Reprint.Models.CheckComp[];
            EmployeeName!: string;
            GrandTotal!: number;
            GuestCount!: number;
            Id!: string;
            Items!: Reprint.Models.CheckLineItem[];
            OrderMode!: string;
            Payments!: Reprint.Models.CheckPayment[];
            Promos!: Reprint.Models.CheckPromo[];
            SubTotal!: number;
            Surcharges!: Reprint.Models.CheckSurcharge[];
            Table!: string;
            TaxExempts!: string[];
            Time!: string;
            TotalTax!: number;
        }
    }
    export namespace Reprint.Models {
        export class CheckLineItem { constructor(init?: Partial<CheckLineItem>) {

            if (init !== null) { Object.assign(this, init); }}

            DiscountedPrice!: number;
            Label!: string;
            Price!: number;
            Promos!: Reprint.Models.CheckPromo[];
            Quantity!: number;
            SubItems!: Reprint.Models.CheckLineItem[];
            UnitPrice!: number;
            Voided!: boolean;
        }
    }
    export namespace Reprint.Models {
        export class CheckPayment { constructor(init?: Partial<CheckPayment>) {

            if (init !== null) { Object.assign(this, init); }}

            CardLastFourDigits!: string;
            Name!: string;
            Tip!: number;
            Total!: number;
            Type!: Reprint.Models.CheckPaymentType;
        }
    }
    export namespace Reprint.Models {
        export enum CheckPaymentType {
            Generic = 0,
            CreditCard = 1,
            DebitCard = 2,
            Cash = 3,
            HouseAccount = 4,
        }
    }
    export namespace Reprint.Models {
        export class CheckPromo { constructor(init?: Partial<CheckPromo>) {

            if (init !== null) { Object.assign(this, init); }}

            Amount!: number;
            InstanceId!: string;
            Name!: string;
            Type!: Reprint.Models.CheckPromoType;
        }
    }
    export namespace Reprint.Models {
        export enum CheckPromoType {
            Generic = 0,
            BuyOneGetOne = 1,
            Combo = 2,
            Coupon = 3,
            NewPrice = 4,
        }
    }
    export namespace Reprint.Models {
        export class CheckSurcharge { constructor(init?: Partial<CheckSurcharge>) {

            if (init !== null) { Object.assign(this, init); }}

            Amount!: number;
            Name!: string;
            Type!: Reprint.Models.CheckSurchargeType;
        }
    }
    export namespace Reprint.Models {
        export enum CheckSurchargeType {
            Generic = 0,
            OrderMode = 1,
            AdditionalCharge = 2,
        }
    }
    export namespace Reprint.Models {
        export enum CheckType {
            Sale = 0,
            Refund = 1,
        }
    }
    export namespace Reprint.Models {
        export class GetChecks extends Common.RequestBase { constructor(init?: Partial<GetChecks>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            SiteId!: string;
        }
    }
    export namespace Reprint.Models {
        export class GetChecksResponse extends Common.ResponseBase { constructor(init?: Partial<GetChecksResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Checks!: Reprint.Models.Check[];
        }
    }
    export namespace TipShare.Models {
        export class Contribution { constructor(init?: Partial<Contribution>) {

            if (init !== null) { Object.assign(this, init); }}

            Amount!: number;
            ContributionTime!: string;
            Distributed!: boolean;
            EmployeeId!: string;
            EmployeeName!: string;
            Excess!: number;
            Excesses!: TipShare.Models.Excess[];
            Id!: string;
            IncludedIds!: TipShare.Models.IdData[];
            JobTitle!: string;
            Pool!: string;
            RoleId!: string;
            SelectedToDistribute!: boolean;
            ShiftId!: string;
        }
    }
    export namespace TipShare.Models {
        export class Distribution { constructor(init?: Partial<Distribution>) {

            if (init !== null) { Object.assign(this, init); }}

            Amount!: number;
            DistributionTime!: string;
            EmployeeId!: string;
            EmployeeName!: string;
            JobTitle!: string;
            RoleId!: string;
        }
    }
    export namespace TipShare.Models {
        export class Excess { constructor(init?: Partial<Excess>) {

            if (init !== null) { Object.assign(this, init); }}

            Amount!: number;
            EmployeeId!: string;
        }
    }
    export namespace TipShare.Models {
        export class GetContributions extends Common.RequestBase { constructor(init?: Partial<GetContributions>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            FromBusinessDay!: string;
            SiteId!: string;
            ToBusinessDay!: string;
        }
    }
    export namespace TipShare.Models {
        export class GetContributionsResponse extends Common.ResponseBase { constructor(init?: Partial<GetContributionsResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Contributions!: TipShare.Models.Contribution[];
        }
    }
    export namespace TipShare.Models {
        export class GetDistributionPreview extends Common.RequestBase { constructor(init?: Partial<GetDistributionPreview>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            ContributionIds!: TipShare.Models.IdData[];
            FromBusinessDay!: string;
            SiteId!: string;
            ToBusinessDay!: string;
        }
    }
    export namespace TipShare.Models {
        export class GetDistributionPreviewResponse extends Common.ResponseBase { constructor(init?: Partial<GetDistributionPreviewResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Distributions!: TipShare.Models.Distribution[];
        }
    }
    export namespace TipShare.Models {
        export class IdData { constructor(init?: Partial<IdData>) {

            if (init !== null) { Object.assign(this, init); }}

            BusinessDay!: string;
            Id!: string;
        }
    }
    export namespace TipShare.Models {
        export class TriggerDistribution extends Common.RequestBase { constructor(init?: Partial<TriggerDistribution>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            ContributionIds!: TipShare.Models.IdData[];
            FromBusinessDay!: string;
            SiteId!: string;
            ToBusinessDay!: string;
        }
    }
    export namespace TipShare.Models {
        export class TriggerDistributionResponse { constructor(init?: Partial<TriggerDistributionResponse>) {

            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace User.Models {
        export class GetSitesRequest extends Common.RequestBase { constructor(init?: Partial<GetSitesRequest>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace User.Models {
        export class GetSitesResponse extends Common.ResponseBase { constructor(init?: Partial<GetSitesResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Sites!: Common.SiteInfo[];
        }
    }
    export namespace User.Models {
        export class UserDetailsResponse extends Common.ResponseBase { constructor(init?: Partial<UserDetailsResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Authorizations!: string[];
            FullName!: string;
            Organization!: string;
        }
    }
    export namespace User {
        export enum UserRoles {
            ALOHA_MANAGER_ONE_SUPPORT = 10,
            ALOHA_MANAGER_ONE_USER = 20,
            ALOHA_MANAGER_ONE_CONFIGURATION_REFRESH_COMMAND = 30,
            ALOHA_MANAGER_ONE_FORCE_END_OF_DAY_COMMAND = 40,
            TIP_MANAGEMENT_SERVICE_TIP_SHARE_DISTRIBUTOR = 50,
            LABOR_DATA_MANAGEMENT_SHIFT_WRITER = 60,
            TIP_MANAGEMENT_WRITER = 70,
            LABOR_DATA_MANAGEMENT_SCHEDULE_WRITER = 80,
            FINANCIAL_RECONCILIATION_DEPOSIT_ADMINISTRATOR = 90,
            LABOR_DATA_MANAGEMENT_PAY_RATE_VIEWER = 100,
            FINANCIAL_RECONCILIATION_HOUSE_ACCOUNT_ADMINISTRATOR = 110,
        }
    }
    export namespace Utilities.Models {
        export class BusinessDayRangeResponse extends Common.ResponseBase { constructor(init?: Partial<BusinessDayRangeResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            FirstBusinessDay!: string;
            LastBusinessDay!: string;
        }
    }
    export namespace Utilities.Models {
        export class BusinessDayResponse extends Common.ResponseBase { constructor(init?: Partial<BusinessDayResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            BusinessDay?: string;
        }
    }
    export namespace Utilities.Models {
        export class BusinessDaysResponse extends Common.ResponseBase { constructor(init?: Partial<BusinessDaysResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            BusinessDays!: string[];
        }
    }
    export namespace Utilities.Models {
        export class RequestEndOfDay extends Common.RequestBase { constructor(init?: Partial<RequestEndOfDay>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            SiteId!: string;
        }
    }
    export namespace Utilities.Models {
        export class RequestEndOfDayResponse extends Common.ResponseBase { constructor(init?: Partial<RequestEndOfDayResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            CorrelationId!: string;
        }
    }
    export namespace Utilities.Models {
        export class RequestLogs extends Common.RequestBase { constructor(init?: Partial<RequestLogs>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            SiteId!: string;
        }
    }
    export namespace Utilities.Models {
        export class RequestLogsResponse extends Common.ResponseBase { constructor(init?: Partial<RequestLogsResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Logs!: Utilities.Models.UtilitiesLog[];
        }
    }
    export namespace Utilities.Models {
        export class RequestRefresh extends Common.RequestBase { constructor(init?: Partial<RequestRefresh>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            SiteId!: string;
        }
    }
    export namespace Utilities.Models {
        export class RequestRefreshResponse extends Common.ResponseBase { constructor(init?: Partial<RequestRefreshResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            CorrelationId!: string;
        }
    }
    export namespace Utilities.Models {
        export class UtilitiesLog { constructor(init?: Partial<UtilitiesLog>) {

            if (init !== null) { Object.assign(this, init); }}

            Finished!: string;
            Id!: string;
            Operation!: string;
            SiteId!: string;
            Started!: string;
            Status!: string;
            User!: string;
        }
    }
    export namespace Reports.Models.Builder {
        export class OptionParameter<T> extends Reports.Models.Builder.Parameter { constructor(init?: Partial<OptionParameter<T>>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Common {
        export class ErrorResponse extends Common.ResponseBase { constructor(init?: Partial<ErrorResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Common {
        export class ManagerOneLogin extends Common.RequestBase { constructor(init?: Partial<ManagerOneLogin>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Organization!: string;
            Password!: string;
            Username!: string;
        }
    }
    export namespace Common {
        export class ManagerOneLoginResponse extends Common.ResponseBase { constructor(init?: Partial<ManagerOneLoginResponse>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            CredentialsExpired?: boolean;
            LoggedIn?: boolean;
            Token?: string;
            Username?: string;
        }
    }
    export namespace Reports.Models.Builder {
        export class AuditFiltersParameterSet extends Reports.Models.Builder.ParameterSet { constructor(init?: Partial<AuditFiltersParameterSet>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models.Builder {
        export class AuditLaborParameterSet extends Reports.Models.Builder.ParameterSet { constructor(init?: Partial<AuditLaborParameterSet>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models.Builder {
        export class AuditOtherParameterSet extends Reports.Models.Builder.ParameterSet { constructor(init?: Partial<AuditOtherParameterSet>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models.Builder {
        export class AuditPaymentsParameterSet extends Reports.Models.Builder.ParameterSet { constructor(init?: Partial<AuditPaymentsParameterSet>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models.Builder {
        export class DetailsOrTotalsParameterSet extends Reports.Models.Builder.ParameterSet { constructor(init?: Partial<DetailsOrTotalsParameterSet>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models.Builder {
        export class GrossOrNetSalesParameter extends Reports.Models.Builder.OptionParameter<Reports.Models.Builder.GrossOrNetSalesOption> { constructor(init?: Partial<GrossOrNetSalesParameter>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models.Builder {
        export class GroupByEmployeeParameterSet extends Reports.Models.Builder.ParameterSet { constructor(init?: Partial<GroupByEmployeeParameterSet>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models.Builder {
        export class HourlySalesParameterSet extends Reports.Models.Builder.ParameterSet { constructor(init?: Partial<HourlySalesParameterSet>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models.Builder {
        export class HouseAccountFiltersParameterSet extends Reports.Models.Builder.ParameterSet { constructor(init?: Partial<HouseAccountFiltersParameterSet>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models.Builder {
        export class ListAllOrListWithValuesOrTotalsParameter extends Reports.Models.Builder.OptionParameter<Reports.Models.Builder.ListAllOrListWithValuesOrTotalsOption> { constructor(init?: Partial<ListAllOrListWithValuesOrTotalsParameter>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models.Builder {
        export class ListAllOrListWithValuesOrTotalsParameterSet extends Reports.Models.Builder.ParameterSet { constructor(init?: Partial<ListAllOrListWithValuesOrTotalsParameterSet>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models.Builder {
        export class ShiftGroupingColumnParameter extends Reports.Models.Builder.OptionParameter<Reports.Models.Builder.ShiftGroupingOption> { constructor(init?: Partial<ShiftGroupingColumnParameter>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models.Builder {
        export class ShowDetailOrTotalColumnParameter extends Reports.Models.Builder.OptionParameter<Reports.Models.Builder.DetailsOrTotalsOption> { constructor(init?: Partial<ShowDetailOrTotalColumnParameter>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models.Builder {
        export class ShowSummaryTotalsOrCheckDetailsParameter extends Reports.Models.Builder.OptionParameter<Reports.Models.Builder.SummaryTotalsOrCheckDetailsOption> { constructor(init?: Partial<ShowSummaryTotalsOrCheckDetailsParameter>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

        }
    }
    export namespace Reports.Models {
        export class ReportTemplateCompatibilitySection extends Reports.Models.ReportTemplateGroup { constructor(init?: Partial<ReportTemplateCompatibilitySection>) {
            super();
            if (init !== null) { Object.assign(this, init); }}

            Columns!: Reports.Models.AggregateColumn[];
            Compatibility!: Reports.Models.Builder.CompatibilityCriteria;
            SectionId!: string;
            Sort!: Reports.Models.Builder.Sort[];
            SortingSuppressed!: boolean;
            Transposed!: boolean;
        }
    }
}
