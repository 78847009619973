import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { getReportsCall } from 'services/api';
import { GetReportsResponse } from 'services/ManagerOneApi';
import FunctionBar from './FunctionBar';
import TableContents from './TableContents';

const FunctionBarSpacing = styled.div`
    margin-bottom: '20px';
`;

const Reports: FC = (): JSX.Element => {
    const [rows, setRows] = useState(new GetReportsResponse());
    const [searchText, setSearchText] = useState('');
    const [reportType, setReportType] = useState('All');

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const updateReports = async () => {
                const getReportsData = await getReportsCall();
                setRows(getReportsData);
            };
            updateReports();
        }
        return () => {
            // prevents memory leak
            mounted = false;
        };
    }, []);

    return (
        <Box data-testid="reportContent" sx={{ height: 'calc(100vh - 72px);' }}>
            <FunctionBar
                setSearchText={setSearchText}
                setReportType={setReportType}
            />
            <FunctionBarSpacing />

            <TableContents
                reports={rows}
                searchText={searchText}
                reportType={reportType}
            />
        </Box>
    );
};

export default Reports;
