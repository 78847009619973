import { FC } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { RootState } from 'state/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedJobCodes } from 'state/schedulerSlice';

const JobChips: FC = () => {
    const dispatch = useDispatch();
    const selectedJobCodes = useSelector(
        (state: RootState) => state.scheduler.selectedJobCodes
    );

    const handleDelete = (value: string) => {
        dispatch(
            setSelectedJobCodes(selectedJobCodes.filter((job) => job !== value))
        );
    };

    return (
        <>
            <Stack
                direction="row"
                flexWrap="wrap"
                spacing={1}
                padding="8px !important"
            >
                {selectedJobCodes.map((job) => (
                    <Chip
                        key={job}
                        label={job}
                        data-testid={`${job}-chip-test-id`}
                        onDelete={() => handleDelete(job)}
                        sx={{
                            marginBottom: '4px !important',
                            backgroundColor: '#cce8eb',
                        }}
                    />
                ))}
            </Stack>
        </>
    );
};
export default JobChips;
