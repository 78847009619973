import { FC } from 'react';
import useSelect from 'state/selector';
import DeleteDepositsDialog from './DeleteDepositsDialog';
import EditAddDepositsDialog from './EditAddDepositsDialog';

type DepositDialogVariant = 'add' | 'edit' | 'delete';
export type DepositStatus = DepositDialogVariant | 'submit' | null;

const DepositsDialog: FC = () => {
    const variant = useSelect((s) => s.deposits.dialogStatus);

    switch (variant) {
        case 'add':
        case 'edit':
            return <EditAddDepositsDialog addOrEdit={variant} />;
        case 'delete':
            return <DeleteDepositsDialog />;
        default:
            return null;
    }
};

export default DepositsDialog;
