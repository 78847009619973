import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import uuid from 'uuid-random';
import { FormattedVoidDetails, VoidsColumn } from './types';

const StyledTable = styled(Table)`
    table-layout: fixed;
`;

const HeaderRow = styled(TableRow)`
    border-bottom: 1.1px solid #757575;
`;

const TotalsBodyRow = styled(TableRow)`
    border-bottom: hidden;
    font-weight: bold;
`;

const Cell = styled(TableCell)`
    font-weight: revert;
    padding-top: 9px;
    padding-bottom: 9px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const FirstCell = styled(Cell)`
    text-align: left;
    padding-left: 0;
    width: 124px;
`;

const MidCell = styled(Cell)`
    text-align: right;
    padding-left: 0;
    padding-right: 0;
    width: 40px;
`;

const LastCell = styled(Cell)`
    text-align: right;
    padding-right: 0;
    width: 124px;
`;

type CellsProps = { data: ReactNode[] };
const Cells: FC<CellsProps> = ({ data }) => (
    <>
        <FirstCell>{data[0]}</FirstCell>
        <MidCell>{data[1]}</MidCell>
        <LastCell>{data[2]}</LastCell>
    </>
);

export type Props = {
    voidsRows: FormattedVoidDetails[];
    totalsRow: FormattedVoidDetails;
    cols: VoidsColumn[];
};

export const VoidsTable: FC<Props> = ({ voidsRows, totalsRow, cols }) => (
    <StyledTable>
        <TableHead>
            <HeaderRow>
                <Cells data={cols?.map((col) => col.headerName)} />
            </HeaderRow>
        </TableHead>
        <TableBody>
            {voidsRows?.map((row) => (
                <TableRow key={uuid()}>
                    <Cells data={cols?.map(({ field }) => row[field])} />
                </TableRow>
            ))}
            <TotalsBodyRow>
                <Cells data={cols?.map(({ field }) => totalsRow[field])} />
            </TotalsBodyRow>
        </TableBody>
    </StyledTable>
);
