import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    showSettingsDropdown: false,
    selectedBusinessDay: String(new Date()),
    showViewOnlyAlert: false,
    mockingEnabled: false,
    showTrainingVideoModal: false,
    showReleaseNotesModal: false,
    viewingManagerOneApplet: true,
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setShowSettingsDropdown: (state, action) => {
            state.showSettingsDropdown = action.payload;
        },
        setSelectedBusinessDay: (state, action) => {
            state.selectedBusinessDay = action.payload;
        },
        setShowViewOnlyAlert: (state, action) => {
            state.showViewOnlyAlert = action.payload;
        },
        setMockingEnabled: (state, action) => {
            state.mockingEnabled = action.payload;
        },
        setShowTrainingVideoModal: (state, action) => {
            state.showTrainingVideoModal = action.payload;
        },
        setShowReleaseNotesModal: (state, action) => {
            state.showReleaseNotesModal = action.payload;
        },
        setViewingManagerOneApplet: (state, action) => {
            state.viewingManagerOneApplet = action.payload;
        },
        purge: (state) => ({
            ...initialState,
            mockingEnabled: state.mockingEnabled,
        }),
    },
});

export const {
    setLoading,
    setShowSettingsDropdown,
    setSelectedBusinessDay,
    setShowViewOnlyAlert,
    setMockingEnabled,
    setShowTrainingVideoModal,
    setShowReleaseNotesModal,
    purge,
    setViewingManagerOneApplet,
} = commonSlice.actions;

export default commonSlice;
