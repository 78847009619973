/* istanbul ignore file */
import { useCallback, useEffect } from 'react';

const useEventListener = <K extends keyof WindowEventMap>(
    type: K,
    listener: (this: Window, ev: WindowEventMap[K]) => unknown,
    options?: boolean | AddEventListenerOptions
): void => {
    useEffect(() => {
        window.addEventListener(type, listener, options);
        return () => {
            // prevents side effects and possible memory leaks
            window.removeEventListener(type, listener, options);
        };
    }, [type, listener, options]);
};

/**
 * - Cancels refresh if fulfills given condition, using the `onbeforeunload` event handler.
 * - however, please note that there are some caveats with `onbeforeunload` in general, and especially for SPA, such as with React
 */
export const usePromptOnBeforeUnload = (shouldPrompt: boolean): void => {
    const onBeforeUnload = useCallback(
        (e: WindowEventMap['beforeunload']) => {
            if (!shouldPrompt) return true;

            e.preventDefault();
            e.returnValue = '';
            return false;
        },
        [shouldPrompt]
    );
    useEventListener('beforeunload', onBeforeUnload);
};
