/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import moment, { MomentInput } from 'moment-timezone';

export const formatCurrency = (value: number | string): string =>
    // TODO (Chris): Use react-intl to translate this, once we have added it.
    `$${value}`;

export const toAmPmTime = (date: string | Date): string =>
    moment(date).format('h:mm A');

export const padYear = (n: number): string => {
    let yearString = `${n}`;
    while (yearString.length < 4) {
        yearString = `0${yearString}`;
    }
    return yearString;
};

export const pad = (n: number): string => (n < 10 ? `0${n}` : `${n}`);

export const getDateString = (date: Date): string =>
    `${padYear(date.getFullYear())}-${pad(date.getMonth() + 1)}-${pad(
        date.getDate()
    )}`;

export const getTimeString = /* istanbul ignore next */ (date: Date): string =>
    `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
        date.getSeconds()
    )}`;

export const stripZulu = (value: string): string => value.replace('Z', '');

export const dateToBusinessDay = (date: Date): string =>
    `${getDateString(date)}T00:00:00Z`;

export const momentBusinessDay = (date?: MomentInput): string =>
    moment(date).format('YYYY-MM-DDT00:00:00[Z]');

export const dateToIsoString = /* istanbul ignore next */ (
    date: Date
): string => `${getDateString(date)}T${getTimeString(date)}`;

export const getCurrentDay = /* istanbul ignore next */ (): string => {
    const dt = new Date();
    dt.setHours(0, 0, 0, 0);
    return dateToIsoString(dt);
};

// for debugging purposes
export const getSpecificDay = /* istanbul ignore next */ (
    yr: number,
    mo: number,
    day: number
): string => {
    const dt = new Date(yr, mo, day);
    dt.setHours(0, 0, 0, 0);
    return dateToIsoString(dt);
};

export const intervalsOverlap = /* istanbul ignore next */ (
    startA: string,
    endA: string,
    startB: string,
    endB: string
): boolean => startA < endB && startB < endA;

export const moveItemsInArray = /* istanbul ignore next */ (
    array: any[],
    from: number,
    to: number
): any[] => {
    if (to === from) {
        return array;
    }
    const target = array[from];
    const increment = to < from ? -1 : 1;
    for (let k = from; k !== to; k += increment) {
        array[k] = array[k + increment];
    }
    array[to] = target;
    return array;
};

export const calculateHoursDifference = (
    start: string | Date,
    end: string | Date,
    siteTimezone: string
): number => {
    const msPerHour = 1000 * 60 * 60;
    const dStart = moment.tz(start, siteTimezone).toDate();
    const dEnd = moment.tz(end, siteTimezone).toDate();

    const difference = (dEnd.getTime() - dStart.getTime()) / msPerHour;
    return Number(difference.toFixed(2));
};
