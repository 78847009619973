import { createSlice } from '@reduxjs/toolkit';
import { SiteInfo } from '../services/ManagerOneApi';

interface InitialState {
    sites: SiteInfo[];
    selectedSiteId: string;
}
const initialState: InitialState = {
    sites: [],
    selectedSiteId: '',
};

const sitesInfoSlice = createSlice({
    name: 'sitesInfo',
    initialState,
    reducers: {
        setSelectedSiteId: (state, action) => {
            state.selectedSiteId = action.payload;
        },
        setSites: (state, action) => {
            state.sites = action.payload;
        },
        purge: () => initialState,
    },
});
export const { setSelectedSiteId, setSites, purge } = sitesInfoSlice.actions;
export default sitesInfoSlice;
