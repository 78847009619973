import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RootState } from 'state/reducers';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/Check';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import {
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Tooltip,
    Typography,
    Box,
    DialogContentText,
} from '@mui/material';
import {
    setScheduleWasCopied,
    setShowConflictConfirmModal,
    setShowCopyScheduleModal,
} from 'state/schedulerSlice';
import { doCopy } from 'services/api';
import useAppDispatch from 'state/dispatch';
import styled from 'styled-components';
import MainButton from 'components/MainButton';
import CopyScheduleDatePicker from './CopyScheduleDatePicker';
import { CopyFromCopyToDates } from '..';

const Header = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin: 20px !important;
    align-content: space-between;
`;
const StyledCloseIcon = styled(CloseIcon)`
    cursor: pointer;
`;
const StyledWarningIcon = styled(WarningIcon)`
    font-size: 1.6em;
    margin-right: 15px;
    margin-top: 10px;
`;
const StyledCheckIcon = styled(CheckIcon)`
    font-size: 1.6em;
    margin-right: 15px;
    margin-top: 10px;
`;

export interface Props {
    usedDays: string[];
    setCopyDates: Dispatch<SetStateAction<CopyFromCopyToDates>>;
}
const CopySchedule: FC<Props> = ({ usedDays, setCopyDates }) => {
    const selectedBusinessDay = useSelector(
        (state: RootState) => state.common.selectedBusinessDay
    );
    const defaultSelectedDay = new Date(selectedBusinessDay);

    const isVisible = useSelector(
        (state: RootState) => state.scheduler.isCopyScheduleModalVisible
    );
    const currentSiteId = useSelector(
        (state: RootState) => state.sitesInfo.selectedSiteId
    );
    const userName = useSelector((state: RootState) => state.user.username);

    const [date, setNewDate] = useState(new Date());
    const endOfCurrentBusinessWeek = moment(endOfWeek(defaultSelectedDay))
        .add(1, 'day')
        .toDate();
    const nextWeek = moment(endOfWeek(new Date())).add(1, 'day').toDate();
    const minDate =
        nextWeek.getTime() > endOfCurrentBusinessWeek.getTime()
            ? nextWeek
            : endOfCurrentBusinessWeek;
    const [isDateValid, setIsDateValid] = useState<boolean>(true);
    const [weekRangeTitle, setWeekRangeTitle] = useState<string>('');
    const [copyTo, setCopyTo] = useState<string>('');
    const [existingFlag, setExistingFlag] = useState<boolean>(false);
    const defaultCalendarDate = moment(minDate).add(1, 'week').toDate();
    const dispatch = useAppDispatch();

    const setWeekRange = () => {
        const firstDayOfCopyFromWeek = startOfWeek(defaultSelectedDay);
        const lastDayOfCopyFromWeek = endOfWeek(defaultSelectedDay);
        const start = moment(firstDayOfCopyFromWeek)
            .add(1, 'day')
            .format('MMM D');
        const end = moment(lastDayOfCopyFromWeek).add(1, 'day').format('MMM D');
        const copyFromDisplayText = `${start} - ${end}`;
        setWeekRangeTitle(copyFromDisplayText);
    };

    const updateHasExistingScheduleFlag = () => {
        const firstDayOfCopyToWeek = startOfWeek(date);
        const lastDayOfCopyToWeek = endOfWeek(date);
        const startDayString = moment(firstDayOfCopyToWeek)
            .add(1, 'day')
            .format('YYYY-MM-DD');
        const endDayString = moment(lastDayOfCopyToWeek)
            .add(1, 'day')
            .format('YYYY-MM-DD');
        const daysInRange = usedDays?.filter(
            (d) => d >= startDayString && d <= endDayString
        );
        setExistingFlag(!!daysInRange?.length);
    };
    const handleCancel = () => {
        dispatch(setShowCopyScheduleModal(false));
    };

    const handleCopy = async () => {
        const lastDayOfWeek = endOfWeek(defaultSelectedDay);
        const copyFrom = String(
            moment(lastDayOfWeek).add(1, 'day').format('YYYY-MM-DD')
        );

        const result = await doCopy({
            SiteId: currentSiteId,
            CopyFrom: copyFrom,
            CopyTo: copyTo,
            UpdatedBy: userName,
            Overwrite: false,
        });
        setCopyDates([copyFrom, copyTo]);
        dispatch(setShowCopyScheduleModal(false));
        if (result.Conflict) {
            dispatch(setShowConflictConfirmModal(true));
        } else {
            dispatch(setScheduleWasCopied(true));
        }
    };
    const handleDateValidation = () => {
        if (date && date?.getTime() >= minDate.getTime()) setIsDateValid(true);
        else setIsDateValid(false);
    };

    const updateDate = (newDate: Date) => {
        setWeekRange();
        const getStartOfCopyToWeek = startOfWeek(newDate);

        setCopyTo(
            String(
                moment(getStartOfCopyToWeek)
                    .add(1, 'day')
                    .format('YYYY-MM-DDTHH:MM:SS')
            )
        );

        if (newDate) {
            updateHasExistingScheduleFlag();
            handleDateValidation();
        }
    };
    useEffect(() => {
        let mounted = true;
        if (date === null) {
            setIsDateValid(false);
        }
        if (mounted) {
            updateDate(date);
        }
        return () => {
            // prevents memory leak
            mounted = false;
        };
    }, [date]);

    return (
        <div id="copy-schedule-modal-wrapper">
            <Dialog
                open={isVisible}
                id="copy-schedule-modal"
                data-testid="copySchedule-modal"
                maxWidth={false}
                aria-labelledby="copy-schedule-modal-display"
                aria-describedby="copy-schedule-modal-body"
                PaperProps={{
                    style: {
                        overflowX: 'hidden',
                        overflowY: 'hidden',
                        width: '600px',
                        height: '380px',
                    },
                }}
            >
                <Header>
                    <Box
                        component="div"
                        data-testid="copy-schedule-modal-heading"
                        display="flex"
                        flexDirection="column"
                        width="100%"
                    >
                        <Typography
                            variant="subtitle1"
                            component="div"
                            sx={{
                                fontSize: '20px',
                                fontWeight: 500,
                            }}
                        >
                            <FormattedMessage id="App.Containers.CopySchedule.Copy" />
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            component="div"
                            sx={{
                                color: '#757575',
                                fontSize: '16px',
                            }}
                        >
                            {weekRangeTitle}
                        </Typography>
                    </Box>
                    <Tooltip title="cancel">
                        <span>
                            <IconButton
                                data-testid="close-icon"
                                sx={{
                                    float: 'right',
                                    width: '5%',
                                    pointerEvents: 'stroke',
                                    cursor: 'pointer',
                                }}
                                onClick={handleCancel}
                            >
                                <StyledCloseIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Header>
                <DialogContent dividers>
                    <DialogContentText
                        sx={{ fontSize: '18px', color: 'black' }}
                    >
                        <FormattedMessage id="App.Containers.CopySchedule.CopyTo" />
                    </DialogContentText>

                    <CopyScheduleDatePicker
                        defaultSelectedDay={defaultCalendarDate}
                        setNewDate={setNewDate}
                        minDate={minDate}
                        isMonthValid={isDateValid}
                    />
                    {existingFlag ? (
                        <Box
                            component="div"
                            data-testid="copy-schedule-copy-warning"
                            display="flex"
                            flexDirection="row"
                            width="100%"
                        >
                            <StyledWarningIcon />
                            <Typography
                                variant="body1"
                                component="div"
                                sx={{ marginTop: '12px' }}
                            >
                                <FormattedMessage id="App.Containers.CopySchedule.CopyWarning" />
                            </Typography>
                        </Box>
                    ) : (
                        <Box
                            component="div"
                            data-testid="copy-schedule-copy-clear"
                            display="flex"
                            flexDirection="row"
                            width="100%"
                        >
                            <StyledCheckIcon />
                            <Typography
                                variant="body1"
                                component="div"
                                sx={{ marginTop: '12px' }}
                            >
                                <FormattedMessage id="App.Containers.CopySchedule.CopyClear" />
                            </Typography>
                        </Box>
                    )}
                </DialogContent>

                <DialogActions>
                    <MainButton
                        sx={{ textTransform: 'uppercase' }}
                        onClick={handleCopy}
                        disabled={copyTo === 'Invalid date' || !isDateValid}
                        dataTestId="copy-button"
                    >
                        <FormattedMessage id="Common.Copy" />
                    </MainButton>
                    <MainButton
                        onClick={handleCancel}
                        sx={{ textTransform: 'uppercase' }}
                        dataTestId="cancel-button"
                    >
                        <FormattedMessage id="Common.Cancel" />
                    </MainButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CopySchedule;
