import { createContext, FC, useCallback, useEffect, useState } from 'react';

type ISideEffectContext = {
    sideEffect: (() => Promise<void> | void) | null;
    setSideEffect: (sideEffect: ISideEffectContext['sideEffect']) => void;
};

const defaultValue = {
    sideEffect: null,
    setSideEffect: () => {},
};

export const SideEffectContext =
    createContext<ISideEffectContext>(defaultValue);
const { Provider } = SideEffectContext;

/**
 * - TODO (Dan): if this usage of context scales, then look into using xState library, custom redux middleware/enhancer, or redux-saga/thunks. xState allows passing of callback more easily, but stores data separately from redux store (specifically, into context). Redux middleware/thunks/sagas may be overkill, but we need to be careful to intercept callbacks, and avoid storing them into the store
 * - PLEASE don't add more data into this context. If you want to store data publically, then do so in redux.
 * - The main purpose of this context is to store functions safely, as a work-around to storing functions in redux.
 *   - This is because storing non-serializable data (such as functions) may cause Redux to have issues with persistence and rehydration
 */
const SideEffectProvider: FC = ({ children }) => {
    const [sideEffectRaw, setSideEffectRaw] = useState<
        ISideEffectContext['sideEffect']
    >(() => defaultValue.sideEffect);

    const sideEffect = useCallback(async () => {
        await sideEffectRaw?.();
        setSideEffectRaw(null);
    }, [sideEffectRaw]);

    const setSideEffect = useCallback(
        (fn: ISideEffectContext['sideEffect']) => {
            setSideEffectRaw(() => fn);
        },
        []
    );

    useEffect(
        () => () => {
            setSideEffectRaw(null);
        },
        [setSideEffect]
    );

    return (
        <Provider value={{ sideEffect, setSideEffect }}>{children}</Provider>
    );
};

export default SideEffectProvider;
