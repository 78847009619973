import { GetReportsResponse } from 'services/ManagerOneApi';

export const mockedReportsData = new GetReportsResponse({
    Header: { Success: true, Statuses: [] },
    Reports: [
        {
            Name: 'Sales Summary',
            Description:
                'Provides a configurable summary of sales information including daily totals, sales categories, day parts, and others',
            ReportId: 'b4d61809-f045-4220-9d94-ab7f5d41c201',
            Owner: 'System',
            IsSystem: true,
            ReportType: 1,
        },
        {
            Name: 'Daily Voids',
            Description:
                'Displays detailed information on voids performed on the POS from the Front of House',
            ReportId: '9a635814-d108-4b9a-b2ce-f96d2ec816c9',
            Owner: 'System',
            IsSystem: true,
            ReportType: 1,
        },
        {
            Name: 'Hourly Sales & Labor',
            Description:
                'Provides sales and labor information based on a chosen time period, including the labor percentage as it pertains to net/gross sales',
            ReportId: 'b2bd03f2-01e3-4fef-a636-af256d74f7f6',
            Owner: 'System',
            IsSystem: true,
            ReportType: 1,
        },
        {
            Name: 'Chris Anderson',
            Description: 'Chris Test Report',
            ReportId: '721b2ac5-4d14-4734-9efe-904d36aec106',
            Owner: 'acct:int-acpng-m1-ts@cfc',
            IsSystem: false,
            ReportType: 2,
        },
        {
            Name: 'TBRR',
            Description: 'descrr',
            ReportId: 'bb759047-e76f-4ea7-b418-8d39f1a29251',
            Owner: 'acct:int-acpng-m1-ts@cfc',
            IsSystem: false,
            ReportType: 2,
        },
        {
            Name: 'Automated Report',
            Description: 'Report with all sections added except Audit',
            ReportId: 'd2237a12-5095-4049-b2fa-a42bab4623d0',
            Owner: 'acct:int-acpng-m1-ts@cfc',
            IsSystem: false,
            ReportType: 2,
        },
    ],
});
