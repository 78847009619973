import { FC, SyntheticEvent, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tab, Tabs, Toolbar } from '@mui/material';
import {
    GetOtherWagesDataResponse,
    GetPunchedShiftDataResponse,
} from 'services/ManagerOneApi';
import moment from 'moment';
import { dateToIsoString } from 'services/utils.service';
import useSelect from 'state/selector';
import ShiftsTable from '../Shift';
import PunchToolbar from './PunchToolbar';
import OtherWagesTable from '../OtherWages';

/**
 * prohibits editing shifts that are too far in the past, relative to now (ie `new Date()`)
 */
export const getFirstEditableDay = (firstConfigurationDay: string): string => {
    const maxWeeksAllowed = 5;
    const now = new Date();
    const earliestEditableDay = new Date(now.getDate() - maxWeeksAllowed * 7);
    return moment(earliestEditableDay).isBefore(firstConfigurationDay)
        ? dateToIsoString(new Date(firstConfigurationDay))
        : dateToIsoString(earliestEditableDay);
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Article = styled.article`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 15px 24px;
`;

const BoxContainer = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
        0 1px 5px 0 rgb(0 0 0 / 12%);
`;

const ToolbarRoot = styled.section`
    padding: 0 !important;
`;

export enum PunchTabIndex {
    Shifts = 0,
    OtherWages = 1,
}

type TabContentProps = {
    renderOnIndex: PunchTabIndex;
    selectedIndex: PunchTabIndex;
};

const TabContent: FC<TabContentProps> = ({
    renderOnIndex,
    selectedIndex,
    children,
}) => <>{renderOnIndex === selectedIndex && children}</>;

export type Props = {
    shiftsData?: GetPunchedShiftDataResponse;
    otherWagesData?: GetOtherWagesDataResponse;
    lastBusinessDay?: string;
    firstConfigurationDay?: string;
};

const PunchEditor: FC<Props> = ({
    shiftsData,
    otherWagesData,
    lastBusinessDay = dateToIsoString(new Date()),
    firstConfigurationDay = dateToIsoString(new Date()),
}) => {
    const { formatMessage } = useIntl();
    const businessDay = useSelect((s) => s.punchEditor.selectedBusinessDay);

    const firstEditableDay = getFirstEditableDay(firstConfigurationDay);

    const inEditableRange = moment(businessDay).isBetween(
        firstEditableDay,
        lastBusinessDay,
        undefined,
        '[)'
    );

    const canEdit = !!shiftsData && inEditableRange;
    const canAddShift = canEdit && !!shiftsData?.Employees?.length;

    const [selectedTab, setSelectedTab] = useState<PunchTabIndex>(
        PunchTabIndex.Shifts
    );

    const onTabChange = (_: SyntheticEvent, newTab: PunchTabIndex): void =>
        setSelectedTab(newTab);

    return (
        <Root>
            {!canEdit && (
                <section role="banner" className="banner-message">
                    <FormattedMessage id="App.Containers.Punch.ViewOnly" />
                </section>
            )}
            <Article>
                <PunchToolbar
                    hasShiftsData={!!shiftsData}
                    businessDay={businessDay}
                    firstConfigurationDay={firstConfigurationDay}
                    lastBusinessDay={lastBusinessDay}
                    canAddShift={canAddShift}
                    selectedTab={selectedTab}
                />
                <BoxContainer
                    aria-label={formatMessage({
                        id: 'App.Containers.Punch.Editor',
                    })}
                >
                    <Toolbar component={ToolbarRoot}>
                        <Tabs value={selectedTab} onChange={onTabChange}>
                            <Tab
                                label={formatMessage({
                                    id: 'App.Containers.Punch.ShiftsTab',
                                })}
                                aria-controls="shifts-tabpanel"
                            />
                            <Tab
                                label={formatMessage({
                                    id: 'App.Containers.Punch.OtherWagesTab',
                                })}
                                aria-controls="other-wages-tabpanel"
                            />
                        </Tabs>
                    </Toolbar>
                    <TabContent
                        renderOnIndex={PunchTabIndex.Shifts}
                        selectedIndex={selectedTab}
                    >
                        <ShiftsTable
                            shifts={shiftsData?.Shifts}
                            canEdit={canEdit}
                        />
                    </TabContent>
                    <TabContent
                        renderOnIndex={PunchTabIndex.OtherWages}
                        selectedIndex={selectedTab}
                    >
                        <OtherWagesTable
                            otherWages={otherWagesData?.OtherWages}
                            canEdit={canEdit}
                        />
                    </TabContent>
                </BoxContainer>
            </Article>
        </Root>
    );
};

export default PunchEditor;
