import { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Close from '@mui/icons-material/Close';
import MainButton from 'components/MainButton';
import { FormattedMessage } from 'react-intl';
import useAppDispatch from 'state/dispatch';
import { setIsConfirmingDelivery } from 'state/schedulerSlice';
import { rawIntl } from 'services/intl';
import styled from 'styled-components';
import { deliverSchedules } from 'services/api';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';

const { formatMessage } = rawIntl;

const DialogRoot = styled(Paper)`
    border-radius: 4px;
`;

const Header = styled(DialogTitle)`
    display: flex;
    justify-content: space-between;
`;

type Props = {
    save: () => Promise<void>;
};

const ConfirmDeliveryModal: FC<Props> = ({ save }) => {
    const dispatch = useAppDispatch();
    const siteId = useSelector((s: RootState) => s.sitesInfo.selectedSiteId);

    const close = () => dispatch(setIsConfirmingDelivery(false));

    const confirm = async () => {
        close();
        deliverSchedules(siteId);
        await save();
    };

    return (
        <Dialog
            open
            data-testid="confirm-delivery-modal"
            aria-labelledby="confirm-delivery-title"
            aria-describedby="confirm-delivery-message"
            onClose={close}
            PaperComponent={DialogRoot}
        >
            <Header>
                <section id="confirm-delivery-title">
                    <FormattedMessage id="App.Containers.Scheduler.ConfirmDelivery" />
                </section>
                <Tooltip title={formatMessage({ id: 'Common.Cancel' })}>
                    <IconButton onClick={close}>
                        <Close />
                    </IconButton>
                </Tooltip>
            </Header>
            <DialogContent>
                <DialogContentText id="confirm-delivery-message">
                    <FormattedMessage id="App.Containers.Scheduler.ConfirmDeliveryMessage" />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <MainButton
                    variant="text"
                    data-testid="confirm-schedule-delivery"
                    aria-label={formatMessage({ id: 'Common.Yes' })}
                    onClick={confirm}
                    sx={{ textTransform: 'uppercase' }}
                >
                    <FormattedMessage id="Common.Yes" />
                </MainButton>
                <MainButton
                    variant="text"
                    data-testid="no-schedule-delivery"
                    aria-label={formatMessage({ id: 'Common.No' })}
                    onClick={close}
                    sx={{ textTransform: 'uppercase' }}
                >
                    <FormattedMessage id="Common.No" />
                </MainButton>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeliveryModal;
