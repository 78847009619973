import { GetDashboardResponse } from 'services/ManagerOneApi';

export const dashboardData = new GetDashboardResponse({
    BusinessDay: '2021-10-06T00:00:00',
    Weather: {
        Header: {
            MessageID: '',
            StackTrace: '',
            Success: true,
            Statuses: [],
        },
        Icon: '02d',
        Description: 'Clouds',
        Temp: 283,
        Site: {
            Name: 'Padma TS Store',
            Number: '301',
            AddressLine1: '100 North 40 Street',
            AddressLine2: 'suite 301',
            TelephoneNumber1: '972-650-1222',
            TelephoneNumber2: '',
            CityOrTown: 'Dallas',
            State: 'TX',
            County: '',
            PostalCode: '76203',
            SiteTimeZone: 'CST6CDT',
        },
        TimeZone: '-05',
    },
    NetSales: {
        NetSales: 133.71,
        BusinessDay: '2021-10-06T00:00:00',
        NetSalesByRevenueCenter: { 'Dine In': 133.71 },
        GuestAvgSales: 16.71375,
        CheckAvgSales: 26.742,
        TotalGuests: 8.0,
        TotalChecks: 5.0,
        TotalReturns: 0.0,
    },
    LaborPay: {
        RegularPay: 0.0,
        OvertimePay: 0.0,
        TotalPay: 0.0,
        PaidBreakPay: 0.0,
        BusinessDay: '2021-10-06T00:00:00',
    },
    Products: {
        Categories: [
            {
                Id: '1',
                Name: 'FOOD',
                TotalAmount: 98.71,
                TotalQuantity: 21.0,
                Products: [
                    {
                        CategoryId: '1',
                        Name: 'Pork Chops',
                        Quantity: 3.0,
                        Amount: 21.0,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Grilled Chicken',
                        Quantity: 1.0,
                        Amount: 8.75,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Coke-Long Name',
                        Quantity: 2.0,
                        Amount: 5.0,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Mashed Potatoes',
                        Quantity: 1.0,
                        Amount: 1.5,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Cajun Chicken',
                        Quantity: 2.0,
                        Amount: 19.0,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Filet Mignon',
                        Quantity: 1.0,
                        Amount: 14.99,
                    },
                    {
                        CategoryId: '1',
                        Name: '30pc Wings',
                        Quantity: 1.0,
                        Amount: 20.97,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Corn',
                        Quantity: 3.0,
                        Amount: 1.5,
                    },
                    {
                        CategoryId: '1',
                        Name: 'French Fries',
                        Quantity: 3.0,
                        Amount: 2.0,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Baked Potato',
                        Quantity: 2.0,
                        Amount: 2.5,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Rice Pilaf',
                        Quantity: 2.0,
                        Amount: 1.5,
                    },
                ],
            },
            {
                Id: '5',
                Name: 'N/A BEVERAGE',
                TotalAmount: 35.0,
                TotalQuantity: 16.0,
                Products: [
                    {
                        CategoryId: '5',
                        Name: 'Sprite',
                        Quantity: 3.0,
                        Amount: 7.5,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Ice Tea',
                        Quantity: 2.0,
                        Amount: 5.0,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Hot Tea',
                        Quantity: 1.0,
                        Amount: 1.5,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Root Beer',
                        Quantity: 3.0,
                        Amount: 7.5,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Coffee',
                        Quantity: 1.0,
                        Amount: 1.5,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Decaf',
                        Quantity: 3.0,
                        Amount: 4.5,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Diet Coke',
                        Quantity: 3.0,
                        Amount: 7.5,
                    },
                ],
            },
        ],
        BusinessDay: '2021-10-06T00:00:00',
    },
    Voids: {
        Voids: [{ Reason: 'Did Not Order', Quantity: 1, Amount: 1.5 }],
        BusinessDay: '2021-10-06T00:00:00',
        TotalQuantity: 1,
        TotalAmount: 1.5,
    },
    SalesByOrder: {
        BusinessDay: '2021-10-06T00:00:00',
        salesByOrder: {
            'Dine-In': 133.71,
            'To Go': 52.71,
            Delivery: 234.12,
            'Walk-In': 90.03,
        },
        Refunds: 0.0,
    },
});

export const emptyDashboardData = new GetDashboardResponse({
    BusinessDay: '2021-04-27T00:00:00',
    Weather: {
        Header: {
            MessageID: '',
            StackTrace: '',
            Success: true,
            Statuses: [],
        },
        Icon: '',
        Description: '',
        Temp: 0,
        Site: {
            Name: 'DEV3',
            Number: '3',
            AddressLine1: '3 South Batavia Avenue',
            AddressLine2: 'Batavia, Illinois 60510',
            TelephoneNumber1: '630-482-3663',
            TelephoneNumber2: '',
            CityOrTown: '',
            State: '',
            County: '',
            PostalCode: '',
            SiteTimeZone: 'Europe/Prague',
        },
        TimeZone: '+02',
    },
    NetSales: {
        NetSales: 0.0,
        BusinessDay: '2021-04-27T00:00:00',
        NetSalesByRevenueCenter: {},
        GuestAvgSales: 0.0,
        CheckAvgSales: 0.0,
        TotalGuests: 0.0,
        TotalChecks: 0.0,
        TotalReturns: 0.0,
    },
    LaborPay: {
        RegularPay: 0.0,
        OvertimePay: 0.0,
        TotalPay: 0.0,
        PaidBreakPay: 0.0,
        BusinessDay: '2021-04-27T00:00:00',
    },
    Products: { Categories: [], BusinessDay: '2021-04-27T00:00:00' },
    Voids: {
        Voids: [],
        BusinessDay: '2021-04-27T00:00:00',
        TotalQuantity: 0,
        TotalAmount: 0.0,
    },
    SalesByOrder: {
        BusinessDay: '2021-04-27T00:00:00',
        salesByOrder: {},
        Refunds: 0.0,
    },
});

export const dashboardDataSite3 = new GetDashboardResponse({
    BusinessDay: '2021-10-06T00:00:00',
    Weather: {
        Header: {
            MessageID: '',
            StackTrace: '',
            Success: true,
            Statuses: [],
        },
        Icon: '02d',
        Description: 'Clouds',
        Temp: 283,
        Site: {
            Name: 'Site 3',
            Number: '301',
            AddressLine1: '100 North 40 Street',
            AddressLine2: 'suite 301',
            TelephoneNumber1: '972-650-1222',
            TelephoneNumber2: '',
            CityOrTown: 'Dallas',
            State: 'TX',
            County: '',
            PostalCode: '76203',
            SiteTimeZone: 'CST6CDT',
        },
        TimeZone: '-05',
    },
    NetSales: {
        NetSales: 133.71,
        BusinessDay: '2021-10-06T00:00:00',
        NetSalesByRevenueCenter: { 'Dine In': 133.71 },
        GuestAvgSales: 16.71375,
        CheckAvgSales: 26.742,
        TotalGuests: 8.0,
        TotalChecks: 5.0,
        TotalReturns: 0.0,
    },
    LaborPay: {
        RegularPay: 0.0,
        OvertimePay: 0.0,
        TotalPay: 0.0,
        PaidBreakPay: 0.0,
        BusinessDay: '2021-10-06T00:00:00',
    },
    Products: {
        Categories: [
            {
                Id: '1',
                Name: 'FOOD',
                TotalAmount: 98.71,
                TotalQuantity: 21.0,
                Products: [
                    {
                        CategoryId: '1',
                        Name: 'Pork Chops',
                        Quantity: 3.0,
                        Amount: 21.0,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Grilled Chicken',
                        Quantity: 1.0,
                        Amount: 8.75,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Coke-Long Name',
                        Quantity: 2.0,
                        Amount: 5.0,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Mashed Potatoes',
                        Quantity: 1.0,
                        Amount: 1.5,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Cajun Chicken',
                        Quantity: 2.0,
                        Amount: 19.0,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Filet Mignon',
                        Quantity: 1.0,
                        Amount: 14.99,
                    },
                    {
                        CategoryId: '1',
                        Name: '30pc Wings',
                        Quantity: 1.0,
                        Amount: 20.97,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Corn',
                        Quantity: 3.0,
                        Amount: 1.5,
                    },
                    {
                        CategoryId: '1',
                        Name: 'French Fries',
                        Quantity: 3.0,
                        Amount: 2.0,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Baked Potato',
                        Quantity: 2.0,
                        Amount: 2.5,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Rice Pilaf',
                        Quantity: 2.0,
                        Amount: 1.5,
                    },
                ],
            },
            {
                Id: '5',
                Name: 'N/A BEVERAGE',
                TotalAmount: 35.0,
                TotalQuantity: 16.0,
                Products: [
                    {
                        CategoryId: '5',
                        Name: 'Sprite',
                        Quantity: 3.0,
                        Amount: 7.5,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Ice Tea',
                        Quantity: 2.0,
                        Amount: 5.0,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Hot Tea',
                        Quantity: 1.0,
                        Amount: 1.5,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Root Beer',
                        Quantity: 3.0,
                        Amount: 7.5,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Coffee',
                        Quantity: 1.0,
                        Amount: 1.5,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Decaf',
                        Quantity: 3.0,
                        Amount: 4.5,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Diet Coke',
                        Quantity: 3.0,
                        Amount: 7.5,
                    },
                ],
            },
        ],
        BusinessDay: '2021-10-06T00:00:00',
    },
    Voids: {
        Voids: [{ Reason: 'Did Not Order', Quantity: 1, Amount: 1.5 }],
        BusinessDay: '2021-10-06T00:00:00',
        TotalQuantity: 1,
        TotalAmount: 1.5,
    },
    SalesByOrder: {
        BusinessDay: '2021-10-06T00:00:00',
        salesByOrder: {
            'Dine-In': 133.71,
            'To Go': 52.71,
            Delivery: 234.12,
            'Walk-In': 90.03,
        },
        Refunds: 0.0,
    },
});

export const dashboardDataSite4 = new GetDashboardResponse({
    BusinessDay: '2021-10-06T00:00:00',
    Weather: {
        Header: {
            MessageID: '',
            StackTrace: '',
            Success: true,
            Statuses: [],
        },
        Icon: '02d',
        Description: 'Clouds',
        Temp: 283,
        Site: {
            Name: 'Site 4',
            Number: '301',
            AddressLine1: '100 North 40 Street',
            AddressLine2: 'suite 301',
            TelephoneNumber1: '972-650-1222',
            TelephoneNumber2: '',
            CityOrTown: 'Dallas',
            State: 'TX',
            County: '',
            PostalCode: '76203',
            SiteTimeZone: 'CST6CDT',
        },
        TimeZone: '-05',
    },
    NetSales: {
        NetSales: 133.71,
        BusinessDay: '2021-10-06T00:00:00',
        NetSalesByRevenueCenter: { 'Dine In': 133.71 },
        GuestAvgSales: 16.71375,
        CheckAvgSales: 26.742,
        TotalGuests: 8.0,
        TotalChecks: 5.0,
        TotalReturns: 0.0,
    },
    LaborPay: {
        RegularPay: 0.0,
        OvertimePay: 0.0,
        TotalPay: 0.0,
        PaidBreakPay: 0.0,
        BusinessDay: '2021-10-06T00:00:00',
    },
    Products: {
        Categories: [
            {
                Id: '1',
                Name: 'FOOD',
                TotalAmount: 98.71,
                TotalQuantity: 21.0,
                Products: [
                    {
                        CategoryId: '1',
                        Name: 'Pork Chops',
                        Quantity: 3.0,
                        Amount: 21.0,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Grilled Chicken',
                        Quantity: 1.0,
                        Amount: 8.75,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Coke-Long Name',
                        Quantity: 2.0,
                        Amount: 5.0,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Mashed Potatoes',
                        Quantity: 1.0,
                        Amount: 1.5,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Cajun Chicken',
                        Quantity: 2.0,
                        Amount: 19.0,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Filet Mignon',
                        Quantity: 1.0,
                        Amount: 14.99,
                    },
                    {
                        CategoryId: '1',
                        Name: '30pc Wings',
                        Quantity: 1.0,
                        Amount: 20.97,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Corn',
                        Quantity: 3.0,
                        Amount: 1.5,
                    },
                    {
                        CategoryId: '1',
                        Name: 'French Fries',
                        Quantity: 3.0,
                        Amount: 2.0,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Baked Potato',
                        Quantity: 2.0,
                        Amount: 2.5,
                    },
                    {
                        CategoryId: '1',
                        Name: 'Rice Pilaf',
                        Quantity: 2.0,
                        Amount: 1.5,
                    },
                ],
            },
            {
                Id: '5',
                Name: 'N/A BEVERAGE',
                TotalAmount: 35.0,
                TotalQuantity: 16.0,
                Products: [
                    {
                        CategoryId: '5',
                        Name: 'Sprite',
                        Quantity: 3.0,
                        Amount: 7.5,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Ice Tea',
                        Quantity: 2.0,
                        Amount: 5.0,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Hot Tea',
                        Quantity: 1.0,
                        Amount: 1.5,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Root Beer',
                        Quantity: 3.0,
                        Amount: 7.5,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Coffee',
                        Quantity: 1.0,
                        Amount: 1.5,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Decaf',
                        Quantity: 3.0,
                        Amount: 4.5,
                    },
                    {
                        CategoryId: '5',
                        Name: 'Diet Coke',
                        Quantity: 3.0,
                        Amount: 7.5,
                    },
                ],
            },
        ],
        BusinessDay: '2021-10-06T00:00:00',
    },
    Voids: {
        Voids: [{ Reason: 'Did Not Order', Quantity: 1, Amount: 1.5 }],
        BusinessDay: '2021-10-06T00:00:00',
        TotalQuantity: 1,
        TotalAmount: 1.5,
    },
    SalesByOrder: {
        BusinessDay: '2021-10-06T00:00:00',
        salesByOrder: {
            'Dine-In': 133.71,
            'To Go': 52.71,
            Delivery: 234.12,
            'Walk-In': 90.03,
        },
        Refunds: 0.0,
    },
});
