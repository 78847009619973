import { FC, useState, useEffect } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { GetDashboardResponse } from 'services/ManagerOneApi';
import CardContent from '@mui/material/CardContent';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import DataChart from './DataChart';
import NoDataChart from './NoDataChart';
import {
    ChartCenterLabel,
    ChartHeader,
    ChartSubHeader,
    DashboardCard,
    IDashboardData,
} from '../index';
import { LaborCardi18n } from './i18n';

const LaborOvertimePayCard: FC<IDashboardData> = ({ dashboardData }) => {
    const [dataRows, setDataRows] = useState<(string | number)[][]>([]);
    const [hasData, setHasData] = useState<boolean>(false);
    const loading = useSelector((s: RootState) => s.common.loading);
    const [laborTotal, setTotalLabor] = useState<number>(0.0);

    const updateData = (data: GetDashboardResponse) => {
        try {
            setTotalLabor(data.LaborPay.TotalPay);

            if (data.LaborPay.TotalPay === 0) {
                setHasData(false);
            } else {
                const graphData = [
                    [LaborCardi18n.payType, LaborCardi18n.amount],
                    [LaborCardi18n.regularPay, data.LaborPay.RegularPay],
                    [LaborCardi18n.overtimePay, data.LaborPay.OvertimePay],
                    [LaborCardi18n.paidBreaks, data.LaborPay.PaidBreakPay],
                ];
                setDataRows(graphData);
                setHasData(true);
            }
        } catch (e) {
            setHasData(false);
        }
    };

    useEffect(() => {
        updateData(dashboardData);
    }, [dashboardData]);

    let height = '305px';
    let width = '305px';

    if (dataRows && hasData) {
        height = '320px';
        width = '320px';
    }

    return (
        <DashboardCard sx={{ paddingTop: 0 }}>
            {!loading && (
                <CardContent>
                    <ChartCenterLabel
                        sx={{
                            height,
                            width,
                        }}
                    >
                        <ChartHeader id="labor-total">
                            <FormattedNumber
                                value={laborTotal}
                                style="currency"
                                currency="USD"
                                maximumFractionDigits={2}
                                minimumFractionDigits={2}
                            />
                        </ChartHeader>
                        <ChartSubHeader>
                            <FormattedMessage id="App.Containers.LaborCard.TotalLabor" />
                        </ChartSubHeader>
                    </ChartCenterLabel>
                    {dataRows && hasData ? (
                        <DataChart dataRows={dataRows} />
                    ) : (
                        <NoDataChart />
                    )}
                </CardContent>
            )}
        </DashboardCard>
    );
};

export default LaborOvertimePayCard;
