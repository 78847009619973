import { FC, useEffect, useState } from 'react';
import { GetDashboardResponse } from 'services/ManagerOneApi';
import { formatCurrency } from 'services/utils.service';
import { rawIntl } from 'services/intl';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import DataChart from './DataChart';
import NoDataChart from './NoDataChart';
import {
    ChartCenterLabel,
    ChartHeader,
    ChartSubHeader,
    DashboardCard,
    IDashboardData,
} from '../index';

const OrderModeSalesCard: FC<IDashboardData> = ({ dashboardData }) => {
    const [dataRows, setDataRows] = useState<(string | number)[][]>([]);
    const [netSalesTotal, setNetSalesTotal] = useState<string>('$0.00');
    const [hasData, setHasData] = useState<boolean>(false);
    const loading = useSelector((s: RootState) => s.common.loading);
    const [hasRefunds, setHasRefunds] = useState<boolean>(false);

    const refunds = dashboardData?.SalesByOrder?.Refunds;

    const resetData = () => {
        setDataRows([]);
        setNetSalesTotal('$0.00');
        setHasData(false);
    };

    const i18n = {
        orderModeTitle: rawIntl.formatMessage({
            id: 'App.Containers.OrderModeSalesCard.DataChart',
        }),
    };

    const updateData = (data: GetDashboardResponse) => {
        setHasRefunds(refunds < 0);
        try {
            const netSales = formatCurrency(data.NetSales.NetSales);
            if (data.NetSales.NetSales > 0) {
                const salesBreakdown = data.SalesByOrder.salesByOrder;

                const dataRows2 = Object.keys(salesBreakdown).map((key) => [
                    key,
                    salesBreakdown[key],
                ]);
                setDataRows(dataRows2);
                setNetSalesTotal(netSales);
                setHasData(true);
            } else {
                resetData();
            }
        } catch (e) {
            resetData();
        }
    };

    useEffect(() => {
        updateData(dashboardData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardData]);

    let height = '305px';

    if (hasRefunds) {
        height = '270px';
    } else if (dataRows && hasData) height = '290px';

    return (
        <DashboardCard
            sx={{
                paddingTop: '0px',
                height: '304px',
            }}
        >
            {!loading && (
                <>
                    <ChartCenterLabel
                        sx={{
                            height,
                            marginTop: dataRows && hasData ? '8px' : '0px',
                        }}
                    >
                        <ChartHeader id="sales-by-order-mode-total">
                            {netSalesTotal}
                        </ChartHeader>
                        <ChartSubHeader>Net Sales</ChartSubHeader>
                    </ChartCenterLabel>
                    {dataRows && hasData ? (
                        <DataChart
                            dataRows={dataRows}
                            hasMoneyBack={hasRefunds}
                            moneyBack={refunds}
                            orderMode
                        />
                    ) : (
                        <NoDataChart title={i18n.orderModeTitle} />
                    )}
                </>
            )}
        </DashboardCard>
    );
};

export default OrderModeSalesCard;
