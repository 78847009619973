import { FC, useRef } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import styled from 'styled-components';
import { rawIntl } from 'services/intl';
import MainButton from 'components/MainButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SMALL } from 'styles/responsive_breakpoints';

const { formatMessage } = rawIntl;

/**
 * I know that we can use declarative intl api, but this object would make the jsx more concise.
 * Plus, for react intl specifically, imperative api has better performance than that of declarative api anyway (though the difference is probably negligable).
 */
const messages = {
    textFilter: formatMessage({
        id: 'App.Containers.GuestChecks.TextFilter',
    }),
    search: formatMessage({ id: 'Common.Search' }),
    amountFilters: formatMessage({
        id: 'App.Containers.GuestChecks.AmountFilters',
    }),
    timeFilters: formatMessage({
        id: 'App.Containers.GuestChecks.TimeFilters',
    }),
    clear: formatMessage({
        id: 'App.Containers.GuestChecks.Clear',
    }),
    from: formatMessage({ id: 'Common.From' }),
    to: formatMessage({ id: 'Common.To' }),
};

/**
 * This is for preventing 'grid blowouts' issues.
 */
const fr = (num = 1) => `minmax(80px, ${num}fr)`;

const FiltersRoot = styled.section`
    display: grid;
    grid-template-areas:
        'txt    . amt   amt   . tm   tm   . .'
        'search . amtFr amtTo . tmFr tmTo . clr';
    grid-template-columns: ${fr(2)} 0 ${fr()} ${fr()} 0 ${fr()} ${fr()} 0 80px;
    gap: 7px 15px;

    @media (max-width: ${SMALL}) {
        grid-template-areas:
            'txt    .'
            'search clr';
        grid-template-columns: ${fr(2)} ${fr()};
        gap: 7px 30px;
    }
`;

const FilterInput: FC<TextFieldProps> = (props) => (
    <TextField
        variant="outlined"
        size="small"
        InputProps={{ sx: { borderRadius: '4px' } }}
        {...props}
    />
);

const TextSearch = styled(FilterInput)`
    grid-area: search;
`;

const AmountFrom = styled(FilterInput)`
    grid-area: amtFr;
`;

const AmountTo = styled(FilterInput)`
    grid-area: amtTo;
`;

const TimeFrom = styled(FilterInput)`
    grid-area: tmFr;
`;

const TimeTo = styled(FilterInput)`
    grid-area: tmTo;
`;

const FilterLabel = styled.div`
    font-weight: bold;
    line-height: 100%;
`;

const TextLabel = styled(FilterLabel)`
    grid-area: txt;
`;

const AmountLabel = styled(FilterLabel)`
    grid-area: amt;
`;

const TimeLabel = styled(FilterLabel)`
    grid-area: tm;
`;

const CheckFilters: FC = () => {
    const exceedsSm = useMediaQuery(`(min-width: ${SMALL})`);

    // TODO: These values will be dispatched. Confirm if the dispatches will trigger re-rendering (ie guest checks table is correctly filtered). If not, then useState instead of useRef. Else, just keep using ref
    const search = useRef<HTMLInputElement>(null);
    const amtFr = useRef<HTMLInputElement>(null);
    const amtTo = useRef<HTMLInputElement>(null);
    const tmFr = useRef<HTMLInputElement>(null);
    const tmTo = useRef<HTMLInputElement>(null);

    const clearFilters = () => {
        [search, amtFr, amtTo, tmFr, tmTo].forEach(({ current }) => {
            if (current) {
                // eslint-disable-next-line no-param-reassign
                current.value = '';
            }
        });
    };

    return (
        <FiltersRoot>
            <TextLabel>{messages.textFilter}</TextLabel>
            <TextSearch placeholder={messages.search} inputRef={search} />
            {exceedsSm && (
                <>
                    <AmountLabel>{messages.amountFilters}</AmountLabel>
                    <AmountFrom placeholder={messages.from} inputRef={amtFr} />
                    <AmountTo placeholder={messages.to} inputRef={amtTo} />
                </>
            )}
            {exceedsSm && (
                <>
                    <TimeLabel>{messages.timeFilters}</TimeLabel>
                    <TimeFrom placeholder={messages.from} inputRef={tmFr} />
                    <TimeTo placeholder={messages.to} inputRef={tmTo} />
                </>
            )}
            <MainButton
                variant="outlined"
                onClick={clearFilters}
                sx={{
                    gridArea: 'clr',
                    borderRadius: '4px',
                    textTransform: 'uppercase',
                }}
            >
                {messages.clear}
            </MainButton>
        </FiltersRoot>
    );
};

export default CheckFilters;
