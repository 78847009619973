import { ManagerOne } from 'services/ManagerOneApi/ManagerOne.API';

export const daysOfWeek = [
    'Day1',
    'Day2',
    'Day3',
    'Day4',
    'Day5',
    'Day6',
    'Day7',
] as const;

const isDayOfWeek = (data: unknown): data is typeof daysOfWeek[number] => {
    if (typeof data === 'string') {
        return !!(daysOfWeek as readonly string[]).includes(data);
    }
    return false;
};

export const sortScheduleData = (
    array: ManagerOne.API.Labor.Models.Scheduler.EmployeeSchedule[],
    orderBy: string,
    order: string
): ManagerOne.API.Labor.Models.Scheduler.EmployeeSchedule[] => {
    if (array === undefined) return array;
    const tempArray = [...array];
    tempArray?.sort((a, b) => {
        if (orderBy === 'Name') {
            const first = a.Employee[orderBy] || '';
            const second = b.Employee[orderBy] || '';
            if (order === 'asc') return first.localeCompare(second);
            return second.localeCompare(first);
        }
        if (orderBy === 'JobTitle') {
            const first = a.Employee.Jobs?.[0]?.[orderBy] || '';
            const second = b.Employee.Jobs?.[0]?.[orderBy] || '';
            if (order === 'asc') return first.localeCompare(second);
            return second.localeCompare(first);
        }

        if (orderBy === 'TotalHours') {
            const first = a[orderBy];
            const second = b[orderBy];
            if (order === 'asc') return first - second;
            return second - first;
        }
        // DAYS OF WEEK
        if (isDayOfWeek(orderBy)) {
            const first: unknown[] = a[orderBy];
            const second: unknown[] = b[orderBy];
            if (order === 'asc') {
                const isFirstEmpty = first.length === 0;
                const isSecondEmpty = second.length === 0;
                if (isFirstEmpty && isSecondEmpty) return 0;
                if (isFirstEmpty && !isSecondEmpty) return 1;
                return -1;
            }
            // else, desc
            const isFirstEmpty = first.length === 0;
            const isSecondEmpty = second.length === 0;
            if (isFirstEmpty && isSecondEmpty) return 0;
            if (isFirstEmpty && !isSecondEmpty) return -1;
            return 1;
        }

        return 0;
    });

    return tempArray;
};
