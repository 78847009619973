import { rawIntl } from '../../services/intl';

const messages = {
    functions: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.Functions',
    }),
    deposits: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.EditDeposits',
    }),
    punches: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.EditPunches',
    }),
    scheduler: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.Scheduler',
    }),
    guestChecks: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.GuestChecks',
    }),
    houseAccounts: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.HouseAccounts',
    }),
    tipShare: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.TipShare',
    }),
    reports: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.Reports',
    }),
    utilities: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.Utilities',
    }),
    menu: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.Menu',
    }),
    menuItems: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.MenuItems',
    }),
    menuItemRouting: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.MenuItemRouting',
    }),
    modifierGroups: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.ModifierGroups',
    }),
    menuCategories: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.MenuCategories',
    }),
    panels: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.Panels',
    }),
    submenus: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.Submenus',
    }),
    employees: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.Employees',
    }),
    users: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.Users',
    }),
    dashboard: rawIntl.formatMessage({
        id: 'App.Components.SidebarNavigation.Routes.Dashboard',
    }),
};

export default messages;
