import { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Loader from 'components/Loader';
import { RootState } from 'state/reducers';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Props {}

/**
 * The angular app used this variation of the loader mask in only one component. You should prob use the regular LoaderMask instead
 * TODO: after migrating all of styles, look into refactoring this component to only use LoaderMask
 */

export interface LoaderMaskProps {
    isSignInModalVisible: boolean;
}

const ContentContainer = styled.div<LoaderMaskProps>`
    left: ${(props) => (props.isSignInModalVisible ? '20px' : '0px')};
    top: ${(props) => (props.isSignInModalVisible ? '30px' : '0px')};
    margin-left: ${(props) =>
        props.isSignInModalVisible ? '-20px' : '0px'} !important;
    margin-top: ${(props) =>
        props.isSignInModalVisible ? '-30px' : '0px'} !important;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3000;
    opacity: 0.5;
    background-color: #535453;
`;

export const FullScreenLoaderMask: FC<Props> = () => {
    const isSignInModalVisible = useSelector(
        (state: RootState) => state.user.isSignInModalVisible
    );

    return (
        <ContentContainer isSignInModalVisible={isSignInModalVisible}>
            <Loader />
        </ContentContainer>
    );
};
