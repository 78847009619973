import { FC } from 'react';
import styled from 'styled-components';
import TableContent from './TableContent';
import FunctionBar from './FunctionBar';
import ReportInformationForm from './ReportInformationForm';

const Container = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
`;

const ReportBuilder: FC = () => (
    <Container>
        <FunctionBar />
        <ReportInformationForm />
        <TableContent />
    </Container>
);
export default ReportBuilder;
