import { FC } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { rawIntl } from 'services/intl';
import { SMALL, MEDIUM } from 'styles/responsive_breakpoints';
import { ROUTES } from 'routes';

const Header = styled(DialogTitle)`
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    margin-left: 10px !important;
    @media (max-width: ${SMALL}) {
        margin-left: -10px !important;
    }
    @media (max-width: ${MEDIUM}) {
        margin-left: -10px !important;
    }
`;
const { formatMessage } = rawIntl;
export const messages = {
    save: formatMessage({ id: 'Common.Save' }),
    cancel: formatMessage({ id: 'Common.Cancel' }),
    reportNameErrorMessage: formatMessage({
        id: 'App.Containers.Reports.ReportNameRequired',
    }),
    descriptionErrorMessage: formatMessage({
        id: 'App.Containers.Reports.DescriptionRequired',
    }),
    reportBuilderTitle: formatMessage({
        id: 'App.Containers.Reports.ReportBuilder.Title',
    }),
};

const FunctionBar: FC = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Header>
                <Box component="div"> {messages.reportBuilderTitle}</Box>
                <Box
                    component="div"
                    id="reports-save-icon"
                    sx={{
                        marginLeft: 'auto',
                        marginTop: '-7px',
                    }}
                >
                    <Tooltip title={messages.save}>
                        <span>
                            <IconButton disabled data-testid="save-icon">
                                <SaveIcon fontSize="medium" />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={messages.cancel}>
                        <span>
                            <IconButton
                                data-testid="close-icon"
                                onClick={() => {
                                    navigate(ROUTES.REPORTS.BASE);
                                }}
                                sx={{
                                    pointerEvents: 'stroke',
                                    color: 'black',
                                }}
                            >
                                <CloseIcon fontSize="medium" />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Header>
        </div>
    );
};

export default FunctionBar;
