import { FC } from 'react';
import { IconButton, IconButtonProps, TableCell, Tooltip } from '@mui/material';
import { Create, Delete } from '@mui/icons-material';
import styled from 'styled-components';
import { OtherWages } from 'services/ManagerOneApi';
import { FormattedNumber, useIntl } from 'react-intl';
import { Column } from './Columns';
import { otherWagesTableMap } from './dataHelper';

const EmployeeNameCell: FC = ({ children }) => (
    <TableCell>{children}</TableCell>
);

export const EmployeeIdCell: FC = ({ children }) => (
    <TableCell>{children}</TableCell>
);

const PayAdjustmentCell: FC = ({ children }) => (
    <TableCell>{children}</TableCell>
);

export const TypeCell: FC = ({ children }) => <TableCell>{children}</TableCell>;

export const HoursCell: FC = ({ children }) => (
    <TableCell align="right">{children}</TableCell>
);

export const AmountCell: FC = ({ children }) => (
    <TableCell align="right">{children}</TableCell>
);

export const JobTitleCell: FC = ({ children }) => (
    <TableCell>{children}</TableCell>
);

const StyledActionCell = styled(TableCell)`
    padding: 0 !important;
`;

export const ActionCell: FC = ({ children }) => (
    <StyledActionCell>{children}</StyledActionCell>
);

type ColumnCells = {
    [key in Column['field']]: FC;
};

export const columnCells: ColumnCells = {
    EmployeeName: EmployeeNameCell,
    EmployeeId: EmployeeIdCell,
    PayAdjustment: PayAdjustmentCell,
    Type: TypeCell,
    Hours: HoursCell,
    Amount: AmountCell,
    JobTitle: JobTitleCell,
};

const EmployeeName: FC<Pick<OtherWages, 'Employee'>> = (props) => (
    <EmployeeNameCell>
        {otherWagesTableMap.EmployeeName(props)}
    </EmployeeNameCell>
);

const EmployeeId: FC<Pick<OtherWages, 'Employee'>> = (props) => (
    <EmployeeIdCell>{otherWagesTableMap.EmployeeId(props)}</EmployeeIdCell>
);

const PayAdjustmentBodyCell: FC<Pick<OtherWages, 'PayAdjustment'>> = (
    props
) => (
    <PayAdjustmentCell>
        {otherWagesTableMap.PayAdjustment(props)}
    </PayAdjustmentCell>
);

const Type: FC<Pick<OtherWages, 'SystemGenerated'>> = (props) => (
    <TypeCell>{otherWagesTableMap.Type(props)}</TypeCell>
);

const HoursBodyCell: FC<Pick<OtherWages, 'Hours'>> = (props) => (
    <HoursCell>
        <FormattedNumber
            value={otherWagesTableMap.Hours(props) as number}
            maximumFractionDigits={2}
        />
    </HoursCell>
);

const AmountBodyCell: FC<Pick<OtherWages, 'Amount'>> = (props) => (
    <AmountCell>
        <FormattedNumber
            value={otherWagesTableMap.Amount(props) as number}
            style="currency"
            currency="USD"
        />
    </AmountCell>
);

const JobTitle: FC<Pick<OtherWages, 'Job'>> = (props) => (
    <JobTitleCell>{otherWagesTableMap.JobTitle(props)}</JobTitleCell>
);

/**
 * TODO: make the props required once the click handlers are implemented
 */
type ActionsProps = Partial<{
    editClick: IconButtonProps['onClick'];
    deleteClick: IconButtonProps['onClick'];
}>;

export const ActionBodyCell: FC<ActionsProps> = ({
    editClick,
    deleteClick,
}) => {
    const { formatMessage } = useIntl();
    const titleEditShift = formatMessage({ id: 'App.Containers.Punch.Edit' });
    const titleDeleteShift = formatMessage({
        id: 'App.Containers.Punch.Delete',
    });

    return (
        <>
            <Tooltip title={titleEditShift}>
                <IconButton onClick={editClick} style={{ padding: '0 8px' }}>
                    <Create />
                </IconButton>
            </Tooltip>
            <Tooltip title={titleDeleteShift}>
                <IconButton onClick={deleteClick} style={{ padding: '0 8px' }}>
                    <Delete />
                </IconButton>
            </Tooltip>
        </>
    );
};

type BodyCells = {
    [key in Column['field']]: FC<OtherWages>;
};

// Why are there so many components that only wrap data in Cell component, and do nothing else?
// It's very redundant, isn't it?
// Well, the reason is that this makes styling the cells a lot easier in the long term, if we choose to do so.
export const bodyCells: BodyCells = {
    EmployeeName,
    EmployeeId,
    PayAdjustment: PayAdjustmentBodyCell,
    Type,
    Hours: HoursBodyCell,
    Amount: AmountBodyCell,
    JobTitle,
};
