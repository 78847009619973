import { MenuItem } from './mockedData';

export const mockedMenuItems: MenuItem[] = [
    {
        name: 'Chicken Ramen',
        defaultPrice: 13.95,
        submenu: 'Ramen',
        retailCategory: 'Category',
        longName: 'JINYA Chicken Ramen',
        shortName: 'Jimmy Buffets',
        chitName: 'CPB',
        onlineName: 'Cheeseburger in Paradise',
        sku: 123456789,
        onlineDescription:
            'chicken broth: chicken chashu, spinach, green onion, fried onion \u00BBserved with thin noodles',
        receipeText:
            'chicken broth: chicken chashu, spinach, green onion, fried onion \u00BBserved with thin noodles',
        combinePriceWithParent: false,
        allowSitesToChangePrice: false,
        printOnCheckWhenPriceIsZero: false,
        printerGroupRouting: 'Kitch',
        videoGroupRouting: 'Prep',
        modifierGroups: [
            {
                modifierId: 70125,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Bamboo Shoot',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70448,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Bean Sprouts',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70129,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Bok Choy',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70135,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Broccoli',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70662,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.5,
                caloricValue: 0,
                Name: 'Broccolini',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70123,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.95,
                caloricValue: 0,
                Name: 'Brussels Sprouts',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70149,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Butter',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70145,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Cabbage',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70131,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Chicken Chashu',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70127,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Chicken Soboro',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70450,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Cilantro',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70137,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Corn',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70154,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 0.0,
                caloricValue: 0,
                Name: 'Fresh Garlic',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70146,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Fried Onion',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70148,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Green Onion',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 71091,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.0,
                caloricValue: 0,
                Name: 'Impossible Meat Patty 1pc',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70136,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.5,
                caloricValue: 0,
                Name: 'Kikurage',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70130,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Mushroom',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70138,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Nori Dried Seaweed',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70133,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Pork Chashu',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70126,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Pork Soboro (spicy ground pork)',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70142,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.75,
                caloricValue: 0,
                Name: 'Seasoned Egg',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70144,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.75,
                caloricValue: 0,
                Name: 'Spicy Bean Sprouts',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70140,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Spinach',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70139,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.5,
                caloricValue: 0,
                Name: 'Tofu',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70132,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.95,
                caloricValue: 0,
                Name: 'Wonton (Chicken)',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70805,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 4.95,
                caloricValue: 0,
                Name: 'Wonton (Shrimp)',
                Description: '',
                IsDefault: false,
            },
        ],
        backgroundColor: '#12FA12',
        salesRetailCategory: 'Food',
    },
    {
        name: 'Beef Ramen',
        defaultPrice: 14.55,
        submenu: 'Ramen',
        retailCategory: 'Category',
        longName: 'JINYA Beef Ramen',
        shortName: 'Beef Ramen',
        chitName: 'BRM',
        onlineName: 'Beef Ramen',
        sku: 2222222222,
        onlineDescription:
            'beef broth: beef chashu, spinach, green onion, fried onion \u00BBserved with thin noodles',
        receipeText:
            'beef broth: beef chashu, spinach, green onion, fried onion \u00BBserved with thin noodles',
        combinePriceWithParent: false,
        allowSitesToChangePrice: false,
        printOnCheckWhenPriceIsZero: false,
        printerGroupRouting: 'Kitch',
        videoGroupRouting: 'Prep',
        modifierGroups: [
            {
                modifierId: 70125,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Bamboo Shoot',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70448,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Bean Sprouts',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70129,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Bok Choy',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70135,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Broccoli',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70662,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.5,
                caloricValue: 0,
                Name: 'Broccolini',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70123,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.95,
                caloricValue: 0,
                Name: 'Brussels Sprouts',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70149,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Butter',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70145,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Cabbage',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70131,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Chicken Chashu',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70127,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Chicken Soboro',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70450,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Cilantro',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70137,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Corn',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70154,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 0.0,
                caloricValue: 0,
                Name: 'Fresh Garlic',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70146,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Fried Onion',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70148,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Green Onion',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 71091,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.0,
                caloricValue: 0,
                Name: 'Impossible Meat Patty 1pc',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70136,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.5,
                caloricValue: 0,
                Name: 'Kikurage',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70130,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Mushroom',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70138,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Nori Dried Seaweed',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70133,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Pork Chashu',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70126,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Pork Soboro (spicy ground pork)',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70142,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.75,
                caloricValue: 0,
                Name: 'Seasoned Egg',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70144,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.75,
                caloricValue: 0,
                Name: 'Spicy Bean Sprouts',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70140,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Spinach',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70139,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.5,
                caloricValue: 0,
                Name: 'Tofu',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70132,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.95,
                caloricValue: 0,
                Name: 'Wonton (Chicken)',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70805,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 4.95,
                caloricValue: 0,
                Name: 'Wonton (Shrimp)',
                Description: '',
                IsDefault: false,
            },
        ],
        backgroundColor: '#12FA12',
        salesRetailCategory: 'Food',
    },
    {
        name: 'Spicy Ramen',
        defaultPrice: 13.95,
        submenu: 'Ramen',
        retailCategory: 'Category',
        longName: 'JINYA Spicy Ramen',
        shortName: 'Spicy Ramen',
        chitName: 'SRM',
        onlineName: 'Spicy Ramen',
        sku: 3333333333,
        onlineDescription:
            'chicken broth, chicken chashu, spinach, spicy bean sprouts, green onion served with thin noodles Choose your spice level, mild, spicy or hot.',
        receipeText:
            'chicken broth, chicken chashu, spinach, spicy bean sprouts, green onion served with thin noodles Choose your spice level, mild, spicy or hot.',
        combinePriceWithParent: false,
        allowSitesToChangePrice: false,
        printOnCheckWhenPriceIsZero: false,
        printerGroupRouting: 'Kitch',
        videoGroupRouting: 'Prep',
        modifierGroups: [
            {
                modifierId: 70125,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Bamboo Shoot',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70448,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Bean Sprouts',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70129,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Bok Choy',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70135,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Broccoli',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70662,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.5,
                caloricValue: 0,
                Name: 'Broccolini',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70123,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.95,
                caloricValue: 0,
                Name: 'Brussels Sprouts',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70149,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Butter',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70145,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Cabbage',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70131,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Chicken Chashu',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70127,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Chicken Soboro',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70450,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Cilantro',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70137,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Corn',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70154,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 0.0,
                caloricValue: 0,
                Name: 'Fresh Garlic',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70146,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Fried Onion',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70148,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Green Onion',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 71091,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.0,
                caloricValue: 0,
                Name: 'Impossible Meat Patty 1pc',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70136,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.5,
                caloricValue: 0,
                Name: 'Kikurage',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70130,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Mushroom',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70138,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Nori Dried Seaweed',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70133,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Pork Chashu',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70126,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Pork Soboro (spicy ground pork)',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70142,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.75,
                caloricValue: 0,
                Name: 'Seasoned Egg',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70144,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.75,
                caloricValue: 0,
                Name: 'Spicy Bean Sprouts',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70140,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Spinach',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70139,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.5,
                caloricValue: 0,
                Name: 'Tofu',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70132,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.95,
                caloricValue: 0,
                Name: 'Wonton (Chicken)',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70805,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 4.95,
                caloricValue: 0,
                Name: 'Wonton (Shrimp)',
                Description: '',
                IsDefault: false,
            },
        ],
        backgroundColor: '#12FA12',
        salesRetailCategory: 'Food',
    },
    {
        name: 'Flying Vegan Harvest Ramen',
        defaultPrice: 13.95,
        submenu: 'Ramen',
        retailCategory: 'Category',
        longName: 'Flying Vegan Harvest Ramen',
        shortName: 'Vegan Ramen',
        chitName: 'VRM',
        onlineName: 'Flying Vegan Harvest Ramen',
        sku: 4444444444,
        onlineDescription:
            'vegan miso broth: Impossible™ meat made from plants, tofu, bean sprouts, broccolini, green onion, corn, red onion, crispy garlic, chili seasoning &gt;&gt;served with thick noodles',
        receipeText:
            'vegan miso broth: Impossible™ meat made from plants, tofu, bean sprouts, broccolini, green onion, corn, red onion, crispy garlic, chili seasoning &gt;&gt;served with thick noodles',
        combinePriceWithParent: false,
        allowSitesToChangePrice: false,
        printOnCheckWhenPriceIsZero: false,
        printerGroupRouting: 'Kitch',
        videoGroupRouting: 'Prep',
        modifierGroups: [
            {
                modifierId: 70125,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Bamboo Shoot',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70448,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Bean Sprouts',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70129,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Bok Choy',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70135,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Broccoli',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70662,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.5,
                caloricValue: 0,
                Name: 'Broccolini',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70123,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.95,
                caloricValue: 0,
                Name: 'Brussels Sprouts',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70149,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Butter',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70145,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Cabbage',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70131,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Chicken Chashu',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70127,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Chicken Soboro',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70450,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Cilantro',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70137,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Corn',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70154,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 0.0,
                caloricValue: 0,
                Name: 'Fresh Garlic',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70146,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Fried Onion',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70148,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Green Onion',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 71091,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.0,
                caloricValue: 0,
                Name: 'Impossible Meat Patty 1pc',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70136,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.5,
                caloricValue: 0,
                Name: 'Kikurage',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70130,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Mushroom',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70138,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Nori Dried Seaweed',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70133,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Pork Chashu',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70126,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Pork Soboro (spicy ground pork)',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70142,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.75,
                caloricValue: 0,
                Name: 'Seasoned Egg',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70144,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.75,
                caloricValue: 0,
                Name: 'Spicy Bean Sprouts',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70140,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Spinach',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70139,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.5,
                caloricValue: 0,
                Name: 'Tofu',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70132,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.95,
                caloricValue: 0,
                Name: 'Wonton (Chicken)',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70805,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 4.95,
                caloricValue: 0,
                Name: 'Wonton (Shrimp)',
                Description: '',
                IsDefault: false,
            },
        ],
        backgroundColor: '#12FA12',
        salesRetailCategory: 'Food',
    },
    {
        name: 'Gluten Free Ramen',
        defaultPrice: 14.95,
        submenu: 'Ramen',
        retailCategory: 'Category',
        longName: 'Gluten Free Ramen',
        shortName: 'Gluten Free Ramen',
        chitName: 'GFRM',
        onlineName: 'Gluten Free Ramen',
        sku: 5555555555,
        onlineDescription:
            'Choose one broth from tonkotsu, chicken or vegan. Choose one protein from tofu, chicken chashu or plant-based meat. Choose two toppings from kikurage, spinach, corn, mushroom, broccoli or bok choy. Served with gluten-free noodles and green onions.  (Gluten-free noodles are cooked in the same noodle cooker as regular/non gluten-free noodles. This gluten-free noodle is not an option if you have gluten allergies.)',
        receipeText:
            'Choose one broth from tonkotsu, chicken or vegan. Choose one protein from tofu, chicken chashu or plant-based meat. Choose two toppings from kikurage, spinach, corn, mushroom, broccoli or bok choy. Served with gluten-free noodles and green onions.  (Gluten-free noodles are cooked in the same noodle cooker as regular/non gluten-free noodles. This gluten-free noodle is not an option if you have gluten allergies.)',
        combinePriceWithParent: false,
        allowSitesToChangePrice: false,
        printOnCheckWhenPriceIsZero: false,
        printerGroupRouting: 'Kitch',
        videoGroupRouting: 'Prep',
        modifierGroups: [
            {
                modifierId: 70125,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Bamboo Shoot',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70448,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Bean Sprouts',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70129,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Bok Choy',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70135,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Broccoli',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70662,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.5,
                caloricValue: 0,
                Name: 'Broccolini',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70123,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.95,
                caloricValue: 0,
                Name: 'Brussels Sprouts',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70149,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Butter',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70145,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Cabbage',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70131,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Chicken Chashu',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70127,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Chicken Soboro',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70450,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Cilantro',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70137,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Corn',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70154,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 0.0,
                caloricValue: 0,
                Name: 'Fresh Garlic',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70146,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Fried Onion',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70148,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.0,
                caloricValue: 0,
                Name: 'Green Onion',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 71091,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.0,
                caloricValue: 0,
                Name: 'Impossible Meat Patty 1pc',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70136,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.5,
                caloricValue: 0,
                Name: 'Kikurage',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70130,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.0,
                caloricValue: 0,
                Name: 'Mushroom',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70138,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Nori Dried Seaweed',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70133,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Pork Chashu',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70126,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.5,
                caloricValue: 0,
                Name: 'Pork Soboro (spicy ground pork)',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70142,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.75,
                caloricValue: 0,
                Name: 'Seasoned Egg',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70144,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.75,
                caloricValue: 0,
                Name: 'Spicy Bean Sprouts',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70140,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 1.5,
                caloricValue: 0,
                Name: 'Spinach',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70139,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 2.5,
                caloricValue: 0,
                Name: 'Tofu',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70132,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 3.95,
                caloricValue: 0,
                Name: 'Wonton (Chicken)',
                Description: '',
                IsDefault: false,
            },
            {
                modifierId: 70805,
                modifierGroupId: 8256,
                modifierAction: 0,
                weight: 1,
                price: 4.95,
                caloricValue: 0,
                Name: 'Wonton (Shrimp)',
                Description: '',
                IsDefault: false,
            },
        ],
        backgroundColor: '#12FA12',
        salesRetailCategory: 'Food',
    },
    {
        name: 'Crispy Chicken (10 pcs)',
        defaultPrice: 13.5,
        submenu: 'Small Plates',
        retailCategory: 'Category',
        longName: 'Crispy Chicken (10 pcs)',
        shortName: 'Crispy Chicken',
        chitName: 'CSCK',
        onlineName: 'Crispy Chicken (10 pcs)',
        sku: 6666666666,
        onlineDescription:
            'juicy fried chicken thigh with an original garlic pepper served with mixed baby greens and JINYA’s original ponzu sauce',
        receipeText:
            'juicy fried chicken thigh with an original garlic pepper served with mixed baby greens and JINYA’s original ponzu sauce',
        combinePriceWithParent: false,
        allowSitesToChangePrice: false,
        printOnCheckWhenPriceIsZero: false,
        printerGroupRouting: 'Kitch',
        videoGroupRouting: 'Prep',
        modifierGroups: [],
        backgroundColor: '#12FA12',
        salesRetailCategory: 'Food',
    },
    {
        name: 'Caramelized Cauliflower',
        defaultPrice: 7.95,
        submenu: 'Small Plates',
        retailCategory: 'Category',
        longName: 'Caramelized Cauliflower',
        shortName: 'Caramelized Cauliflower',
        chitName: 'CCF',
        onlineName: 'Caramelized Cauliflower',
        sku: 7777777777,
        onlineDescription:
            'caramelized cauliflower with toasted pine nuts, crispy mint leaves, and lime sauce',
        receipeText:
            'caramelized cauliflower with toasted pine nuts, crispy mint leaves, and lime sauce',
        combinePriceWithParent: false,
        allowSitesToChangePrice: false,
        printOnCheckWhenPriceIsZero: false,
        printerGroupRouting: 'Kitch',
        videoGroupRouting: 'Prep',
        modifierGroups: [],
        backgroundColor: '#12FA12',
        salesRetailCategory: 'Food',
    },
];
