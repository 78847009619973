/* eslint-disable jsx-a11y/media-has-caption */
import { useState, FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowTrainingVideoModal } from 'state/commonSlice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import MainButton from 'components/MainButton';
import { RootState } from 'state/reducers';
import config from 'appConfig';

const TrainingVideo: FC = () => {
    const videoRef = useRef();
    const dispatch = useDispatch();

    const open = useSelector(
        (state: RootState) => state.common.showTrainingVideoModal
    );
    const videoSrc = `${config.applicationUrl}/videos`;
    const gettingStartedSource = `${videoSrc}/getting-started.mp4`;
    const functionsSource = `${videoSrc}/functions.mp4`;
    const [currentView, setCurrentView] = useState<
        'gettingStartedView' | 'functionsView'
    >('gettingStartedView');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        dispatch(setShowTrainingVideoModal(false));
    };

    const getVideoSource = () => {
        if (currentView === 'gettingStartedView') return gettingStartedSource;
        return functionsSource;
    };

    const getTitle = () => {
        if (currentView === 'gettingStartedView')
            return (
                <FormattedMessage id="App.Components.TrainingVideo.GettingStarted" />
            );
        return <FormattedMessage id="App.Components.TrainingVideo.Functions" />;
    };

    useEffect(() => {
        if (videoRef.current) {
            // @ts-ignore
            videoRef.current.load();
        }
    }, [currentView, open]);

    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{getTitle()}</DialogTitle>
            <DialogContent sx={{ width: ['100%', '100%', 800, 1000] }}>
                {/* @ts-ignore */}
                <video ref={videoRef} width="100%" height="100%" controls>
                    <source
                        key={getVideoSource()}
                        src={getVideoSource()}
                        type="video/mp4"
                    />
                    <FormattedMessage id="App.Components.TrainingVideo.VideoError" />
                </video>
            </DialogContent>
            <DialogActions sx={{ textTransform: 'uppercase' }}>
                <MainButton
                    dataTestId="video-ok-button-test-id"
                    onClick={handleClose}
                    variant="text"
                >
                    <FormattedMessage id="App.Components.TrainingVideo.Ok" />
                </MainButton>
                {currentView === 'gettingStartedView' && (
                    <MainButton
                        variant="text"
                        sx={{ textTransform: 'uppercase' }}
                        dataTestId="video-next-button-test-id"
                        onClick={() => setCurrentView('functionsView')}
                    >
                        <FormattedMessage id="App.Components.TrainingVideo.NextVideo" />
                    </MainButton>
                )}
                {currentView === 'functionsView' && (
                    <MainButton
                        variant="text"
                        onClick={() => setCurrentView('gettingStartedView')}
                        dataTestId="video-previous-button-test-id"
                        sx={{ textTransform: 'uppercase' }}
                    >
                        <FormattedMessage id="App.Components.TrainingVideo.PreviousVideo" />
                    </MainButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default TrainingVideo;
