import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BusinessDayParams, getBusinessDayRange } from 'services/api';
import { BusinessDayRangeResponse } from '../services/ManagerOneApi';
import { RootState } from './reducers';

type InitialState = Record<string, never>;

const initialState: InitialState = {};

export enum BusinessDayType {
    Sale = 'sale',
    Control = 'control',
    Labor = 'labor',
    Configuration = 'configuration',
}

/**
 * TODO: when implementing caching for businessDays or Punch Editor data, decide whether to:
 *      - delete this file, since this thunk isn't actually storing anything in redux.
 *        This means that we would need to fetch request directly from component
 *      - or refactor this thunk to use redux toolkit's `createApi` function
 */
export const getBusinessDayRangeThunk = createAsyncThunk<
    BusinessDayRangeResponse,
    BusinessDayParams,
    { state: RootState }
>('businessDay/range', getBusinessDayRange);

const businessDaySlice = createSlice({
    name: 'businessDay',
    initialState,
    reducers: { purge: () => initialState },
});

export const { purge } = businessDaySlice.actions;

export default businessDaySlice;
