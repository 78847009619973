import { rawIntl } from 'services/intl';
import { OtherWages } from 'services/ManagerOneApi';
import { Column } from './Columns';

type OtherWagesTableAccessor = {
    [key in Column['field']]: (wages: OtherWages) => string | number | boolean;
};

export const otherWagesTableMap: OtherWagesTableAccessor = {
    EmployeeName: (w) => w.Employee?.Name || '',
    EmployeeId: (w) => w.Employee?.EmployeeId || '',
    PayAdjustment: (w) => w.PayAdjustment?.Name || '',
    Type: (w) =>
        rawIntl.formatMessage({
            id: w.SystemGenerated
                ? 'App.Containers.Punch.OtherWage.SystemType'
                : 'App.Containers.Punch.OtherWage.UserType',
        }),
    Hours: (s) => s.Hours || 0,
    Amount: (s) => s.Amount || 0,
    JobTitle: (w) => w.Job?.JobTitle || '',
};

// boolean and Date are automatically coerced by js for their respective comparisons, so no worries with that
export const wagesAreDesc = (
    first: OtherWages,
    second: OtherWages,
    sortBy: Column['field']
): boolean => {
    const getFieldValue = otherWagesTableMap[sortBy];
    const a = getFieldValue(first);
    const b = getFieldValue(second);
    return a < b;
};

export const filterWages =
    (target: string) =>
    (wage: OtherWages): boolean => {
        if (!target) {
            return true;
        }
        const found = Object.values(otherWagesTableMap).some((accessor) => {
            const cellContent = `${accessor(wage)}`
                .replace(/\s\s+/g, ' ') // removes extra spaces between words
                .toLowerCase();
            const targetFound = cellContent.includes(target.toLowerCase());
            return targetFound;
        });
        return found;
    };
