/* eslint-disable @typescript-eslint/no-explicit-any */
import { axiosError, axiosSuccess } from 'services/axios';
import { store } from 'store/createStore';
import { setLoading } from 'state/commonSlice';
import { UncapitalizeType } from 'services/utilTypes';
import { MenuItem } from 'commonEditsMockedData/mockedData';
import { ManagerOne } from '../ManagerOneApi/ManagerOne.API';
import { getMockedDataForUser } from './mockedDataHelper';

import * as ManagerOneApi from '../ManagerOneApi';

let axiosErrorResponse: (error: any) => any;
let axiosSuccessResponse: (response: any) => any;

export const setupMockedInterceptors = (): void => {
    axiosErrorResponse = () => {
        const mockedError = {
            response: {
                status: 500,
            },
        };
        return axiosError(mockedError);
    };
    axiosSuccessResponse = (response: any) => {
        const mockedResponse = {
            data: { ...response },
        };
        return axiosSuccess(mockedResponse);
    };
};

export const sleep = (ms = 500): Promise<unknown> =>
    new Promise((resolve) =>
        setTimeout(resolve, process.env.NODE_ENV === 'test' ? 50 : ms)
    );

const getMockedResponse = async (username = '') => {
    store.dispatch(setLoading(true));
    await sleep();
    store.dispatch(setLoading(false));
    return getMockedDataForUser(username);
};

export const logIn = async (
    username: string,
    password: string,
    _organization: string
): Promise<ManagerOneApi.ManagerOneLoginResponse> => {
    const userDetails = await getMockedResponse(username);

    if (userDetails.password !== password) {
        return axiosErrorResponse('error');
    }

    return axiosSuccessResponse(
        new ManagerOneApi.ManagerOneLoginResponse({ LoggedIn: true })
    );
};

export const getDashboardData = async (
    SiteId: string
): Promise<ManagerOneApi.GetDashboardResponse> => {
    const res = await getMockedResponse();

    const siteData = res.dashboardData.find(
        (item) => item.siteId === SiteId
    )?.data;

    if (siteData === undefined) return new ManagerOneApi.GetDashboardResponse();

    return siteData;
};

type GetDepositsType = Omit<
    ManagerOneApi.GetDeposits,
    keyof ManagerOneApi.RequestBase
>;
type GetDepositsData = UncapitalizeType<GetDepositsType>;
export const getDepositsRequest = async (
    _data: GetDepositsData
): Promise<ManagerOneApi.GetDepositsResponse> => {
    const res = await getMockedResponse();

    const { depositsData } = res;

    if (depositsData === undefined)
        return new ManagerOneApi.GetDepositsResponse();

    return depositsData;
};

type EditDepositType = Omit<
    ManagerOneApi.EditDeposit,
    keyof ManagerOneApi.RequestBase
>;
type EditDepositData = UncapitalizeType<EditDepositType>;
export const editDepositRequest = async (
    _data: EditDepositData
): Promise<ManagerOneApi.Deposit> => {
    await sleep();

    return new ManagerOneApi.EditDepositResponse().Deposit;
};

type GetCopyScheduleRequest = Omit<
    ManagerOneApi.CopySchedule,
    keyof ManagerOneApi.RequestBase
>;
export const doCopy = async (
    _data: GetCopyScheduleRequest
): Promise<ManagerOneApi.CopyScheduleResponse> => {
    await sleep();
    return { Conflict: true };
};

type CreateDepositType = Omit<
    ManagerOneApi.CreateDeposit,
    keyof ManagerOneApi.RequestBase
>;
type CreateDepositData = UncapitalizeType<CreateDepositType>;
export const addDepositRequest = async (
    _data: CreateDepositData
): Promise<ManagerOneApi.Deposit> => {
    await sleep();

    return new ManagerOneApi.CreateDepositResponse().Deposit;
};

type DeleteDepositType = Omit<
    ManagerOneApi.DeleteDeposit,
    keyof ManagerOneApi.RequestBase
>;
type DeleteDepositData = UncapitalizeType<DeleteDepositType>;
export const deleteDepositRequest = async (
    _data: DeleteDepositData
): Promise<void> => {
    await sleep();
};

export const daysToBackEditDeposits = async <
    Data extends Record<string, unknown>
>(
    _data: Data
): Promise<ManagerOneApi.GetDaysToBackEditResponse['DaysToBackEdit']> => {
    await sleep();

    return new ManagerOneApi.GetDaysToBackEditResponse({ DaysToBackEdit: 0 })
        .DaysToBackEdit;
};

export const getSites = async (): Promise<ManagerOneApi.GetSitesResponse> => {
    const res = await getMockedResponse();
    return res.sitesResponse;
};

export const getScheduleData = async (
    _siteId: string,
    _selectedBusinessDay: string
): Promise<ManagerOneApi.GetScheduleDataResponse> => {
    const res = await getMockedResponse();
    return res.scheduleResponse;
};

type SaveScheduleType = Omit<
    ManagerOneApi.SaveSchedule,
    keyof ManagerOneApi.RequestBase
>;
type SaveScheduleData = UncapitalizeType<SaveScheduleType>;
export const saveSchedule = async (
    _data: SaveScheduleData
): Promise<ManagerOneApi.SaveScheduleResponse> => {
    await sleep();

    return new ManagerOneApi.SaveScheduleResponse();
};

export const fetchUserInfo = async (): Promise<
    ManagerOne.API.User.UserRoles[]
> => {
    const res = await getMockedResponse();
    return res.userDetails;
};

export const recoverPassword =
    async (): Promise<ManagerOneApi.RecoverPasswordResponse> =>
        axiosErrorResponse('hello');

export const changePassword = async (
    password: string
): Promise<ManagerOneApi.ChangePasswordResponse> => {
    let result: ManagerOneApi.ChangePasswordResponse['Result'] = 0;

    // Success = 0,
    // InvalidLength = 1,
    // InvalidStrength = 2,
    // PasswordMatches = 3,
    // SomethingWentWrong = 4,

    if (password === 'AlohaOne9!!') result = 0;
    else if (password === 'AlohaOne9!!InvalidLength') result = 1;
    else if (password === 'AlohaOne9!!InvalidStrength') result = 2;
    else if (password === 'AlohaOne9!!PasswordMatches') result = 3;
    else if (password === 'AlohaOne9!!SomethingWentWrong') result = 4;

    return new ManagerOneApi.ChangePasswordResponse({
        Result: result,
    });
};

export const getMenuItems = async (): Promise<MenuItem[]> => {
    const res = await getMockedResponse();
    return res.getMenuItemsResponse;
};
