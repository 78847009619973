/* eslint-disable react/style-prop-object */
import { FC, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { GetDashboardResponse } from 'services/ManagerOneApi';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { DashboardCard, IDashboardData } from '../index';

const DailySalesCard: FC<IDashboardData> = ({ dashboardData }): JSX.Element => {
    const theme = useTheme();
    const [netSalesTotal, setNetSalesTotal] = useState<number>(0.0);
    const [guestAverage, setGuestAverage] = useState<number>(0.0);
    const [checkAverage, setCheckAverage] = useState<number>(0.0);
    const [totalChecks, setTotalChecks] = useState<number>(0);
    const [totalGuests, setTotalGuests] = useState<number>(0);
    const [isBusinessDay, setBusinessDay] = useState<boolean>(false);
    const [businessDate, setBusinessDate] = useState<string>('1.1.1111');
    const loading = useSelector((s: RootState) => s.common.loading);

    const setNoData = () => {
        setNetSalesTotal(0.0);
        setCheckAverage(0.0);
        setGuestAverage(0.0);
        setTotalChecks(0);
        setTotalGuests(0);
        setBusinessDay(false);
    };

    const updateData = (data: GetDashboardResponse) => {
        let netSales = 0.0;

        try {
            if (data.NetSales && data.NetSales.BusinessDay) {
                if (data.NetSales.NetSales > 0) {
                    netSales = data.NetSales.NetSales;
                    setNetSalesTotal(data.NetSales.NetSales);
                    setCheckAverage(data.NetSales.CheckAvgSales);
                    setGuestAverage(data.NetSales.GuestAvgSales);
                    setTotalChecks(data.NetSales.TotalChecks);
                    setTotalGuests(data.NetSales.TotalGuests);
                    setBusinessDate(data.NetSales.BusinessDay);
                    setBusinessDay(true);
                } else {
                    setBusinessDate(data.NetSales.BusinessDay);
                    setBusinessDay(true);
                    setNoData();
                }
            } else {
                setNoData();
            }
        } catch (e) {
            setNoData();
        } finally {
            if (netSales === 0.0) {
                setNoData();
            }
        }
    };

    useEffect(() => {
        updateData(dashboardData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardData]);

    return (
        <DashboardCard>
            {!loading && (
                <CardContent>
                    <Typography
                        fontWeight="500"
                        component="div"
                        fontSize="18px"
                        marginBottom="25px"
                    >
                        <FormattedMessage id="App.Components.DailySales.Title" />
                    </Typography>
                    <Typography
                        component="div"
                        fontWeight="400"
                        fontSize="18px"
                        id="daily-sales-total"
                    >
                        <FormattedMessage id="App.Components.DailySales.NetSales" />
                        <FormattedNumber
                            value={netSalesTotal}
                            style="currency"
                            currency="USD"
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                        />
                    </Typography>
                    {isBusinessDay ? (
                        <Typography
                            component="div"
                            variant="caption"
                            color={theme.palette.text.disabled}
                        >
                            <FormattedMessage id="App.Components.DailySales.BusinessDay" />
                            <FormattedDate
                                value={businessDate.slice(
                                    0,
                                    businessDate.indexOf('T')
                                )}
                            />
                        </Typography>
                    ) : (
                        <Typography component="div">
                            <FormattedDate
                                value={businessDate.slice(
                                    0,
                                    businessDate.indexOf('T')
                                )}
                            />
                        </Typography>
                    )}
                    <Typography
                        component="div"
                        fontWeight="400"
                        fontSize="18px"
                        mt={2}
                    >
                        <FormattedMessage id="App.Components.DailySales.GuestAvg" />{' '}
                        <FormattedNumber
                            value={guestAverage}
                            style="currency"
                            currency="USD"
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                        />
                    </Typography>
                    <Typography
                        component="div"
                        variant="caption"
                        color={theme.palette.text.disabled}
                    >
                        <FormattedNumber value={totalGuests} />{' '}
                        <FormattedMessage id="App.Components.DailySales.Guests" />
                    </Typography>
                    <Typography
                        component="div"
                        fontWeight="400"
                        fontSize="18px"
                        mt={2}
                    >
                        <FormattedMessage id="App.Components.DailySales.CheckAvg" />
                        <FormattedNumber
                            value={checkAverage}
                            style="currency"
                            currency="USD"
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                        />
                    </Typography>
                    <Typography
                        component="div"
                        variant="caption"
                        color={theme.palette.text.disabled}
                    >
                        <FormattedNumber value={totalChecks} />{' '}
                        <FormattedMessage id="App.Components.DailySales.Checks" />
                    </Typography>
                </CardContent>
            )}
        </DashboardCard>
    );
};

export default DailySalesCard;
